import moment from 'moment';

import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, DateSelectContainer } from '../../index';

export default class TerminatedEmployee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props
        };
    }

    stateChangeComplete() {
        this.props.onChange(this.state);
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.stateChangeComplete);
    }

    onChildUpdate(index, child) {
        let {children} = this.state;
        children = children.filter(c => c.index !== index);
        children.push(child);
        this.setState({children}, this.stateChangeComplete);
    }

    onSpouseUpdate(spouse) {
        this.setState({spouse}, this.stateChangeComplete)
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div className='terminated-employee'>
                <div className='row'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={this.state.first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        text={this.state.last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='dob'
                        label="Date of Birth"
                        className='dob'
                        ref='dob'
                        type='text'
                        text={this.state.dob}
                        error={missingFields.indexOf('dob') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                    />
                    <TextFieldContainer
                        key='ssn'
                        label="Social Security Number"
                        className='ssn'
                        ref='ssn'
                        type='text'
                        text={this.state.ssn}
                        error={missingFields.indexOf('ssn') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                    />
                </div>
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='To best of your knowledge, will this employee exercise their COBRA option?'
                        options={[
                            {name: 'exercise_cobra', value: false, display: 'No', selected: (this.state.exercise_cobra !== undefined && !this.state.exercise_cobra)},
                            {name: 'exercise_cobra', value: true, display: 'Yes', selected: this.state.exercise_cobra}
                        ]}
                        error={missingFields.indexOf('exercise_cobra') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <DateSelectContainer
                        key='termination_date'
                        className='termination_date'
                        label={'What was the employee\'s termination date?'}
                        theme='full-width'
                        value={this.state.termination_date ? moment(this.state.termination_date) : null}
                        minDate={moment().subtract(3, 'months')}
                        startDate={moment().startOf('month')}
                        error={missingFields.indexOf('termination_date') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='qualifying_event'
                        label='What is the qualifying event that would cause terminated employees to enroll in COBRA?'
                        className='qualifying_event'
                        ref='qualifying_event'
                        theme='full-width'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Employment Termination', value: 'termination'},
                            {display: 'Disability', value: 'disability'},
                            {display: 'Reduction in Work Hours', value: 'work_hours_reduction'},
                            {display: 'Ex-Spouse of Employee', value: 'ex_spouse'},
                            {display: 'Surviving Dependent(s) of Employee', value: 'surviving_dependent'},
                            {display: 'Dependent Child\'s Age Exceeds Eligibility' , value: 'dependent_child_age_exceeds_eligibility'},

                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.qualifying_event === value}
                        })}
                    />
                </div>
                <div className='row'>
                    Type of coverage eligible for question - need more info
                </div>
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='Is this employee presently disabled?'
                        options={[
                            {name: 'disabled', value: false, display: 'No', selected: (this.state.disabled !== undefined && !this.state.disabled)},
                            {name: 'disabled', value: true, display: 'Yes', selected: this.state.disabled}
                        ]}
                        error={missingFields.indexOf('disabled') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.disabled &&
                    <div className='row'>
                        <TextFieldContainer
                            key='disabled_reason'
                            label="What's the disabling condition?"
                            className='disabled_reason'
                            ref='disabled_reason'
                            type='text'
                            text={this.state.disabled_reason}
                            error={missingFields.indexOf('disabled_reason') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
            </div>
        )
    }
}
