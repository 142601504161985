import React, { Component } from 'react';

import moment from 'moment';

import Config from '../../config';
import { validateFields } from '../../utils';

import {DropdownContainer, DateSelectContainer} from '../index';

import CoverageSelect from './CoverageSelect';
import RecipientSelect from './RecipientSelect';
import QleOeOption from './QLEOEOption';


import './Application.scss';


class StepQLEOE extends Component {
    constructor(props) {
        super(props);

        const {formData, transaction_type} = props;
        const {qle = {}, currentPlans = {}, selectedPlans = {}, dependents = {}} = formData;
        this.state = {
            isQLE: transaction_type === 'qualifying_life_event',
            qle, currentPlans, selectedPlans, dependents,
            missingFields: []
        }
    }

    onChange(key, value) {
        let {qle} = this.state;
        qle[key] = value;
        this.setState({qle}, () => this.props.onChange(this.state));
    }

    onPlanChange(coverage_option, plan) {
        let selectedPlans = this.state.selectedPlans;
        selectedPlans[coverage_option] = plan;
        this.setState({selectedPlans}, this.props.onChange(this.state));
    }

    onQleOeOptionChange(value, line_of_coverage) {
        let currentPlans = this.state.currentPlans;
        if (!value.open) {
            value = null;
        }
        currentPlans[line_of_coverage] = value;
        this.setState({currentPlans}, this.props.onChange(this.state));
    }

    onNoChangeToggle(checked, line_of_coverage) {
        const {currentPlans} = this.state;
        if (checked) {
            this.setState({noChanges: checked, selectedPlans: currentPlans});
        } else {
            this.setState({noChanges: checked, selectedPlans: {}});
        }
    }

    onRecipientChange(type, planType, key, value, remove=false) {
        let {dependents} = this.state;
        let plans = [];

        if (type === 'toggle') {
            plans = dependents[key][planType] || [];

            if (remove) {
                const idx = plans.indexOf(value);
                if (idx > -1) {
                    plans.splice(idx, 1);
                }
            } else {
                plans.push(value);
            }
            console.log(key, planType, plans);
            dependents[key][planType] = plans;

        } else if (type === 'change') {
            if (remove) {
                delete dependents[key];
            } else {
                if (!dependents[key]) {
                    plans = Object.keys(this.state[planType]);
                    dependents[key] = {
                        first_name: null,
                        [planType]: plans
                    };
                    console.log(key, planType, plans);
                }
                dependents[key].first_name = value;
            }
        }
        this.setState({dependents}, this.props.onChange(this.state));
    }

    validate() {
        const requiredFields = this.state.isQLE ? ['reason', 'event_date'] : [];
        const missingFields = validateFields(requiredFields, this.state.qle);

        let childrenValidated = true;
        let foundOpen = false;
        Config.REACT_APP_LINES_OF_COVERAGE.forEach((option, index) => {
            const ref = this['qleoe_option_' + option];
            if (ref && ref.state.open) {
                foundOpen = true;
                childrenValidated &= ref.validate();
            }
        });
        if (!foundOpen) {
            missingFields.push('qleoeoption');
        }
        this.setState({missingFields});

        return missingFields.length === 0 && childrenValidated;
    }

    _getCoverageOptions() {
        return this.props.application.carrier_form.eligibility_groups[0].coverage_options;
    }

    getQleOeOptions() {
        const {transaction_type} = this.props;
        const {selectedPlans, dependents, currentPlans, noChanges} = this.state;
        const coverage_options = this._getCoverageOptions();
        let options = [];
        Config.REACT_APP_LINES_OF_COVERAGE.forEach((option, index) => {
            const coverage_option = coverage_options.find(coverage_option => coverage_option.line_of_coverage === option);
            if (coverage_option) {
                const key = 'qleoe_option_' + option;

                let qleOeDiv = [];
                qleOeDiv.push(
                    <QleOeOption
                        ref={ref => this[key] = ref}
                        key={option}
                        index={index}
                        coverage_option={coverage_option}
                        carrier_name={coverage_option.carrier_name}
                        carrier_state={coverage_option.carrier_state}
                        selectedPlans={selectedPlans}
                        currentPlans={currentPlans}
                        dependents={dependents}
                        line_of_coverage={option}
                        transaction_type={transaction_type}
                        onChange={this.onQleOeOptionChange.bind(this)}
                        getCoverageOptions={this._getCoverageOptions.bind(this)}
                        onRecipientChange={(key, lineOfCoverage, remove) => {
                            this.onRecipientChange('change', 'currentPlans', key, lineOfCoverage, remove);
                        }}
                        toggleDependentCoverage={(key, lineOfCoverage, remove) => {
                            this.onRecipientChange('toggle', 'currentPlans', key, lineOfCoverage, remove);
                        }}
                    />
                );

                if (currentPlans[option]) {
                    const {line_of_coverage, carrier} = coverage_option;

                    const currentOptions = [];
                    let selectedPlan = null;
                    coverage_option.plans.forEach(plan => {
                        const {id, name, has_plan_details_file, waived} = plan;

                        let selected = false;
                        if (selectedPlans[line_of_coverage] && (selectedPlans[line_of_coverage].id === id || selectedPlans[line_of_coverage].plan_id === id)) {
                            selectedPlan = selectedPlans[line_of_coverage];
                            selected = true;

                            if (!selectedPlan.display) {
                                selectedPlan.display = name;
                            }
                        }
                        currentOptions.push({
                            display: name,
                            value: name.toLowerCase().replace(' ', '_'),
                            id,
                            selected,
                            waived,
                            has_plan_details_file,
                            carrier_name: carrier.name,
                            reason: selectedPlan ? selectedPlan.reason : null
                        });
                    });

                    qleOeDiv.push(
                        <div className='row margin-top'>
                            <div className='header16'>What changes would you like to make to your coverage?</div>
                        </div>
                    )
                    // TODOBT - CoverageSelect needs getPlanDetailsDownload in props
                    qleOeDiv.push(
                        <div key={'step_coverage_select_' + index}>
                            <CoverageSelect
                              line_of_coverage={line_of_coverage}
                              transaction_type={transaction_type}
                              options={currentOptions}
                              onChange={this.onPlanChange.bind(this)}
                              onNoChangeToggle={this.onNoChangeToggle.bind(this)}
                            />
                            {(selectedPlan && !selectedPlan.waived) &&
                                <RecipientSelect
                                    noChanges={noChanges}
                                    line_of_coverage={line_of_coverage}
                                    selectedPlanName={selectedPlan.display}
                                    dependents={this.state.dependents}
                                    onCoverageToggle={(key, lineOfCoverage, remove) => {
                                        this.onRecipientChange('toggle', 'selectedPlans', key, lineOfCoverage, remove);
                                    }}
                                    onChange={(key, lineOfCoverage, remove) => {
                                        this.onRecipientChange('change', 'selectedPlans', key, lineOfCoverage, remove);
                                    }}
                                />
                            }
                        </div>
                    );
                }

                options.push(qleOeDiv);
            }
        });
        return options;
    }

    render() {

        const {
            qle, isQLE, missingFields
        } = this.state;
        const {reason, event_date, discharge_date} = qle;

        return (
            <div>
                {isQLE &&
                <div>
                    <div className='main-text'>
                        <div className='name light'>Tell us about your Qualifying Life Event</div>
                    </div>
                    <div className='row'>
                        <div className='header16'>What is the reason you are requesting a change in coverage?</div>
                    </div>
                    <div className='row'>
                        <DropdownContainer
                            key='reason'
                            label="Reason"
                            className='reason'
                            ref='reason'
                            options={[
                                {display: 'Marriage', value: 'marriage', selected: reason === 'marriage'},
                                {display: 'Divorce', value: 'divorce', selected: reason === 'divorce'},
                                {display: 'Adoption', value: 'adoption', selected: reason === 'adoption'},
                                {display: 'Court Order', value: 'court_order', selected: reason === 'court_order'},
                                {display: 'Birth of a Child', value: 'child_birth', selected: reason === 'child_birth'},
                                {display: 'Dependent Lost Coverage', value: 'dependent_lost_coverage', selected: reason === 'dependent_lost_coverage'}
                            ]}
                            error={missingFields.indexOf('reason') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    {reason === 'child_birth' &&
                        <div className='row'>
                            <DateSelectContainer
                                key='discharge_date'
                                label="Date of Discharge from Hospital"
                                className='discharge_date'
                                ref='discharge_date'
                                type='text'
                                value={discharge_date ? moment(discharge_date) : null}
                                startDate={moment().startOf('month')}
                                minDate={moment().subtract(3, 'months')}
                                error={missingFields.indexOf('discharge_date') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                            />
                        </div>
                    }
                    {reason === 'dependent_lost_coverage' &&
                        <div className='row'>
                            Coverage Type here (TBD)
                        </div>
                    }
                    <div className='row'>
                        <DateSelectContainer
                            key='event_date'
                            label="Date of Event"
                            className='event_date'
                            ref='event_date'
                            type='text'
                            value={event_date ? moment(event_date) : null}
                            startDate={moment().startOf('month')}
                            minDate={moment().subtract(3, 'months')}
                            error={missingFields.indexOf('event_date') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                        />
                    </div>
                </div>
                }
                <div className='main-text'>
                    <div className='name light'>Tell us about the changes you would like made to your coverage</div>
                </div>
                <div className='row'>
                    <div className='header16'>What coverage are you currently enrolled in?</div>
                    {missingFields.indexOf('qleoeoption') > -1 && <div className='header16 error'>&nbsp;(Must select one) </div>}
                </div>
                <div className='eligibility-group'>{this.getQleOeOptions()}</div>
            </div>
        );
    }
}

export default StepQLEOE;
