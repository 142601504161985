import { connect } from 'react-redux';
import Application from './Application';

import {
    getApplication,
    submitEmployeeApplication,
    submitEmployerApplication,
    getCarriers
} from '../../actions/noyo';

import {
    setApplicationId,
    setApplicationData,
} from '../../actions/application';

const mapStateToProps = ({ User, Application }) => {
    const {
        id,
        data,
    } = Application;

    return {
        currentUser: User.currentUser,
        location: window.location,
        applicationId: id,
        application: data,
    }
};

const mapDispatchToProps = {
    getCarriers,
    getApplication,
    setApplicationId,
    setApplicationData,
    submitEmployeeApplication,
    submitEmployerApplication,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Application);
