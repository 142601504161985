import React, { Component } from 'react';

import { validateFields } from '../../../utils';

import {TextFieldContainer, DropdownContainer, UploadContainer} from '../../index';

import '../Application.scss';


export default class NameInformation extends Component {
    constructor(props) {
        super(props);

        const {name_info} = props.primary;
        this.state = {
            open: name_info.open || false,
            missingFields: [],
            new: name_info.new || {},
            current: name_info.current || {}
        }
    }

    componentWillReceiveProps(nextProps) {
        const {first_name, middle_name, last_name, suffix, ssn} = nextProps.primary;

        let current = this.state.current;
        current.first_name = first_name;
        current.middle_name = middle_name;
        current.last_name = last_name;
        current.suffix = suffix;
        current.ssn = ssn;

        this.setState({current});
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        });
    }

    onChange(key, value, type) {
        if (type) {
            let typeInfo = this.state[type];
            typeInfo[key] = value;
            this.setState({[type]: typeInfo}, this.props.onChange(this.state));
        } else {
            this.setState({[key]: value}, this.props.onChange(this.state));
        }
    }

    validate() {
        const requiredFields = [];
        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields, open} = this.state;
        return (
            <div>
                <div className={'link arrow' + (open ? ' open' : '')} onClick={this.toggleOpen.bind(this)}>
                    Change Personal Information
                </div>
                {open &&
                <div className='section-container'>
                    <div className='section'>
                        <div className='section__header'>Name</div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'first_name'}
                                    ref={'first_name'}
                                    text={this.state.new.first_name}
                                    label={'First Name'}
                                    className={'first_name'}
                                    error={missingFields.indexOf('first_name') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                />
                                <TextFieldContainer
                                    key='middle_name'
                                    label='M.I.'
                                    className='middle_name'
                                    ref='middle_name'
                                    type='text'
                                    theme='tiny'
                                    text={this.state.new.middle_name}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    maxLength={1}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'last_name'}
                                    ref={'last_name'}
                                    text={this.state.new.last_name}
                                    label={'Last Name'}
                                    className={'last_name'}
                                    error={missingFields.indexOf('last_name') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                />
                                <TextFieldContainer
                                    key='suffix'
                                    label='Suffix'
                                    className='suffix'
                                    ref='suffix'
                                    type='text'
                                    theme='tiny'
                                    text={this.state.new.suffix}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    maxLength={1}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'first_name'}
                                    ref={'first_name'}
                                    text={this.state.current.first_name || this.props.primary.first_name}
                                    label={'First Name'}
                                    className={'first_name'}
                                    error={missingFields.indexOf('first_name') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                />
                                <TextFieldContainer
                                    key='middle_name'
                                    label='M.I.'
                                    className='middle_name'
                                    ref='middle_name'
                                    type='text'
                                    theme='tiny'
                                    text={this.state.current.middle_name}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    maxLength={1}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'last_name'}
                                    ref={'last_name'}
                                    text={this.state.current.last_name || this.props.primary.last_name}
                                    label={'Last Name'}
                                    className={'last_name'}
                                    error={missingFields.indexOf('last_name') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                />
                                <TextFieldContainer
                                    key='suffix'
                                    label='Suffix'
                                    className='suffix'
                                    ref='suffix'
                                    type='text'
                                    theme='tiny'
                                    text={this.state.current.suffix}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    maxLength={1}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <DropdownContainer
                                key='name_change_reason'
                                className='name_change_reason'
                                label='Reason'
                                options={[
                                    {display: 'Marriage', value: 'marriage', selected: this.state.name_change_reason === 'marriage'},
                                    {display: 'Divorce', value: 'divorce', selected: this.state.name_change_reason === 'divorce'},
                                    {display: 'Other', value: 'other', selected: this.state.name_change_reason === 'other'}
                                ]}
                                onChange={(key, val) => this.onChange(key, val, 'new')}
                            />
                        </div>
                        <div className='row'>
                            <UploadContainer
                                label='Upload Documentation'
                                onChange={file => this.onChange('name_change_file', file)}
                            />
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>SSN</div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'ssn'}
                                    ref={'ssn'}
                                    text={this.state.new.ssn}
                                    label={'SSN'}
                                    className={'ssn'}
                                    error={missingFields.indexOf('ssn') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                                />
                            </div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'ssn'}
                                    ref={'ssn'}
                                    text={this.state.current.ssn}
                                    label={'SSN'}
                                    className={'ssn'}
                                    error={missingFields.indexOf('ssn') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                                />
                            </div>
                            <div className='row'>
                                <UploadContainer
                                    label='Upload Documentation'
                                    onChange={file => this.onChange('ssn_change_file', file)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>Date of Birth</div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'dob'}
                                    ref={'dob'}
                                    text={this.state.new.dob}
                                    label={'Date of Birth'}
                                    className={'dob'}
                                    error={missingFields.indexOf('dob') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                                />
                            </div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'dob'}
                                    ref={'dob'}
                                    text={this.state.current.dob}
                                    label={'Date of Birth'}
                                    className={'dob'}
                                    error={missingFields.indexOf('dob') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                                />
                            </div>
                            <div className='row'>
                                <UploadContainer
                                    label='Upload Documentation'
                                    onChange={file => this.onChange('dob_change_file', file)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>Sex</div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <DropdownContainer
                                    key={'sex'}
                                    ref={'sex'}
                                    label={'Sex'}
                                    className={'sex'}
                                    options={[
                                        {display: 'Male', value: 'male', selected: this.state.new.sex === 'male'},
                                        {display: 'Female', value: 'female', selected: this.state.new.sex === 'female'}
                                    ]}
                                    error={missingFields.indexOf('sex') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                />
                            </div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <DropdownContainer
                                    key={'sex'}
                                    ref={'sex'}
                                    label={'Sex'}
                                    className={'sex'}
                                    options={[
                                        {display: 'Male', value: 'male', selected: this.state.current.sex === 'male'},
                                        {display: 'Female', value: 'female', selected: this.state.current.sex === 'female'}
                                    ]}
                                    error={missingFields.indexOf('sex') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                />
                            </div>
                            <div className='row'>
                                <UploadContainer
                                    label='Upload Documentation'
                                    onChange={file => this.onChange('sex_change_file', file)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>Marital Status</div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <DropdownContainer
                                    key={'marital_status'}
                                    ref={'marital_status'}
                                    label={'Marital Status'}
                                    className={'marital_status'}
                                    options={[
                                        {display: 'Single', value: 'single', selected: this.state.new.marital_status === 'single'},
                                        {display: 'Married', value: 'married', selected: this.state.new.marital_status === 'married'},
                                        {display: 'Domestic partner', value: 'domestic-partner', selected: this.state.new.marital_status === 'domestic-partner'},
                                        {display: 'Divorced', value: 'divorced', selected: this.state.new.marital_status === 'divorced'},
                                        {display: 'Widowed', value: 'widowed', selected: this.state.new.marital_status === 'widowed'},
                                        {display: 'Legally separated', value: 'legally-separated', selected: this.state.new.marital_status === 'legally-separated'}
                                    ]}
                                    error={missingFields.indexOf('marital_status') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                />
                            </div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <DropdownContainer
                                    key={'marital_status'}
                                    ref={'marital_status'}
                                    label={'Marital Status'}
                                    className={'marital_status'}
                                    options={[
                                        {display: 'Single', value: 'single', selected: this.state.current.marital_status === 'single'},
                                        {display: 'Married', value: 'married', selected: this.state.current.marital_status === 'married'},
                                        {display: 'Domestic partner', value: 'domestic-partner', selected: this.state.current.marital_status === 'domestic-partner'},
                                        {display: 'Divorced', value: 'divorced', selected: this.state.current.marital_status === 'divorced'},
                                        {display: 'Widowed', value: 'widowed', selected: this.state.current.marital_status === 'widowed'},
                                        {display: 'Legally separated', value: 'legally-separated', selected: this.state.current.marital_status === 'legally-separated'}
                                    ]}
                                    error={missingFields.indexOf('marital_status') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                />
                            </div>
                            <div className='row'>
                                <UploadContainer
                                    label='Upload Documentation'
                                    onChange={file => this.onChange('marital_status_change_file', file)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
