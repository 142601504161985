import { connect } from 'react-redux';
import TextField from './TextField';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextField);
