import { browserHistory } from 'react-router';

import Config from '../config';

import {STANDARD_HTTP_HEADERS, setMessage} from './app';
import {getTransactions} from './noyo';

/** Checks if there is a currently logged in user to the app. */
export function checkLoggedIn() {
    return (dispatch, getState) => {
        const userId = localStorage.getItem('userId');
        const accessToken = localStorage.getItem('accessToken');
        if (userId !== null && accessToken !== null) {
            console.info(`looks like user is logged in as ${userId}, loading user data`);
            dispatch(userLoad(userId, accessToken));

            dispatch(getTransactions());
        }
    };
}


export function updateLocation(location) {
    return (dispatch, getState) => {
        dispatch({
            type: 'USER_LOCATION',
            payload: {
                pathname: location.pathname
            }
        });
    }
}

/** Loads a user id into the redux store. */
export function userLoad(userId, accessToken, user) {
    return (dispatch, getState, {auth}) => {
        if (user) {
            dispatch({
                type: 'USER_STORE',
                payload: { user }
            });
            dispatch(getTransactions());
        } else {
            let headers = STANDARD_HTTP_HEADERS;
            headers['Authorization'] = 'Bearer ' + accessToken;
            fetch(Config.REACT_APP_BASE_API_URL + '/users/' + userId, {
                headers
            })
            .then(resp => {
                if (resp.ok) {
                    return resp.json().then(data => {
                        dispatch({
                            type: 'USER_STORE',
                            payload: {
                                user: data
                            }
                        });
                    });
                } else {
                    dispatch(logout());
                }
            });
        }
    };
}

export function login(authData) {
    return (dispatch, getState, {auth}) => {
        const {user_id, access_token, user} = authData;
        localStorage.setItem('userId', user_id);
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('oauth', JSON.stringify(authData));
        dispatch(userLoad(user_id, access_token, user));
    };
}

export function loginOrRegister(type, payload, onSuccess) {
    return (dispatch, getState, {auth}) => {
        auth.loginOrRegister(type, payload)
            .then(data => {
                dispatch(login(data));
                onSuccess && onSuccess();
            })
            .catch(errors => {
                dispatch(setMessage('Error', errors[0]));
            })
    };
}

export function update(payload, onSuccess) {
    return (dispatch, getState, {auth}) => {
        // update user logic here
        auth.updateUser(payload).then(data => {
            if (data.error) {
                console.error(data.error);
                dispatch(setMessage('Error', data.error));
            } else {
                dispatch({
                    type: 'USER_STORE',
                    payload: {
                        user: data
                    }
                });
                if (onSuccess) { onSuccess(); }
            }
        });
    };
}

/** Resets the db, clears redux, clears async storage. */
export function logout(onSuccess) {
    return (dispatch, getState, {apollo}) => {
        dispatch({type: 'USER_LOGOUT'});
        localStorage.clear();
        browserHistory.push('/login');
        // reload default state
        dispatch(checkLoggedIn());
    };
}

export function forgotPassword(email, onSuccess) {
    return (dispatch, getState, {auth}) => {
        auth.forgotPassword({email})
            .then(data => onSuccess && onSuccess(data));
    };
}

export function resetPassword(payload, onSuccess) {
    return (dispatch, getState, {auth}) => {
        auth.resetPassword(payload)
            .then(onSuccess)
            .catch(errors => {
                dispatch(setMessage('Error', errors[0]));
            });
    };
}
