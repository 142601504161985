import moment from 'moment';

import React, { Component } from 'react';

import Config from '../../../config';

import { TextFieldContainer, DateSelectContainer, Address } from '../../index';

import EmployeeDemographics from './EmployeeDemographics';

import { stringToInt, validateFields } from '../../../utils';

const PREFILL_DATA = {
  "missingFields": [],
  "group_name": "New Test Company",
  "full_time_employees_out_state": 10,
  "part_time_employees_out_state": 2,
  "effective_date": "2019-05-01",
  "expiration_date": "2019-04-28",
  "first_name": "Andy",
  "last_name": "Davis",
  "email": "an@kitdesai.com",
  "full_time_employees_total": "12",
  "full_time_employees_in_state": "2",
  "part_time_employees_total": "2",
  "part_time_employees_in_state": "0",
  "full_time_equivalents": "2",
  "past_year_average": "2",
  "small_employer": true,
  "title": "Manager",
  "full_time_employees_eligible": "12",
  "part_time_employees_eligible": "2",
  "eligible_hours_per_week": "40",
  "offer_full_time_coverage": true,
  "thirty_hour_week_average": true,
  "ineligible_reason": "part-time",
  "coverage_outside_us": false,
  "coverage_out_of_state_eligible_count": "0",
  "phone": "232-829-3923",
  "work_address": {
    "addressString": "12397 E Kings Canyon Rd, Sanger, CA 93657, USA",
    "city": "Sanger",
    "county": "Fresno County",
    "state": "CA",
    "zip_code": "93657",
    "street": "12397 East Kings Canyon Road"
  },
  "employees_other_than_married": true
}

export default class InitialInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props.initial_information
        }
    }

    prefill() {
        this.setState(PREFILL_DATA, this.cleanup);
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.cleanup);
    }

    cleanup() {
        let missingFields = this.state.missingFields;
        const {
            full_time_employees_total,
            full_time_employees_in_state,
            part_time_employees_total,
            part_time_employees_in_state
        } = this.state;

        let full_time_employees_out_state = '';
        let part_time_employees_out_state = '';

        if (full_time_employees_total && full_time_employees_in_state) {
            full_time_employees_out_state = stringToInt(full_time_employees_total) - stringToInt(full_time_employees_in_state);
        }
        if (part_time_employees_total &&  part_time_employees_in_state) {
            part_time_employees_out_state = stringToInt(part_time_employees_total) - stringToInt(part_time_employees_in_state);
        }

        if (full_time_employees_out_state < 0) missingFields.push('full_time_employees_out_state');
        if (part_time_employees_out_state < 0) missingFields.push('part_time_employees_out_state');

        this.setState({
            missingFields, full_time_employees_out_state, part_time_employees_out_state
        }, this.props.onChange('initial_information', this.state));
    }

    validate() {
        const requiredFields = [
            'group_name', 'effective_date', 'expiration_date', 'first_name', 'last_name', 'title', 'email', 'phone', 'work_address',
            'full_time_employees_total', 'full_time_employees_in_state', 'part_time_employees_total', 'part_time_employees_in_state',
            'full_time_equivalents', 'past_year_average', 'small_employer', 'employees_other_than_married',
            'full_time_employees_eligible', 'part_time_employees_eligible', 'eligible_hours_per_week', 'offer_full_time_coverage',
            'coverage_out_of_state_eligible_count'
        ];

        if (this.state.coverage_out_of_state_eligible_count > 0) {
            requiredFields.push('coverage_out_of_state_states');
        }

        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div>
                <div className='initial-header'>Prepare the group application for your client to complete by answering the questions below</div>
                <div className='initial-info'>
                    {Config.REACT_APP_DEV_MODE &&
                        <div className='link' style={{textAlign: 'left'}} onClick={this.prefill.bind(this)}>PREFILL INFO</div>
                    }
                    <div className='row'>
                        <TextFieldContainer
                            key='group_name'
                            label="Group Name"
                            className='group_name'
                            ref='group_name'
                            text={this.state.group_name}
                            type='text'
                            theme='full-width'
                            error={missingFields.indexOf('group_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row'>
                        <DateSelectContainer
                            key='effective_date'
                            className='effective_date'
                            label='Group Effective Date'
                            value={this.state.effective_date}
                            minDate={moment().subtract(3, 'months')}
                            startDate={moment().startOf('month')}
                            error={missingFields.indexOf('effective_date') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <DateSelectContainer
                            key='expiration_date'
                            className='expiration_date'
                            label='Expiration Date'
                            value={this.state.expiration_date}
                            error={missingFields.indexOf('expiration_date') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='subheader'>Group Administrator</div>
                    <div className='row three'>
                        <TextFieldContainer
                            key='first_name'
                            label="First Name"
                            className='first_name'
                            ref='first_name'
                            type='text'
                            helperText='Who should receive this application to complete and sign off?'
                            helperOnHover={true}
                            text={this.state.first_name}
                            error={missingFields.indexOf('first_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextFieldContainer
                            key='last_name'
                            label="Last Name"
                            className='last_name'
                            ref='last_name'
                            type='text'
                            text={this.state.last_name}
                            error={missingFields.indexOf('last_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextFieldContainer
                            key='title'
                            label="Title"
                            className='title'
                            ref='title'
                            type='text'
                            text={this.state.title}
                            error={missingFields.indexOf('title') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='email'
                            label="Email"
                            className='email'
                            ref='email'
                            type='text'
                            text={this.state.email}
                            error={missingFields.indexOf('email') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextFieldContainer
                            key='phone'
                            label="Phone Number"
                            className='phone'
                            ref='phone'
                            type='text'
                            text={this.state.phone}
                            error={missingFields.indexOf('phone') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                        />
                    </div>
                    <div className='row'>
                        <Address
                            label='Work Address'
                            value={this.state.work_address}
                            error={missingFields.indexOf('work_address') > -1}
                            onChange={(address) => this.onChange('work_address', address)}
                        />
                    </div>
                </div>
                <EmployeeDemographics ref={ref => this.employeeDemographics = ref} onChange={this.onChange.bind(this)} {...this.state} />
            </div>
        )
    }
}
