import moment from 'moment';
import React, { Component } from 'react';

import {DropdownContainer} from '../index';

import AdditionalInformation from './AdditionalInformation';
import PersonalInformation from './PersonalInformation';

import './Application.scss';


class Dependent extends Component {
    onChange(key, value) {

        let { dependent } = this.props;

        dependent[key] = value;

        if (value instanceof moment){
            dependent[key] = value.format();
        }

        this.props.onChange(dependent);
    }

    render() {
        const {
            disabled,
            dependent,
            dependentKey,
        } = this.props;

        const { relationship_type } = dependent;

        return (
            <div>
                <div className='main-text'>
                    <img alt="" src={require('../../assets/images/ic-person.svg')} height='24' width='24' />
                    <div className='name light'>Enter information for {dependent.first_name}</div>
                </div>
                <div className='personal'>
                    {/*<div className='row radio'>
                        {carrierName &&
                            <RadioButtonQuestionContainer
                                questionText={'Has this person had medical coverage with ' + carrierName + ' before?'}
                                options={[
                                    {name: 'past_medical_coverage', value: 'no', selected: past_medical_coverage === 'no'},
                                    {name: 'past_medical_coverage', value: 'yes', selected: past_medical_coverage === 'yes'}
                                ]}
                                onChange={this.onChange.bind(this)}
                            />
                        }
                    </div>*/}
                    <div className='row'>
                        <DropdownContainer
                            key='relationship_type'
                            label="Type of Dependent"
                            className='relationship_type'
                            ref='relationship_type'
                            options={['Spouse', 'Child', 'Domestic Partner', 'Civil Union', 'Step Child', 'Foster Child', 'Adopted Child', 'Legal Guardianship'].map(display => {
                                const value = display.toLowerCase().replace(' ', '-');
                                return {display, value, selected: relationship_type === value}
                            })}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <PersonalInformation
                        ref={ref => this.personalInformation = ref}
                        {...dependent}
                        dependentKey={dependentKey}
                        isDependent={true}
                        onChange={this.onChange.bind(this)}
                    />

                    <AdditionalInformation
                        ref={ref => this.additionalInformation = ref}
                        {...dependent}
                        dependentKey={dependentKey}
                        isDependent={true}
                        disabled={disabled}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default Dependent;
