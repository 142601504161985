// @flow

import EventEmitter from 'events';
import Config from '../../config';
import {newUrl, ClientError} from '../../utils';
import {STANDARD_HTTP_HEADERS} from '../index';

export class AuthError extends ClientError {
}

export class RpcError extends ClientError {
}

export class ApiError extends RpcError {
    data: Object;

    constructor(url: string, data: Object) {
        super('api_error', `Error fetching ${url}`);
        this.data = data;
    }
}

export class NotFoundError extends ApiError {
}

export default class Rpc extends EventEmitter {
    fetch: any;

    constructor(fetch: any) {
        super();
        this.fetch = fetch;
    }

    get(url: string, params: Object): Promise<Object> {
        const finalUrl = newUrl(`${Config.REACT_APP_BASE_API_URL}${url}`, params);
        // TODO: this.fetch is returning an "illegal invocation" error in chrome. not sure why, using fetch for now
        return fetch(finalUrl, {
            method: 'GET',
            headers: STANDARD_HTTP_HEADERS,
            credentials: 'include'
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 401) {
                throw new AuthError('no_access_token');
            } else {
                this.handleError(finalUrl, resp);
            }
        }).catch(err => {
            if (err instanceof ClientError) {
                throw err;
            } else {
                throw new RpcError('error', err);
            }
        });
    }

    post(url: string, data: Object): Promise<Object> {
        const finalUrl = Config.REACT_APP_API_PREFIX + url;
        // TODO: this.fetch is returning an "illegal invocation" error in chrome. not sure why, using fetch for now
        return fetch(finalUrl, {
            method: 'POST',
            headers: STANDARD_HTTP_HEADERS,
            body: JSON.stringify(data),
            credentials: 'include'
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 401) {
                throw new AuthError('no_access_token');
            } else {
                return this.handleError(finalUrl, resp);
            }
        }).catch(err => {
            if (err instanceof ClientError) {
                throw err;
            } else {
                throw new RpcError('error', err);
            }
        });
    }

    handleError(url: string, resp: Response) {
        return resp.json().then(err => {
            if (resp.status === 404) {
                throw new NotFoundError(url, err);
            } else {
                throw new ApiError(url, err);
            }
        });
    }
}
