import moment from 'moment';
import React, { Component } from 'react';

import {Terms} from '../../index';

import Modal from '../../shared/Modal/Modal';

import '../Application.scss';

import SignaturePad from 'signature_pad';


export default class Verify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            termsVisible: false,
            missingFields: []
        }
        this.props.onChange(this.state);
    }

    componentDidMount() {
        this.signaturePad = new SignaturePad(this.refs.signatureCanvas);
        console.log(this.signaturePad);
    }

    toggleTerms() {
        this.setState({
            termsVisible: !this.state.termsVisible
        });
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    validate() {
        const missingFields = [];

        if (this.signaturePad.isEmpty()) {
            missingFields.push('signature');
        }

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields} = this.state;
        const {formData} = this.props;
        let finalNote = 'Note: submissions are final. If you need to make changes after submitting, contact your broker.';
        return (
            <div className='step-verify'>
                <div className='main-text'>
                    <img alt="" src={require('../../../assets/images/ic-edit.svg')} height='24' width='24' />
                    <div className='name light'>Review & sign your application</div>
                    <div>You’re almost done! Confirm the information below is correct, add your signature, and then submit your application.</div>
                </div>
                <div className='step-verify__container'>
                    <div className='verify-information'>
                        <div className='verify-information__personal'>
                            <div className='verify-information__header'>
                                <span>Fields Completed by Broker</span>
                            </div>
                            <BrokerInformation data={this.props.application.properties} />
                        </div>
                        <div className='verify-information__health'>
                            <div className='verify-information__header'>
                                <span>Fields Completed by Employer</span>
                            </div>
                            <EmployerInformation data={formData} back={this.props.back} />
                        </div>
                    </div>
                </div>
                <div className='signature-container'>
                    <div>Draw your signature with your mouse</div>
                    {missingFields.indexOf('signature') > -1 &&
                        <div className='error'>Signature is required</div>
                    }
                    <div className='signature'><canvas ref="signatureCanvas" width="360" height="120"></canvas></div>
                    <div>
                        <div>By signing, you agree to the <span className='terms' onClick={this.toggleTerms.bind(this)}>Terms of Use & Privacy Policy.</span></div>
                        <div style={{margin: '10px 0'}}>{finalNote}</div>
                    </div>
                </div>
                <Modal isOpen={this.state.termsVisible} close={this.toggleTerms.bind(this)}>
                    <div className='termsContent'>
                        <Terms />
                    </div>
                </Modal>
            </div>
        );
    }
}


class BrokerInformation extends Component {
    _getFormattedKey(key) {
        key = key.replace(/_/g, ' ');
        if (key === 'ssn') {
            key = key.toUpperCase();
        } else {
            key = key.charAt(0).toUpperCase() + key.slice(1);
        }
        return key;
    }

    render() {
        const {data} = this.props;
        const {initial_information} = data;
        const effective_date = moment(initial_information.effective_date).format('MM/DD/YYYY');
        const expiration_date = moment(initial_information.expiration_date).format('MM/DD/YYYY');
        return (
            <ul className='bullet-list'>
                <li>Initial Information</li>
                <ul>
                    <li>Group Name: {initial_information.group_name}</li>
                    <li>Group Effective Date: {effective_date}</li>
                    <li>Expiration Date: {expiration_date}</li>
                    <li>Group Administration</li>
                    <li>First Name: {initial_information.first_name}</li>
                    <li>Last Name: {initial_information.last_name}</li>
                    <li>Title: {initial_information.title}</li>
                    <li>Email: {initial_information.email}</li>
                    <li>Phone #: {initial_information.phone}</li>
                    <li>Work Address: {initial_information.work_address.addressString}</li>
                    <li>
                        Current Company Employees:
                        <ul>
                            <li>Full-time Counts:</li>
                            <ul>
                                <li>Total: {initial_information.full_time_employees_total}</li>
                                <li>In-State: {initial_information.full_time_employees_in_state}</li>
                                <li>Out of State: {initial_information.full_time_employees_out_state}</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>Part-time Counts:</li>
                            <ul>
                                <li>Total: {initial_information.part_time_employees_total}</li>
                                <li>In-State: {initial_information.part_time_employees_in_state}</li>
                                <li>Out of State: {initial_information.part_time_employees_out_state}</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>Full-time Equivalents: {initial_information.full_time_equivalents}</li>
                            <li>Past Year Average: {initial_information.past_year_average}</li>
                            <li>Does the group meet the definition of a small employer? {initial_information.small_employer ? 'yes' : 'no'}</li>
                            <li>Did company have employees other than Group Administrator and spouse during the preceding calendar year? {initial_information.employees_other_than_married ? 'yes' : 'no'}</li>
                        </ul>
                        <ul>
                            <li>How many employees at the company are eligible for coverage?</li>
                            <ul>
                                <li>Full-Time: {initial_information.full_time_employees_eligible}</li>
                                <li>Part-Time: {initial_information.part_time_employees_eligible}</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>Eligible Employee Information</li>
                            <ul>
                                <li>How many hours per week must employees work to be eligible for insurance? {initial_information.eligible_hours_per_week}</li>
                                <li>Are all full-time employees being offered coverage? {initial_information.offer_full_time_coverage ? 'yes' : 'no'}</li>
                                {initial_information.thirty_hour_week_average &&
                                    <li>Are these employees working an average of 30 hours per week? {initial_information.thirty_hour_week_average ? 'yes' : 'no'}</li>
                                }
                                {initial_information.eligible_group_class_offered &&
                                    <li>What is the group/class for whom coverage is being offered? {initial_information.eligible_group_class_offered}</li>
                                }
                            </ul>
                        </ul>
                        <ul>
                            <li>Ineligible Employee Information</li>
                            <ul>
                                {initial_information.ineligible_reason &&
                                    <li>For employees that are ineligible, what is the reason? {initial_information.ineligible_reason}</li>
                                }
                                {initial_information.coverage_outside_us &&
                                    <li>Do any people requesting coverage live or work outside of United States? {initial_information.coverage_outside_us ? 'yes' : 'no'}</li>
                                }
                                <li>How many out-of-state employees are eligible for coverage? {initial_information.coverage_out_of_state_eligible_count}</li>
                                {initial_information.coverage_out_of_state_states &&
                                    <li>In which states are they eligible for coverage? {initial_information.coverage_out_of_state_states}</li>
                                }
                            </ul>
                        </ul>
                    </li>
                </ul>
            </ul>
        )
    }
}

class EmployerInformation extends Component {
    render() {
        const {data} = this.props;
        const {company_information, contact_information} = data;
        const banking_information = company_information.banking;
        return (
            <ul className='bullet-list'>
                <li>
                    Company Information <span className='link' onClick={() => this.props.back(1)}>(edit)</span>
                    <ul>
                        <li>Group Name: {company_information.group_name}</li>
                        {company_information.dba && <li>DBA: {company_information.dba}</li>}
                        <li>FEIN: {company_information.fein}</li>
                        <li>SIC Code: {company_information.sic}</li>
                        <li>Organization Type: {company_information.organization_type}</li>
                        <li>Business Type: {company_information.business_type}</li>
                        <li>Established Date: {company_information.established_date}</li>
                        {company_information.company_services && <li>Company Services: {company_information.company_services}</li>}
                        {company_information.peo_name && <li>PEO Name: {company_information.peo_name}</li>}
                        <li>Company Address: {company_information.address.addressString}</li>
                        <li>Website: {company_information.website}</li>
                        <li>Phone #: {company_information.phone}</li>
                        {company_information.fax && <li>Fax #: {company_information.fax}</li>}
                        {company_information.email && <li>Email #: {company_information.email}</li>}
                    </ul>
                </li>
                <li>
                    Contact Information <span className='link' onClick={() => this.props.back(2)}>(edit)</span>
                    <ul>
                        {
                            contact_information.contacts.map((contact, index) => {
                                return (
                                    <li>
                                        Contact #{index+1}
                                        <ul>
                                            {contact.contact_type && <li>Contact Type: {contact.contact_type}</li>}
                                            <li>First Name: {contact.first_name}</li>
                                            <li>Last Name: {contact.last_name}</li>
                                            <li>Title: {contact.title}</li>
                                            <li>Address: {contact.address.addressString}</li>
                                            {contact.phone && <li>Phone #: {contact.phone}</li>}
                                            {contact.fax && <li>Fax #: {contact.fax}</li>}
                                            {contact.email && <li>Email Address: {contact.email}</li>}
                                            {contact.preferred_contact_method && <li>Preferred Correspondence Method: {contact.preferred_contact_method}</li>}
                                            {contact.contact_authorized && <li>Is this contact authorized to make changes to the group contract? {contact.contact_authorized ? 'yes' : 'no'}</li>}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </li>
                <li>
                    Banking Information <span className='link' onClick={() => this.props.back(3)}>(edit)</span>
                    <ul>
                        <li>Bank Name: {banking_information.bank_name}</li>
                        <li>Bank Address: {banking_information.address.addressString}</li>
                        {banking_information.phone && <li>Bank Phone #: {banking_information.phone}</li>}
                        {banking_information.account_type && <li>Account Type: {banking_information.account_type}</li>}
                        {banking_information.account_holder && <li>Account Holder: {banking_information.account_holder}</li>}
                        {banking_information.account_number && <li>Account Number: {banking_information.account_number}</li>}
                        {banking_information.routing_number && <li>Routing Number: {banking_information.routing_number}</li>}
                        {banking_information.first_payment_debit_amount && <li>How much would you like to debit in this first payment? {banking_information.first_payment_debit_amount}</li>}
                        {banking_information.other_amount && <li>Other Amount: {banking_information.other_amount}</li>}
                        {banking_information.premium_amount && <li>What's the premium amount? {banking_information.premium_amount}</li>}
                        {banking_information.monthly_payment_option && <li>How would you like to pay each month after the first month? {banking_information.monthly_payment_option}</li>}
                        {banking_information.withdrawal_date && <li>What date of each month do you want this withdrawal to happen? {banking_information.withdrawal_date}</li>}
                    </ul>
                </li>
            </ul>
        )
    }
}
