import { get } from 'lodash';

export function setApplicationId(id) {
	return (dispatch) => {
		console.log('application id: ', id);
		dispatch({
			type: 'CURRENT_APPLICATION_ID',
			payload: id,
		});
	}
}

export function setApplicationData(application) {
	return (dispatch) => {
		console.log('application data: ', application);
		dispatch({
			type: 'CURRENT_APPLICATION_DATA',
			payload: application,
		});
	}
}

export function setApplicationDependents(dependents) {
	return (dispatch) => {
		console.log('application dependents: ', dependents);
		dispatch({
			type: 'CURRENT_APPLICATION_DEPENDENTS',
			payload: dependents,
		});
	}
}

export function setApplicationSelectedPlans(selectedPlans) {
	return (dispatch) => {
		console.log('application selected plans: ', selectedPlans);
		dispatch({
			type: 'CURRENT_APPLICATION_SELECTED_PLANS',
			payload: selectedPlans,
		});
	}
}

export function setApplicationCurrentStep(currentStep) {
	return (dispatch) => {
		console.log('application current step: ', currentStep);
		dispatch({
			type: 'CURRENT_APPLICATION_STEP',
			payload: currentStep,
		});
	}
}

export function setApplicationFormData(formData) {
	return (dispatch) => {
		console.log('application current form data: ', formData);
		dispatch({
			type: 'CURRENT_APPLICATION_FORM_DATA',
			payload: formData,
		});
	}
}

export function setApplicationError(error) {
	return (dispatch) => {
		console.log('application current error: ', error);
		dispatch({
			type: 'CURRENT_APPLICATION_ERROR',
			payload: error,
		});
	}
}

export function setApplicationSubmitState(submitting) {
	return (dispatch) => {
		console.log('application current submit state: ', submitting);
		dispatch({
			type: 'CURRENT_APPLICATION_SUBMIT_STATE',
			payload: submitting,
		});
	}
}

export function setApplicationShowSuccess(showSuccess) {
	return (dispatch) => {
		console.log('application current show success: ', showSuccess);
		dispatch({
			type: 'CURRENT_APPLICATION_SHOW_SUCCESS',
			payload: showSuccess,
		});
	}
}

export function setApplicationSignature(signature) {
	return (dispatch) => {
		console.log('application current signature: ', signature);
		dispatch({
			type: 'CURRENT_APPLICATION_SIGNATURE',
			payload: signature,
		});
	}
}

export function setApplicationMissingFields(missingFields) {
	return (dispatch) => {
		console.log('application current missing fields: ', missingFields);
		dispatch({
			type: 'CURRENT_APPLICATION_MISSING_FIELDS',
			payload: missingFields,
		});
	}
}

export function setApplicationTerminationOptions(terminationOptions) {
	return (dispatch) => {
		console.log('application current termination options: ', terminationOptions);
		dispatch({
			type: 'CURRENT_APPLICATION_TERMINATION_OPTIONS',
			payload: terminationOptions,
		});
	}
}

export function setApplicationTerminationInfo(terminationInfo) {
	return (dispatch) => {
		console.log('application current termination info: ', terminationInfo);
		dispatch({
			type: 'CURRENT_APPLICATION_TERMINATION_INFO',
			payload: terminationInfo,
		});
	}
}

export function setApplicationCompanyAdminInfo(contactInfo) {
	return (dispatch) => {
		console.log('application company admin current contact info: ', contactInfo);
		dispatch({
			type: 'CURRENT_APPLICATION_COMPANY_ADMIN_CONTACT_INFO',
			payload: contactInfo,
		});
	}
}

export function setApplicationNoyoTerms(bool) {
	return (dispatch) => {
		dispatch({
			type: 'CURRENT_APPLICATION_NOYO_TERMS',
			payload: bool,
		})
	}
}

export function setApplicationCarrierTerms(bool) {
	return (dispatch) => {
		dispatch({
			type: 'CURRENT_APPLICATION_CARRIER_TERMS',
			payload: bool,
		})
	}
}

export function getTermsofService() {
	return (dispatch, getState, { auth }) => {

		const state = getState()['Application'];
		const application = state.data;
		const formData = state.formData;


		const transaction_type = get(application, 'carrier_form.form_type');

		const termination_path = 'termination_info.termination_options';
		const new_hire_path = 'selectedPlans';

		const path = transaction_type === 'new_hire_enrollment' ? new_hire_path : termination_path;
		const fallback = transaction_type === 'new_hire_enrollment' ? {} : [];

		const carriers = get(formData, path, fallback);

		let effective_date;

		if (transaction_type === 'new_hire_enrollment') {
			effective_date = application.effective_date;
		} else {
			effective_date = get(formData, 'primary.last_work_date');
		}

		const carrier_ids = [];

		Object.values(carriers).forEach(({carrier_id, value}) => {
			carrier_ids.push({
				carrier_id,
				value,
			});
		});


		const tos_promises = [];

		function newHireEnrollmentTextType(value) {
			const map = {
				'waive_coverage': 'new-hire-waiver',
			};

			return map[value] || 'new-hire-enrollment';
		}

		// TODO make this dynamic
		const type = transaction_type === 'new_hire_enrollment' ? 'new-hire' : 'termination';

		carrier_ids.forEach(({ carrier_id, value }) => {

			const text_type = transaction_type === 'new_hire_enrollment' ? newHireEnrollmentTextType(value) : 'termination';

			const params = {
				carrier_id,
				effective_date,
				transaction_type: type,
				text_type,
			};

			var payload = Object.keys(params).map(key => key + '=' + params[key]).join('&');


			const promise = auth.getCarrierText(payload).then(({ carrier_text_content }) => {
				console.log(`terms for carrier ${carrier_id}: `, carrier_text_content);
				return carrier_text_content;
			}).catch((err) => {
				console.log('error retrieving terms for id: ', carrier_id);
			});

			tos_promises.push(promise);
		});

		if (tos_promises.length) {
			Promise.all(tos_promises).then((terms) => {

				// TODO format all terms better than concat'ing with `-----`
				const all_terms = terms.join('\n ----- \n'); 
				console.log(all_terms);
				dispatch({
					type: 'CURRENT_APPLICATION_CARRIER_TERMS_OF_SERVICE',
					payload: all_terms,
				});
			}).catch((err) => {
				console.warn(err, 'error, needs ToS to proceed');
			});
		}

		// TODO add `else` action

	};
}