import { connect } from 'react-redux';

import { setMessage } from '../../actions/app';

import QLEOEApplication from './QLEOEApplication';

const mapStateToProps = ({ User, Noyo }) => {
    return {};
};

const mapDispatchToProps = {
    setMessage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QLEOEApplication);
