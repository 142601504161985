import produce from 'immer';

const initialState = {
    carriers: [],
    transactions: []
};

const noyoReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch(action.type) {
            case 'CARRIERS_STORE':
                const { carriers } = action.payload;
                draft.carriers = carriers;
                break;

            case 'TRANSACTIONS_STORE':
                const { transactions } = action.payload;
                draft.transactions = transactions;
                break;

            case 'USER_LOGOUT':
                draft = initialState;
                break;

            default:
        }
    });

export default noyoReducer;
