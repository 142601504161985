import moment from 'moment';

import React, { Component } from 'react';

import { TextFieldContainer, DropdownContainer, DateSelectContainer } from '../../index';

export default class CobraEmployee extends Component {
    constructor(props) {
        super(props);

        const {first_name, last_name, dob, ssn, qualifying_event, qualifying_event_date, isChild, index} = props;
        this.state = {
            missingFields: [],
            first_name, last_name, dob, ssn, qualifying_event, qualifying_event_date, isChild, index
        };
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    render() {
        const {missingFields, isChild, index} = this.state;
        const text = isChild ? 'Child' : 'Spouse';
        return (
            <div className='spouse-child'>
                <div>{text} {index ? ('#' + index) : ''} Information</div>
                <div className='row'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={this.state.first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        text={this.state.last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='dob'
                        label="Date of Birth"
                        className='dob'
                        ref='dob'
                        type='text'
                        text={this.state.dob}
                        error={missingFields.indexOf('dob') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                    />
                    <TextFieldContainer
                        key='ssn'
                        label="Social Security Number"
                        className='ssn'
                        ref='ssn'
                        type='text'
                        text={this.state.ssn}
                        error={missingFields.indexOf('ssn') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                    />
                </div>
                <div className='row'>
                    Type of coverage eligible for question - need more info
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='qualifying_event'
                        label='What was the qualifying event?'
                        className='qualifying_event'
                        ref='qualifying_event'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Employment Termination', value: 'termination'},
                            {display: 'Disability', value: 'disability'},
                            {display: 'Reduction in Work Hours', value: 'work_hours_reduction'},
                            {display: 'Ex-Spouse of Employee', value: 'ex_spouse'},
                            {display: 'Surviving Dependent(s) of Employee', value: 'surviving_dependent'},
                            {display: 'Dependent Child\'s Age Exceeds Eligibility' , value: 'dependent_child_age_exceeds_eligibility'},

                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.qualifying_event === value}
                        })}
                    />
                </div>
                <div className='row'>
                    <DateSelectContainer
                        key='qualifying_event_date'
                        className='qualifying_event_date'
                        label='Date of the qualifying event?'
                        value={this.state.qualifying_event_date ? moment(this.state.qualifying_event_date) : null}
                        minDate={moment().subtract(3, 'months')}
                        startDate={moment().startOf('month')}
                        error={missingFields.indexOf('qualifying_event_date') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>
        )
    }
}
