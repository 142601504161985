import moment from 'moment';

import React, { Component } from 'react';

import { validateFields, validateAddress } from '../../utils';

import { ButtonContainer, TextFieldContainer, DropdownContainer, DateSelectContainer, Address } from '../index';

import EligibilityGroup from './EligibilityGroupEmployee';

import './Broker.scss';

const INITIAL_STATE = {
    carrier_id: null,
    company_name: null,
    effective_date: null,
    expiration_date: null,
    success_message: null,
    eligibility_groups: [],
    missingFields: [],
    submitting: false,
    transaction_type: null,
    company_address: {},
    company_contact: {},
    coverage_options: []
}

class BrokerEmployeeApplication extends Component {
    constructor(props) {
        super(props);

        INITIAL_STATE.eligibility_groups = [];
        this.state = INITIAL_STATE;
    }

    enableButton() {
        this.setState({submitting: false});
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => console.log(this.state));
    }

    onCompanyContactChange(key, value) {
        let {company_contact} = this.state;
        company_contact[key] = value;
        this.onChange('company_contact', company_contact);
    }

    onChangeEligibilityGroup(value, index) {
        let eligibility_groups = this.state.eligibility_groups;

        // https://noyotechnologies.atlassian.net/browse/NY-137
        // OK'd to do this to get around backend validations for now
        value.name = value.name || '';
        eligibility_groups[index] = value;

        this.setState({eligibility_groups}, console.log(this.state));
    }

    applicationStarted() {
        const {company_name, effective_date, expiration_date, eligibility_groups} = this.state;
        return company_name || effective_date || expiration_date || eligibility_groups.length > 0;
    }

    validate() {
        /*
            proper broker application state:
            {
                transaction_type: required,
                company_name: required,
                effective_date: required,
                expiration_date: required,
                eligibility_groups: [{validation in component}]
            }
        */

        const {transaction_type} = this.state;

        let requiredFields = ['transaction_type', 'company_name', 'effective_date', 'expiration_date'];
        let missingFields;
        let childrenValidated;

        switch (transaction_type) {
            case 'new_hire_enrollment':
                missingFields = validateFields(requiredFields, this.state);

                // // NY-137
                // childrenValidated = this.eligibility_group.validate();
                // if (!childrenValidated) {
                //     missingFields.push('eligibility_groups');
                // }

                childrenValidated = true;
                break;
            case 'termination':
                // no effective date or expiration date for termination
                requiredFields.splice(-2);
                requiredFields.push('company_contact');
                missingFields = validateFields(requiredFields, this.state);
                // no children for termination
                childrenValidated = true;
                break;
            case 'demographic_change':
            case 'qualifying_life_event':
            case 'open_enrollment':
                const additionalRequired = ['company_address', 'company_contact'];
                if (transaction_type === 'demographic_change') {
                    additionalRequired.push('client_emails');
                }
                requiredFields = requiredFields.concat(additionalRequired);
                missingFields = validateFields(requiredFields, this.state);

                const company_address = this.state.company_address;
                if (!validateAddress(company_address)) {
                    missingFields.push('company_address');
                }

                const contactRequiredFields = ['first_name', 'last_name', 'email', 'work_phone'];
                const companyContact = this.state.company_contact;
                const additionalMissingFields = validateFields(contactRequiredFields, companyContact)

                missingFields = missingFields.concat(additionalMissingFields);

                childrenValidated = this.eligibility_group.validate();
                if (!childrenValidated) {
                    missingFields.push('eligibility_groups');
                }
                break;
            default:
                childrenValidated = false;
        }

        if (missingFields.length === 0 && childrenValidated) {
            this.setState({submitting: true, missingFields}, this.props.onSubmit(this.state));
        } else {
            this.setState({missingFields});
        }

    }

    _getBasicInfo(excludeCompany = false, showSpacer = true) {
        const {missingFields} = this.state;
        return (
            <div key={'basic-info'} className={'row three' + (showSpacer ? ' spacer' : '')}>
                {!excludeCompany &&
                    <TextFieldContainer
                        key='company_name'
                        label="Company Name"
                        className='company_name'
                        ref='company_name'
                        type='text'
                        error={missingFields.indexOf('company_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                }
                <DateSelectContainer
                    key='effective_date'
                    className='effective_date'
                    label='Effective Date'
                    minDate={moment().subtract(3, 'months')}
                    startDate={moment().startOf('month')}
                    error={missingFields.indexOf('effective_date') > -1}
                    onChange={this.onChange.bind(this)}
                />
                <DateSelectContainer
                    key='expiration_date'
                    className='expiration_date'
                    label='Last Day to Enroll'
                    error={missingFields.indexOf('expiration') > -1}
                    onChange={this.onChange.bind(this)}
                />
            </div>
        );
    }

    render() {
        const {type} = this.props;
        const {missingFields, submitting, transaction_type, company_address} = this.state;

        /*
        // Re-enable employee transaction type dropdown options here
        const transactionTypeOptions = [
            {display: 'New Hire Enrollment', value: 'new_hire_enrollment'},
            {display: 'Termination', value: 'termination'},
            {display: 'Demographic Change', value: 'demographic_change'},
            {display: 'Qualifying Life Event', value: 'qualifying_life_event'},
            {display: 'Open Enrollment', value: 'open_enrollment'}
        ];
        */
        const transactionTypeOptions = [
            {display: 'New Hire Enrollment', value: 'new_hire_enrollment'},
            {display: 'Termination', value: 'termination'}
        ];

        let applicationDiv = [];
        let buttonText = submitting ? 'submitting...' : 'launch application';

        switch (transaction_type) {
            case 'new_hire_enrollment':
                applicationDiv.push(this._getBasicInfo(true));
                applicationDiv.push(
                    <div key={'nhe'}>
                        <div className='row bold'>Company Information</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='company_name'
                                label="Company Name"
                                className='company_name'
                                ref='company_name'
                                type='text'
                                text={this.props.company_name}
                                error={missingFields.indexOf('company_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className='row bold'>Company Contact</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='first_name'
                                label="First Name"
                                className='first_name'
                                ref='first_name'
                                type='text'
                                text={this.props.first_name}
                                error={missingFields.indexOf('first_name') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='last_name'
                                label="Last Name"
                                className='last_name'
                                ref='last_name'
                                type='text'
                                text={this.props.last_name}
                                error={missingFields.indexOf('last_name') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                        </div>
                        <div className='row three'>
                            <TextFieldContainer
                                key='title'
                                label="Title"
                                className='title'
                                ref='title'
                                type='text'
                                text={this.props.title}
                                error={missingFields.indexOf('title') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='work_phone'
                                label="Work Phone #"
                                className='work_phone'
                                ref='work_phone'
                                type='text'
                                text={this.props.work_phone}
                                error={missingFields.indexOf('work_phone') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                                formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                            />
                            <TextFieldContainer
                                key='email'
                                label="Email address"
                                className='email'
                                ref='email'
                                type='text'
                                text={this.props.email}
                                error={missingFields.indexOf('email') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                        </div>
                    </div>
                )
                applicationDiv.push(
                        <EligibilityGroup
                            key={'eligibility-group-1'}
                            ref={ref => this.eligibility_group = ref}
                            type={type}
                            index={0}
                            onChange={this.onChangeEligibilityGroup.bind(this)}
                        />
                    );
                break;
            case 'demographic_change':
                applicationDiv.push(this._getBasicInfo(true, false));
                applicationDiv.push(
                    <div key={'demographic-email'} className='row spacer'>
                        <TextFieldContainer
                            key='client_name'
                            label="Employee Name"
                            className='client_name'
                            ref='client_name'
                            type='text'
                            error={missingFields.indexOf('client_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextFieldContainer
                            key='client_emails'
                            label="Employee Email"
                            className='client_emails'
                            ref='client_emails'
                            type='text'
                            error={missingFields.indexOf('client_emails') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                );
                applicationDiv.push(
                    <div key={'qle_oe'}>
                        <div className='row bold'>Company Information</div>
                        <div className='row three'>
                            <TextFieldContainer
                                key='company_name'
                                label="Company Name"
                                className='company_name'
                                ref='company_name'
                                type='text'
                                text={this.props.company_name}
                                error={missingFields.indexOf('company_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='parent_company_name'
                                label="Parent Company Name"
                                className='parent_company_name'
                                ref='parent_company_name'
                                type='text'
                                text={this.props.parent_company_name}
                                error={missingFields.indexOf('parent_company_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className='row'>
                            <Address
                                label='Company Address'
                                value={company_address}
                                error={missingFields.indexOf('company_address') > -1}
                                onChange={(address) => this.onChange('company_address', address)}
                            />
                        </div>
                        <div className='row bold'>Company Contact</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='first_name'
                                label="First Name"
                                className='first_name'
                                ref='first_name'
                                type='text'
                                text={this.props.first_name}
                                error={missingFields.indexOf('first_name') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='last_name'
                                label="Last Name"
                                className='last_name'
                                ref='last_name'
                                type='text'
                                text={this.props.last_name}
                                error={missingFields.indexOf('last_name') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                        </div>
                        <div className='row three'>
                            <TextFieldContainer
                                key='work_phone'
                                label="Work Phone #"
                                className='work_phone'
                                ref='work_phone'
                                type='text'
                                text={this.props.work_phone}
                                error={missingFields.indexOf('work_phone') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                                formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                            />
                            <TextFieldContainer
                                key='email'
                                label="Email address"
                                className='email'
                                ref='email'
                                type='text'
                                text={this.props.email}
                                error={missingFields.indexOf('email') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                        </div>
                    </div>
                );
                applicationDiv.push(<EligibilityGroup key={'eligibility-group-1'} ref={ref => this.eligibility_group = ref} type={type} index={0} transaction_type={transaction_type} onChange={this.onChangeEligibilityGroup.bind(this)} />);
                break;
            case 'termination':
                buttonText = 'create request';
                applicationDiv.push(
                    <div key={'termination-email'} className='row'>
                        <TextFieldContainer
                            key='company_name'
                            label="Company Name"
                            className='company_name'
                            ref='company_name'
                            type='text'
                            error={missingFields.indexOf('company_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextFieldContainer
                            key='email'
                            label="Email of company contact"
                            className='email'
                            ref='email'
                            type='text'
                            error={missingFields.indexOf('email') > -1}
                            onChange={this.onCompanyContactChange.bind(this)}
                        />
                    </div>
                );
                break;
            case 'qualifying_life_event':
            case 'open_enrollment':
                applicationDiv.push(this._getBasicInfo(true));
                applicationDiv.push(
                    <div key={'qle_oe'}>
                        <div className='row bold'>Company Information</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='company_name'
                                label="Company Name"
                                className='company_name'
                                ref='company_name'
                                type='text'
                                text={this.props.company_name}
                                error={missingFields.indexOf('company_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className='row'>
                            <Address
                                label='Company Address'
                                value={company_address}
                                error={missingFields.indexOf('company_address') > -1}
                                onChange={(address) => this.onChange('company_address', address)}
                            />
                        </div>
                        <div className='row bold'>Company Contact</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='first_name'
                                label="First Name"
                                className='first_name'
                                ref='first_name'
                                type='text'
                                text={this.props.first_name}
                                error={missingFields.indexOf('first_name') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='last_name'
                                label="Last Name"
                                className='last_name'
                                ref='last_name'
                                type='text'
                                text={this.props.last_name}
                                error={missingFields.indexOf('last_name') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                        </div>
                        <div className='row three'>
                            <TextFieldContainer
                                key='title'
                                label="Title"
                                className='title'
                                ref='title'
                                type='text'
                                text={this.props.title}
                                error={missingFields.indexOf('title') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='work_phone'
                                label="Work Phone #"
                                className='work_phone'
                                ref='work_phone'
                                type='text'
                                text={this.props.work_phone}
                                error={missingFields.indexOf('work_phone') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                                formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                            />
                            <TextFieldContainer
                                key='email'
                                label="Email address"
                                className='email'
                                ref='email'
                                type='text'
                                text={this.props.email}
                                error={missingFields.indexOf('email') > -1}
                                onChange={this.onCompanyContactChange.bind(this)}
                            />
                        </div>
                    </div>
                );

                applicationDiv.push(<EligibilityGroup key={'eligibility-group-1'} ref={ref => this.eligibility_group = ref} type={type} index={0} transaction_type={transaction_type} onChange={this.onChangeEligibilityGroup.bind(this)} />);
                break;
            default:
        }
        return (
            <div className='application'>
                <div className="row">
                    <DropdownContainer
                        className='transaction_type'
                        label='Transaction type'
                        options={transactionTypeOptions}
                        error={missingFields.indexOf('transaction_type') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {applicationDiv}
                {transaction_type &&
                    <div className='submit-application'>
                        <ButtonContainer text={buttonText} disabled={submitting} selected={true} theme={['filled', 'double']} onClick={this.validate.bind(this)} />
                        {missingFields.length > 0 &&
                            <div className='submit-application__error'>Errors found above</div>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default BrokerEmployeeApplication;
