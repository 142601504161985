import React, { Component } from 'react';

import { TextFieldContainer, Address } from '../../index';

export default class Location extends Component {
    constructor(props) {
        super(props);

        const {id, name, address, contact_first_name, contact_last_name, contact_phone, contact_fax, contact_email, employee_count} = props;
        this.state = {
            missingFields: [],
            id, name, address, contact_first_name, contact_last_name, contact_phone, contact_fax, contact_email, employee_count
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div className='location'>
                <div className='row'>
                    <div>Additional Company Location #{this.props.index}</div>
                    <img
                        alt=""
                        style={{cursor: 'pointer', marginLeft: 10, marginTop: 3}}
                        className='close'
                        src={require('../../../assets/images/ic-close.svg')}
                        height='12' width='12'
                        onClick={this.props.remove}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='name'
                        label="Location Name"
                        className='name'
                        ref='name'
                        type='text'
                        text={this.state.name}
                        error={missingFields.indexOf('name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <Address
                        label='Company Address'
                        value={this.state.address}
                        error={missingFields.indexOf('address') > -1}
                        onChange={(address) => this.onChange('address', address)}
                    />
                </div>
                <div style={{marginBottom: 10}}>Location Contact</div>
                <div className='row'>
                    <TextFieldContainer
                        key='contact_first_name'
                        label="First Name"
                        className='contact_first_name'
                        ref='contact_first_name'
                        type='text'
                        text={this.state.contact_first_name}
                        error={missingFields.indexOf('contact_first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='contact_last_name'
                        label="Last Name"
                        className='contact_last_name'
                        ref='contact_last_name'
                        type='text'
                        theme='margin-left'
                        text={this.state.contact_last_name}
                        error={missingFields.indexOf('contact_last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row three'>
                    <TextFieldContainer
                        key='contact_phone'
                        label="Phone #"
                        className='contact_phone'
                        ref='contact_phone'
                        type='text'
                        text={this.state.contact_phone}
                        error={missingFields.indexOf('contact_phone') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                    <TextFieldContainer
                        key='contact_fax'
                        label="Fax #"
                        className='contact_fax'
                        ref='contact_fax'
                        type='text'
                        text={this.state.contact_fax}
                        error={missingFields.indexOf('contact_fax') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                    <TextFieldContainer
                        key='contact_email'
                        label="Email Address"
                        className='contact_email'
                        ref='contact_email'
                        type='text'
                        text={this.state.contact_email}
                        error={missingFields.indexOf('contact_email') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='employee_count'
                        label="Total Employee Count"
                        className='employee_count'
                        ref='employee_count'
                        type='text'
                        text={this.state.employee_count}
                        error={missingFields.indexOf('employee_count') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                </div>
            </div>
        )
    }
}
