import React, { Component } from 'react';

import {Checkbox} from '../index';

import './Application.scss';

class RecipientSelect extends Component {
    _getDependentKey() {
        // random guid for dependent for uniqueness
        return 'dependent_' + Math.floor(Math.random()*10000);
    }

    toggleDependent(key, checked) {
        this.props.onCoverageToggle(key, this.props.line_of_coverage, !checked);
    }

    addDependent() {
        this.props.onChange(this._getDependentKey(), null);
    }

    removeDependent(key) {
        this.props.onChange(key, null, true);
    }

    render() {
        const {
            type = 'selectedPlans',
            line_of_coverage,
            // TODO - set default value for `dependents` in Application redux store
            dependents,
            transaction_type,
            selectedPlanName,
            noChanges = false
        } = this.props;

        const me = (
            <div key='me' className='select-container'>
                <div className='select'>
                    <Checkbox
                        checkboxId={'me'}
                        checked
                        disabled
                    />
                    <span>Me</span>
                </div>
            </div>
        )
        const optionsDiv = [me];

        if (['ltd', 'std'].indexOf(line_of_coverage) === -1) {
            Object.keys(dependents).forEach(key => {
                const firstName = dependents[key].first_name || '';
                const plans = dependents[key][type] || [];
                const checked = plans.indexOf(line_of_coverage) > -1;
                optionsDiv.push(
                    <div key={key} className='select-container'>
                        <div className='select'>
                            <Checkbox
                                checkboxId={key}
                                checked={checked}
                                disabled={noChanges}
                                onClick={(event) => this.toggleDependent(key, event)}
                            />
                            <input
                                ref={key}
                                type='text'
                                placeholder='Dependent name'
                                value={firstName}
                                onChange={(event) => this.props.onChange(key, event.target.value)}
                            />
                            <img
                                alt=""
                                className='select-container__close'
                                src={require('../../assets/images/ic-close.svg')}
                                height='12' width='12'
                                onClick={this.removeDependent.bind(this, key)}
                            />
                        </div>
                    </div>
                );
            });

            const add_dependent = (
                <div key='add_dependent' className='select-container dependent' onClick={this.addDependent.bind(this)}>
                    <span className='select'>
                        <img alt="" src={require('../../assets/images/ic_add_circle_outline.svg')} height='24' width='24' />
                        <span>Add Dependent</span>
                    </span>
                </div>
            )
            if (!noChanges) {
                optionsDiv.push(add_dependent);
            }
        }

        let headerText = 'Who would you like your ' + selectedPlanName + ' ' + line_of_coverage + ' plan to cover?';
        if (transaction_type === 'qualifying_life_event' || transaction_type === 'open_enrollment') {
            headerText = 'Who is currently enrolled on this coverage?'
        }

        return (
            <div className='recipient-select'>
                <div className='recipient-select__header'>{headerText}</div>
                <div className='options'>{optionsDiv}</div>
            </div>
        );
    }
}

export default RecipientSelect;
