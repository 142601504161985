import React, { Component } from 'react';

import { ALL_STATES } from '../../utils';
import { validateFields } from '../../utils';

import { TextFieldContainer, DropdownContainer } from '../index';

import RecipientSelect from './RecipientSelect';

class QLEOEOption extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            carrier_id: null,
            state: null,
            plan_id: null,
            missingFields: [],
            dependents: this.props.dependents
        };
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.stateChangeComplete);
    }

    close() {
        this.setState({open: false}, this.stateChangeComplete);
    }

    stateChangeComplete() {
        this.props.onChange(this.state, this.props.line_of_coverage);
    }

    validate() {
        /*
            proper termination option state:
            {
                carrier_id: required,
                state: required,
                plan_id: required
            }
        */
        const {coverage_option, line_of_coverage, currentPlans} = this.props;

        const requiredFields = ['carrier_id', 'state', 'plan_id'];

        const otherPlan = coverage_option.plans.find(p => p.name.toLowerCase() === 'other');
        if (otherPlan && currentPlans[line_of_coverage] && currentPlans[line_of_coverage].plan_id === otherPlan.id) {
            requiredFields.push('other_plan_name');
        }

        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        }, this.stateChangeComplete);
    }

    getLineOfCoverageText() {
        const {line_of_coverage} = this.props;
        let line_of_coverage_text;
        switch(line_of_coverage) {
            case 'std':
                line_of_coverage_text = 'Short-Term Disability';
                break;
            case 'ltd':
                line_of_coverage_text = 'Long-Term Disability';
                break;
            default:
                line_of_coverage_text = line_of_coverage.charAt(0).toUpperCase() + line_of_coverage.slice(1)
        }
        return line_of_coverage_text;
    }

    toggleDependentCoverage(key, lineOfCoverage, remove=false) {
        let {dependents} = this.state;
        let currentPlans = dependents[key].currentPlans || [];

        if (remove) {
            const idx = currentPlans.indexOf(lineOfCoverage);
            if (idx > -1) {
                currentPlans.splice(idx, 1);
            }
        } else {
            currentPlans.push(lineOfCoverage);
        }
        dependents[key].currentPlans = currentPlans;
        console.log(key, currentPlans);
        this.setState({dependents}, this.props.onChange(this.state));
    }

    render() {
        // TODOBT - This is very much broken after refactor
        //          StepQLEOE Component passes in the carrier_name and carrier_state strings
        //           Check if this components needs to use the ID and where it passes it
        const {line_of_coverage, coverage_option, transaction_type, currentPlans} = this.props;
        const {open, missingFields, other_plan_name} = this.state;
        const carrier = {
          id: '',
          name: this.props.carrier_name,
          state: this.propr.carrier_state
        }

        const line_of_coverage_text = this.getLineOfCoverageText();
        let html = (
            <div className='link edit' onClick={this.toggleOpen.bind(this)}>
                {line_of_coverage_text} coverage
            </div>
        );
        if (open) {
            let coverageImage = 'ic-box';
            switch (line_of_coverage) {
                case 'medical':
                    coverageImage = 'ic-medical';
                    break;
                case 'dental':
                    coverageImage = 'ic-dental';
                    break;
                case 'vision':
                    coverageImage = 'ic-vision';
                    break;
                default:
                    coverageImage = 'ic-medical';
            }

            const {plans} = coverage_option;
            const otherPlan = plans.find(p => p.name.toLowerCase() === 'other');
            let otherPlanChosen = false;
            if (otherPlan) {
                otherPlanChosen = currentPlans[line_of_coverage] && currentPlans[line_of_coverage].plan_id === otherPlan.id;
            }

            html = (
                <div>
                    <div className='line-of-coverage'>
                        <img alt="line of coverage" src={require('../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                        <span>{line_of_coverage_text}</span>
                        <img alt="close" className='line-of-coverage__close' src={require('../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this)} />
                    </div>
                    <div className='coverage-details'>
                        <DropdownContainer
                            className='plan_id'
                            label='Plans'
                            options={plans.map(plan => {
                                return {display: plan.name, value: plan.id};
                            })}
                            error={missingFields.indexOf('plan_id') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <DropdownContainer
                            className='carrier_id'
                            label='Carrier Name'
                            options={[{display: carrier.name, value: carrier.id}]}
                            error={missingFields.indexOf('carrier_id') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <DropdownContainer
                            className='state'
                            label='State'
                            options={ALL_STATES.map(state => {
                                return {display: state.name + ' (' + state.id + ')', value: state.id};
                            })}
                            error={missingFields.indexOf('state') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    {otherPlanChosen &&
                        <div className='coverage-details'>
                            <TextFieldContainer
                                key='other_plan_name'
                                label="Plan Name"
                                className='other_plan_name'
                                ref='other_plan_name'
                                type='text'
                                text={other_plan_name}
                                error={missingFields.indexOf('other_plan_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                    <RecipientSelect
                        type='currentPlans'
                        line_of_coverage={line_of_coverage}
                        transaction_type={transaction_type}
                        dependents={this.state.dependents}
                        onCoverageToggle={this.props.toggleDependentCoverage.bind(this)}
                        onChange={this.props.onRecipientChange.bind(this)}
                    />
                </div>
            );
        }

        return (
            <div className='coverage-option'>
                {html}
            </div>
        );
    }
}

export default QLEOEOption;
