import React, { Component } from 'react';

import { ALL_STATES } from '../../../utils';
import { validateFields } from '../../../utils';
import { shouldDisplayCarriers } from '../../../utils';

import { TextFieldContainer, DropdownContainer, RadioButtonQuestionContainer } from '../../index';

import '../Broker.scss';

export default class CoverageOptionEmployer extends Component {
    constructor(props) {
        super(props);

        const {line_of_coverage, plans = [{name: null, index: 0}]} = props;
        const maxPlans = line_of_coverage === 'medical' ? 3 : line_of_coverage === 'dental' ? 2 : line_of_coverage === 'vision' ? 1 : 0;
        this.state = {
            ...props,
            maxPlans,
            plans,
            numPlans: plans.length,
            missingFields: []
        };
    }

    _findPlan(index) {
        const {plans = []} = this.state;
        return plans.find(plan => plan.index === index);
    }

    onPlanChange(key, value, index) {
        let {plans = []} = this.state;
        let plan = this._findPlan(index);
        if (plan) {
            plan[key] = value;
            plans = plans.filter(plan => plan.index !== index);
            plans.push(plan);
        } else {
            plan = {[key]: value, index};
            plans.push(plan);
        }
        plans = plans.sort((a,b) => a.index - b.index);
        this.setState({plans}, this.stateChangeComplete);
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.stateChangeComplete);
    }

    close() {
        this.setState({open: false}, this.stateChangeComplete);
    }

    stateChangeComplete() {
        this.props.onChange(this.state, this.props.line_of_coverage);
    }

    validate() {
        /*
            proper coverage option state:
            {
                carrier_id: required,
                group_id: required,
                line_of_coverage: required,
                termination_window: required,
                waiting_period: required,
                group_id: optional,
                plans: [
                    {
                        name: required
                    }
                ]
            }
        */
        const {plans, line_of_coverage} = this.state;

        let requiredFields = ['carrier_id', 'state', 'contribution_scheme'];
        if (line_of_coverage === 'medical') {
            requiredFields.push('enrolling_employees_count');
            requiredFields.push('waiving_employees_with_qualifying_count');
            requiredFields.push('waiving_employees_without_qualifying_count');

        } else if (line_of_coverage !== 'ltd' && line_of_coverage !== 'std') {
            requiredFields.push('enrolling_employees_count');
            requiredFields.push('waiving_employees_count');
        }
        const missingFields = validateFields(requiredFields, this.state);

        if (line_of_coverage === 'medical' || line_of_coverage === 'dental' || line_of_coverage === 'vision') {
            plans.forEach(plan => {
                if (!plan.name) {
                    missingFields.push('plan_' + plan.index);
                }
            });
        }

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        }, this.stateChangeComplete);
    }

    addPlan() {
        this.setState({
            numPlans: this.state.numPlans + 1
        });
    }

    removePlan(index) {
        const plans = this.state.plans.filter(plan => plan.index !== index);
        this.setState({
            plans,
            numPlans: this.state.numPlans - 1
        }, this.validate);
    }

    getLineOfCoverageText() {
        const {line_of_coverage} = this.props;
        let line_of_coverage_text;
        switch(line_of_coverage) {
            case 'std':
                line_of_coverage_text = 'Short-term disability';
                break;
            case 'ltd':
                line_of_coverage_text = 'Long-term disability';
                break;
            default:
                line_of_coverage_text = line_of_coverage.charAt(0).toUpperCase() + line_of_coverage.slice(1)
        }
        return line_of_coverage_text;
    }

    getPlans() {
        const {line_of_coverage} = this.props;
        const {numPlans, missingFields} = this.state;

        let plansArray = [];
        for (let i = 0; i < numPlans; i++) {
            const key = 'plan_' + i;
            let planTypes = null;
            switch (line_of_coverage) {
                case 'medical':
                    planTypes = ['hmo', 'ppo', 'epo'];
                    break;
                case 'dental':
                    planTypes = ['dhmo', 'ppo'];
                    break;
                default:
            }
            plansArray.push(
                <div className='plan' key={key + '_container'}>
                    <div className='row'>
                        <div>Plan #{i+1}</div>
                        {i > 0 &&
                            <img
                                alt=""
                                className='plan__close'
                                src={require('../../../assets/images/ic-close.svg')}
                                height='12' width='12'
                                onClick={this.removePlan.bind(this, i)}
                            />
                        }
                    </div>
                    <div className='row three'>
                        <TextFieldContainer
                            key={key + '_network'}
                            label={'Network'}
                            className='network'
                            ref={key}
                            type='text'
                            error={missingFields.indexOf(key + '_network') > -1}
                            onChange={(key, value) => this.onPlanChange(key, value, i)}
                        />
                        <TextFieldContainer
                            key={key + '_name'}
                            label={'Plan Name'}
                            className='name'
                            ref={key + '_network'}
                            type='text'
                            error={missingFields.indexOf(key) > -1}
                            onChange={(key, value) => this.onPlanChange(key, value, i)}
                        />
                        <TextFieldContainer
                            key={key + '_code'}
                            label={'Plan Code'}
                            className='code'
                            ref={key + '_network'}
                            type='text'
                            error={missingFields.indexOf(key + '_network') > -1}
                            onChange={(key, value) => this.onPlanChange(key, value, i)}
                        />
                    </div>
                    {planTypes &&
                        <div>
                            <DropdownContainer
                                key='type'
                                label='Plan Type'
                                className='type'
                                ref='type'
                                onChange={(key, value) => this.onPlanChange(key, value, i)}
                                options={planTypes.map(value => {
                                    return {display: value.toUpperCase(), value, selected: this.state.type === value}
                                })}
                            />
                        </div>
                    }
                </div>
            );
        }
        return plansArray;
    }

    filterCarriersList(carriers) {
        const {state} = this.state;
        const {line_of_coverage} = this.props;
        return shouldDisplayCarriers(carriers, state, line_of_coverage);
    }

    render() {
        const {line_of_coverage} = this.props;
        const {open, missingFields, contribution_scheme, maxPlans, numPlans, coverage_type, coverage_level} = this.state;

        const line_of_coverage_text = this.getLineOfCoverageText();
        let html = (
            <div className='link plus' onClick={this.toggleOpen.bind(this)}>
                add {line_of_coverage_text} coverage
            </div>
        );
        if (open) {
            let coverageImage = 'ic-box';
            switch (line_of_coverage) {
                case 'medical':
                    coverageImage = 'ic-medical';
                    break;
                case 'dental':
                    coverageImage = 'ic-dental';
                    break;
                case 'vision':
                    coverageImage = 'ic-vision';
                    break;
                default:
            }

            const contributionSchemeDiv = [
                <DropdownContainer
                    key='contribution_scheme'
                    label="Contribution Scheme"
                    className='contribution_scheme'
                    ref='contribution_scheme'
                    onChange={this.onChange.bind(this)}
                    error={missingFields.indexOf('contribution_scheme') > -1}
                    options={[
                        {display: 'Percentage', value: 'percentage'},
                        {display: 'Fixed Dollar Amount', value: 'fixed'},
                        {display: 'Percentage of Plan', value: 'plan'}
                    ].map(o => {
                        const {display, value} = o;
                        return {display, value, selected: this.state.contribution_scheme === value}
                    })}
                />
            ];
            if (contribution_scheme !== undefined) {
                let text = '%';
                if (contribution_scheme === 'fixed') {
                    text = '$';
                }
                if (contribution_scheme === 'plan') {
                    contributionSchemeDiv.push(
                        <TextFieldContainer
                            key={'base_plan'}
                            label={'Base Plan'}
                            className='base_plan'
                            ref={'base_plan'}
                            type='text'
                            text={this.state.base_plan}
                            error={missingFields.indexOf('base_plan') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    );
                }
                contributionSchemeDiv.push(
                    <TextFieldContainer
                        key={'employee_contribution'}
                        label={'Employee ' + text}
                        className='employee_contribution'
                        ref={'employee_contribution'}
                        type='text'
                        text={this.state.employee_contribution}
                        formatOptions={{numericOnly: true}}
                        error={missingFields.indexOf('employee_contribution') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                );
                contributionSchemeDiv.push(
                    <TextFieldContainer
                        key={'dependent_contribution'}
                        label={'Dependent ' + text}
                        className='dependent_contribution'
                        ref={'dependent_contribution'}
                        type='text'
                        text={this.state.dependent_contribution}
                        formatOptions={{numericOnly: true}}
                        error={missingFields.indexOf('dependent_contribution') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                );
            }

            let countsDiv = [
                (<TextFieldContainer
                    key={'enrolling_employees_count'}
                    label={'Count of Enrolling Employees'}
                    className='enrolling_employees_count'
                    ref={'enrolling_employees_count'}
                    type='text'
                    text={this.state.enrolling_employees_count}
                    formatOptions={{numericOnly: true}}
                    error={missingFields.indexOf('enrolling_employees_count') > -1}
                    onChange={this.onChange.bind(this)}
                />)
            ];
            if (line_of_coverage === 'medical') {
                countsDiv.push(
                    <TextFieldContainer
                        key={'waiving_employees_with_qualifying_count'}
                        label={'Count of Employees Waiving with Qualifying Coverage'}
                        className='waiving_employees_with_qualifying_count'
                        ref={'waiving_employees_with_qualifying_count'}
                        type='text'
                        theme='smaller-placeholder'
                        text={this.state.waiving_employees_with_qualifying_count}
                        formatOptions={{numericOnly: true}}
                        error={missingFields.indexOf('waiving_employees_with_qualifying_count') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                );
                countsDiv.push(
                    <TextFieldContainer
                        key={'waiving_employees_without_qualifying_count'}
                        label={'Count of Employees Waiving without Qualifying Coverage'}
                        className='waiving_employees_without_qualifying_count'
                        ref={'waiving_employees_without_qualifying_count'}
                        type='text'
                        theme='smaller-placeholder'
                        text={this.state.waiving_employees_without_qualifying_count}
                        formatOptions={{numericOnly: true}}
                        error={missingFields.indexOf('waiving_employees_without_qualifying_count') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                );
            } else {
                countsDiv.push(
                    <TextFieldContainer
                        key={'waiving_employees_count'}
                        label={'Count of Waiving Employees'}
                        className='waiving_employees_count'
                        ref={'waiving_employees_count'}
                        type='text'
                        text={this.state.waiving_employees_count}
                        formatOptions={{numericOnly: true}}
                        error={missingFields.indexOf('waiving_employees_count') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                )
            }

            html = (
                <div>
                    <div className='line-of-coverage'>
                        <img alt="" src={require('../../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                        <span>{line_of_coverage_text}</span>
                        <img alt="" className='line-of-coverage__close' src={require('../../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this)} />
                    </div>
                    <div className='row'>
                    {line_of_coverage === 'life' &&
                        <DropdownContainer
                            key='coverage_type'
                            label="Coverage Type"
                            className='coverage_type'
                            ref='coverage_type'
                            onChange={this.onChange.bind(this)}
                            options={[
                                {display: 'Basic Life', value: 'basic'},
                                {display: 'Voluntary Basic Life', value: 'voluntary_basic'},
                                {display: 'Dependent Life', value: 'dependent'},
                                {display: 'Voluntary Dependent Life', value: 'voluntary_dependent'},
                            ].map(o => {
                                const {display, value} = o;
                                return {display, value, selected: this.state.coverage_type === value}
                            })}
                        />
                    }
                    {(line_of_coverage === 'ltd' || line_of_coverage === 'std') &&
                        <DropdownContainer
                            key='coverage_type'
                            label="Coverage Type"
                            className='coverage_type'
                            ref='coverage_type'
                            onChange={this.onChange.bind(this)}
                            options={[
                                {display: 'Short Term Disability', value: 'short_term'},
                                {display: 'Voluntary Short Term Disability', value: 'voluntary_short_term'},
                                {display: 'Long Term Disability', value: 'long_term'},
                                {display: 'Voluntary Long Term Disability', value: 'voluntary_long_term'},
                            ].map(o => {
                                const {display, value} = o;
                                return {display, value, selected: this.state.coverage_type === value}
                            })}
                        />
                    }
                    </div>
                    <div className='row'>
                        <DropdownContainer
                            className='state'
                            label='Carrier State'
                            options={ALL_STATES.map(state => {
                                return {display: state.name + ' (' + state.id + ')', value: state.id, selected: state.id === this.state.state};
                            })}
                            error={missingFields.indexOf('state') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <DropdownContainer
                            className='carrier_id'
                            label='Carrier Name'
                            options={this.filterCarriersList(this.props.carriers).map(carrier => {
                                return {display: carrier.name, value: carrier.id, selected: carrier.id === this.state.carrier_id};
                            })}
                            error={missingFields.indexOf('carrier_id') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    {(line_of_coverage === 'life' && (coverage_type === 'dependent' || coverage_type === 'voluntary_dependent')) &&
                        <div className='row'>
                            <TextFieldContainer
                                key='spouse_plan_name'
                                label="Spouse Plan Name"
                                className='spouse_plan_name'
                                ref='spouse_plan_name'
                                type='text'
                                text={this.state.spouse_plan_name}
                                error={missingFields.indexOf('spouse_plan_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='child_plan_name'
                                label="Child Plan Name"
                                className='child_plan_name'
                                ref='child_plan_name'
                                type='text'
                                text={this.state.child_plan_name}
                                error={missingFields.indexOf('child_plan_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                    <div className='row three'>{contributionSchemeDiv}</div>
                    {(line_of_coverage === 'medical' || line_of_coverage === 'dental' || line_of_coverage === 'vision') &&
                        <div className='row'>
                            <RadioButtonQuestionContainer
                                questionText={'Employer Sponsored Plan?'}
                                options={[
                                    {name: 'employer_sponsored_plan', display: 'no', value: false, selected: (this.state.employer_sponsored_plan !== undefined && !this.state.employer_sponsored_plan)},
                                    {name: 'employer_sponsored_plan', display: 'yes', value: true, selected: this.state.employer_sponsored_plan}
                                ]}
                                error={missingFields.indexOf('employer_sponsored_plan') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                    {(line_of_coverage === 'dental' || line_of_coverage === 'vision') &&
                        <div className='row'>
                            <RadioButtonQuestionContainer
                                questionText={'Packaged with Medical Coverage?'}
                                options={[
                                    {name: 'packaged_with_medical', display: 'no', value: false, selected: (this.state.packaged_with_medical !== undefined && !this.state.packaged_with_medical)},
                                    {name: 'packaged_with_medical', display: 'yes', value: true, selected: this.state.packaged_with_medical}
                                ]}
                                error={missingFields.indexOf('packaged_with_medical') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                    {(line_of_coverage !== 'ltd' && line_of_coverage !== 'std') &&
                        <div className={'row ' + (line_of_coverage === 'medical' ? 'three' : '')}>{countsDiv}</div>
                    }
                    {(line_of_coverage === 'medical' || line_of_coverage === 'dental' || line_of_coverage === 'vision') &&
                        <div className='plans-container'>
                            <div className='plans'>{this.getPlans()}</div>
                            {numPlans < maxPlans &&
                                <div className='link plus' onClick={this.addPlan.bind(this)}>Add Plan</div>
                            }
                        </div>
                    }
                    {(line_of_coverage === 'life' && coverage_type !== 'dependent' && coverage_type !== 'voluntary_dependent') &&
                        <div className='row'>
                            <DropdownContainer
                                key='coverage_level'
                                label="Coverage Level"
                                className='coverage_level'
                                ref='coverage_level'
                                onChange={this.onChange.bind(this)}
                                options={[
                                    {display: 'Flat Amount', value: 'flat'},
                                    {display: '1x Multiple of Salary', value: 'salary_multiple_1'},
                                    {display: '2x Multiple of Salary', value: 'salary_multiple_2'},
                                    {display: 'Graded class', value: 'graded_class'},
                                ].map(o => {
                                    const {display, value} = o;
                                    return {display, value, selected: this.state.coverage_level === value}
                                })}
                            />
                            {coverage_level === 'flat' &&
                                <TextFieldContainer
                                    key={'flat_amount'}
                                    label={'Amount'}
                                    className='flat_amount'
                                    ref={'flat_amount'}
                                    type='text'
                                    text={this.state.flat_amount}
                                    formatOptions={{numeral: true, prefix: '$', noImmediatePrefix: true}}
                                    error={missingFields.indexOf('flat_amount') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            }
                            {(coverage_level === 'salary_multiple_1' || coverage_level === 'salary_multiple_2') &&
                                <TextFieldContainer
                                    key={'maximum_amount'}
                                    label={'Maximum Amount'}
                                    className='maximum_amount'
                                    ref={'maximum_amount'}
                                    type='text'
                                    text={this.state.maximum_amount}
                                    formatOptions={{numeral: true, prefix: '$', noImmediatePrefix: true}}
                                    error={missingFields.indexOf('maximum_amount') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            }
                        </div>
                    }
                    {coverage_level === 'graded_class' &&
                        <div className='row'>
                            <TextFieldContainer
                                key={'graded_class_name'}
                                label={'Class Name'}
                                className='graded_class_name'
                                ref={'graded_class_name'}
                                type='text'
                                text={this.state.graded_class_name}
                                error={missingFields.indexOf('graded_class_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextFieldContainer
                                key={'graded_amount'}
                                label={'Amount'}
                                className='graded_amount'
                                ref={'graded_amount'}
                                type='text'
                                text={this.state.graded_amount}
                                formatOptions={{numeral: true, prefix: '$', noImmediatePrefix: true}}
                                error={missingFields.indexOf('graded_amount') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                </div>
            );
        }

        return (
            <div className={'coverage-option-employer ' + (open ? ' open' : '')}>
                {html}
            </div>
        );
    }
}
