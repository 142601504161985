import React, { Component } from 'react';

import './StatusBar.scss';

class StatusBar extends Component {
    render() {
        const {currentStep = 0, maxSteps = 4} = this.props;
        const percentage = 100 / maxSteps;
        const progress = percentage + (currentStep * percentage);
        const className = 'width' + progress;
        return (
            <div>
                <div className={'header status-bar ' + className} style={{width: progress + '%'}}></div>
                <div className='status-bar-background'></div>
            </div>
        );
    }
}

export default StatusBar;
