import moment from 'moment';

import React, { Component } from 'react';

import {ButtonContainer, StatusBar} from '../../index';

import CompanyInformation from './CompanyInformation';
import ContactInformation from './ContactInformation';
import CompanyBankingInformation from './CompanyBankingInformation';
import Verify from './Verify';

import { get } from 'lodash';

import '../Application.scss';

const STEPS = [
    null, // step 0
    CompanyInformation, // step 1
    ContactInformation, // step 2
    CompanyBankingInformation, // step 3
    Verify // step 4
]

export default class EmployerApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                company_information: {
                    owners: [],
                    subsidiaries: [],
                    locations: [],
                    // group_name: props.application.company.name,
                    email: props.application.email,
                    group_name: '',
                    // wtf - this dupe key overwrote the one right above it
                    // keeping the comments here for when I can figure out
                    // why this was the way it was
                    // email: '',
                    banking: {}
                },
                contact_information: {
                    contacts: []
                }
            },
            currentStep: 0,
            showSuccess: false,
            error: null,
            submitting: false
        }
    }

    componentWillMount() {
        document.title = 'Noyo - Employer Application';
    }

    onChange(data) {

        let {
            formData,
            currentStep
        } = this.state

        if (currentStep === 1) {
            formData.company_information = data;
        } else if (currentStep === 2) {
            formData.contact_information = data;
        } else if (currentStep === 3) {
            formData.company_information.banking = data;
        }

        this.setState({formData}, () => console.log(this.state.formData));
    }

    back(currentStep) {
        this.setState({currentStep}, () => window.scrollTo(0,0));
    }

    continue() {
        const {currentStep} = this.state;
        if (currentStep === 0 || this.currentStepComponent.validate()) {
            let nextStep = currentStep + 1;
            this.setState({
                currentStep: nextStep,
                error: false
            }, () => window.scrollTo(0,0));
        } else {
            this.setState({error: true});
        }
    }

    _getSignature() {
        let imageDataUrl = this.currentStepComponent.signaturePad.toDataURL();
        let imageData = imageDataUrl.replace('data:image/png;base64,','');
        return imageData;
    }

    submit() {
        if (this.currentStepComponent.validate()) {
            const {formData} = this.state;
            const {application} = this.props;
            const signature = this._getSignature();

            formData.signature = signature;
            formData.signature_date = moment();
            this.setState(
                {submitting: true, formData},
                () => this.props.submitEmployerApplication(
                        application.id, this.state.formData,
                        this.showSuccess.bind(this),
                        this.showError.bind(this)
                )
            );
        }
    }

    showSuccess() {
        this.setState({showSuccess: true}, window.scrollTo(0,0));
    }

    showError() {
        this.props.setMessage('Error', 'An error occurred. Please try again.');
        this.setState({submitting: false}, window.scrollTo(0,0));
    }

    render() {
        const { application } = this.props;

        const {
            currentStep,
            formData,
            showSuccess,
            error,
            submitting
        } = this.state;

        let displayDiv;
        if (showSuccess) {
            displayDiv = (
                <div className='employer-application'>
                    <div className='header status'></div>
                    <div className='employer-application__success-container'>
                        <div className='employer-application__success-container__checkmark'>
                            <img alt="" src={require('../../../assets/images/ic-green-check.svg')} height='48' width='48' />
                        </div>
                        <div className='employer-application__success-container__header'>Application Complete!</div>
                        <div className='employer-application__success-container__text'>
                            {"Thanks for submitting your application. Your company's broker will reach out to you with next steps, if necessary."}
                        </div>
                        <div className='employer-application__success-container__text'>Have a great day.</div>
                    </div>
                </div>
            );
        }
        else if (application) {
            const buttonText = currentStep === 0 ? 'get started' : currentStep < (STEPS.length - 1) ? 'continue' : 'submit';
            const buttonOnChange = currentStep < (STEPS.length - 1) ? this.continue.bind(this) : this.submit.bind(this);

            if (currentStep === 0) {
                displayDiv = (
                    <div className='employer-application termination'>
                        <div className='employer-application__container'>
                            <div className='main-text'>
                                <div className='name'>Hello {get(application, 'user.first_name', '##### REPLACE THIS #####')} {get(application, 'user.last_name', '##### REPLACE THIS #####')}!</div>
                                <div>{'To apply for coverage, please complete the following information on behalf of ' + get(application, 'company.name', '##### REPLACE THIS #####') + '. Your broker will take it from there and get your company set up to offer coverage to employees'}</div>
                                <div>This should take about 15 minutes to complete.</div>
                                <div className='bold'></div>
                            </div>
                        </div>
                        <div className='footer initial'>
                            <ButtonContainer text={buttonText} selected={true} theme='filled' onClick={buttonOnChange} />
                        </div>
                    </div>
                );
            } else {
                const CurrentStepComponent = STEPS[currentStep];
                displayDiv = (
                    <div className='employer-application'>
                        <div className='employer-application__container'>
                            <CurrentStepComponent ref={ref => this.currentStepComponent = ref} back={this.back.bind(this)} currentStep={currentStep} application={application} formData={formData} onChange={this.onChange.bind(this)} />
                        </div>
                        <div className={'footer ' + (currentStep < 2 ? 'initial' : '')}>
                            {currentStep > 1 &&
                                <ButtonContainer text={'back'} disabled={submitting} selected={true} theme='filled' onClick={this.back.bind(this, currentStep - 1)} />
                            }
                            {error &&
                                <div className='employer-application__footer__error'>Errors found above</div>
                            }
                            <ButtonContainer text={submitting ? 'submitting...' : buttonText} disabled={submitting} selected={true} theme='filled' onClick={buttonOnChange} />
                        </div>
                    </div>
                )
            }
        }
        return (
            <div>
                <StatusBar currentStep={currentStep} maxSteps={STEPS.length} />
                {displayDiv}
            </div>
        );
    }
}
