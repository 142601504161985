import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import { ButtonContainer } from '../index';
import { capitalize } from '../../utils';

import BrokerEmployeeApplication from './BrokerEmployeeApplication';
import BrokerEmployerApplication from './BrokerEmployerApplication';

import './Broker.scss';

class Broker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            showEmployeeSuccess: false,
            showEmployerSuccess: false,
            carrierName: null,
            companyName: null
        }
    }

    componentWillMount() {
        document.title = 'Noyo - Broker Config Dashboard';

        this.props.getCarriers();
    }

    toggleApplication(type) {
        if (type === this.state.selected) {
            return;
        }

        if (this.brokerApplication && this.brokerApplication.applicationStarted()) {
            const alertData = {
                header: 'Abandon Changes',
                text: 'Would you like to abandon your changes and switch to the ' + type + ' setup flow?',
                successButton: {
                    text: 'Yes',
                    onClick: () => this.setState({selected: type})
                },
                cancelButton: {
                    text: 'No'
                }
            }
            this.props.showAlert(alertData);
        } else {
            this.setState({selected: type});
        }
    }

    onEmployeeSubmit(data) {
        this.props.createEmployeeApplication(
            data,
            this.showEmployeeSuccess.bind(this, data.transaction_type),
            this.onError.bind(this)
        );

        this.setState({
            companyName: data.company_name
        });
    }

    showEmployeeSuccess(transactionType) {
        this.setState({showEmployeeSuccess: true, transactionType}, window.scrollTo(0,0));
    }

    onEmployerSubmit(data) {
        this.props.createEmployerApplication(data, this.showEmployerSuccess.bind(this), this.onError.bind(this));

        const {
            group_name,
            carrier_id
        } = data.initial_information;

        const carrier = this.props.carriers.find(c => c.id === carrier_id);

        let carrierName = null;
        if (carrier) {
            carrierName = carrier.name;
        }

        this.setState({companyName: group_name, carrierName});
    }

    showEmployerSuccess() {
        this.setState({showEmployerSuccess: true}, window.scrollTo(0,0));
    }

    onError(errors) {
        this.props.setMessage('Error', errors[0]);
        this.brokerApplication.enableButton();
    }

    startAgain() {
        this.setState({
            showEmployeeSuccess: false,
            showEmployerSuccess: false,
            carrierName: null,
            companyName: null
        }, console.log(this.state));
    }

    render() {
        const {selected, companyName, showEmployeeSuccess, showEmployerSuccess, transactionType} = this.state;
        // Re-enable employer application flow buttons here
        // const applicationTypes = ['employer', 'employee'];
        const applicationTypes = ['employee'];
        const buttons = applicationTypes.map((value, i) => {
            let subTextDiv = null;
            if (!selected) {
                let subtext;
                if (value === 'employer') {
                    subtext = 'Invite your client to complete their group application';
                } else {
                    subtext = 'Invite your clients to complete their member applications';
                }
                subTextDiv = (<div className='button-subtext'>{subtext}</div>);
            }
            return (
                <div key={value}>
                    <ButtonContainer
                        key={value}
                        text={capitalize(value)}
                        selected={selected === value}
                        theme='gray'
                        onClick={this.toggleApplication.bind(this, value)}
                        image={require(value === 'employee' ? '../../assets/images/ic-person.svg' : '../../assets/images/ic-business.svg')}
                        imageSelected={require(value === 'employee' ? '../../assets/images/ic-person-selected.svg' : '../../assets/images/ic-business-selected.svg')}
                        className={`${value}-button`}
                    />
                    {subTextDiv}
                </div>
            );
        });

        let brokerDiv = null;
        if (showEmployeeSuccess) {
            let header;
            let body;

            if (transactionType === 'termination') {
                header = 'Request Sent';
                body = 'The application to request termination of coverage has been sent. Your client will receive an email inviting them to complete the request online. You can track the status of the change request in the Transaction Dashboard. Once the request has been completed by the user, it will appear in the "In Progress" tab. Until then, you can find it in the "Not Started" tab.';
            } else {
                header = 'Application Complete!';
                body = "Your client(s) will receive an email inviting them to complete the application for " + companyName + "'s insurance. You can track the status of their application in the Transaction Dashboard. Once the client has complete the application, it will appear in the \"In Progress\" tab. Until then, you can find it in the \"Not Started\" tab.";
            }

            brokerDiv = (
                <div className='broker__success-container'>
                    <div className='broker__success-container__checkmark'>
                        <img alt="" src={require('../../assets/images/ic-green-check.svg')} height='48' width='48' />
                    </div>
                    <div className='broker__success-container__header'>{header}</div>
                    <div className='broker__success-container__text'>{body}</div>
                    <ButtonContainer
                        text='start another application'
                        selected={true}
                        theme={['filled', 'double']}
                        onClick={this.startAgain.bind(this)}
                    />
                    <div className='link' onClick={() => browserHistory.push('/')}>View Transaction Dashboard</div>
                </div>
            );
        } else if (showEmployerSuccess) {
            brokerDiv = (
                <div className='broker__success-container'>
                    <div className='broker__success-container__checkmark'>
                        <img alt="" src={require('../../assets/images/ic-green-check.svg')} height='48' width='48' />
                    </div>
                    <div className='broker__success-container__header'>{'Application Complete!'}</div>
                    <div className='broker__success-container__text'>
                        <div>{'The application has been sent to ' + companyName + ' to complete.'}</div>
                        <div>{'The group administrators you sent this application to will receive an email inviting them to complete the application for ' + companyName + '. You can track the status of their application in the Transaction Dashboard. Once the client has completed the application, it will appear in the \'In Progress\' tab. Until then, you can find it in the \'Not Started\' tab.'}</div>
                    </div>
                    <ButtonContainer
                        text='new application'
                        selected={true}
                        theme={['filled', 'double']}
                        onClick={this.startAgain.bind(this)}
                    />
                    <div className='link' onClick={() => browserHistory.push('/')}>View Transaction Dashboard</div>
                </div>
            );
        } else {
            brokerDiv = (
                <div className='broker__container'>
                    <div className='buttons'>{buttons}</div>
                    {selected === 'employer' &&
                        <BrokerEmployerApplication
                            ref={ref => this.brokerApplication = ref}
                            carriers={this.props.carriers}
                            onSubmit={this.onEmployerSubmit.bind(this)}
                        />
                    }
                    {selected === 'employee' &&
                        <BrokerEmployeeApplication
                            ref={ref => this.brokerApplication = ref}
                            carriers={this.props.carriers}
                            onSubmit={this.onEmployeeSubmit.bind(this)}
                        />
                    }
                </div>
            );
        }

        return (
            <div className='broker'>{brokerDiv}</div>
        );
    }
}

export default Broker;
