import produce from 'immer';

const initialState = {
    isLoggedIn: false,
    currentUser: null
};

const userReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case 'USER_LOCATION':
                const { pathname } = action.payload;
                draft.userLocation = pathname;
                break;

            case 'USER_STORE':
                const { user } = action.payload;
                draft.user = !!user;
                draft.currentUser = user;
                break;

            case 'USER_LOGOUT':
                draft = initialState;
                break;

            default:
        }
    });

export default userReducer;
