import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    getTermsofService
} from '../../../actions/application';

import noyoTerms from './noyoTerms';

class Terms extends Component {

    componentDidMount() {
        // in case we don't already have terms
        if (!this.props.carrierTerms) {
            this.props.getTermsofService();
        }
    }

    render() {

        const {
            carrierTerms,
            noyo,
        } = this.props;

        if (!carrierTerms && !noyo) return null;

        let formattedTerms;

        if (noyo) {
            formattedTerms = noyoTerms.split('\n').map((line) => {
                if (line) return <p>{line}</p>;
                return '\n';
            });
        } else {
            formattedTerms = carrierTerms.split('\n').map((line) => {
                if (line) return <p>{line}</p>;
                return '\n';
            });
        }

        return (
            <div classname="terms-container" style={{padding: 20}}>
                <h1>Terms of Use & Privacy Policy</h1>
                {formattedTerms}
            </div>
        );
    }
};

const mapStateToProps = ({ Application }) => {

    const { carrierTerms } = Application;

    return {
        carrierTerms,
    }
}

const mapDispatchToProps = {
    getTermsofService,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Terms);