import { connect } from 'react-redux';
import Alert from './Alert';
import { hideAlert } from '../../../actions/app';

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    hideAlert
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Alert);
