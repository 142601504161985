import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer } from '../../index';

export default class BrokerEmployeeApplication extends Component {
    render() {
        const {
            missingFields,
            full_time_employees_total,
            full_time_employees_in_state,
            full_time_employees_out_state,
            part_time_employees_total,
            part_time_employees_in_state,
            part_time_employees_out_state
        } = this.props;
        return (
            <div className='employee-demographics'>
                <div className='main-header-container border-top'>
                    <div className='text light'>Employee Demographics</div>
                    <div className='sub-text'>Describe the company's employee base</div>
                </div>
                <div className='section-header'>Current Company Employees</div>
                <div className='subheader'>Full-Time</div>
                <div className='row three'>
                    <TextFieldContainer
                        key='full_time_employees_total'
                        label='Total'
                        className='full_time_employees_total'
                        ref='full_time_employees_total'
                        type='text'
                        text={full_time_employees_total}
                        error={missingFields.indexOf('full_time_employees_total') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                    <TextFieldContainer
                        key='full_time_employees_in_state'
                        label='In-State'
                        className='full_time_employees_in_state'
                        ref='full_time_employees_in_state'
                        type='text'
                        text={full_time_employees_in_state}
                        error={missingFields.indexOf('full_time_employees_in_state') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                    <TextFieldContainer
                        key='full_time_employees_out_state'
                        label='Out of State'
                        className='full_time_employees_out_state'
                        ref='full_time_employees_out_state'
                        type='text'
                        text={full_time_employees_out_state}
                        disabled={true}
                        helperText={'Must be greater than or equal to 0'}
                        helperOnlyOnError={true}
                        error={missingFields.indexOf('full_time_employees_out_state') > -1}
                        onChange={this.props.onChange.bind(this)}
                    />
                </div>
                <div className='subheader'>Part-Time</div>
                <div className='row three'>
                    <TextFieldContainer
                        key='part_time_employees_total'
                        label='Total'
                        className='part_time_employees_total'
                        ref='part_time_employees_total'
                        type='text'
                        text={part_time_employees_total}
                        error={missingFields.indexOf('part_time_employees_total') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                    <TextFieldContainer
                        key='part_time_employees_in_state'
                        label='In-State'
                        className='part_time_employees_in_state'
                        ref='part_time_employees_in_state'
                        type='text'
                        text={part_time_employees_in_state}
                        error={missingFields.indexOf('part_time_employees_in_state') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                    <TextFieldContainer
                        key='part_time_employees_out_state'
                        label='Out of State'
                        className='part_time_employees_out_state'
                        ref='part_time_employees_out_state'
                        type='text'
                        text={part_time_employees_out_state}
                        disabled={true}
                        helperText={'Must be greater than or equal to 0'}
                        helperOnlyOnError={true}
                        error={missingFields.indexOf('part_time_employees_out_state') > -1}
                        onChange={this.props.onChange.bind(this)}
                    />
                </div>
                <div className='row three'>
                    <TextFieldContainer
                        key='full_time_equivalents'
                        label='Full-Time Equivalents'
                        className='full_time_equivalents'
                        ref='full_time_equivalents'
                        type='text'
                        text={this.props.full_time_equivalents}
                        helperText='Combine the number of hours of service of all non-full-time employees for the most recent month and divide by 120. Do not include more than 120 hours of service per employee'
                        helperOnHover={true}
                        error={missingFields.indexOf('full_time_equivalents') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                    <TextFieldContainer
                        key='past_year_average'
                        label='Past Year Average'
                        className='past_year_average'
                        ref='past_year_average'
                        type='text'
                        helperText='Add all monthly employees totals together, then divide by number of months company was in business last year'
                        helperOnHover={true}
                        text={this.props.past_year_average}
                        error={missingFields.indexOf('past_year_average') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Does the group meet the definition of a small employer?'}
                        theme='small-text'
                        options={[
                            {name: 'small_employer', display: 'no', value: false, selected: (this.props.small_employer !== undefined && !this.props.small_employer)},
                            {name: 'small_employer', display: 'yes', value: true, selected: this.props.small_employer}
                        ]}
                        error={missingFields.indexOf('small_employer') > -1}
                        onChange={this.props.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Did company have employees other than Group Administrator and spouse during the preceding calendar year?'}
                        theme='small-text'
                        options={[
                            {name: 'employees_other_than_married', display: 'no', value: false, selected: (this.props.employees_other_than_married !== undefined && !this.props.employees_other_than_married)},
                            {name: 'employees_other_than_married', display: 'yes', value: true, selected: this.props.employees_other_than_married}
                        ]}
                        error={missingFields.indexOf('employees_other_than_married') > -1}
                        onChange={this.props.onChange.bind(this)}
                    />
                </div>
                <div className='subheader'>How many employees at the company are eligible for coverage?</div>
                <div className='row'>
                    <TextFieldContainer
                        key='full_time_employees_eligible'
                        label='Full-Time'
                        className='full_time_employees_eligible'
                        ref='full_time_employees_eligible'
                        type='text'
                        text={this.props.full_time_employees_eligible}
                        error={missingFields.indexOf('full_time_employees_eligible') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                    <TextFieldContainer
                        key='part_time_employees_eligible'
                        label='Part-Time'
                        className='part_time_employees_eligible'
                        ref='part_time_employees_eligible'
                        type='text'
                        text={this.props.part_time_employees_eligible}
                        error={missingFields.indexOf('part_time_employees_eligible') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                </div>
                <div className='section-header'>Eligible Employee Information</div>
                <div className='row'>
                    <TextFieldContainer
                        key='eligible_hours_per_week'
                        label='How many hours per week must employees work to be eligible for insurance?'
                        className='eligible_hours_per_week'
                        ref='eligible_hours_per_week'
                        type='text'
                        theme='full-width'
                        text={this.props.eligible_hours_per_week}
                        error={missingFields.indexOf('eligible_hours_per_week') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Are all full-time employees being offered coverage?'}
                        theme='small-text'
                        options={[
                            {name: 'offer_full_time_coverage', display: 'no', value: false, selected: (this.props.offer_full_time_coverage !== undefined && !this.props.offer_full_time_coverage)},
                            {name: 'offer_full_time_coverage', display: 'yes', value: true, selected: this.props.offer_full_time_coverage}
                        ]}
                        error={missingFields.indexOf('offer_full_time_coverage') > -1}
                        onChange={this.props.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    {this.props.offer_full_time_coverage &&
                        <RadioButtonQuestionContainer
                            questionText={'Are these employees working an average of 30 hours per week?'}
                            theme='small-text'
                            options={[
                                {name: 'thirty_hour_week_average', display: 'no', value: false, selected: (this.props.thirty_hour_week_average !== undefined && !this.props.thirty_hour_week_average)},
                                {name: 'thirty_hour_week_average', display: 'yes', value: true, selected: this.props.thirty_hour_week_average}
                            ]}
                            error={missingFields.indexOf('thirty_hour_week_average') > -1}
                            onChange={this.props.onChange.bind(this)}
                        />
                    }
                    {(this.props.offer_full_time_coverage !== undefined && !this.props.offer_full_time_coverage) &&
                        <TextFieldContainer
                            key='eligible_group_class_offered'
                            label='What is the group/class for whom coverage is being offered?'
                            className='eligible_group_class_offered'
                            ref='eligible_group_class_offered'
                            type='text'
                            theme='full-width'
                            text={this.props.eligible_group_class_offered}
                            error={missingFields.indexOf('eligible_group_class_offered') > -1}
                            onChange={this.props.onChange.bind(this)}
                            formatOptions={{numeral: true}}
                        />
                    }
                </div>
                <div className='section-header'>Ineligible Employee Information</div>
                <div className='row'>
                    <DropdownContainer
                        className='ineligible_reason'
                        label='For employees that are ineligible, what is the reason?'
                        theme='full-width'
                        options={
                            [
                                {name: 'Part-Time', id: 'part-time'},
                                {name: 'Union', id: 'union'},
                                {name: 'Non-Management', id: 'non-management'},
                                {name: 'Hourly', id: 'hourly'}
                            ].map(o => {
                            return {display: o.name, value: o.id, selected: this.props.ineligible_reason === o.id};
                        })}
                        error={missingFields.indexOf('ineligible_reason') > -1}
                        onChange={this.props.onChange.bind(this)}
                    />
                </div>
                <div className='section-header'>Out of State Employee Information</div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        className='coverage_outside_us'
                        questionText={'Do any people requesting coverage live or work outside of United States?'}
                        theme='small-text'
                        options={[
                            {name: 'coverage_outside_us', display: 'no', value: false, selected: (this.props.coverage_outside_us !== undefined && !this.props.coverage_outside_us)},
                            {name: 'coverage_outside_us', display: 'yes', value: true, selected: this.props.coverage_outside_us}
                        ]}
                        error={missingFields.indexOf('coverage_outside_us') > -1}
                        onChange={this.props.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='coverage_out_of_state_eligible_count'
                        label='How many out-of-state employees are eligible for coverage?'
                        className='coverage_out_of_state_eligible_count'
                        ref='coverage_out_of_state_eligible_count'
                        type='text'
                        theme='full-width'
                        text={this.props.coverage_out_of_state_eligible_count}
                        error={missingFields.indexOf('coverage_out_of_state_eligible_count') > -1}
                        onChange={this.props.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                </div>
                {this.props.coverage_out_of_state_eligible_count > 0 &&
                    <div className='row'>
                        <TextFieldContainer
                            key='coverage_out_of_state_states'
                            label='In which states are they eligible for coverage?'
                            className='coverage_out_of_state_states'
                            ref='coverage_out_of_state_states'
                            type='text'
                            theme='full-width'
                            text={this.props.coverage_out_of_state_states}
                            error={missingFields.indexOf('coverage_out_of_state_states') > -1}
                            onChange={this.props.onChange.bind(this)}
                        />
                    </div>
                }
            </div>
        )
    }
}
