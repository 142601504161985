import React, { Component } from 'react';

import { getApplicationType } from '../../utils';

import NewHireApplicationContainer from './NewHireApplication';
import TerminationApplicationContainer from './TerminationApplication';
import DemographicApplicationContainer from './DemographicApplicationContainer';
import QLEOEApplicationContainer from './QLEOEApplicationContainer';
import EmployerApplicationContainer from './employer/EmployerApplicationContainer';

import './Application.scss';

import { connect } from 'react-redux';

import {
    setApplicationData,
    setApplicationId,
} from '../../actions/application';

const applicationTypes = {
    'new_hire_enrollment': NewHireApplicationContainer,
    'termination': TerminationApplicationContainer,
    'demographic_change': DemographicApplicationContainer,
    'qualifying_life_event': QLEOEApplicationContainer,
    'open_enrollment': QLEOEApplicationContainer,
    'employer': EmployerApplicationContainer
}

class Application extends Component {
    constructor(props) {
        super(props);

        this.state = {
            application: null
        }
    }

    componentWillMount() {
        document.title = 'Noyo - Employee Application';
        // params is from react-router
        const application_id = this.props.params.application_id;
        this.props.getApplication(
            application_id,
            this.getApplicationDataSuccess.bind(this),
            this.getApplicationError.bind(this)
        );
    }

    getApplicationDataSuccess(application) {
        this.props.setApplicationData(application);
        if (getApplicationType(application) === 'termination') {
            this.props.getCarriers();
        }
    }

    getApplicationError(errors) {
        if (errors[0]=== 'Application has already been completed') {
            this.setState({error: 'already_complete'});
        } else if (errors[0]=== 'Application has expired') {
            this.setState({error: 'expired'});
        } else {
            this.setState({error: 'unknown'});
        }
    }

    render() {
        const { error } = this.state;

        const {
            application,
            submitEmployeeApplication,
            submitEmployerApplication,
            setApplicationDependents,
            setApplicationSelectedPlans,
            setApplicationId,
            setApplicationData,
        } = this.props;

        let displayDiv = (<div className='application-loading'>Loading application...</div>);

        if (error) {
            if (error === 'already_complete') {
                displayDiv = (
                    <div className='employee-application'>
                        <div className='header status'></div>
                        <div className='employee-application__success-container'>
                            <div className='employee-application__success-container__checkmark'>
                                <img alt="" src={require('../../assets/images/ic-green-check.svg')} height='48' width='48' />
                            </div>
                            <div className='employee-application__success-container__header'>Application already complete!</div>
                            <div className='employee-application__success-container__text'>
                                {"This application has already been submitted. Your company's HR representative will reach out to you with next steps, if necessary."}
                            </div>
                            <div className='employee-application__success-container__text'>Have a great day.</div>
                        </div>
                    </div>
                );
            } else if (error === 'expired') {
                displayDiv = (
                    <div className='employee-application'>
                        <div className='header status'></div>
                        <div className='employee-application__success-container'>
                            <div className='employee-application__success-container__checkmark'>
                                <img alt="" src={require('../../assets/images/ic-error.svg')} height='48' width='48' />
                            </div>
                            <div className='employee-application__success-container__header'>Application no longer available</div>
                            <div className='employee-application__success-container__text'>
                                {"This application is no longer available. Please contact your HR representative if you would like to request a change to your health insurance coverage."}
                            </div>
                            <div className='employee-application__success-container__text'>Have a great day.</div>
                        </div>
                    </div>
                );
            } else if (error === 'unknown') {
                displayDiv = (
                    <div className='employee-application'>
                        <div className='header status'></div>
                        <div className='employee-application__success-container'>
                            <div className='employee-application__success-container__checkmark'>
                                <img alt="" src={require('../../assets/images/ic-error.svg')} height='48' width='48' />
                            </div>
                            <div className='employee-application__success-container__header'>Unable to Load Application</div>
                            <div className='employee-application__success-container__text'>
                                {"Unable to load application. Please contact your HR representative if you would like to request a change to your health insurance coverage."}
                            </div>
                            <div className='employee-application__success-container__text'>Have a great day.</div>
                        </div>
                    </div>
                );
            }
        }
        else if (application.isFetched) {
            const carrier_form = application.carrier_form;

            let ApplicationComponent;
            if (!carrier_form) {
                ApplicationComponent = applicationTypes.employer;
            } else {
                ApplicationComponent = applicationTypes[application.carrier_form.form_type];
            }
            displayDiv = (
                <ApplicationComponent
                    application={application}
                    submitEmployeeApplication={submitEmployeeApplication}
                    submitEmployerApplication={submitEmployerApplication}
                    setApplicationDependents={setApplicationDependents}
                    setApplicationSelectedPlans={setApplicationSelectedPlans}
                    setApplicationId={setApplicationId}
                    setApplicationData={setApplicationData}
                />
            );
        }

        return (
            <div>{displayDiv}</div>
        );
    }
}

const mapStateToProps = ({ Application }) => {
    return {
        application: Application.data,
    }
};

const mapDispatchToProps = {
    setApplicationData,
    setApplicationId,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Application);
