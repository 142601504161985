import React, { Component } from 'react';

import TerminatedEmployee from './TerminatedEmployee';

export default class TerminatedEmployees extends Component {
    constructor(props) {
        super(props);

        const {employees = []} = props;
        this.state = {
            employees,
            numEmployees: employees.length
        }
    }

    onEmployeeChange(employeeId, employee) {
        let {employees} = this.state;
        const index = employees.findIndex(e => e.id === employeeId);
        employees[index] = employee;
        this.setState({employees}, () => this.props.onChange(this.state));
    }

    addEmployee() {
        const employee = {id: 'terminated_employee_' + Math.floor(Math.random()*10000)}
        let {employees} = this.state;
        employees.push(employee);
        this.setState({employees});
    }

    removeEmployee(employeeId) {
        let {employees} = this.state;
        employees = employees.filter(e => e.id !== employeeId);
        this.setState({employees}, () => this.props.onChange(this.state));
    }

    render() {
        const {employees} = this.state;
        let employeesDiv = [];
        employees.forEach((e, index) => {
            if (!e.children) {
                e.children = [];
            }
            employeesDiv.push(
                <div key={e.id}>
                    <div className='row'>
                        <div>Terminated Employee #{index + 1}</div>
                        <img
                            alt=""
                            style={{cursor: 'pointer', marginLeft: 10, marginTop: 3}}
                            className='close'
                            src={require('../../../assets/images/ic-close.svg')}
                            height='12' width='12'
                            onClick={this.removeEmployee.bind(this, e.id)}
                        />
                    </div>
                    <TerminatedEmployee key={e.id} {...e} onChange={data => this.onEmployeeChange(e.id, data)} />
                </div>
            );
        });
        return (
            <div className='terminated-employees'>
                {employeesDiv}
                <div className='link plus' onClick={this.addEmployee.bind(this)}>Add Terminated Employee</div>
            </div>
        )
    }
}
