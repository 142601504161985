import { connect } from 'react-redux';
import Broker from './Broker';

import {showAlert, setMessage} from '../../actions/app';
import {getCarriers, createEmployeeApplication, createEmployerApplication} from '../../actions/noyo';

const mapStateToProps = ({User, Noyo}) => {
    return {
        currentUser: User.currentUser,
        carriers: Noyo.carriers
    }
};

const mapDispatchToProps = {
    getCarriers,
    createEmployeeApplication,
    createEmployerApplication,
    showAlert, setMessage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Broker);
