import React, { Component } from 'react';

import './Upload.scss';

class Upload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null
        }
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
      });
    }

    onChange(event) {
        const {onChange} = this.props;

        let file = null;
        let name = null;

        if (event) {
            file = event.target.files[0];
            name = file.name;

            let fileData = {name};

            this.getBase64(file).then(data => {
                fileData.blob = data;
                if (onChange) {
                    onChange(fileData);
                }
            });
        }

        // if no event, file is being removed
        this.setState({name});
        if (onChange) {
            onChange(file);
        }
    }

    render() {
        const {label = 'Upload File'} = this.props;
        const {name} = this.state;
        let uploadDiv;
        if (name) {
            uploadDiv = (
                <div>
                    <div className='file-name'>{name}</div>
                    <div className='remove' onClick={() => this.onChange(null)}>Remove</div>
                </div>
            );
        } else {
            uploadDiv = (
                <span className='link'>
                    <input ref={(ref) => this.upload = ref} type='file' onChange={this.onChange.bind(this)} style={{display: 'none'}} />
                    <label onClick={() => this.upload.click()}>{label}</label>
                </span>
            )
        }
        return (<div className='upload'>{uploadDiv}</div>);
    }
}

export default Upload;
