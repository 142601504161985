import React, { Component, Fragment } from 'react';

import { bindAll } from 'lodash';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import Config from '../../config';

import CoverageSelect from './CoverageSelect';
import RecipientSelect from './RecipientSelect';

import {
    setApplicationDependents,
    setApplicationSelectedPlans,
} from '../../actions/application';

import {
    getPlanDetailsDownload,
} from '../../actions/noyo';

import './Application.scss';

const propTypes = {
    selectedPlans: PropTypes.objectOf(PropTypes.object),
    dependents: PropTypes.objectOf(PropTypes.object),
};

const defaultProps = {
    selectedPlans: {
        medical: {},
    }
};

class StepCoverage extends Component {

    constructor(props) {
        super(props);

        bindAll(this, [
            'validate',
            'onPlanChange',
            'toggleDependentCoverage',
            'onRecipientChange',
        ]);
    }

    onPlanChange(coverage_option, plan) {
        const {
            selectedPlans,
            setApplicationSelectedPlans,
        } = this.props;

        selectedPlans[coverage_option] = plan;
        console.log(coverage_option, plan);
        setApplicationSelectedPlans(selectedPlans);
    }

    toggleDependentCoverage(key, lineOfCoverage, remove=false) {
        if (['std', 'ltd'].indexOf(lineOfCoverage) > -1) {
            return;
        }

        let { dependents } = this.props;
        let selectedPlans = dependents[key].selectedPlans || [];

        if (remove) {
            const idx = selectedPlans.indexOf(lineOfCoverage);
            if (idx > -1) selectedPlans.splice(idx, 1);
        } else {
            selectedPlans.push(lineOfCoverage);
        }

        dependents[key].selectedPlans = selectedPlans;
        console.log(key, selectedPlans);

        this.props.setApplicationDependents(dependents);
    }

    //
    //
    // CHANGE THIS SO THAT THIS LOGIC LIVES IN RECIPIENTSELECT
    // AND HAVE THAT TALK TO REDUX STORE DIRECTLY, INSTEAD OF THIS
    // PASS A HANDLER DOWN PASS IT AROUND PASS THE VALUES BACK UP
    // NONSENSE
    //
    //
    onRecipientChange(key, value, remove=false) {
        const {
            dependents
        } = this.props;

        if (remove) {
            delete dependents[key];
        } else {
            if (!dependents[key]) {
                const coverage_options = this._getCoverageOptions();
                const selectedPlans = [];
                coverage_options.forEach(coverage_option => {
                    if (['std', 'ltd'].indexOf(coverage_option.line_of_coverage) === -1) {
                        selectedPlans.push(coverage_option.line_of_coverage);
                    }
                });
                dependents[key] = {
                    first_name: null,
                    selectedPlans
                };
            }
            dependents[key].first_name = value;
        }

        this.props.setApplicationDependents(dependents);
    }

    validate() {
        // TODO: add validation here
        console.log(this.props);

        const { selectedPlans } = this.props;
        const coverage_options = this._getCoverageOptions();

        let validated = true;
        Config.REACT_APP_LINES_OF_COVERAGE.forEach((option, i) => {
            const coverage_option = coverage_options.find(coverage_option => coverage_option.line_of_coverage === option);
            if (coverage_option) {
                const selectedPlan = selectedPlans[coverage_option.line_of_coverage];
                if (!selectedPlan || !selectedPlan.id) {
                    validated = false;
                } else if (selectedPlan.waived) {
                    // make sure valid waive coverage reason has been chosen
                    // TODO:
                }
            }
        });
        return validated;
    }

    _getCoverageOptions() {
        return this.props.application.carrier_form.eligibility_groups[0].coverage_options;
    }

    render() {
        const {
            application,
            selectedPlans,
            dependents,
        } = this.props;

        const { user } = application;
        const company = application.carrier_form.company;
        const coverage_options = this._getCoverageOptions();

        const selectOptions = [];
        Config.REACT_APP_LINES_OF_COVERAGE.forEach((option, i) => {
            const coverage_option = coverage_options.find(coverage_option => coverage_option.line_of_coverage === option);

            if (coverage_option) {
                const {
                    line_of_coverage,
                    carrier_id,
                    carrier_name,
                    carrier_state,
                } = coverage_option;

                const options = [];
                let selectedPlan = null;
                coverage_option.plans.forEach(plan => {
                    const {
                        id,
                        name,
                        waived,
                        has_plan_details_file
                    } = plan;

                    // "other" plan option is not meant for NHE applications
                    if (name.toLowerCase().indexOf('other') > -1) return;

                    let selected = false;
                    if (selectedPlans[line_of_coverage] && selectedPlans[line_of_coverage].id === id) {
                        selectedPlan = selectedPlans[line_of_coverage];
                        selected = true;
                        selectedPlan.selected = selected;
                        selectedPlan.carrier_id = carrier_id;
                        selectedPlan.carrier_state = carrier_state;
                        options.push(selectedPlan);
                    } else {
                        options.push({
                            name,
                            display: name,
                            value: name.toLowerCase().replace(' ', '_'),
                            id,
                            selected,
                            waived,
                            carrier_id,
                            carrier_name,
                            carrier_state,
                            has_plan_details_file,
                        });
                    }
                });

                selectOptions.push(
                    <Fragment key={'selectedOptionsFragment_' + i}>
                        <div key={'step_coverage_select_' + i}>
                            <CoverageSelect
                                key={'CoverageSelect_' + i}
                                line_of_coverage={line_of_coverage}
                                options={options}
                                selectedPlans={selectedPlans}
                                onChange={this.onPlanChange}
                                getPlanDetailsDownload={this.props.getPlanDetailsDownload.bind(this)}
                            />
                            {(selectedPlan && !selectedPlan.waived) &&
                                <RecipientSelect
                                    key={'RecipientSelect_' + i}
                                    line_of_coverage={line_of_coverage}
                                    selectedPlanName={selectedPlan.name}
                                    dependents={dependents}
                                    onCoverageToggle={this.toggleDependentCoverage}
                                    onChange={this.onRecipientChange}
                                />
                            }
                        </div>
                        <hr key={'line_' + i} className="horizontal-line-separator" />
                    </Fragment>
                );
            }
        });

        return (
            <div>
                <div className='main-text'>
                    <div className='name'>Welcome {user.email}!</div>
                    <div>You are invited to enroll in health insurance through {company.name}.</div>
                    <div>To make your selections, review your choices below, and then answer some questions about yourself to complete your application. If you have questions about your insurance options, reach out to your company’s HR contact.</div>
                    <div className='bold'>You must complete this form at one time. It will take ~5 minutes.</div>
                </div>
                {selectOptions}
            </div>
        );
    }
}

StepCoverage.propTypes = propTypes;
StepCoverage.defaultProps = defaultProps;

const mapStateToProps = ({ Application, Noyo }) => {
    const {
        data,
        formData,
    } = Application;

    const {
        dependents,
        selectedPlans,
    } = formData;

    return {
        application: data,
        dependents,
        selectedPlans,
    }
};

const mapDispatchToProps = {
    setApplicationDependents,
    setApplicationSelectedPlans,
    getPlanDetailsDownload,
};

const ConnectedStepCoverage = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true},
)(StepCoverage);

export default ConnectedStepCoverage;
