import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import {DebounceInput} from 'react-debounce-input';
import {CSVLink} from 'react-csv';

import { formatTransaction } from '../../../utils';

import {ButtonContainer} from '../../index';

import TransactionRow from './TransactionRow.js';

import { Link } from 'react-router';

import './Home.scss';

const COLUMNS = [
    'Type',
    'Client Name',
    'Company',
    'Date Received',
    'Effective date',
    'Priority',
    'Status',
    'Carrier',
    'Transaction type',
    'Coverage type',
    'Associated platform',
    'Submission method',
    'Broker',
    'Account manager',
    'Membership team rep',
    'Notes',
    'Application'
];

const PER_PAGE = 25;

class Home extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);

        const {transactions} = props;

        this.state = {
            currentTab: 0,
            pageNum: 1,
            filter: "",
            transactions: transactions || []
        };
    }

    componentWillReceiveProps(nextProps) {
        let currentTab;
        switch(nextProps.location.query.tab) {
            case 'in_progress':
                currentTab = 0;
                break;
            case 'not_started':
                currentTab = 1;
                break;
            case 'completed':
                currentTab = 2;
                break;
            default:
                currentTab = 0;
        }

        let {transactions, pageNum} = nextProps;
        const newTab = (currentTab !== this.state.currentTab);
        const newTransactions = (transactions && transactions !== this.state.transactions);

        if (newTransactions || newTab) {
            pageNum = 1;
            this.setState({transactions, pageNum, currentTab});
        }
    }

    componentWillMount() {
        document.title = 'Noyo - Transaction Dashboard';
    }

    newApplication() {
        browserHistory.push('/broker');
    }

    filterTransactions(event) {
        const text = event.target.value;
        this.setState({filter: text});
        this.props.getTransactions(text);
    }

    showPriority() {
        const {showPriority, currentTab} = this.state;
        let transactions = this.props.transactions;
        if (currentTab === 0 && !showPriority) {
            transactions = this.state.transactions.filter(t => t.priority);
        }
        this.setState({transactions, showPriority: !showPriority});
    }

    prev() {
        this.setState({pageNum: this.state.pageNum - 1});
    }

    next() {
        this.setState({pageNum: this.state.pageNum + 1});
    }

    render() {
        let classes = ['home'];
        const {currentTab, transactions = [], showPriority = false, pageNum} = this.state;

        let headers = [];
        for (let i = 0; i < 3; i++) {
            // empty columns for spacing
            headers.push(<th key={'empty_' + i}>&nbsp;</th>);
        }
        COLUMNS.forEach((column, i) => {
            const header = (<th key={column + '_' + i}>{column}</th>);
            headers.push(header);
        });

        let rows = [];

        let numPriority = 0;
        transactions.forEach((transaction, index) => {
            if (currentTab) {
                if (currentTab === 1) {
                    if (transaction.status >= 2) {
                        return;
                    }
                } else if (currentTab === 2) {
                    if (transaction.status !== 9) {
                        return;
                    }
                }
            } else {
                if ((transaction.status < 2) || (transaction.status === 9)) {
                    return;
                } else {
                    if (transaction.priority) {
                        numPriority += 1;
                    }
                }
            }

            let uniqueKey = transaction.id + "-" + transaction.status;

            rows.push(
                <TransactionRow
                    key={uniqueKey}
                    transaction={transaction}
                    updateTransaction={this.props.updateTransaction.bind(this)}
                    getTransactionDownload={this.props.getTransactionDownload.bind(this)}
                />
            );
        });

        console.log('done sorting');

        let totalPages = 0
        if (rows.length > 0) {
            const initialTransactionIndex = (pageNum - 1) * PER_PAGE;
            totalPages = Math.ceil(rows.length / PER_PAGE);

            rows = rows.slice(initialTransactionIndex, initialTransactionIndex + PER_PAGE);

            if (rows.length > 11) {
                // empty row for spacing
                rows.push(<tr className='transaction-row empty' key={-1}><td>&nbsp;</td></tr>);
            }
        }

        const tabClass0 = currentTab === 0 ? "tab active" : "tab";
        const tabClass1 = currentTab === 1 ? "tab active" : "tab";
        const tabClass2 = currentTab === 2 ? "tab active" : "tab";

        const searchIcon = (<img alt="" src={require('../../../assets/images/search.svg')} className="filterIcon"/>)

        const minLength = this.props.debounceMinLength || 2;
        const timeout = this.props.debounceTimeout || 800;

        const priorityText = (showPriority ? 'Hide Priority' : 'Show Priority') + ' (' + numPriority + ')';

        const csvData = transactions.map(transaction => {return formatTransaction(transaction, true)});

        return (
            <div className={classes.join(' ')}>
                <div className='nav'>
                    <div className='tabs'>
                        <Link to="/?tab=in_progress"><div className={tabClass0}>In Progress</div></Link>
                        <Link to="/?tab=not_started"><div className={tabClass1}>Not Started</div></Link>
                        <Link to="/?tab=completed"><div className={tabClass2}>Completed</div></Link>
                    </div>
                    {numPriority > 0 &&
                        <div style={{marginRight: 20}}>
                            <ButtonContainer text={priorityText} lowercase image={require('../../../assets/images/ic-alert.svg')} imageSelected={require('../../../assets/images/ic-alert-selected.svg')} selected={showPriority} theme='white' onClick={this.showPriority.bind(this)} />
                        </div>
                    }
                    <div className='filter-container'>
                        {searchIcon}
                        <DebounceInput
                            key='filter'
                            label="filter"
                            className='filter'
                            ref='filter'
                            type='text'
                            placeholder='Filter transactions...'
                            value={this.state.filter}
                            minLength={minLength}
                            debounceTimeout={timeout}
                            onChange={this.filterTransactions.bind(this)}
                        />
                    </div>
                    <ButtonContainer
                        text='NEW APPLICATION'
                        selected={true}
                        theme='filled'
                        onClick={this.newApplication.bind(this)}
                        className="new-application-button"
                    />
                </div>
                <div className='content'>
                    <table>
                        <thead>
                            <tr>{headers}</tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
                <div className='home__footer'>
                    <div className='footer-right'>
                        <CSVLink
                            data={csvData}
                            filename='data.csv'
                            style={{display: 'flex'}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{lineHeight: '20px'}}>{'Download full CSV'}</div>
                                    <img alt="" src={require('../../../assets/images/ic-download-selected.svg')}/>
                                </div>
                        </CSVLink>
                        <div className='pagination'>
                            {totalPages > 0 &&
                                <div style={{marginLeft: 25}}>{pageNum} of {totalPages}</div>
                            }
                            {pageNum > 1 &&
                                <div className='link arrow left' onClick={this.prev.bind(this)}></div>
                            }
                            {pageNum < totalPages &&
                                <div className='link arrow' onClick={this.next.bind(this)}></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
