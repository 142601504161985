import React, { Component } from 'react';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
    ButtonContainer,
    StatusBar
} from '../index';

import {
    setApplicationCurrentStep,
    setApplicationSubmitState,
    setApplicationSignature,
    setApplicationError,
    setApplicationShowSuccess,
} from '../../actions/application';

import { setMessage } from '../../actions/app';

import StepCoverage from './StepCoverage';
import StepPersonal from './StepPersonal';
import StepDependents from './StepDependents';
import StepVerify from './StepVerify';

import './Application.scss';

export const STEPS = [
    StepCoverage, // step 0
    StepPersonal, // step 1
    StepDependents, // step 2
    StepVerify // step 3
]

const propTypes = {
    curentStep: PropTypes.number,
    showSuccess: PropTypes.bool,
    error: PropTypes.any,
    submitting: PropTypes.bool,
};

// TODO move this to redux?
const defaultProps = {
    currentStep: 0,
    showSuccess: false,
    error: null,
    submitting: false
};

class NewHireApplication extends Component {

    constructor(props) {
        super(props);

        this.state = {}

        // make all of the refs once up front because
        // the parent component may not get rerendered?
        // anyways, this works, setting 1 ref to this.props.CurrentStepComponent
        // doesn't really work
        STEPS.forEach(step => {
            if (step && step.WrappedComponent) {
                const name = step.WrappedComponent.name.toLowerCase();
                this[`${name}_ref`] = React.createRef();
            }
        });

    }

    componentWillMount() {
        document.title = 'Noyo - New Hire Application';
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setApplicationCurrentStep(this.props.currentStep);
    }

    continue() {
        const {
            dependents,
            currentStep,
            setApplicationCurrentStep,
            setApplicationError,
        } = this.props;

        const CurrentStepComponent = STEPS[currentStep];
        const validated = this[`${CurrentStepComponent.WrappedComponent.name.toLowerCase()}_ref`].current.validate();

        if (validated) {
            let nextStep = currentStep + 1;
            if (currentStep === 1) {
                if (!dependents || Object.keys(dependents).length === 0) {
                    nextStep += 1;
                }
            }

            setApplicationCurrentStep(nextStep);
            setApplicationError(false);
            window.scrollTo(0, 0);

        } else {
            setApplicationError(true);
        }
    }

    _getSignature(currentStepComponentRef) {
        let imageDataUrl = currentStepComponentRef.current.signaturePad.toDataURL();
        let imageData = imageDataUrl.replace('data:image/png;base64,','');
        return imageData;
    }

    submit() {

        const {
            application,
            currentStep,
            setApplicationSignature,
            setApplicationSubmitState,
            submitEmployeeApplication,
        } = this.props;


        const CurrentStepComponent = STEPS[currentStep];
        const currentStepComponentRef = this[`${CurrentStepComponent.WrappedComponent.name.toLowerCase()}_ref`]
        const signature = this._getSignature(currentStepComponentRef);
        const validated = currentStepComponentRef.current.validate({ signature });

        if (validated) {

            setApplicationSignature(signature);
            setApplicationSubmitState(true);

            // 2nd arg is null because it used to be `formData`
            // but now in this dispatched action I call
            // `getState` *right* before dispatching it to get
            // the latest state. Will remove 2nd arg from function
            // signature later when I have time to track down all
            // instances of this action being used.
            submitEmployeeApplication(
                application.id,
                null,
                this.showSuccess.bind(this),
                this.showError.bind(this)
            );
        }


    }

    showSuccess() {
        this.props.setApplicationShowSuccess(true);
        window.scrollTo(0, 0)
    }

    showError(errors) {

        const error_message = (
            <div className="alert__error_list">
                <ul>
                    {errors.map((err, idx) => ( <li key={idx}>{err}</li>))}
                </ul>
            </div>
        )

        this.props.setMessage('Error', error_message);

        this.props.setApplicationSubmitState(false);
        window.scrollTo(0, 0);

        this.setState(() => {
            return { shouldRepopulateForm: true }
        });
    }

    render() {

        const {
            currentStep,
            showSuccess,
            error,
            submitting,
            application,
        } = this.props;

        const CurrentStepComponent = STEPS[currentStep];

        let displayDiv;
        if (showSuccess) {
            displayDiv = (
                <div className='employee-application'>
                    <div className='header status'></div>
                    <div className='employee-application__success-container'>
                        <div className='employee-application__success-container__checkmark'>
                            <img alt="" src={require('../../assets/images/ic-green-check.svg')} height='48' width='48' />
                        </div>
                        <div className='employee-application__success-container__header'>Application Complete!</div>
                        <div className='employee-application__success-container__text'>
                            {"Thanks for submitting your application. Your company's HR representative will reach out to you with next steps, if necessary."}
                        </div>
                        <div className='employee-application__success-container__text'>Have a great day.</div>
                    </div>
                </div>
            );
        }
        else if (application) {
            const formType = application.carrier_form.form_type;

            const buttonText = currentStep < 3 ? 'continue' : 'submit';
            const buttonOnChange = currentStep < 3 ? this.continue.bind(this) : this.submit.bind(this);

            let ref;
            if (CurrentStepComponent.WrappedComponent) {
                ref = `${CurrentStepComponent.WrappedComponent.name.toLowerCase()}_ref`;
            }

            displayDiv = (
                <div className='employee-application'>
                    <div className='employee-application__container'>
                        <CurrentStepComponent
                            ref={this[ref]}
                            // back={this.back.bind(this)}
                            transaction_type={formType}
                        />

                    </div>
                    <div className='employee-application__footer'>
                        {error && currentStep !== 0 &&
                            <div className='employee-application__footer__error'>Errors found above</div>
                        }

                        {error && currentStep === 0 &&
                            <div className='employee-application__footer__error'>Errors found above. Please enroll or waive each line of coverage above.</div>
                        }

                        <ButtonContainer text={submitting ? 'submitting...' : buttonText} disabled={submitting} selected={true} theme='filled' onClick={buttonOnChange} />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <StatusBar currentStep={currentStep} />
                {displayDiv}
            </div>
        );
    }
}

NewHireApplication.propTypes = propTypes;
NewHireApplication.defaultProps = defaultProps;

const mapStateToProps = ({ Application }) => {

    const {
        currentStep,
        CurrentStepComponent,
        submitting,
        showSuccess,
        error,
        formData,
    } = Application;

    const { dependents } = formData;

    return {
        currentStep,
        dependents,
        CurrentStepComponent,
        submitting,
        showSuccess,
        error,
    }
};

const mapDispatchToProps = {
    setApplicationCurrentStep,
    setApplicationSubmitState,
    setApplicationSignature,
    setApplicationError,
    setApplicationShowSuccess,
    setMessage,
};

const ConnectedNewHireApplication = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewHireApplication);

export default ConnectedNewHireApplication;
