import React, { Component } from 'react';

import T from 'prop-types';

import {ButtonContainer, TextFieldContainer} from '../../index';

class ResetPassword extends Component {
    static propTypes = {
        navigate: T.func,
        onClick: T.func
    }

    constructor(...args) {
        super(...args);

        this.state = {
            password: null,
            submitted: false
        };
    }

    submit(event) {
        event.preventDefault();
        this.setState({submitted: true}, this.props.onClick(this.state));
    }

    onChange(className, text) {
        this.setState({[className]: text});
    }

    displayLogin(e) {
        e.preventDefault();
        window.location.search = '';
        this.props.navigate('login');
        return false;
    }

    render () {
        return (
            <div className="login">
                <div className="signup-form content">
                    <div className='login-header'>Reset Password</div>
                    <form ref='signup' className="form">
                        <div className="row">
                            <TextFieldContainer
                                key='password'
                                label="New password"
                                className='password'
                                ref='password'
                                type='password'
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className="ctaRow center">
                            <ButtonContainer
                                text='Reset Password'
                                disabled={this.state.submitted}
                                selected={true}
                                theme={['filled', 'double']}
                                onClick={this.submit.bind(this)}
                            />
                        </div>
                        <div className='ctaRow reset-password'>
                            <a href="/" className='reset-password' onClick={this.displayLogin.bind(this)}>Cancel</a>
                        </div>
                    </form>
                    <br />
                </div>
            </div>
        );
    }
}

export default ResetPassword;
