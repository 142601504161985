import React, { Component } from 'react';

import { validateFields } from '../../utils';

import {
    TextFieldContainer, DateSelectContainer
} from '../index';

import ContactInformation from './Demographic/ContactInformation';
import EmploymentInformation from './Demographic/EmploymentInformation';
import NameInformation from './Demographic/NameInformation';

import moment from 'moment';

import './Application.scss';


export default class StepDemographicPersonal extends Component {
    constructor(props) {
        super(props);

        const {primary} = props.formData;
        if (!primary.email) {
            primary.email = this.props.application.email;
            primary.first_name = this.props.application.first_name;
            primary.last_name = this.props.application.last_name;
        }
        this.state = {
            primary,
            missingFields: []
        }
    }

    onChange(key, value) {
        let {primary} = this.state;
        primary[key] = value;
        if (value instanceof moment){
            primary[key] = value.format();
        }
        this.setState({primary}, () => this.props.onChange(primary));
    }

    validate() {
        const {primary} = this.state;
        const requiredFields = ['first_name', 'last_name', 'ssn', 'change_effective_date'];

        const missingFields = validateFields(requiredFields, primary);

        let childrenValidated = true;
        childrenValidated &= this.nameInformation.validate();
        childrenValidated &= this.contactInformation.validate();
        childrenValidated &= this.employmentInformation.validate();

        this.setState({missingFields});

        return missingFields.length === 0 && childrenValidated;
    }

    render() {
        const {primary, missingFields} = this.state;
        const {first_name, last_name, middle_name, suffix, ssn, change_effective_date} = primary;
        return (
            <div className='step-personal'>
                <div className='main-text'>
                    <img alt="" src={require('../../assets/images/ic-person.svg')} height='24' width='24' />
                    <div className='name light'>Personal Information</div>
                    <div>Indicate the changes you are requesting to your personal information.</div>
                </div>
                <div className='personal-container'>
                    <div className='personal-container__subheader'>To help your insurance company find your record, please tell us the Name and SSN that are currently in use in your insurance records. You can make updates to this data in the form below.</div>
                    <div className='row'>
                        <TextFieldContainer
                            key='first_name'
                            label="First Name"
                            className='first_name'
                            ref='first_name'
                            type='text'
                            text={first_name}
                            error={missingFields.indexOf('first_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextFieldContainer
                            key='middle_name'
                            label="M.I."
                            className='middle_name'
                            ref='middle_name'
                            type='text'
                            theme='tiny'
                            text={middle_name}
                            onChange={this.onChange.bind(this)}
                            maxLength={1}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='last_name'
                            label="Last Name"
                            className='last_name'
                            ref='last_name'
                            type='text'
                            text={last_name}
                            error={missingFields.indexOf('last_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextFieldContainer
                            key='suffix'
                            label="Suffix"
                            className='suffix'
                            ref='suffix'
                            type='text'
                            theme='tiny'
                            text={suffix}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='ssn'
                            label="Soc. Security #"
                            className='ssn'
                            ref='ssn'
                            type='text'
                            text={ssn}
                            error={missingFields.indexOf('ssn') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                        />
                    </div>
                </div>
                <div className='demographic-information'>
                    <NameInformation ref={ref => this.nameInformation = ref} primary={primary} onChange={(state) => this.onChange('name_info', state)} />
                    <ContactInformation ref={ref => this.contactInformation = ref} primary={primary} onChange={(state) => this.onChange('contact_info', state)} />
                    <EmploymentInformation ref={ref => this.employmentInformation = ref} primary={primary} onChange={(state) => this.onChange('employment_info', state)} />
                    <div className='row margin-top'>
                        <DateSelectContainer
                            key='change_effective_date'
                            label="When should these changes take effect?"
                            className='change_effective_date'
                            ref='change_effective_date'
                            type='text'
                            top={true}
                            value={change_effective_date ? moment(change_effective_date) : null}
                            startDate={moment().startOf('month')}
                            minDate={moment().subtract(3, 'months')}
                            error={missingFields.indexOf('change_effective_date') > -1}
                            onChange={(key, val) => this.onChange(key, val, 'new')}
                            formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
