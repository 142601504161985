import { connect } from 'react-redux';

import { setMessage } from '../../actions/app';

import DemographicApplication from './DemographicApplication';

const mapStateToProps = ({ User, Noyo }) => {
    return {};
};

const mapDispatchToProps = {
    setMessage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemographicApplication);
