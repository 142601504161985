import { connect } from 'react-redux';
import DateSelect from './DateSelect';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DateSelect);
