import React, { Component } from 'react';
import { bindAll } from 'lodash';
import {MDCMenu} from '@material/menu/dist/mdc.menu';

import './DropdownMenu.scss';

export default class DropdownMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }

        bindAll(this, [
            'handleClick',
        ]);

        this.dropdown = React.createRef();
    }

    componentDidMount() {
        this.menu = new MDCMenu(this.refs.menu);
        this.menu.listen('MDCMenu:cancel', () => {
            this.setState({open: false});
        });
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick(e) {
        if (this.dropdown.current.contains(e.target)) return;
        this.setState(() => {
            return { open: false }
        });
    }

    toggleMenu(cb) {
        const open = !this.state.open;
        this.setState(() => {
            return { open }
        });
    }

    itemClick(i) {
        this.toggleMenu();
        this.props.onChange(i);
    }

    render() {
        const {disabled, theme, className, selectedComponent, options} = this.props;

        let classes = 'dropdown-menu';
        if (disabled) {
            classes += ' disabled';
        }
        if (theme) {
            classes += ' ' + theme;
        }
        if (className) {
            classes += ' ' + className;
        }
        if (this.state.open) {
            classes += ' open';
        }

        const menuItems = options.map(option => {
            const {display, value, component} = option;
            return (
                <li key={'dropdown_' + value} className='mdc-list-item' role='menuitem' tabIndex='0' value={value} onClick={this.itemClick.bind(this, value)}>
                    {component || display || value}
                </li>
            );
        });

        return (
            <div className={classes} ref={this.dropdown}>
                <div className="mdc-menu-anchor" ref="toolbar">
                    <div className='mdc-menu-selected' onClick={this.toggleMenu.bind(this)}>
                        <span>{selectedComponent}</span>
                        <span className='mdc-menu-selected__arrow'></span>
                    </div>
                    <div className="mdc-menu mdc-menu-surface" ref="menu">
                        <ul className="mdc-menu__items mdc-list" role="menu" aria-hidden="true">
                            {menuItems}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
