import React, { Component } from 'react';
import T from 'prop-types';

import './Dropdown.scss';

import {MDCSelect} from '@material/select/dist/mdc.select';

class Dropdown extends Component {
    static propTypes = {
        options: T.array,
        onChange: T.func
    };

    constructor(props) {
        super(props);

        this.state = {
            dropdownVisible: false
        }

        this.p = this.p.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    componentDidMount () {
        document.addEventListener('click', this.handleOutsideClick, false);
        const select = new MDCSelect(this.refs.select);
        select.listen('change', () => {
            const {className} = this.props;
            const value = select.value;
            this.props.onChange(className, value);
        });
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }

    componentWillReceiveProps(nextProps) {
        let {options} = nextProps;
        if (this.props.options !== nextProps.options) {
            options = options.filter(o => o.selected);
            if (options && options[0]) {
                const select = new MDCSelect(this.refs.select);
                select.label_.float(true);
            }
        }
    }

    onChange(event) {
        const {className} = this.props;
        const value = event.target.id;

        this.props.onChange(className, value);
    }

    p(n) {
        const area = this.refs.area;
        if (n === area) {
            return true;
        }
        let parent = n.parentNode;
        if (parent){
            if (parent === area) {
                return true;
            }
            return this.p(parent);
        }
        return false;
    }

    handleOutsideClick(e) {
        if (!this.p(e.target)) {
            this.toggleDropdown(false);
        }
    }

    toggleDropdown(bool) {
        this.setState({
            dropdownVisible: typeof(bool) === 'boolean' ? bool : !this.state.dropdownVisible
        });
    }

    render() {
        const {theme, label, error = false, helperText, className} = this.props;
        let customHelperText = helperText;

        let options = [];
        let selectedOption = {};
        this.props.options.forEach((option, i) => {
            const {value, display, selected} = option;
            if (selected) {
                selectedOption = option;
            }
            options.push(
                <option key={'dropdown_option_'+i} selected={selected} value={value}>{display ? display : value}</option>
            );
        });

        let classNames = `${className} dropdown no-select`;
        if (theme) {
            if (theme.constructor === Array) {
                theme.forEach(t => {
                    classNames += ' ' + t;
                });
            } else {
                classNames += ' ' + theme;
            }
        }
        if (error) {
            classNames += ' error';
            if (!helperText) {
                customHelperText = 'Required';
            }
        }

        let floatLabelClass = 'asdf mdc-floating-label';
        if (!!selectedOption) {
            floatLabelClass += ' mdc-floating-label--float-above mdc-floating-label';
        }

        return (
            <div className={classNames}>
                <div className="mdc-select" ref="select">
                  <select className="mdc-select__native-control">
                    <option value="" disabled selected={!!selectedOption}></option>
                    {options}
                  </select>
                  <label className={floatLabelClass}>{this.props.label}</label>
                  <div className="mdc-line-ripple"></div>
                </div>
                <div className={'helperText ' + (error ? 'error' : '')}>{customHelperText}</div>
            </div>
        );
    }
}

export default Dropdown;
