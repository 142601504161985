// Custom NOYO config vars can be assigned here -
// anything in process.env will overwrite what is insisde the first
// object within Object.assign
const Config = Object.assign({
	REACT_APP_LINES_OF_COVERAGE: ['medical', 'dental', 'vision', 'life', 'std', 'ltd'],
	REACT_APP_BASE_API_URL: getApiUrl()
}, process.env);

function getApiUrl() {
    if (process.env.NODE_ENV === 'development') {
        return `${process.env.REACT_APP_API_LOCAL_DOMAIN}/api`
    } else {
        if (window.location.hostname.includes('localhost')) {
            return `${process.env.REACT_APP_API_LOCAL_DOMAIN}/api`
        } else if (window.location.hostname.includes('development')) {
            return `${process.env.REACT_APP_API_DEVELOPMENT_DOMAIN}/api`
        } else if (window.location.hostname.includes('staging')) {
            return `${process.env.REACT_APP_API_STAGING_DOMAIN}/api`
        } else {
            return `${process.env.REACT_APP_API_PRODUCTION_DOMAIN}/api`
        }
    }
}


export default Config;
