import React, { Component } from 'react';

import { validateFields } from '../../../utils';

import moment from 'moment';

import {TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, DateSelectContainer} from '../../index';

import '../Application.scss';


export default class EmploymentInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            missingFields: [],
            new: {},
            current: {}
        }
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        });
    }

    onChange(key, value, type) {
        if (type) {
            let typeInfo = this.state[type];
            typeInfo[key] = value;
            this.setState({[type]: typeInfo}, this.props.onChange(this.state));
        } else {
            this.setState({[key]: value}, this.props.onChange(this.state));
        }
    }

    validate() {
        const requiredFields = [];
        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields, open} = this.state;
        return (
            <div>
                <div className={'link arrow' + (open ? ' open' : '')} onClick={this.toggleOpen.bind(this)}>
                    Change Employment Information
                </div>
                {open &&
                <div className='section-container'>
                    <div className='section'>
                        <div className='section__header'>Employment Change</div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <DateSelectContainer
                                    key='hire_date'
                                    label="Hire Date"
                                    className='hire_date'
                                    ref='hire_date'
                                    type='text'
                                    value={this.state.hire_date ? moment(this.state.hire_date) : null}
                                    startDate={moment().startOf('month')}
                                    minDate={moment().subtract(3, 'months')}
                                    error={missingFields.indexOf('hire_date') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'occupation'}
                                    ref={'occupation'}
                                    text={this.state.occupation}
                                    label={'Occupation'}
                                    className={'occupation'}
                                    error={missingFields.indexOf('occupation') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                />
                            </div>
                            <div className='row'>
                                <DropdownContainer
                                    key='employment_status'
                                    label="Employment Status"
                                    className='employment_status'
                                    ref='employment_status'
                                    type='text'
                                    error={missingFields.indexOf('employment_status') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    options={[
                                        {display: 'Full time', value: 'full-time', selected: this.state.employment_status === 'full-time'},
                                        {display: 'Part time', value: 'part-time', selected: this.state.employment_status === 'part-time'},
                                        {display: 'Contract', value: 'contract', selected: this.state.employment_status === 'contract'},
                                        {display: 'Disabled', value: 'disabled', selected: this.state.employment_status === 'disabled'},
                                        {display: 'Retired', value: 'retired', selected: this.state.employment_status === 'retired'}
                                    ]}
                                />
                            </div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <DateSelectContainer
                                    key='hire_date'
                                    label="Hire Date"
                                    className='hire_date'
                                    ref='hire_date'
                                    type='text'
                                    value={this.state.hire_date ? moment(this.state.hire_date) : null}
                                    startDate={moment().startOf('month')}
                                    minDate={moment().subtract(3, 'months')}
                                    error={missingFields.indexOf('hire_date') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'occupation'}
                                    ref={'occupation'}
                                    text={this.state.occupation}
                                    label={'Occupation'}
                                    className={'occupation'}
                                    error={missingFields.indexOf('occupation') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                />
                            </div>
                            <div className='row'>
                                <DropdownContainer
                                    key='employment_status'
                                    label="Employment Status"
                                    className='employment_status'
                                    ref='employment_status'
                                    type='text'
                                    error={missingFields.indexOf('employment_status') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    options={[
                                        {display: 'Full time', value: 'full-time', selected: this.state.employment_status === 'full-time'},
                                        {display: 'Part time', value: 'part-time', selected: this.state.employment_status === 'part-time'},
                                        {display: 'Contract', value: 'contract', selected: this.state.employment_status === 'contract'},
                                        {display: 'Disabled', value: 'disabled', selected: this.state.employment_status === 'disabled'},
                                        {display: 'Retired', value: 'retired', selected: this.state.employment_status === 'retired'}
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>Salary Change</div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'income'}
                                    ref={'income'}
                                    text={this.state.income}
                                    label={'Income'}
                                    className={'income'}
                                    error={missingFields.indexOf('income') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    formatOptions={{numeral: true, prefix: '$', noImmediatePrefix: true}}
                                />
                                <DropdownContainer
                                    key='income_unit'
                                    label='Frequency'
                                    className='income_unit'
                                    ref='income_unit'
                                    type='text'
                                    theme={['small', 'margin-left']}
                                    formatOptions={{numericOnly: true}}
                                    error={missingFields.indexOf('income_unit') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    options={[
                                        {display: 'Annual', value: 'annual', selected: this.state.income_unit === 'annual'},
                                        {display: 'Month', value: 'month', selected: this.state.income_unit === 'month'},
                                        {display: 'Week', value: 'week', selected: this.state.income_unit === 'week'},
                                        {display: 'Hour', value: 'hour', selected: this.state.income_unit === 'hour'}
                                    ]}
                                />
                            </div>
                            <div className='row radio'>
                                <RadioButtonQuestionContainer
                                    questionText='What type of employee are you?'
                                    options={[
                                        {name: 'income_type', value: 'hourly', selected: this.state.income_type === 'hourly'},
                                        {name: 'income_type', value: 'salary', display: 'salaried', selected: this.state.income_type === 'salary'}
                                    ]}
                                    error={missingFields.indexOf('income_type') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'new')}
                                    className='income_type'
                                />
                            </div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <TextFieldContainer
                                    key={'income'}
                                    ref={'income'}
                                    text={this.state.income}
                                    label={'Income'}
                                    className={'income'}
                                    error={missingFields.indexOf('income') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    formatOptions={{numeral: true, prefix: '$', noImmediatePrefix: true}}
                                />
                                <DropdownContainer
                                    key='income_unit'
                                    label='Frequency'
                                    className='income_unit'
                                    ref='income_unit'
                                    type='text'
                                    theme={['small', 'margin-left']}
                                    formatOptions={{numericOnly: true}}
                                    error={missingFields.indexOf('income_unit') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    options={[
                                        {display: 'Annual', value: 'annual', selected: this.state.income_unit === 'annual'},
                                        {display: 'Month', value: 'month', selected: this.state.income_unit === 'month'},
                                        {display: 'Week', value: 'week', selected: this.state.income_unit === 'week'},
                                        {display: 'Hour', value: 'hour', selected: this.state.income_unit === 'hour'}
                                    ]}
                                />
                            </div>
                            <div className='row radio'>
                                <RadioButtonQuestionContainer
                                    questionText='What type of employee are you?'
                                    options={[
                                        {name: 'income_type', value: 'hourly', selected: this.state.income_type === 'hourly'},
                                        {name: 'income_type', value: 'salary', display: 'salaried', selected: this.state.income_type === 'salary'}
                                    ]}
                                    error={missingFields.indexOf('income_type') > -1}
                                    onChange={(key, val) => this.onChange(key, val, 'current')}
                                    className='income_type'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
