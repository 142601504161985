import moment from 'moment';

import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, Address, DateSelectContainer } from '../../index';

import SpouseChild from './SpouseChild';

export default class CobraEmployee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props
        };
    }

    stateChangeComplete() {
        this.props.onChange(this.state);
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.stateChangeComplete);
    }

    onChildUpdate(index, child) {
        let {children} = this.state;
        children = children.filter(c => c.index !== index);
        children.push(child);
        this.setState({children}, this.stateChangeComplete);
    }

    onSpouseUpdate(spouse) {
        this.setState({spouse}, this.stateChangeComplete)
    }

    render() {
        const {missingFields} = this.state;
        let childrenDiv = [];
        for (let i = 1; i <= parseInt(this.state.num_cobra_children); i++) {
            childrenDiv.push(
                <SpouseChild index={i} isChild={true} onChange={child => this.onChildUpdate(i, child)} />
            )
        }
        return (
            <div className='cobra-employee'>
                <div className='row'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={this.state.first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        text={this.state.last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <Address
                        label='Address'
                        value={this.state.address}
                        error={missingFields.indexOf('address') > -1}
                        onChange={(address) => this.onChange('address', address)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='phone'
                        label="Phone #"
                        className='phone'
                        ref='phone'
                        type='text'
                        text={this.state.phone}
                        error={missingFields.indexOf('phone') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                    <TextFieldContainer
                        key='email'
                        label="Email address"
                        className='email'
                        ref='email'
                        type='text'
                        text={this.state.email}
                        error={missingFields.indexOf('email') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='Sex'
                        options={[
                            {name: 'sex', value: 'F', display: 'female', selected: this.state.sex === 'F'},
                            {name: 'sex', value: 'M', display: 'male', selected: this.state.sex === 'M'}
                        ]}
                        error={missingFields.indexOf('sex') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='dob'
                        label="Date of Birth"
                        className='dob'
                        ref='dob'
                        type='text'
                        text={this.state.dob}
                        error={missingFields.indexOf('dob') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                    />
                    <TextFieldContainer
                        key='ssn'
                        label="Social Security Number"
                        className='ssn'
                        ref='ssn'
                        type='text'
                        text={this.state.ssn}
                        error={missingFields.indexOf('ssn') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                    />
                </div>
                <div className='row'>
                    Type of coverage eligible for question - need more info
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='qualifying_event'
                        label='What was the qualifying event?'
                        className='qualifying_event'
                        ref='qualifying_event'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Employment Termination', value: 'termination'},
                            {display: 'Disability', value: 'disability'},
                            {display: 'Reduction in Work Hours', value: 'work_hours_reduction'},
                            {display: 'Ex-Spouse of Employee', value: 'ex_spouse'},
                            {display: 'Surviving Dependent(s) of Employee', value: 'surviving_dependent'},
                            {display: 'Dependent Child\'s Age Exceeds Eligibility' , value: 'dependent_child_age_exceeds_eligibility'},

                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.qualifying_event === value}
                        })}
                    />
                </div>
                <div className='row'>
                    <DateSelectContainer
                        key='qualifying_event_date'
                        className='qualifying_event_date'
                        label='Date of the qualifying event?'
                        value={this.state.qualifying_event_date ? moment(this.state.qualifying_event_date) : null}
                        minDate={moment().subtract(3, 'months')}
                        startDate={moment().startOf('month')}
                        error={missingFields.indexOf('qualifying_event_date') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='Is the person currently covered under another group policy or Medicare?'
                        options={[
                            {name: 'group_policy_medicare', value: false, display: 'No', selected: (this.state.group_policy_medicare !== undefined && !this.state.group_policy_medicare)},
                            {name: 'group_policy_medicare', value: true, display: 'Yes', selected: this.state.group_policy_medicare}
                        ]}
                        error={missingFields.indexOf('group_policy_medicare') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.group_policy_medicare &&
                    <div className='row'>
                        <DateSelectContainer
                            key='group_policy_medicare_effective_date'
                            className='group_policy_medicare_effective_date'
                            label={'What is the coverage\'s effective date?'}
                            value={this.state.group_policy_medicare_effective_date ? moment(this.state.group_policy_medicare_effective_date) : null}
                            minDate={moment().subtract(3, 'months')}
                            startDate={moment().startOf('month')}
                            error={missingFields.indexOf('group_policy_medicare_effective_date') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='Does the employee have a spouse on COBRA?'
                        options={[
                            {name: 'cobra_spouse', value: false, display: 'No', selected: (this.state.cobra_spouse !== undefined && !this.state.cobra_spouse)},
                            {name: 'cobra_spouse', value: true, display: 'Yes', selected: this.state.cobra_spouse}
                        ]}
                        error={missingFields.indexOf('cobra_spouse') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.cobra_spouse &&
                    <SpouseChild {...this.state.spouse} onChange={spouse => this.onSpouseUpdate(spouse)} />
                }
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='Does the employee have any children on COBRA?'
                        options={[
                            {name: 'cobra_children', value: false, display: 'No', selected: (this.state.cobra_children !== undefined && !this.state.cobra_children)},
                            {name: 'cobra_children', value: true, display: 'Yes', selected: this.state.cobra_children}
                        ]}
                        error={missingFields.indexOf('cobra_spouse') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.cobra_children &&
                    <div className='row'>
                        <TextFieldContainer
                            key='num_cobra_children'
                            label="How many children?"
                            className='num_cobra_children'
                            ref='num_cobra_children'
                            type='text'
                            text={this.state.num_cobra_children}
                            error={missingFields.indexOf('num_cobra_children') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numeral: true}}
                        />
                    </div>
                }
                {this.state.cobra_children &&
                    childrenDiv
                }
            </div>
        )
    }
}
