import React, { Component } from 'react';

import PriorCoverageOption from './PriorCoverageOption';

const COVERAGE_OPTIONS = ['medical', 'dental', 'vision', 'life', 'std', 'ltd'];

export default class OtherCoverageInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props.other_coverage_information
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange('other_coverage_information', this.state));
    }

    validate() {
        return true;
    }

    onPriorCoverageOptionChange(value, line_of_coverage) {
        let coverage_options = this.state.coverage_options || [];
        coverage_options = coverage_options.filter(option => option.line_of_coverage !== line_of_coverage)
        if (value.open) {
            value.line_of_coverage = line_of_coverage;
            coverage_options.push(value);
        }
        this.onChange('coverage_options', coverage_options);
    }

    getPriorCoverageOptions() {
        const {coverage_options = []} = this.state;
        let options = [];
        COVERAGE_OPTIONS.forEach((option, index) => {
            options.push(
                <PriorCoverageOption
                    ref={ref => this[`coverage_option_${option}`] = ref}
                    key={option} index={index}
                    line_of_coverage={option}
                    {...coverage_options.filter(c => c.line_of_coverage === option)[0]}
                    onChange={this.onPriorCoverageOptionChange.bind(this)}
                />
            );
        });
        return options;
    }

    render() {
        return (
            <div className='other-coverage-information'>
                <div className='main-header-container border-top'>
                    <div className='text light'>Other Coverage Information</div>
                    <div className='sub-text'>Details about any current or prior coverage for the company</div>
                </div>
                <div className='section-header'>Prior Coverage</div>
                <div>{this.getPriorCoverageOptions()}</div>
            </div>
        );
    }
}
