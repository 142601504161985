import { connect } from 'react-redux';
import { Account } from './Account';

import { setMessage } from '../../../actions/app';
import { loginOrRegister, forgotPassword, resetPassword } from '../../../actions/user';

const mapStateToProps = ({User}, ownProps) => {
    return {
    	currentUser: User.currentUser,
        location: window.location
    };
};

const mapDispatchToProps = {
    loginOrRegister, forgotPassword, resetPassword, setMessage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Account);
