import React, { Component } from 'react';

import { validateFields, getApplicationType } from '../../utils';

import moment from 'moment';

import {TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, DateSelectContainer} from '../index';

import './Application.scss';


class EmploymentInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applicationType: getApplicationType(this.props.application),
            missingFields: [],
            ...this.props
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({...nextProps});
        }
    }

    onChange(key, value) {
        if (key === 'income') {
            value = value.replace(/[$,]/g , '');
        } else if (key === 'hire_date') {
            // Comes in as a Moment obj
            if (value instanceof moment) {
                value = value.format('MM/DD/YYYY')
            }
        }
        this.setState({[key]: value}, this.props.onChange(key, value));
    }

    validate() {
        let requiredFields = ['hire_date', 'occupation', 'employment_status', 'hours_worked'];

        switch(this.state.applicationType) {
            case 'new_hire_enrollment':
                const newHireRequiredFields = ['union_member', 'income', 'income_unit', 'income_type'];
                requiredFields = requiredFields.concat(newHireRequiredFields);
                break;
            case 'termination':
                requiredFields = requiredFields.concat(['termination_reason', 'last_work_date', 'last_coverage_date']);
                break;
            default:
        }

        const missingFields = validateFields(requiredFields, this.state);

        if (parseInt(this.state.hours_worked) >= 100) {
            missingFields.push('hours_worked_max')
        }

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields, applicationType} = this.state;
        const {
            hire_date, full_time_start_date, occupation,
            employment_status, union_member, hours_worked,
            income, income_unit, income_type,
            termination_reason, last_work_date, last_coverage_date
        } = this.props;

        let hireDateDiv = null;
        let isTermination = false;
        if (applicationType === 'termination') {
            isTermination = true;
            hireDateDiv = (
                <TextFieldContainer
                    key='hire_date'
                    label="Hire Date"
                    className='hire_date'
                    ref='hire_date'
                    type='text'
                    text={hire_date}
                    error={missingFields.indexOf('hire_date') > -1}
                    onChange={this.onChange.bind(this)}
                    formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                />
            );
        } else {
            hireDateDiv = (
                <DateSelectContainer
                    key='hire_date'
                    label="Hire Date"
                    className='hire_date'
                    ref='hire_date'
                    type='text'
                    value={hire_date ? moment(hire_date) : null}
                    startDate={moment().startOf('month')}
                    minDate={moment().subtract(100, 'years')}
                    error={missingFields.indexOf('hire_date') > -1}
                    onChange={this.onChange.bind(this)}
                    formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                />
            );
        }
        return (
            <div className='employment'>
                <div className='row'>{hireDateDiv}</div>
                {!isTermination &&
                    <div className='row'>
                        <DateSelectContainer
                            key='full_time_start_date'
                            label="Full Time Start Date"
                            className='full_time_start_date'
                            ref='full_time_start_date'
                            type='text'
                            value={full_time_start_date ? moment(full_time_start_date) : null}
                            startDate={moment().startOf('month')}
                            minDate={moment().subtract(100, 'years')}
                            error={missingFields.indexOf('full_time_start_date') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                        />
                    </div>
                }
                <div className='row'>
                    <TextFieldContainer
                        key='occupation'
                        label="Occupation"
                        className='occupation'
                        ref='occupation'
                        type='text'
                        text={occupation}
                        error={missingFields.indexOf('occupation') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='employment_status'
                        label="Employment Status"
                        className='employment_status'
                        ref='employment_status'
                        type='text'
                        error={missingFields.indexOf('employment_status') > -1}
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Full time', value: 'full-time', selected: employment_status === 'full-time'},
                            {display: 'Part time', value: 'part-time', selected: employment_status === 'part-time'},
                            {display: 'Contract', value: 'contract', selected: employment_status === 'contract'},
                            {display: 'Disabled', value: 'disabled', selected: employment_status === 'disabled'},
                            {display: 'Retired', value: 'retired', selected: employment_status === 'retired'}
                        ]}
                    />
                </div>
                {!isTermination &&
                    <div className='row radio'>
                        <RadioButtonQuestionContainer
                            questionText='Are you a member of a union?'
                            options={[
                                {name: 'union_member', display: 'no', value: false, selected: (union_member !== undefined && !union_member)},
                                {name: 'union_member', display: 'yes', value: true, selected: union_member}
                            ]}
                            className='union_member'
                            error={missingFields.indexOf('union_member') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
                <div className='row'>
                    <TextFieldContainer
                        key='hours_worked'
                        label="Hours worked per week"
                        className='hours_worked'
                        ref='hours_worked'
                        type='text'
                        text={hours_worked}
                        error={missingFields.indexOf('hours_worked') > -1 || missingFields.indexOf('hours_worked_max') > -1}
                        helperText={missingFields.indexOf('hours_worked_max') > -1 ? 'Hours worked must be fewer than 100 per week' : null}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{numericOnly: true}}
                    />
                </div>
                {!isTermination &&
                    <div>
                        <div className='row'>
                            <TextFieldContainer
                                key='income'
                                label="Income"
                                className='income'
                                ref='income'
                                type='text'
                                text={income}
                                error={missingFields.indexOf('income') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{numeral: true, prefix: '$', noImmediatePrefix: true}}
                            />
                            <DropdownContainer
                                key='income_unit'
                                label="Frequency"
                                className='income_unit'
                                ref='income_unit'
                                type='text'
                                theme={['small', 'margin-left']}
                                formatOptions={{numericOnly: true}}
                                error={missingFields.indexOf('income_unit') > -1}
                                onChange={this.onChange.bind(this)}
                                    options={[
                                        {display: 'Annual', value: 'annual', selected: income_unit === 'annual'},
                                        {display: 'Month', value: 'month', selected: income_unit === 'month'},
                                        {display: 'Week', value: 'week', selected: income_unit === 'week'},
                                        {display: 'Hour', value: 'hour', selected: income_unit === 'hour'}
                                    ]}
                                />
                        </div>
                        <div className='row radio'>
                            <RadioButtonQuestionContainer
                                questionText='What type of employee are you?'
                                options={[
                                    {name: 'income_type', value: 'hourly', selected: income_type === 'hourly'},
                                    {name: 'income_type', value: 'salary', display: 'salaried', selected: income_type === 'salary'}
                                ]}
                                error={missingFields.indexOf('income_type') > -1}
                                onChange={this.onChange.bind(this)}
                                className='income_type'
                            />
                        </div>
                    </div>
                }
                {isTermination &&
                    <div>
                        <div className='row'>
                            <DropdownContainer
                                key='termination_reason'
                                label="Termination Reason"
                                className='termination_reason'
                                ref='termination_reason'
                                type='text'
                                error={missingFields.indexOf('termination_reason') > -1}
                                onChange={this.onChange.bind(this)}
                                options={[
                                    {display: 'Voluntary Termination', value: 'voluntary', selected: termination_reason === 'voluntary'},
                                    {display: 'Involuntary Termination', value: 'involuntary', selected: termination_reason === 'involuntary'},
                                    {display: 'Job Eliminated', value: 'job-eliminated', selected: termination_reason === 'job-eliminated'},
                                    {display: 'Loss of Eligibility', value: 'loss-of-eligibility', selected: termination_reason === 'loss-of-eligibility'},
                                    {display: 'Member Deceased', value: 'member-deceased', selected: termination_reason === 'member-deceased'}
                                ]}
                            />
                        </div>
                        <div className='row'>
                            <DateSelectContainer
                                key='last_work_date'
                                label="Date Last Worked"
                                className='last_work_date'
                                ref='last_work_date'
                                type='text'
                                value={last_work_date ? moment(last_work_date) : null}
                                startDate={moment().startOf('month')}
                                minDate={moment().subtract(3, 'months')}
                                error={missingFields.indexOf('last_work_date') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                            />
                        </div>
                        <div className='row'>
                            <DateSelectContainer
                                key='last_coverage_date'
                                label="Date Coverage Ends"
                                className='last_coverage_date'
                                ref='last_coverage_date'
                                type='text'
                                value={last_coverage_date ? moment(last_coverage_date) : null}
                                startDate={moment().startOf('month')}
                                minDate={moment().subtract(3, 'months')}
                                error={missingFields.indexOf('last_coverage_date') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default EmploymentInformation;
