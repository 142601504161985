import React, { Component } from 'react';

import {ButtonContainer, StatusBar} from '../index';

import StepDemographicPersonal from './StepDemographicPersonal';
import StepDemographicVerify from './StepDemographicVerify';

import './Application.scss';

const STEPS = [
    null, // step 0
    StepDemographicPersonal, // step 1
    StepDemographicVerify // step 2
];

export default class DemographicApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            application: null,
            currentStep: 1,
            formData: {
                primary: {
                    name_info: {},
                    contact_info: {},
                    employment_info: {},
                    change_effective_date: props.application.effective_date
                }
            },
            showSuccess: false,
            error: null,
            submitting: false
        }
    }

    componentWillMount() {
        document.title = 'Noyo - Demographic Application';
    }

    onChange(data) {
        let {formData, currentStep} = this.state
        if (currentStep === 1) {
            formData.primary = data;
        } else if (currentStep === 2) {
            formData.termination_info = data;
        }

        this.setState({formData});
    }

    back(step) {
        this.setState({currentStep: step}, window.scrollTo(0,0));
    }

    continue() {
        const {currentStep} = this.state;
        // validate current step before proceeding
        if (currentStep === 0 || this.currentStepComponent.validate()) {
            this.setState({
                currentStep: currentStep + 1,
                error: false
            }, window.scrollTo(0, 0));
        } else {
            this.setState({error: true});
        }
    }

    _getSignature() {
        let imageDataUrl = this.currentStepComponent.signaturePad.toDataURL();
        let imageData = imageDataUrl.replace('data:image/png;base64,','');
        return imageData;
    }

    submit() {
        let {application} = this.props;
        let {formData} = this.state;
        formData.signature = this._getSignature();

        this.setState(
            {submitting: true},
            this.props.submitEmployeeApplication(application.id, formData, this.showSuccess.bind(this))
        );
    }

    showSuccess() {
        this.setState({showSuccess: true}, window.scrollTo(0,0));
    }

    render() {
        const {application} = this.props;
        const {currentStep, formData, showSuccess, error, submitting} = this.state;
        console.log(this.state);

        let displayDiv;
        if (showSuccess) {
            displayDiv = (
                <div className='employee-application'>
                    <div className='header status'></div>
                    <div className='employee-application__success-container'>
                        <div className='employee-application__success-container__checkmark'>
                            <img alt="green check" src={require('../../assets/images/ic-green-check.svg')} height='48' width='48' />
                        </div>
                        <div className='employee-application__success-container__header'>Application Complete!</div>
                        <div className='employee-application__success-container__text'>
                            {"Thanks for submitting your application."}
                        </div>
                        <div className='employee-application__success-container__text'>Have a great day.</div>
                    </div>
                </div>
            );
        }
        else if (application) {
            const buttonText = currentStep === 0 ? 'get started' : currentStep < (STEPS.length - 1) ? 'continue' : 'submit';
            const buttonOnChange = currentStep < (STEPS.length - 1) ? this.continue.bind(this) : this.submit.bind(this);

            if (currentStep === 0) {
                displayDiv = (
                    <div className='employee-application'>
                        <div className='employee-application__container demographic'>
                            <div className='main-text'>
                                <div className='name'>Hello {application.user.email}!</div>
                                <div>You can use this application to request updates to your personal information with your insurance carrier.</div>
                                <div>If you have any questions, please reach out to your company's HR contact.</div>
                            </div>
                        </div>
                        <div className='employee-application__footer'>
                            <ButtonContainer text={buttonText} selected={true} theme='filled' onClick={buttonOnChange} />
                        </div>
                    </div>
                );
            } else {
                const CurrentStepComponent = STEPS[currentStep];
                displayDiv = (
                    <div className='employee-application'>
                        <div className='employee-application__container demographic'>
                            <CurrentStepComponent ref={ref => this.currentStepComponent = ref} back={this.back.bind(this)} currentStep={currentStep} application={application} formData={formData} onChange={this.onChange.bind(this)} />
                        </div>
                        <div className='employee-application__footer'>
                            {error &&
                                <div className='employee-application__footer__error'>Errors found above</div>
                            }
                            <ButtonContainer text={submitting ? 'submitting...' : buttonText} disabled={submitting} selected={true} theme='filled' onClick={buttonOnChange} />
                        </div>
                    </div>
                )
            }
        }

        return (
            <div>
                <StatusBar currentStep={currentStep} />
                {displayDiv}
            </div>
        );
    }
}
