import { connect } from 'react-redux';
import RadioButtonQuestion from './RadioButtonQuestion';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RadioButtonQuestion);
