import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, CheckboxQuestion } from '../../index';

import { validateFields } from '../../../utils';

export default class CoverageMaintenance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props.coverage_maintenance
        }
    }

    onChange(key, value) {
        let update = {[key]: value};
        if (key === 'company_waiting_period') {
            if (value) {
                update.company_waiting_period_waived = value;
            } else if (value !== undefined) {
                update.company_waiting_period_length = 'hire-date';
            }
        }
        this.setState(update, () => this.props.onChange('coverage_maintenance', this.state));
    }

    validate() {
        let requiredFields = ['company_waiting_period', 'company_waiting_period_length', 'terminated_coverage_end'];
        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields, group_administration = []} = this.state;
        return (
            <div className='coverage-maintenance'>
                <div className='main-header-container border-top'>
                    <div className='text light'>Coverage Maintenance</div>
                    <div className='sub-text'>Setup the rules around ongoing plan maintenance</div>
                </div>
                <div className='section-header'>Waiting Period</div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Does the company have a waiting period?'}
                        options={[
                            {name: 'company_waiting_period', display: 'no', value: false, selected: (this.state.company_waiting_period !== undefined && !this.state.company_waiting_period)},
                            {name: 'company_waiting_period', display: 'yes', value: true, selected: this.state.company_waiting_period}
                        ]}
                        error={missingFields.indexOf('company_waiting_period') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    {this.state.company_waiting_period &&
                        <RadioButtonQuestionContainer
                            questionText={'Is the waiting period waived for initial enrollees?'}
                            options={[
                                {name: 'company_waiting_period_waived', display: 'no', value: false, selected: (this.state.company_waiting_period_waived !== undefined && !this.state.company_waiting_period_waived)},
                                {name: 'company_waiting_period_waived', display: 'yes', value: true, selected: this.state.company_waiting_period_waived}
                            ]}
                            theme='margin-left'
                            error={missingFields.indexOf('company_waiting_period_waived') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    }
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='company_waiting_period_length'
                        label={'What is the length of company\'s waiting period?'}
                        className='company_waiting_period_length'
                        ref='company_waiting_period_length'
                        theme='full-width'
                        onChange={this.onChange.bind(this)}
                        error={missingFields.indexOf('company_waiting_period_length') > -1}
                        options={[
                            {display: 'Date of Hire', value: 'hire-date'},
                            {display: 'First of Month Following Date of Hire', value: 'first-hire-date'},
                            {display: 'First of Month Following 30 Days', value: 'first-thirty-days'},
                            {display: 'First of Month Following 60 Days', value: 'first-sixty-days'},
                            {display: 'First of Month Following 90 Days', value: 'first-ninety-days'},
                            {display: 'First of Month Following 1 Month', value: 'first-one-month'},
                            {display: 'First of Month Following 2 Months', value: 'first-two-months'},
                            {display: 'First of Month Following 3 Months', value: 'first-three-months'}
                        ].map(o => {
                            return {display: o.display, value: o.value, selected: this.state.company_waiting_period_length === o.value}
                        })}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='employees_currently_in_waiting_period'
                        label='How many employees are currently in the waiting period?'
                        className='employees_currently_in_waiting_period'
                        ref='employees_currently_in_waiting_period'
                        type='text'
                        theme='full-width'
                        text={this.state.employees_currently_in_waiting_period}
                        error={missingFields.indexOf('employees_currently_in_waiting_period') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{numericOnly: true}}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Does the waiting period apply to rehired employees?'}
                        options={[
                            {name: 'waiting_period_for_rehired', display: 'no', value: false, selected: (this.state.waiting_period_for_rehired !== undefined && !this.state.waiting_period_for_rehired)},
                            {name: 'waiting_period_for_rehired', display: 'yes', value: true, selected: this.state.waiting_period_for_rehired}
                        ]}
                        error={missingFields.indexOf('waiting_period_for_rehired') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.waiting_period_for_rehired &&
                    <div className='row'>
                        <TextFieldContainer
                            key='company_waiting_period_rehired_length'
                            label={'What is the length of the company\'s waiting period? (in days)'}
                            className='company_waiting_period_rehired_length'
                            ref='company_waiting_period_rehired_length'
                            type='text'
                            theme='full-width'
                            text={this.state.company_waiting_period_rehired_length}
                            error={missingFields.indexOf('company_waiting_period_rehired_length') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numericOnly: true}}
                        />
                    </div>
                }
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Does the company have an orientation period?'}
                        options={[
                            {name: 'company_orientation_period', display: 'no', value: false, selected: (this.state.company_orientation_period !== undefined && !this.state.company_orientation_period)},
                            {name: 'company_orientation_period', display: 'yes', value: true, selected: this.state.company_orientation_period}
                        ]}
                        error={missingFields.indexOf('company_orientation_period') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.company_orientation_period &&
                    <div className='row'>
                        <TextFieldContainer
                            key='company_orientation_period_length'
                            label={'How long is the company\'s orientation period? (in days)'}
                            className='company_orientation_period_length'
                            ref='company_orientation_period_length'
                            type='text'
                            theme='full-width'
                            text={this.state.company_orientation_period_length}
                            error={missingFields.indexOf('company_orientation_period_length') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numericOnly: true}}
                        />
                    </div>
                }
                <div className='row'>
                    <DropdownContainer
                        key='employee_enrollment_managed_method'
                        label={'How will ongoing employee enrollment be managed?'}
                        className='employee_enrollment_managed_method'
                        ref='employee_enrollment_managed_method'
                        theme='full-width'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Online', value: 'online'},
                            {display: 'Paper', value: 'paper'},
                            {display: 'EDI', value: 'edi'},
                        ].map(o => {
                            return {display: o.display, value: o.value, selected: this.state.employee_enrollment_managed_method === o.value}
                        })}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Will enrollment changes be submitted through private exchange?'}
                        options={[
                            {name: 'private_exchange', display: 'no', value: false, selected: (this.state.private_exchange !== undefined && !this.state.private_exchange)},
                            {name: 'private_exchange', display: 'yes', value: true, selected: this.state.private_exchange}
                        ]}
                        error={missingFields.indexOf('private_exchange') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.private_exchange &&
                    <div className='row'>
                        <TextFieldContainer
                            key='private_exchange_name'
                            label={'Exchange Name'}
                            className='private_exchange_name'
                            ref='private_exchange_name'
                            type='text'
                            theme='full-width'
                            text={this.state.private_exchange_name}
                            error={missingFields.indexOf('private_exchange_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
                <div className='row'>
                    <CheckboxQuestion
                        questionText='Check all that apply for this group:'
                        className='group_administration'
                        options={[
                            {display: 'Under Medicare Secondary Payer rules, Medicare is the primary carrier for this group.', value: 'medicare_primary'},
                            {display: 'Group is currently subject to the Family Medical Leave Act of 1993 (50 or more employees)', value: 'family_medical_leave_act'},
                            {display: 'Company will be administering an HRA through this carrier', value: 'hra'},
                            {display: 'Company will be administering an HSA through this carrier', value: 'hsa'},
                            {display: 'Company will be enrolling in a Premium Only Plan', value: 'premium_only'},
                            {display: 'Company will be opting into infertility benefits', value: 'infertility_benefits'},
                            {display: 'Company will be opting into contraceptive benefits', value: 'contraceptive_benefits'},
                            {display: 'Company will be offering a staff model HMO plan alongside plans with this carrier', value: 'staff_model_hmo'},
                        ].map(o => {
                            const {display, value} = o;
                            return {display, value, selected: group_administration.indexOf(value) > -1}
                        })}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='temp_medical_leave_time'
                        label={'For how long are employees eligible to continue group coverage while on temporary medical leave of absence?'}
                        className='temp_medical_leave_time'
                        ref='temp_medical_leave_time'
                        theme={['full-width', 'smaller-placeholder']}
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'One Month', value: 'one-month'},
                            {display: 'Two Months', value: 'two-months'},
                            {display: 'Three Months', value: 'three-months'},
                            {display: 'Four Months', value: 'four-months'},
                            {display: 'Five Months', value: 'five-months'},
                            {display: 'Six Months', value: 'six-months'},
                            {display: 'None', value: 'none'}
                        ].map(o => {
                            return {display: o.display, value: o.value, selected: this.state.temp_medical_leave_time === o.value}
                        })}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='temp_personal_leave_time'
                        label={'For how long are employees eligible to continue group coverage while on temporary personal leave of absence?'}
                        className='temp_personal_leave_time'
                        ref='temp_personal_leave_time'
                        theme={['full-width', 'smaller-placeholder']}
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'One Month', value: 'one-month'},
                            {display: 'Two Months', value: 'two-months'},
                            {display: 'Three Months', value: 'three-months'},
                            {display: 'None', value: 'none'}
                        ].map(o => {
                            return {display: o.display, value: o.value, selected: this.state.temp_personal_leave_time === o.value}
                        })}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='terminated_coverage_end'
                        label={'When an employee is terminated, when should coverage end?'}
                        className='terminated_coverage_end'
                        ref='terminated_coverage_end'
                        theme={['full-width']}
                        onChange={this.onChange.bind(this)}
                        error={missingFields.indexOf('terminated_coverage_end') > -1}
                        options={[
                            {display: 'Last Day Worked', value: 'last-day'},
                            {display: 'Last Day of Month Worked', value: 'end-of-month'}
                        ].map(o => {
                            return {display: o.display, value: o.value, selected: this.state.terminated_coverage_end === o.value}
                        })}
                    />
                </div>
            </div>
        );
    }
}
