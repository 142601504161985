import React, { Component } from 'react';
import T from 'prop-types';

import {ButtonContainer} from '../../index';
import Modal from './Modal';

import './Alert.scss';

class Alert extends Component {
    static propTypes = {
        visible: T.bool,
        header: T.string,
        text: T.string,
        successButton: T.object,
        cancelButton: T.object,
        hideAlert: T.func
    };

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible || false
        };
    }

    componentWillReceiveProps(nextProps) {
        const {visible} = nextProps;
        if (nextProps.visible !== this.props.visible) {
            this.setState({visible});
        }
    }

    onSuccessClicked() {
        const {successButton} = this.props;
        if (successButton.onClick) {
            successButton.onClick();
        }
        this.toggle();
    }

    onCancelClicked() {
        const {cancelButton} = this.props;
        if (cancelButton.onClick) {
            cancelButton.onClick();
        }
        this.toggle();
    }

    toggle() {
        const action = !this.state.visible;
        this.setState({visible: action});
        if (!action) {
            this.props.hideAlert();
        }
    }

    render() {
        const {header, text, successButton, cancelButton} = this.props;
        let buttonsClass = 'alert__buttons';
        if (successButton && cancelButton) {
            buttonsClass += ' multiple';
        }
        return (
            <Modal small priority isOpen={this.state.visible} disableOutsideClick>
                <div className="alert">
                    <div className='alert__header'>{header}</div>
                    <div className='alert__text'>{text}</div>
                    <div className={buttonsClass}>
                        {successButton &&
                            <div className='successButton'>
                                <ButtonContainer onClick={this.onSuccessClicked.bind(this)} text={successButton.text} selected={true} theme='filled' />
                            </div>
                        }
                        {cancelButton &&
                            <div className='cancelButton'>
                                <ButtonContainer onClick={this.onCancelClicked.bind(this)} text={cancelButton.text} selected={false} theme='gray' />
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

export default Alert;
