import moment from 'moment';

import React, { Component } from 'react';

import {Terms} from '../index';

import Modal from '../shared/Modal/Modal';

import './Application.scss';

import SignaturePad from 'signature_pad';


export default class StepDemographicVerify extends Component {
    state = {
        termsVisible: false
    }

    componentDidMount() {
        this.signaturePad = new SignaturePad(this.refs.signatureCanvas);
    }

    toggleTerms() {
        this.setState({
            termsVisible: !this.state.termsVisible
        });
    }

    render() {
        const {formData, back} = this.props;
        return (
            <div className='step-verify'>
                <div className='main-text'>
                    <img alt="" src={require('../../assets/images/ic-edit.svg')} height='24' width='24' />
                    <div className='name light'>Review & sign your application</div>
                    <div>You’re almost done! Confirm the information below is correct, add your signature, and then submit your application.</div>
                </div>
                <VerifyInformation
                    key='verify-primary'
                    client={formData.primary}
                    back={back}
                />
                <div className='signature-container'>
                    <div>Draw your signature with your mouse</div>
                    <div className='signature'><canvas ref="signatureCanvas" width="360" height="120"></canvas></div>
                    <div>
                        <div>By signing, you agree to the <span className='terms' onClick={this.toggleTerms.bind(this)}>Terms of Use & Privacy Policy.</span></div>
                        <div style={{margin: '10px 0'}}>Note: submissions are final. If you need to make changes after submitting, contact your company’s HR representative.</div>
                    </div>
                </div>
                <Modal isOpen={this.state.termsVisible} close={this.toggleTerms.bind(this)}>
                    <div className='terms-content'>
                        <Terms />
                    </div>
                </Modal>
            </div>
        );
    }
}

const KEYS_TO_SKIP = [];
const DATE_KEYS = ['dob', 'hire_date', 'full_time_start_date', 'last_work_date', 'last_coverage_date'];

class VerifyInformation extends Component {
    _getFormattedKey(key) {
        key = key.replace(/_/g, ' ');
        if (key === 'ssn') {
            key = key.toUpperCase();
        } else {
            key = key.charAt(0).toUpperCase() + key.slice(1);
        }
        return key;
    }

    _formatInformation(info = {}) {
        let infoDiv = [];
        Object.keys(info).forEach((key, index) => {
            if (KEYS_TO_SKIP.indexOf(key) === -1) {
                let value = info[key];
                if (DATE_KEYS.indexOf(key) > -1) {
                    value = moment(value).format('MM/DD/YYYY');
                } else if (key === 'address') {
                    value = value.addressString;
                } else if (typeof(value) === 'boolean') {
                    value = value ? 'Yes' : 'No';
                }
                if (value) {
                    infoDiv.push(<li key={key + '-' + index}>{this._getFormattedKey(key)}: {value}</li>);
                }
            }
        });
        return infoDiv;
    }

    render() {
        const {client, back} = this.props;
        let memberType = 'Primary member';

        const newNameInfo = this._formatInformation(client.name_info.new);
        const newContactInfo = this._formatInformation(client.contact_info.new);
        const newEmploymentInfo = this._formatInformation(client.employment_info.new);

        const currentNameInfo = this._formatInformation(client.name_info.current);
        const currentContactInfo = this._formatInformation(client.contact_info.current);
        const currentEmploymentInfo = this._formatInformation(client.employment_info.current);

        return (
            <div className='step-verify__container'>
                <div className='verify-header'>
                    <div>{client.first_name} {client.last_name}</div>
                    <div className='verify-header__subheading'>{memberType}</div>
                </div>
                <div className='verify-information'>
                    <div className='verify-information__personal'>
                        <div className='verify-information__header'>
                            <span>New info </span>
                            <span className='link lower' onClick={() => back(1)}>(edit)</span>
                        </div>
                        <ul className='bullet-list'>{newNameInfo}</ul>
                        <ul className='bullet-list'>{newContactInfo}</ul>
                        <ul className='bullet-list'>{newEmploymentInfo}</ul>
                    </div>
                    <div className='verify-information__health'>
                        <div className='verify-information__header'>
                            <span>Current info </span>
                            <span className='link lower' onClick={() => back(1)}>(edit)</span>
                        </div>
                        <ul className='bullet-list'>{currentNameInfo}</ul>
                        <ul className='bullet-list'>{currentContactInfo}</ul>
                        <ul className='bullet-list'>{currentEmploymentInfo}</ul>
                    </div>
                </div>
            </div>
        );
    }
}
