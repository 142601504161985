import React, { Component } from 'react';
import moment from 'moment';

import {TextFieldContainer} from './../../index';

import './DateSelect.scss';

export default class DateSelect extends Component {
    constructor (props) {
        super (props);

        const {initialValue, currentMonthDate, minDate} = this._handleDateLogic();

        this.state = {
            value: initialValue,
            currentMonthDate,
            picker: false,
            weekStartDay: 0,
            minDate: minDate || moment().startOf('day'),
            textInput: false
        };

        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    _handleDateLogic() {
        const {value, minDate} = this.props;
        let initialValue = undefined;
        let currentMonthDate = undefined;

        if (value && value.isValid()) {
            initialValue = this.props.value;
            currentMonthDate = initialValue.clone().startOf('month');
        } else if (this.props.startDate && this.props.startDate.isValid()) {
            currentMonthDate = this.props.startDate.clone().startOf('month');
        } else if (minDate && minDate.isValid()) {
            currentMonthDate = minDate.clone().startOf('month');
        }

        return {initialValue, currentMonthDate, minDate}
    }

    componentDidMount () {
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state) {
            const {textInput} = this.state;
            if (textInput) {
                return;
            }
        }

        if (nextProps.value && this.props !== nextProps) {
            const {currentMonthDate, minDate} = this._handleDateLogic();

            this.setState({
                value: nextProps.value,
                currentMonthDate,
                minDate: minDate || moment().startOf('day')
            });
        }
    }

    showPicker(e) {
        if (!this.props.disabled) {
            this.setState({
                picker: true
            });
        }
    }

    hidePicker() {
        this.setState({
            picker: false
        });
    }

    handleOutsideClick(e) {
        if (!this.p(e.target)){
            this.hidePicker();
        }
    }

    p(n) {
        let parent = n.parentNode;
        if (parent){
            if (parent === this.refs.area){
                return true;
            }
            return this.p(parent);
        }
        return false;
    }

    handleClick(event, date) {
        event.stopPropagation();
        this.setState({
            value: date,
            picker: false
        });

        const {className, onChange} = this.props;
        if (onChange) {
            onChange(className, date);
        }
    }

    handleTextChange(key, value) {
        this.hidePicker();

        this.setState({textInput: true});

        const {className, onChange} = this.props;
        if (onChange) {
            onChange(className, moment(value).format('MM/DD/YYYY'));
        }
    }

    prev() {
        const {minDate} = this.props;
        let referenceDate = moment().startOf('month');
        if (minDate) {
            referenceDate = minDate.clone().startOf('month');
        }

        let monthDate = moment().startOf('month');
        if (this.state.currentMonthDate) {
            monthDate = this.state.currentMonthDate.clone();
        }

        let prevMonthDate = monthDate.clone().subtract(1,'M');
        if (prevMonthDate >= referenceDate) {
            let s = this.state;
            s.currentMonthDate = prevMonthDate;
            this.setState(s);
        }
    }

    next() {
        let monthDate = moment().startOf('month');
        if (this.state.currentMonthDate){
            monthDate = this.state.currentMonthDate.clone();
        }
        let nextMonthDate = monthDate.clone().add(1,'M');
        let s = this.state;
        s.currentMonthDate = nextMonthDate;
        this.setState(s);
    }

    render() {
        const {error, theme, disabled, className} = this.props;
        const {value, minDate, currentMonthDate} = this.state;

        let classes = [className, 'DateSelect'];
        if (theme) {
            classes.push(theme);
        }

        let valueString = "";
        let referenceDate = moment();
        if (value) {
            valueString = value.format("MM/DD/YYYY");
            referenceDate = value.clone();
        } else if (minDate) {
            referenceDate = minDate.clone();
        }

        let monthDate = referenceDate.clone().startOf('month');
        if (currentMonthDate) {
            monthDate = currentMonthDate.clone();
        }

        let pickerClasses = ['picker'];
        if (this.props.top) {
            pickerClasses.push('top');
        }
        if (this.state.picker) {
            pickerClasses.push('display');
        }

        let monthName = monthDate.format("MMMM YYYY");

        let weekdays = [];
        for (var index = 0; index < 7; index++) {
            let initial = moment().day(index).format("dd").substr(0,1);
            weekdays.push(<div key={'weekday_' + index} className="weekday">{initial}</div>)
        }

        let weeks = [];
        let prevMonthDate = monthDate.clone().subtract(1,'m');

        let currentMonth = monthDate.month();

        let startDate = monthDate.day(0);
        let cursorDate = startDate;
        for (var weekIndex = 0; weekIndex < 6; weekIndex++) {
            let days = []
            for (var dayIndex = 0; dayIndex < 7; dayIndex++) {
                let cloneCursorDate = cursorDate.clone();
                let onClick = (event) => this.handleClick(event, cloneCursorDate);

                let dayClassName = "day";
                if (cursorDate.month() !== currentMonth) {
                    dayClassName += " hidden";
                } else if (value && (cursorDate.format("MM/DD/YYYY") === value.format("MM/DD/YYYY"))){
                    dayClassName += " selected";
                } else if (cloneCursorDate < minDate) {
                    dayClassName += " disabled";
                    onClick = null;
                }
                days.push(<div key={'day_' + dayIndex} className={dayClassName} onClick={onClick}><span>{cursorDate.format("D")}</span></div>)
                cursorDate.add(1, 'd');
            }
            weeks.push(<div key={'week_' + weekIndex} className="week">{days}</div>);
        }

        let prevClassName = "prev"
        if (prevMonthDate < minDate) {
            prevClassName += " hide";
        }

        return (
            <div className={classes.join(' ')} ref="area" onClick={(e)=>this.showPicker(e)}>
                <TextFieldContainer
                    label={this.props.label}
                    text={valueString}
                    error={error}
                    disabled={disabled}
                    onChange={this.handleTextChange.bind(this)}
                    formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                />
                <div className={pickerClasses.join(' ')}>
                    <div className="nav">
                        <div className="month">{monthName}</div>
                        <div className={prevClassName} onClick={this.prev}>
                            <img alt="" src={require('../../../assets/images/ic-chevron.svg')} style={{transform:'rotate(180deg)'}} height='12' width='7' />
                        </div>
                        <div className="next" onClick={this.next}>
                            <img alt="" src={require('../../../assets/images/ic-chevron.svg')} height='12' width='7' />
                        </div>
                    </div>
                    <div className="weekdays">
                        {weekdays}
                    </div>
                    <div className="weeks">
                        {weeks}
                    </div>
                </div>
            </div>
        )
    }
};
