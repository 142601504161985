import React, { Component } from 'react';

import EligibilityGroup from './EligibilityGroupEmployer'

export default class CoverageSelections extends Component {
    constructor(props) {
        super(props);

        const {eligibility_groups = [{}]} = props.coverage_selections || {};
        this.state = {
            missingFields: [],
            eligibility_groups, 
            numEligibilityGroups: eligibility_groups.length || 1
        }
    }

    onChange(value, index) {
        let {eligibility_groups} = this.state;
        if (!eligibility_groups) {
            eligibility_groups = [];
        }
        eligibility_groups[index] = value;
        this.setState({eligibility_groups}, this.stateChangeComplete);
    }

    validate() {
        const {eligibility_groups} = this.state;

        let childrenValidated = eligibility_groups.length > 0;
        eligibility_groups.forEach((eg, i) => {
            childrenValidated &= this[`eligibility_group_${i}`].validate();
        });

        return childrenValidated;
    }

    addEligibilityGroup() {
        let {eligibility_groups} = this.state;
        eligibility_groups.push({});
        this.setState({eligibility_groups}, this.stateChangeComplete);
    }

    stateChangeComplete() {
        this.props.onChange('coverage_selections', this.state);
    }

    close(index) {
        let {eligibility_groups} = this.state;
        eligibility_groups.splice(index, 1);
        this.setState({eligibility_groups}, this.stateChangeComplete);   
    }

    render() {
        const {eligibility_groups} = this.state;
        const eligibilityGroupDiv = eligibility_groups.map((eg, i) => {
            return (<EligibilityGroup {...eg} key={'eligibility_group_' + i} ref={ref => this[`eligibility_group_${i}`] = ref} index={i} onChange={this.onChange.bind(this)} close={this.close.bind(this, i)} />);
        });
        return (
            <div className='coverage-selections'>
                <div className='main-header-container border-top'>
                    <div className='text light'>Coverage Selections</div>
                    <div className='sub-text'>Which lines of coverage will the company be offering?</div>
                </div>
                {eligibilityGroupDiv}
                <div className='link plus border-top' onClick={this.addEligibilityGroup.bind(this)}>Add Eligibility Group</div>
            </div>
        );
    }
}

