import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import store from './store';

import { updateLocation, checkLoggedIn } from './actions/user';

import AppContainer from './components/app/AppContainer';
import { HomeContainer, StatusBar, AccountContainer, BrokerContainer, ApplicationContainer } from './components/index';

import AuthManager from './services/auth/AuthManager';

export class App extends Component {
    handleUpdate() {
        const {action} = this.state.location;

        if (action === 'PUSH') {
            window.scrollTo(0, 0);
        }
    }

    render() {
        // Forces `fetch` to be called against the global namespace. If it's called
        // against any other object but `window`, it will fail with an
        // IllegalInvocation error.
        const boundFetch = (...args) => fetch(...args);
        const auth = new AuthManager(boundFetch);

        const history = syncHistoryWithStore(browserHistory, store);
        history.listen(location => this.props.updateLocation(location));

        const requireAuth = auth => (nextState, replace) => {
            let userId = localStorage.getItem('userId');
            if (!userId) {
                return replace({
                    pathname: '/login',
                    state: {nextPathname: nextState.location.pathname}
                });
            }

        };

        const hasCurrentUser = () => {
            return this.props.checkLoggedIn();
        }

        return (
            <Router history={history} onUpdate={this.handleUpdate}>
                <Route path='/' component={AppContainer} onEnter={hasCurrentUser()}>
                    <IndexRoute component={HomeContainer} onEnter={requireAuth(auth)} />
                    <Route path='login' component={AccountContainer} />
                    <Route path='broker' component={BrokerContainer} onEnter={requireAuth(auth)} />
                    <Route path='applications/:application_id' component={ApplicationContainer} />
                    <Route path='status' component={StatusBar} />
                </Route>
            </Router>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
    checkLoggedIn,
    updateLocation,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);
