import { connect } from 'react-redux';
import App from './App';
import { logout } from '../../actions/user';

const mapStateToProps = ({ App, User }) => {
    return {
        isLoggedIn: User.isLoggedIn,
        currentUser: User.currentUser,
        alert: App.alert
    };
};

const mapDispatchToProps = {
    logout
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
