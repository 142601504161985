import { connect } from 'react-redux';
import Header from './Header';

import {logout} from '../../../actions/user';

const mapStateToProps = ({User}) => {
    const {currentUser, userLocation} = User;
    return {
        currentUser,
        userLocation,
        location: window.location
    }
};

const mapDispatchToProps = {logout};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
