import produce from 'immer';
import appDataToFormData from '../helpers/app_data_to_form_data'

const initialState = {
	id: null,
	currentStep: 0,
	data: {
		isFetched: false,
		dependents: {},
		selectedPlans: {},
		company: {
			admin: {},
		}
	},

	formData: {
		missingFields: [],
		// Shared fields
		primary: {},
		// New hire fields
		dependents: {},
		selectedPlans: {},
		// Termination fields
		company: {
			admin: {},
		},
		termination_info: {
			termination_options: [],
		},
		carrier_terms_agreed: false,
		noyo_terms_agreed: false,
	},

	// Terms of service for the currently open Application.
	// Should be a string.
	carrierTerms: '',
};

const applicationReducer = (state = initialState, action) => {

	const { payload, type } = action;

	return produce(state, draft => {
		switch (type) {
			case 'CURRENT_APPLICATION_ID':
				draft.id = payload;
				break;
			case 'CURRENT_APPLICATION_DATA':
				const application = payload;
				draft.data.isFetched = true;
				Object.keys(application).forEach(key => {
					draft.data[key] = application[key]
				});
				appDataToFormData(application, draft.formData)
				break;
			case 'CURRENT_APPLICATION_DEPENDENTS':
				draft.formData.dependents = payload;
				break;
			case 'CURRENT_APPLICATION_SELECTED_PLANS':
				Object.keys(payload).forEach(key =>
					draft.formData.selectedPlans[key] = payload[key]
				);
				break;
			case 'CURRENT_APPLICATION_STEP':
				draft.currentStep = payload;
				break;
			case 'CURRENT_APPLICATION_FORM_DATA':
				// const data = payload;
				// Object.keys(data).forEach(key =>
				// 	draft.formData[key] = data[key]
				// );
				Object.assign(draft.formData, payload)
				break;
			case 'CURRENT_APPLICATION_ERROR':
				draft.error = payload;
				break;
			case 'CURRENT_APPLICATION_SUBMIT_STATE':
				draft.submitting = payload;
				break;
			case 'CURRENT_APPLICATION_SHOW_SUCCESS':
				draft.showSuccess = payload;
				break;
			case 'CURRENT_APPLICATION_MISSING_FIELDS':
				draft.formData.missingFields = payload;
				break;
			case 'CURRENT_APPLICATION_SIGNATURE':
				draft.formData.signature = payload;
				break;
			case 'CURRENT_APPLICATION_TERMINATION_OPTIONS':
				draft.formData.termination_info.termination_options = payload;
				break;
			case 'CURRENT_APPLICATION_TERMINATION_INFO':
				draft.formData.termination_info = payload;
				break;
			case 'CURRENT_APPLICATION_COMPANY_ADMIN_CONTACT_INFO':
				Object.keys(payload).forEach(key =>
					draft.formData.company.admin[key] = payload[key]
				);
				break;
			case 'CURRENT_APPLICATION_CARRIER_TERMS_OF_SERVICE':
				draft.carrierTerms = payload;
				break;
			case 'CURRENT_APPLICATION_NOYO_TERMS':
				draft.formData.noyo_terms_agreed = payload;
				break;
			case 'CURRENT_APPLICATION_CARRIER_TERMS':
				draft.formData.carrier_terms_agreed = payload;
				break;
			default:
		}

		// TODO FIX THIS!!!
		// this flies directly in the face of what `immer` is meant for
		// BUT, because right now it's safer and easier to store all Application
		// keys in a top level `data` (to avoid having to figure out and declare
		// defaults for initial state)
		draft.data.______________fix_this_hack__store_last_updated = new Date();
	});

}
export default applicationReducer;
