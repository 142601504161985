import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import { browserHistory } from 'react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import createReducer from './reducers/index';
import AuthManager from './services/auth/AuthManager';
import Rpc from './services/rpc/Rpc';

// Forces `fetch` to be called against the global namespace. If it's called
// against any other object but `window`, it will fail with an
// IllegalInvocation error.
const boundFetch = (...args) => fetch(...args);
const rpc = new Rpc(boundFetch);
const auth = new AuthManager(boundFetch);

const serviceMap = {
    browserHistory,
    rpc,
    auth
};

let middlewares = [thunk.withExtraArgument(serviceMap)]
if (process.env.NODE_ENV === 'development') {
    const logger = createLogger();
    middlewares.push(logger)
}

const store = createStore(
    createReducer(),
    process.env.NODE_ENV === 'development' ?
        composeWithDevTools(applyMiddleware(...middlewares)) :
        applyMiddleware(
            ...middlewares
        )
);

export default store;
