import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, Address, CheckboxQuestion } from '../../index';

import { validateFields } from '../../../utils';

export default class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props
        }
    }

    validate() {
        let requiredFields = ['first_name', 'last_name', 'title', 'address'];

        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div className='contact'>
                <div className='row'>
                    <div>Contact #{this.props.index}</div>
                    <img
                        alt=""
                        style={{cursor: 'pointer', marginLeft: 10, marginTop: 3}}
                        className='close'
                        src={require('../../../assets/images/ic-close.svg')}
                        height='12' width='12'
                        onClick={this.props.remove}
                    />
                </div>
                <div className='row'>
                    <CheckboxQuestion
                        questionText='Contact Type'
                        className='contact_type'
                        theme='small-text'
                        options={[
                            // {display: 'Primary Contact', value: 'company'},
                            {display: 'Executive Contact', value: 'executive'},
                            {display: 'Billing Contact', value: 'billing'},
                            {display: 'Online Access Contact', value: 'online'}

                        ]}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={this.state.first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        theme='margin-left'
                        text={this.state.last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='title'
                        label='Title'
                        className='title'
                        ref='title'
                        type='text'
                        text={this.state.title}
                        error={missingFields.indexOf('title') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <Address
                        label='Address'
                        value={this.state.address}
                        error={missingFields.indexOf('address') > -1}
                        onChange={(address) => this.onChange('address', address)}
                    />
                </div>
                <div className='row three'>
                    <TextFieldContainer
                        key='phone'
                        label="Phone #"
                        className='phone'
                        ref='phone'
                        type='text'
                        text={this.state.phone}
                        error={missingFields.indexOf('phone') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                    <TextFieldContainer
                        key='fax'
                        label="Fax #"
                        className='fax'
                        ref='fax'
                        type='text'
                        text={this.state.fax}
                        error={missingFields.indexOf('fax') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                    <TextFieldContainer
                        key='email'
                        label="Email Address"
                        className='email'
                        ref='email'
                        type='text'
                        text={this.state.email}
                        error={missingFields.indexOf('email') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='preferred_contact_method'
                        label='Preferred Correspondence Method'
                        className='preferred_contact_method'
                        ref='preferred_contact_method'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Email', value: 'email'},
                            {display: 'Mail', value: 'mail'},
                            {display: 'Fax', value: 'fax'},

                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.preferred_contact_method === value}
                        })}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Is this contact authorized to make changes to the group contract?'}
                        options={[
                            {name: 'contact_authorized', display: 'no', value: false, selected: (this.state.contact_authorized !== undefined && !this.state.contact_authorized)},
                            {name: 'contact_authorized', display: 'yes', value: true, selected: this.state.contact_authorized}
                        ]}
                        error={missingFields.indexOf('contact_authorized') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>
        )
    }
}
