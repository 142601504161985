import React, { Component } from 'react';

import { ALL_STATES } from '../../../utils';
import { validateFields } from '../../../utils';
import { shouldDisplayCarriers } from '../../../utils';

import { TextFieldContainer, DropdownContainer } from '../../index';

import '../Broker.scss';

export default class CoverageOptionEmployerOther extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            missingFields: []
        };
    }

    onChange(type, key, value) {
        const {options} = this.state;
        const index = options.findIndex(o => o.type === type);
        if (index === -1) {
            const option = {[key]: value, type, open: true};
            options.push(option);
        } else {
            options[index][key] = value;
        }
        this.setState({options}, this.stateChangeComplete);
    }

    close(type) {
        if (type !== 'open') {
            const openKey = type + 'Open';
            const {options} = this.state;
            const index = options.findIndex(o => o.type === type);
            options[index]['open'] = false;
            this.setState({[openKey]: false, options}, this.stateChangeComplete);
        } else {
            this.setState({[type]: false}, this.stateChangeComplete);
        }
    }

    stateChangeComplete() {
        this.props.onChange(this.state.options);
    }

    filterCarriersList(carriers) {
        const {state} = this.state;
        const {line_of_coverage} = this.props;
        return shouldDisplayCarriers(carriers, state, line_of_coverage);
    }

    validate() {
        /*
            proper coverage option state:
            {
                carrier_id: required,
                group_id: required,
                line_of_coverage: required,
                termination_window: required,
                waiting_period: required,
                group_id: optional,
                plans: [
                    {
                        name: required
                    }
                ]
            }
        */
        let requiredFields = [];
        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    toggleOpen(key) {
        this.setState({
            [key]: !this.state[key]
        }, this.stateChangeComplete);
    }

    _getDisabilityHtml(type) {
        const {options, missingFields} = this.state;
        const option = options.filter(o => o.type === type)[0];
        let contribution_scheme;
        if (option) {
            contribution_scheme = option.contribution_scheme;
        }
        const contributionSchemeDiv = [
            <DropdownContainer
                key='contribution_scheme'
                label="Contribution Scheme"
                className='contribution_scheme'
                ref='contribution_scheme'
                onChange={this.onChange.bind(this, type)}
                options={[
                    {display: 'Percentage', value: 'percentage'},
                    {display: 'Fixed Dollar Amount', value: 'fixed'},
                    {display: 'Percentage of Plan', value: 'plan'}
                ].map(o => {
                    const {display, value} = o;
                    return {display, value, selected: contribution_scheme === value}
                })}
            />
        ];
        if (contribution_scheme !== undefined) {
            let text = '%';
            if (contribution_scheme === 'fixed') {
                text = '$';
            }
            if (contribution_scheme === 'plan') {
                contributionSchemeDiv.push(
                    <TextFieldContainer
                        key={'base_plan'}
                        label={'Base Plan'}
                        className='base_plan'
                        ref={'base_plan'}
                        type='text'
                        text={this.state.base_plan}
                        error={missingFields.indexOf('base_plan') > -1}
                        onChange={this.onChange.bind(this, type)}
                    />
                );
            }
            contributionSchemeDiv.push(
                <TextFieldContainer
                    key={'employee_contribution'}
                    label={'Employee ' + text}
                    className='employee_contribution'
                    ref={'employee_contribution'}
                    type='text'
                    text={this.state.employee_contribution}
                    error={missingFields.indexOf('employee_contribution') > -1}
                    onChange={this.onChange.bind(this, type)}
                />
            );
            contributionSchemeDiv.push(
                <TextFieldContainer
                    key={'dependent_contribution'}
                    label={'Dependent ' + text}
                    className='dependent_contribution'
                    ref={'dependent_contribution'}
                    type='text'
                    text={this.state.dependent_contribution}
                    error={missingFields.indexOf('dependent_contribution') > -1}
                    onChange={this.onChange.bind(this, type)}
                />
            );
        }
        return (
            <div>
                <div className='row'>
                    <DropdownContainer
                        key='coverage_type'
                        label="Coverage Type"
                        className='coverage_type'
                        ref='coverage_type'
                        onChange={this.onChange.bind(this, type)}
                        options={[
                            {display: 'Short Term Disability', value: 'short_term'},
                            {display: 'Voluntary Short Term Disability', value: 'voluntary_short_term'},
                            {display: 'Long Term Disability', value: 'long_term'},
                            {display: 'Voluntary Long Term Disability', value: 'voluntary_long_term'},
                        ].map(o => {
                            const {display, value} = o;
                            return {display, value, selected: this.state.coverage_type === value}
                        })}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        className='state'
                        label='Carrier State'
                        options={ALL_STATES.map(state => {
                            return {display: state.name + ' (' + state.id + ')', value: state.id, selected: state.id === this.state.state};
                        })}
                        error={missingFields.indexOf('state') > -1}
                        onChange={this.onChange.bind(this, type)}
                    />
                    <DropdownContainer
                        className='carrier_id'
                        label='Carrier Name'
                        options={this.filterCarriersList(this.props.carriers).map(carrier => {
                            return {display: carrier.name, value: carrier.id, selected: carrier.id === this.state.carrier_id};
                        })}
                        error={missingFields.indexOf('carrier_id') > -1}
                        onChange={this.onChange.bind(this, type)}
                    />
                </div>
                <div className='row three'>{contributionSchemeDiv}</div>
            </div>
        );
    }

    render() {
        const {open, addOpen, illnessOpen} = this.state;

        let html = [];
        if (!open) {
            html.push(
                <div className='link plus' onClick={this.toggleOpen.bind(this, 'open')}>
                    add other coverage
                </div>
            );
        } else {
            let coverageImage = 'ic-box';
            html.push(
                <div className='line-of-coverage'>
                    <img alt="" src={require('../../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                    <span>{'Add other coverage'}</span>
                    <img alt="" className='line-of-coverage__close' src={require('../../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this, 'open')} />
                </div>
            )
            if (!addOpen) {
                html.push(
                    <div className='link plus' onClick={this.toggleOpen.bind(this, 'addOpen')}>
                        add ad&d coverage
                    </div>
                );
            } else {
                html.push(
                    <div className='line-of-coverage'>
                        <img alt="" src={require('../../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                        <span>{'AD&D Coverage'}</span>
                        <img alt="" className='line-of-coverage__close' src={require('../../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this, 'add')} />
                    </div>
                );
                html.push(this._getDisabilityHtml('add'))
            }
            if (!illnessOpen) {
                html.push(
                    <div className='link plus' onClick={this.toggleOpen.bind(this, 'illnessOpen')}>
                        add voluntary critical illness coverage
                    </div>
                );
            } else {
                html.push(
                    <div className='line-of-coverage'>
                        <img alt="" src={require('../../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                        <span>{'Voluntary Critical Illness Coverage'}</span>
                        <img alt="" className='line-of-coverage__close' src={require('../../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this, 'illness')} />
                    </div>
                );
                html.push(this._getDisabilityHtml('illness'))
            }
        }

        return (
            <div className={'coverage-option-employer ' + (open ? ' open' : '')}>
                {html}
            </div>
        );
    }
}
