import moment from 'moment';

// App data comes from the server for an existing application.
// Form data is the currently-being-altered local application's state.
// The schemas are slightly different, so the intent of this function is to map one to the other
// when starting a form retry.
export default function appDataToFormData(appData, formData={}) {
    const formType = appData.carrier_form.form_type;
    nonDateKeys(formType).forEach(key => {
        formData.primary[key] = appData[key]
    })

    dateKeys(formType).forEach(key => {
        if (appData[key] !== undefined) {
            formData.primary[key] = moment(appData[key]).format('MM/DD/YYYY')
        }
    })

    formData.company = appData.company
    if (formType === 'termination') {
        terminationAppDataToFormData(appData, formData);
    } else if (formType === 'new_hire_enrollment') {
        newHireEnrollmentAppDataToFormData(appData, formData);
    }
}

function nonDateKeys(formType) {
    const commonKeys = [
        'email',
        'first_name',
        'last_name',
        'ssn',
        'sex',
        'home_address',
        'home_phone',
        'email_type',
        'occupation',
        'employment_status',
        'hours_worked',
    ];
    if (formType === 'new_hire_enrollment') {
        const newHireKeys = [
            'income',
            'income_type',
            'income_unit',
            'preferred_language',
            'disability',
            'union_member',
            'work_address',
            'marital_status',
        ];
        return commonKeys.concat(newHireKeys);
    } else if (formType === 'termination') {
        const terminationKeys = [
            'termination_reason'
        ];
        return commonKeys.concat(terminationKeys);
    } else {
        return [];
    }
}

function dateKeys(formType) {
    const commonKeys = [
        'dob',
        'hire_date'
    ];
    if (formType === 'new_hire_enrollment') {
        const newHireKeys = [
            'full_time_start_date',
        ]
        return commonKeys.concat(newHireKeys);
    } else if (formType === 'termination') {
        const terminationKeys = [
            'last_work_date',
            'last_coverage_date'
        ];
        return commonKeys.concat(terminationKeys);
    } else {
        return [];
    }
}

function terminationAppDataToFormData(appData, formData) {
    const { termination_info } = appData;
    formData.termination_info.termination_options = termination_info.termination_options;
}

function newHireEnrollmentAppDataToFormData(appData, formData) {
    appData.dependents.forEach(dependent => {
        dateKeys('new_hire_enrollment').forEach(key => {
            if (dependent[key] !== undefined) {
                dependent[key] = moment(dependent[key]).format('MM/DD/YYYY')
            }
        })
        const dep_key = dependent.ssn.substr(dependent.ssn.length - 4)
        dependent.selectedPlans = dependent.properties.selected_plans
        formData.dependents[`dependent_${dep_key}`] = dependent
    })
    addPlanSelectionsToFormData(appData, formData)
}

function addPlanSelectionsToFormData(appData, formData) {
    let formPlans = {}
    appData.plan_selections.forEach(planSelection => {
        const planId = planSelection.plan_id
        const plan = findPlanById(planId, appData)
        if (plan) {
            // Only add one per each line of coverage
            if (!formPlans[plan.line_of_coverage]) {
                formPlans[plan.line_of_coverage] = {
                    name: plan.name,
                    display: plan.name,
                    id: plan.id,
                    carrier_name: plan.carrier_name,
                    carrier_state: plan.carrier_state,
                }
            }
        }
    })
    formData.selectedPlans = formPlans
}

function findPlanById(planId, appData) {
    let plan;
    appData.carrier_form.eligibility_groups.forEach(eligibilityGroup => {
        eligibilityGroup.coverage_options.forEach(coverageOption => {
            const matchingPlan = coverageOption.plans.find(plan => {
                return plan.id === planId
            })

            if (matchingPlan) {
                plan = {
                    id: planId,
                    name: matchingPlan.name,
                    display: matchingPlan.name,
                    carrier_name: coverageOption.carrier_name,
                    carrier_state: coverageOption.carrier_state,
                    line_of_coverage: coverageOption.line_of_coverage,
                }
                return plan
            }
        })
    })

    return plan
}
