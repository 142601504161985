import React, { Component } from 'react';

import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import {
    ButtonContainer,
    StatusBar
} from '../index';

import {
    setApplicationCurrentStep,
    setApplicationSubmitState,
    setApplicationSignature,
    setApplicationError,
    setApplicationShowSuccess,
} from '../../actions/application';

import { setMessage } from '../../actions/app';

import StepTermination from './StepTermination';
import StepPersonal from './StepPersonal';
import StepVerify from './StepVerify';

import './Application.scss';

// first step of null is either clever or dumb
// STEPS should not require a certain length
// but in this case the 1st step (0) is so straightforward
// that render() displays some basic HTML for STEP[0]
const STEPS = [
    null, // step 0
    StepPersonal, // step 1
    StepTermination, // step 2
    StepVerify // step 3
];

const propTypes = {
    application: PropTypes.object,
    currentStep: PropTypes.number,
    formData: PropTypes.object,
    showSuccess: PropTypes.bool,
    error: PropTypes.any,
    submitting: PropTypes.bool,
};

const defaultProps = {
    application: {},
    currentStep: 0,
    formData: {},
    showSuccess: false,
    error: null,
    submitting: false,
};

// formData: {
//     primary: { },
//     company: {
//         admin: {}
//     },
//     termination_info: {
//         termination_options: [],
//         missingFields: []
//     }
// }

class TerminationApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        STEPS.forEach(step => {
            if (step && step.WrappedComponent) {
                const name = step.WrappedComponent.name.toLowerCase();
                this[`${name}_ref`] = React.createRef();
            }
        });
    }

    componentWillMount() {
        document.title = 'Noyo - Termination Application';
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setApplicationCurrentStep(this.props.currentStep);
    }

    back(step) {
        this.setState({currentStep: step}, window.scrollTo(0,0));
    }

    continue() {
        const {
            currentStep,
            setApplicationCurrentStep,
            setApplicationError,
        } = this.props;

        const CurrentStepComponent = STEPS[currentStep];
        const validated = currentStep === 0 ? true : this[`${CurrentStepComponent.WrappedComponent.name.toLowerCase()}_ref`].current.validate();

        // validate current step before proceeding
        if (validated) {
            const nextStep = currentStep + 1;

            setApplicationCurrentStep(nextStep);
            setApplicationError(false);

            window.scrollTo(0, 0);
        } else {
            setApplicationError(true);
        }
    }

    _getSignature(currentStepComponentRef) {
        let imageDataUrl = currentStepComponentRef.current.signaturePad.toDataURL();
        let imageData = imageDataUrl.replace('data:image/png;base64,','');
        return imageData;
    }

    submit() {

        const {
            application,
            currentStep,
            setApplicationSignature,
            setApplicationSubmitState,
            submitEmployeeApplication,
        } = this.props;

        const CurrentStepComponent = STEPS[currentStep];
        const currentStepComponentRef = this[`${CurrentStepComponent.WrappedComponent.name.toLowerCase()}_ref`]
        const signature = this._getSignature(currentStepComponentRef);
        const validated = currentStepComponentRef.current.validate({ signature });

        if (validated) {

            setApplicationSignature(signature);
            setApplicationSubmitState(true);

            submitEmployeeApplication(
                application.id,
                null,
                this.showSuccess.bind(this),
                this.showError.bind(this)
            );
        }

    }

    showSuccess() {
        this.props.setApplicationShowSuccess(true);
        window.scrollTo(0, 0)
    }

    showError(errors) {
        const error_message = (
            <div className="alert__error_list">
                <ul>
                    {errors.map((err, idx) => ( <li key={idx}>{err}</li>))}
                </ul>
            </div>
        )

        this.props.setMessage('Error', error_message);

        this.props.setApplicationSubmitState(false);
        window.scrollTo(0, 0);
    }

    render() {
        const {
            currentStep,
            showSuccess,
            error,
            submitting,
            application
        } = this.props;

        let displayDiv;

        if (showSuccess) {
            displayDiv = (
                <div className='employee-application'>
                    <div className='header status'></div>
                    <div className='employee-application__success-container'>
                        <div className='employee-application__success-container__checkmark'>
                            <img alt="green check" src={require('../../assets/images/ic-green-check.svg')} height='48' width='48' />
                        </div>
                        <div className='employee-application__success-container__header'>Application Complete!</div>
                        <div className='employee-application__success-container__text'>
                            {"Thanks for submitting your application."}
                        </div>
                        <div className='employee-application__success-container__text'>Have a great day.</div>
                    </div>
                </div>
            );
        }
        else if (application) {
            const buttonText = currentStep === 0 ? 'get started' : currentStep < 3 ? 'continue' : 'submit';
            const buttonOnChange = currentStep < 3 ? this.continue.bind(this) : this.submit.bind(this);

            if (currentStep === 0) {
                displayDiv = (
                    <div className='employee-application termination'>
                        <div className='employee-application__container'>
                            <div className='main-text'>
                                <div className='name'>Hello {application.user.email}!</div>
                                <div>To remove someone from insurance coverage at {application.company.name}, please complete the following information. Your broker will take it from there and make the necessary updates to the person's coverage</div>
                                <div className='bold'>This should take 5 minutes to complete.</div>
                            </div>
                        </div>
                        <div className='employee-application__footer'>
                            <ButtonContainer text={buttonText} selected={true} theme='filled' onClick={buttonOnChange} />
                        </div>
                    </div>
                );
            } else {
                const CurrentStepComponent = STEPS[currentStep];
                let ref;
                if (CurrentStepComponent && CurrentStepComponent.WrappedComponent) {
                    ref = `${CurrentStepComponent.WrappedComponent.name.toLowerCase()}_ref`;
                }

                displayDiv = (
                    <div className='employee-application termination'>
                        <div className='employee-application__container'>
                            <CurrentStepComponent ref={this[ref]} />
                        </div>
                        <div className='employee-application__footer'>
                            {error &&
                                <div className='employee-application__footer__error'>Errors found above</div>
                            }
                            <ButtonContainer
                                text={submitting ? 'submitting...' : buttonText}
                                disabled={submitting}
                                selected={true}
                                theme='filled'
                                onClick={buttonOnChange}
                            />
                        </div>
                    </div>
                )
            }
        }

        return (
            <div>
                <StatusBar currentStep={currentStep} />
                {displayDiv}
            </div>
        );
    }
}

TerminationApplication.propTypes = propTypes;
TerminationApplication.defaultProps = defaultProps;

const mapStateToProps = ({ Application }) => {

    const {
        currentStep,
        submitting,
        showSuccess,
        error,
        formData,
        data
    } = Application;

    return {
        application: data,
        currentStep,
        formData,
        showSuccess,
        error,
        submitting,
    }
};

const mapDispatchToProps = {
    setApplicationSignature,
    setApplicationSubmitState,
    setApplicationCurrentStep,
    setApplicationError,
    setApplicationShowSuccess,
    setMessage
};

const ConnectedTerminationApplication = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(TerminationApplication);

export default ConnectedTerminationApplication;
