import React, { Component } from 'react';
import T from 'prop-types';

import './TextField.scss';

import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from 'cleave.js/react';

import {DebounceInput} from 'react-debounce-input';

export default class TextField extends Component {
    static propTypes = {
        label: T.string,
        disabled: T.bool,
        theme: T.string,
        className: T.string,
        onFocus: T.func,
        onChange: T.func,
        onKeyDown: T.func,
        type: T.string,
        formatOptions: T.object
    };

    constructor(...args) {
        super(...args);

        this.state = {
            text: (this.props.text && this.props.text.toString()) || '',
            inFocus: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.text !== this.props.text) {
            this.setState({
                text: nextProps.text
            });
        }
    }

    onChange(event) {
        const text = event.target.value;
        this.setState({text});

        const {onChange, className} = this.props;
        if (onChange) {
            onChange(className, text);
        }
    }

    focus() {
        this.setState({inFocus: true});
        if (this.refs.textField && this.refs.textField.focus) {
            this.refs.textField.focus();
        }
    }

    blur() {
        this.setState({inFocus: false});
    }

    render() {
        const {
            disabled, theme, label, className,
            type = 'text', maxLength = 255, error = false,
            helperOnHover, helperOnlyOnError, helperText, onKeyDown
        } = this.props;
        const {text, inFocus} = this.state;

        let containerClasses = 'textFieldContainer';
        let classes = 'textField';
        let customHelperText = (helperOnlyOnError || helperOnHover) ? '' : helperText;

        if (disabled) {
            classes += ' disabled';
        }
        if (theme) {
            classes += ' ' + theme;
            containerClasses += ' ' + theme;
        }
        if (error) {
            classes += ' error';
            if (!helperText || helperOnHover) {
                customHelperText = 'Required';
            } else {
                customHelperText = helperText;
            }
        }
        if (className) {
            classes += ' ' + className;
        }
        if (inFocus) {
            classes += ' selected';
        }
        let inputElement = (<input ref='textField' onKeyDown={onKeyDown} onChange={this.onChange.bind(this)} type={type} autoComplete="off" value={text} disabled={disabled} onFocus={this.focus.bind(this)} onBlur={this.blur.bind(this)} maxLength={maxLength} required />);
        if (this.props.formatOptions) {
            inputElement = (<Cleave ref='textField' options={this.props.formatOptions} onKeyDown={onKeyDown} onChange={this.onChange.bind(this)} type={type} autoComplete="off" value={text} disabled={disabled} onFocus={this.focus.bind(this)} onBlur={this.blur.bind(this)} required />);
        } else if (this.props.debounce) {
            const minLength = this.props.debounceMinLength || 2;
            const timeout = this.props.debounceTimeout || 800;
            inputElement = (<DebounceInput ref='textField' value={text} minLength={minLength} debounceTimeout={timeout} onChange={this.onChange.bind(this)} onKeyDown={onKeyDown} />);
        }

        return (
            <div className={containerClasses} onClick={this.focus.bind(this)}>
                <div className={classes}>
                    <div className='form-label form-css-label'>
                        <fieldset className="form-label-placeholder">
                            {inputElement}
                            <label>{label}</label>
                        </fieldset>
                    </div>
                </div>
                <div className={'helperText ' + (error ? 'error' : '')}>{customHelperText}</div>
                {helperOnHover &&
                    <div className='helperOnHover'>{helperText}</div>
                }
            </div>
        );
    }
}
