import React, { Component } from 'react';
// import T from 'prop-types';

import {ButtonContainer, TextFieldContainer} from '../../index';

// Login.prototype = {
//     navigate: T.func,
//     onClick: T.func,
//     resetPasswordSuccess: T.bool
// };

export default class Login extends Component {

    constructor (propss) {
        super(propss);

        this.state = {
            'email': null,
            'password': null
        };
    }

    displayForgotPassword(e) {
        e.preventDefault();
        this.props.navigate('forgot_password');
        return false;
    }

    login (event){
        event.preventDefault();
        this.props.onClick('login', this.state);
    }

    onChange(className, text) {
        this.setState({[className]: text});
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            this.login(event);
        }
    }

    render () {

        return (
            <div className='login'>
                <div className="login-form content">
                    <div className='login-header'>Sign In</div>
                    {this.props.resetPasswordSuccess &&
                        <p>Password successfully reset!</p>
                    }
                    <form ref='login' className="form">
                        <div className="row">
                            <TextFieldContainer
                                key='email'
                                label="Email"
                                className='email'
                                ref='email'
                                onChange={this.onChange.bind(this)}
                                onKeyDown={this.onKeyDown.bind(this)}
                            />
                        </div>
                        <div className="row">
                            <TextFieldContainer
                                key='password'
                                label="Password"
                                className='password'
                                ref='password'
                                type='password'
                                onChange={this.onChange.bind(this)}
                                onKeyDown={this.onKeyDown.bind(this)}
                            />
                        </div>
                        <div className="ctaRow">
                            <a href="/" className='forgot-password' onClick={this.displayForgotPassword.bind(this)}>Forgot password?</a>
                            <ButtonContainer text='LOG IN' selected={true} theme='filled' className='submit' onClick={this.login.bind(this)} />
                        </div>
                    </form>
                    <div className='contact'>
                        <div>New to Noyo?</div>
                        <a href="mailto:all@gonoyo.com">Contact sales for an account</a>
                    </div>
                </div>
            </div>
        );
    }
}
