import React, { Component } from 'react';

import { TextFieldContainer, DropdownContainer, Address, CheckboxQuestion } from '../../index';

import Config from '../../../config';
import { guid, validateFields } from '../../../utils';

import Location from './Location';
import Owner from './Owner';
import Subsidiary from './Subsidiary';
import WorkersCompensation from './WorkersCompensation';

const MAX_ITEMS = 3;

const PREFILL_DATA = {
  "numLocations": 1,
  "owners": [],
  "subsidiaries": [],
  "locations": [],
  "group_name": "New Test Company",
  "email": "an@kitdesai.com",
  "banking": {},
  "workers_compensation": {
    "owners": [],
    "offer_coverage": "yes"
  },
  "phone": "678-678-6789",
  "website": "gonoyo.com",
  "address": {
    "addressString": "22226 La Palma Ave, Yorba Linda, CA 92887, USA",
    "city": "Yorba Linda",
    "county": "Orange County",
    "state": "CA",
    "zip_code": "92887",
    "street": "22226 La Palma Avenue"
  },
  "established_date": "07/07/1999",
  "business_type": "biz",
  "organization_type": "llc",
  "sic": "3333",
  "fein": "333333333"
}

export default class CompanyInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            numLocations: 0,
            ...props.formData.company_information
        }
    }

    prefill() {
        this.setState(PREFILL_DATA, () => this.props.onChange(this.state));
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    validate() {
        let requiredFields = [
            'group_name', 'fein', 'sic', 'organization_type', 'business_type', 'established_date',
            'address', 'website', 'phone'
        ];

        const missingFields = validateFields(requiredFields, this.state);

        if (!this.state.workers_compensation || this.state.workers_compensation.offer_coverage === undefined) {
            missingFields.push('offer_coverage');
        }

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    addParent() {
        this.setState({showParent: true});
    }

    removeParent() {
        this.setState({showParent: false});
    }

    addItem(itemType) {
        let items = this.state[itemType];
        if (items.length < MAX_ITEMS) {
            items.push({id: guid()});
            this.setState({[itemType]: items});
        }
    }

    onItemChange(itemType, id, item) {
        let items = this.state[itemType];
        items = items.filter(i => i.id !== id);
        items.push(item);
        items = items.sort((a,b) => a.id - b.id);
        this.setState({[itemType]: items}, () => this.props.onChange(this.state));
    }

    removeItem(itemType, id) {
        let items = this.state[itemType];
        items = items.filter(i => i.id !== id);
        this.setState({[itemType]: items}, () => this.props.onChange(this.state));
    }

    render() {
        const {missingFields, owners, locations, subsidiaries, workers_compensation = {}} = this.state;

        let subsidiariesDiv = [];
        subsidiaries.forEach((s, index) => {
            subsidiariesDiv.push(
                <Subsidiary
                    key={s.id}
                    index={index + 1}
                    {...s}
                    onChange={(subsidiary) => this.onItemChange('subsidiaries', s.id, subsidiary)}
                    remove={this.removeItem.bind(this, 'subsidiaries', s.id)}
                />
            );
        });

        let locationsDiv = [];
        locations.forEach((l, index) => {
            locationsDiv.push(
                <Location
                    key={l.id}
                    index={index + 1}
                    {...l}
                    onChange={(location) => this.onItemChange('locations', l.id, location)}
                    remove={this.removeItem.bind(this, 'locations', l.id)}
                />
            );
        });

        let ownersDiv = [];
        owners.forEach((o, index) => {
            ownersDiv.push(
                <Owner
                    key={o.id}
                    index={index + 1}
                    {...o}
                    onChange={(owner) => this.onItemChange('owners', o.id, owner)}
                    remove={this.removeItem.bind(this, 'owners', o.id)}
                />
            );
        });

        return (
            <div>
                <div>
                    <div className='main-text'>
                        <div className='name light'>Company Information</div>
                        <div>Tell us about your company so we can get you set up for coverage</div>
                    </div>
                    {Config.REACT_APP_DEV_MODE &&
                        <div className='link' style={{textAlign: 'left'}} onClick={this.prefill.bind(this)}>PREFILL INFO</div>
                    }
                    <div className='row'>
                        <TextFieldContainer
                            key='group_name'
                            label="Group Name"
                            className='group_name'
                            ref='group_name'
                            type='text'
                            text={this.state.group_name}
                            error={missingFields.indexOf('group_name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='dba'
                            label="DBA (Doing Business As)"
                            className='dba'
                            ref='dba'
                            type='text'
                            text={this.state.dba}
                            error={missingFields.indexOf('dba') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='fein'
                            label="FEIN"
                            className='fein'
                            ref='fein'
                            type='text'
                            text={this.state.fein}
                            error={missingFields.indexOf('fein') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numericOnly: true, blocks: [9]}}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='sic'
                            label="SIC Code"
                            className='sic'
                            ref='sic'
                            type='text'
                            text={this.state.sic}
                            error={missingFields.indexOf('sic') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numericOnly: true, blocks: [4]}}
                        />
                    </div>
                    <div className='row'>
                        <DropdownContainer
                            key='organization_type'
                            label="Organization Type"
                            className='organization_type'
                            ref='organization_type'
                            onChange={this.onChange.bind(this)}
                            error={missingFields.indexOf('organization_type') > -1}
                            options={[
                                {display: 'Corporation', value: 'corporation'},
                                {display: 'Partnership', value: 'partnership'},
                                {display: 'Proprietorship', value: 'proprietorship'},
                                {display: 'LLC', value: 'llc'},
                                {display: 'LLP', value: 'llp'},
                                {display: 'S-Corp', value: 's-corp'},
                                {display: 'Non-Proft', value: 'non-profit'},
                                {display: 'Government', value: 'government'},
                                {display: 'Organization exempt from Income Tax', value: 'income_tax_exempt'},
                                {display: 'Labor Union Trust', value: 'labor_union_trust'},

                            ].map(option => {
                                const {display, value} = option;
                                return {display, value, selected: this.state.organization_type === value}
                            })}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='business_type'
                            label="Business Type"
                            className='business_type'
                            ref='business_type'
                            type='text'
                            text={this.state.business_type}
                            error={missingFields.indexOf('business_type') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='established_date'
                            label="Date Company was Established"
                            className='established_date'
                            ref='established_date'
                            type='text'
                            text={this.state.established_date}
                            error={missingFields.indexOf('established_date') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                        />
                    </div>
                    <div className='row'>
                        <CheckboxQuestion
                            questionText='Does the company employ any of the following services? (Check all that apply)'
                            className='company_services'
                            options={[
                                {display: 'PEO', value: 'peo'},
                                {display: 'ELC', value: 'elc'},
                                {display: 'SLC', value: 'slc'},
                                {display: 'HRO', value: 'hro'},
                                {display: 'ASO', value: 'aso'}
                            ]}
                            capitalize={false}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    {(this.state.company_services && this.state.company_services.indexOf('peo') > -1) &&
                        <div className='row'>
                            <TextFieldContainer
                                key='peo_name'
                                label="PEO Name"
                                className='peo_name'
                                ref='peo_name'
                                type='text'
                                text={this.state.peo_name}
                                error={missingFields.indexOf('peo_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                    <div className='section-header'>Contact Information</div>
                    <div className='row'>
                        <Address
                            label='Company Address'
                            value={this.state.address}
                            error={missingFields.indexOf('address') > -1}
                            onChange={(address) => this.onChange('address', address)}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='website'
                            label="Website"
                            className='website'
                            ref='website'
                            type='text'
                            text={this.state.website}
                            error={missingFields.indexOf('website') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row three'>
                        <TextFieldContainer
                            key='phone'
                            label="Phone #"
                            className='phone'
                            ref='phone'
                            type='text'
                            text={this.state.phone}
                            error={missingFields.indexOf('phone') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                        />
                        <TextFieldContainer
                            key='fax'
                            label="Fax #"
                            className='fax'
                            ref='fax'
                            type='text'
                            text={this.state.fax}
                            error={missingFields.indexOf('fax') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                        />
                        <TextFieldContainer
                            key='email'
                            label="Email Address"
                            className='email'
                            ref='email'
                            type='text'
                            text={this.state.email}
                            error={missingFields.indexOf('email') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='section-header'>Associated Organizations</div>
                    {this.state.showParent &&
                        <div>
                            <div className='row'>
                                <div>Parent Company</div>
                                <img
                                    alt=""
                                    style={{cursor: 'pointer', marginLeft: 10, marginTop: 3}}
                                    className='close'
                                    src={require('../../../assets/images/ic-close.svg')}
                                    height='12' width='12'
                                    onClick={this.removeParent.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='parent_company_name'
                                    label="Company Name"
                                    className='parent_company_name'
                                    ref='parent_company_name'
                                    type='text'
                                    theme='full-width'
                                    text={this.state.parent_company_name}
                                    error={missingFields.indexOf('parent_company_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                        </div>
                    }
                    {!this.state.showParent &&
                        <div className='row'>
                            <div onClick={this.addParent.bind(this)} className='link no-margin-top plus'>Add parent company</div>
                        </div>
                    }
                    <div className='subsidiaries'>
                        {subsidiariesDiv}
                        {this.state.subsidiaries.length < MAX_ITEMS &&
                            <div className='row'>
                                <div onClick={this.addItem.bind(this, 'subsidiaries')} className='link no-margin-top plus'>Add Subsidiary or Affiliate Company</div>
                            </div>
                        }
                    </div>
                    <div className='section-header no-bold'>Does the company have other locations?</div>
                    <div className='locations'>
                        {locationsDiv}
                        {this.state.locations.length < MAX_ITEMS &&
                            <div className='row'>
                                <div onClick={this.addItem.bind(this, 'locations')} className='link no-margin-top plus'>Add location</div>
                            </div>
                        }
                    </div>
                    <div className='section-header'>Company Owners</div>
                    <div className='owners'>
                        {ownersDiv}
                        {owners.length < MAX_ITEMS &&
                            <div onClick={this.addItem.bind(this, 'owners')} className='link plus'>Add owner</div>
                        }
                    </div>
                </div>
                <WorkersCompensation {...workers_compensation} missingFields={missingFields} onChange={(data) => this.onChange('workers_compensation', data)} />
            </div>
        )
    }
}
