import React, { Component } from 'react';
import T from 'prop-types';

import { capitalize as capitalizeFunc } from '../../../utils';

import { RadioButton } from '../../index';

import './RadioButtonQuestion.scss';

export default class RadioButtonQuestion extends Component {
    static propTypes = {
        label: T.string,
        value: T.string,
        disabled: T.bool,
        theme: T.string,
        className: T.string,
        onFocus: T.func,
        editing: T.bool,
        onChange: T.func,
        type: T.string
    };

    constructor(...args) {
        super(...args);

        this.state = {
            value: this.props.value || ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    onChange(key, value) {
        this.setState({value});

        const {onChange} = this.props;
        if (onChange) {
            onChange(key, value);
        }
    }

    render() {
        const {disabled, theme, questionText, className, options, error, capitalize = true} = this.props;

        let classes = 'radio-button';
        if (disabled) {
            classes += ' disabled';
        }
        if (theme) {
            classes += ' ' + theme;
        }
        if (className) {
            classes += ' ' + className;
        }

        const randInt = Math.floor(Math.random()*10000);
        const radioOptions = options.map((option, index) => {
            let label = option.display || option.value;
            if (capitalize) {
                label = capitalizeFunc(label);
            }
            return (
                <span key={'radio-button-' + index} className='radio__container'>
                    <RadioButton
                        name={option.name + '-' + randInt}
                        checked={option.selected}
                        error={error}
                        label={label}
                        onClick={() => this.onChange(option.name, option.value)}
                        radioId={className + '-' + option.value + '-' + randInt}
                    />
                </span>
            )
        });
        return (
            <div className={classes}>
                <div className='question-text'>{questionText}</div>
                <div className='questions'>{radioOptions}</div>
            </div>
        );
    }
}
