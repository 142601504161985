import React, { Component } from 'react';

import { TextFieldContainer, Address, RadioButtonQuestionContainer } from '../../index';

export default class Subsidiary extends Component {
    constructor(props) {
        super(props);

        const {
            id, name, fein, address,
            business_nature, enrollee_company_relationship, employee_count,
            combined_tax_return, coverage_eligible
        } = props;
        this.state = {
            missingFields: [],
            id, name, fein, address,
            business_nature, enrollee_company_relationship, employee_count,
            combined_tax_return, coverage_eligible
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div className='owner'>
                <div className='row'>
                    <div>Subsidiary or Affiliate Company #{this.props.index}</div>
                    <img
                        alt=""
                        style={{cursor: 'pointer', marginLeft: 10, marginTop: 3}}
                        className='close'
                        src={require('../../../assets/images/ic-close.svg')}
                        height='12' width='12'
                        onClick={this.props.remove}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='name'
                        label="Company Name"
                        className='name'
                        ref='name'
                        type='text'
                        text={this.state.name}
                        error={missingFields.indexOf('name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='fein'
                        label="FEIN"
                        className='fein'
                        ref='fein'
                        type='text'
                        text={this.state.fein}
                        error={missingFields.indexOf('fein') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{numericOnly: true, blocks: [9]}}
                    />
                </div>
                <div className='row'>
                    <Address
                        label='Company Address'
                        value={this.state.address}
                        error={missingFields.indexOf('address') > -1}
                        onChange={(address) => this.onChange('address', address)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='business_nature'
                        label="Nature of Business"
                        className='business_nature'
                        ref='business_nature'
                        type='text'
                        text={this.state.business_nature}
                        error={missingFields.indexOf('business_nature') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='enrollee_company_relationship'
                        label="Relationship to enrolling company"
                        className='enrollee_company_relationship'
                        ref='enrollee_company_relationship'
                        type='text'
                        text={this.state.enrollee_company_relationship}
                        error={missingFields.indexOf('enrollee_company_relationship') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='employee_count'
                        label="Total Employee Count"
                        className='employee_count'
                        ref='employee_count'
                        type='text'
                        text={this.state.employee_count}
                        error={missingFields.indexOf('employee_count') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{numeral: true}}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Is this organization eligible to file a combined tax return with your company?'}
                        options={[
                            {name: 'combined_tax_return', display: 'no', value: false, selected: (this.state.combined_tax_return !== undefined && !this.state.combined_tax_return)},
                            {name: 'combined_tax_return', display: 'yes', value: true, selected: this.state.combined_tax_return}
                        ]}
                        error={missingFields.indexOf('combined_tax_return') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Will employees of this organization be included in coverage?'}
                        options={[
                            {name: 'coverage_eligible', display: 'no', value: false, selected: (this.state.coverage_eligible !== undefined && !this.state.coverage_eligible)},
                            {name: 'coverage_eligible', display: 'yes', value: true, selected: this.state.coverage_eligible}
                        ]}
                        error={missingFields.indexOf('coverage_eligible') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>
        )
    }
}
