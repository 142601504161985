import React, { Component } from 'react';

import { TextFieldContainer } from '../../index';
import { validateFields } from '../../../utils';

import CoverageOptionEmployerContainer from './CoverageOptionEmployerContainer';
import CoverageOptionEmployerOtherContainer from './CoverageOptionEmployerOtherContainer';

import '../Broker.scss';

const COVERAGE_OPTIONS = ['medical', 'dental', 'vision', 'life', 'std', 'ltd', 'other'];

export default class EligibilityGroupEmployer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            coverage_options: this.props.coverage_options || []
        }

        COVERAGE_OPTIONS.forEach((option) => {
            this[`coverage_option_${option}_ref`] = React.createRef();
        });
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state, this.props.index));
    }

    validate() {
        const { coverage_options } = this.state;
        const requiredFields = [
            'eligibility_group_name', 
            'eligibility_group_description'
        ];

        const missingFields = validateFields(requiredFields, this.state);
        const _this = this;
        let childrenValidated = coverage_options.length > 0;

        coverage_options.forEach(option => {
            childrenValidated &= _this[`coverage_option_${option.line_of_coverage}_ref`].current.validate();
        });

        if (coverage_options.length === 0) {
            missingFields.push('coverage_options');
        }

        this.setState({missingFields});

        return missingFields.length === 0 && childrenValidated;
    }

    onCoverageOptionOtherChange(values) {
        let coverage_options = this.state.coverage_options;
        coverage_options = coverage_options.filter(option => option.line_of_coverage !== 'other')
        values.forEach(value => {
            if (value.open) {
                value.line_of_coverage = 'other';
                coverage_options.push(value);
            }
        })
        this.setState({coverage_options}, () => this.props.onChange(this.state, this.props.index));
    }

    onCoverageOptionChange(value, line_of_coverage) {
        let coverage_options = this.state.coverage_options;
        coverage_options = coverage_options.filter(option => option.line_of_coverage !== line_of_coverage)
        if (value.open) {
            value.line_of_coverage = line_of_coverage;
            coverage_options.push(value);
        }
        this.setState({coverage_options}, () => this.props.onChange(this.state, this.props.index));
    }

    getCoverageOptions() {
        const {coverage_options} = this.state;
        let options = [];
        COVERAGE_OPTIONS.forEach((option, index) => {
            if (option === 'other') {
                options.push(
                    <CoverageOptionEmployerOtherContainer
                        ref={ref => this[`coverage_option_${option}`] = ref}
                        key={option} index={index}
                        options={coverage_options.filter(c => c.line_of_coverage === option)}
                        line_of_coverage={option}
                        onChange={this.onCoverageOptionOtherChange.bind(this)}
                    />
                );
            } else {
                options.push(
                    <CoverageOptionEmployerContainer
                        ref={ref => this[`coverage_option_${option}`] = ref}
                        key={option} index={index}
                        {...coverage_options.filter(c => c.line_of_coverage === option)[0]}
                        line_of_coverage={option}
                        onChange={this.onCoverageOptionChange.bind(this)}
                    />
                );
            }
        });
        return options;
    }

    render() {
        const {index} = this.props;
        const {missingFields} = this.state;
        const first = index === 0;

        let coverageOptionsText = 'Coverage Options';
        let coverageOptionsClass = 'coverage-options';
        if (missingFields.indexOf('coverage_options') > -1) {
            coverageOptionsText += ' (select at least one)';
            coverageOptionsClass += ' error';
        }
        return (
            <div className={'eligibility-group-employer ' + (!first ? 'border-top' : '')}>
                <div className="row">
                    <TextFieldContainer
                        key='eligibility_group_name'
                        label="Eligibility Group Name"
                        className='eligibility_group_name'
                        ref='eligibility_group_name'
                        type='text'
                        theme='full-width'
                        text={this.props.eligibility_group_name}
                        error={missingFields.indexOf('eligibility_group_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    {!first &&
                        <img alt="" className='close' src={require('../../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.props.close} />
                    }
                </div>
                <div className="row">
                    <TextFieldContainer
                        key='eligibility_group_description'
                        label="Eligibility Group Description"
                        className='eligibility_group_description'
                        ref='eligibility_group_description'
                        type='text'
                        theme='full-width'
                        text={this.props.eligibility_group_description}
                        error={missingFields.indexOf('eligibility_group_description') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className={coverageOptionsClass}>{coverageOptionsText}</div>
                <div>{this.getCoverageOptions()}</div>
            </div>
        );
    }
}
