import React, { Component } from 'react';

import { TextFieldContainer, DropdownContainer, Address } from '../../index';

import { validateFields } from '../../../utils';

export default class CompanyBankingInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props.formData.company_information.banking
        }
    }

    onChange(key, value) {
        if (key === 'premium_amount') {
            value = value.replace(/[$,]/g , '');
        }
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    validate() {
        let requiredFields = ['bank_name', 'address'];

        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div>
                <div className='main-text'>
                    <div className='name light'>Company Banking Information</div>
                    <div>Provide banking information to set up payment with the insurance company.</div>
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='bank_name'
                        label="Bank Name"
                        className='bank_name'
                        ref='bank_name'
                        type='text'
                        text={this.state.bank_name}
                        error={missingFields.indexOf('bank_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <Address
                        label='Bank Address'
                        value={this.state.address}
                        error={missingFields.indexOf('address') > -1}
                        onChange={(address) => this.onChange('address', address)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='phone'
                        label="Bank Phone #"
                        className='phone'
                        ref='phone'
                        type='text'
                        text={this.state.phone}
                        error={missingFields.indexOf('phone') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='account_type'
                        label='Account Type'
                        className='account_type'
                        ref='account_type'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Checking', value: 'checking'},
                            {display: 'Savings', value: 'savings'}

                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.account_type === value}
                        })}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='account_holder'
                        label="Account Holder"
                        className='account_holder'
                        ref='account_holder'
                        type='text'
                        text={this.state.account_holder}
                        error={missingFields.indexOf('account_holder') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='account_number'
                        label="Account Number"
                        className='account_number'
                        ref='account_number'
                        type='text'
                        text={this.state.account_number}
                        error={missingFields.indexOf('account_number') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='routing_number'
                        label="Routing Number"
                        className='routing_number'
                        ref='routing_number'
                        type='text'
                        theme='margin-left'
                        text={this.state.routing_number}
                        error={missingFields.indexOf('routing_number') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='first_payment_debit_amount'
                        label='How much would you like to debit in this first payment?'
                        className='first_payment_debit_amount'
                        ref='first_payment_debit_amount'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'First Month\'s Premium', value: 'premium'},
                            {display: 'Other Dollar Amount', value: 'other_amount'}

                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.first_payment_debit_amount === value}
                        })}
                    />
                </div>
                {this.state.first_payment_debit_amount === 'first_month_premium' &&
                    <div className='row'>
                        <TextFieldContainer
                            key='premium_amount'
                            label="What's the premium amount?"
                            className='premium_amount'
                            ref='premium_amount'
                            type='text'
                            text={this.state.premium_amount}
                            error={missingFields.indexOf('premium_amount') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numeral: true, prefix: '$', noImmediatePrefix: true}}
                        />
                    </div>
                }
                {this.state.first_payment_debit_amount === 'other_amount' &&
                    <div className='row'>
                        <TextFieldContainer
                            key='other_amount'
                            label="What's the dollar amount you want to debit?"
                            className='other_amount'
                            ref='other_amount'
                            type='text'
                            text={this.state.other_amount}
                            error={missingFields.indexOf('other_amount') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numeral: true}}
                        />
                    </div>
                }
                <div className='row'>
                    <DropdownContainer
                        key='monthly_payment_option'
                        label='How would you like to pay each month after the first month?'
                        className='monthly_payment_option'
                        ref='monthly_payment_option'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Automatic recurring payments online', value: 'recurring'},
                            {display: 'Manually schedule payments online', value: 'manual'},
                            {display: 'Make payments offline', value: 'offline'}

                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.monthly_payment_option === value}
                        })}
                    />
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='withdrawal_date'
                        label='What date of each month do you want this withdrawal to happen?'
                        className='withdrawal_date'
                        ref='withdrawal_date'
                        onChange={this.onChange.bind(this)}
                        options={[...Array(31).keys()].splice(1).map(date => {
                            return {display: date, value: date, selected: this.state.withdrawal_date === date}
                        })}
                    />
                </div>
            </div>
        );
    }
}
