import React, { Component } from 'react';

import { validateFields } from '../../../utils';

import {TextFieldContainer, DropdownContainer, Address, UploadContainer} from '../../index';

import '../Application.scss';


export default class ContactInformation extends Component {
    constructor(props) {
        super(props);

        const {contact_info} = props.primary;
        this.state = {
            open: contact_info.open || false,
            missingFields: [],
            new: contact_info.new || {},
            current: contact_info.current || {}
        }
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        });
    }

    onChange(key, value, type) {
        if (type) {
            let typeInfo = this.state[type];
            typeInfo[key] = value;
            this.setState({[type]: typeInfo}, this.props.onChange(this.state));
        } else {
            this.setState({[key]: value}, this.props.onChange(this.state));
        }
    }

    validate() {
        const requiredFields = [];
        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields, open} = this.state;
        return (
            <div>
                <div className={'link arrow' + (open ? ' open' : '')} onClick={this.toggleOpen.bind(this)}>
                    Change Contact Information
                </div>
                {open &&
                <div className='section-container'>
                    <div className='section'>
                        <div className='section__header'>Address</div>
                        <div className='row'>
                            <DropdownContainer
                                key='address_type'
                                className='address_type'
                                label='Address Type'
                                options={[
                                    {display: 'Home', value: 'home', selected: this.state.new.address_type === 'home'},
                                    {display: 'Mailing', value: 'mailing', selected: this.state.new.address_type === 'mailing'}
                                ]}
                                onChange={(k, v) => this.onChange(k, v, 'new')}
                            />
                        </div>
                        <div>
                            <div className='section__subheader'>New:</div>
                            <div className='row'>
                                <Address
                                    key={'address'}
                                    ref={'address'}
                                    value={this.state.new.address}
                                    label={'Address'}
                                    className={'address'}
                                    error={missingFields.indexOf('address') > -1}
                                    onChange={val => this.onChange('address', val, 'new')}
                                />
                            </div>
                            <div className='section__subheader'>Current:</div>
                            <div className='row'>
                                <Address
                                    key={'address'}
                                    ref={'address'}
                                    value={this.state.current.address}
                                    label={'Address'}
                                    className={'address'}
                                    error={missingFields.indexOf('address') > -1}
                                    onChange={val => this.onChange('address', val, 'current')}
                                />
                            </div>
                            <div className='row'>
                                <UploadContainer
                                    label='Upload Documentation'
                                    onChange={file => this.onChange('address_change_file', file, 'new')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>Home Phone</div>
                        <div className='section__subheader'>New:</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='home_phone'
                                label="Home Phone #"
                                className='home_phone'
                                ref='home_phone'
                                type='text'
                                text={this.state.new.home_phone}
                                error={missingFields.indexOf('home_phone') > -1}
                                onChange={(key, val) => this.onChange(key, val, 'new')}
                                formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                            />
                        </div>
                        <div className='section__subheader'>Current:</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='home_phone'
                                label="Home Phone #"
                                className='home_phone'
                                ref='home_phone'
                                type='text'
                                text={this.state.current.home_phone}
                                error={missingFields.indexOf('home_phone') > -1}
                                onChange={(key, val) => this.onChange(key, val, 'current')}
                                formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                            />
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>Work Phone</div>
                        <div className='section__subheader'>New:</div>
                        <div className='row'>
                            <TextFieldContainer
                                key={'work_phone'}
                                ref={'work_phone'}
                                text={this.state.new.work_phone}
                                label={'Work Phone'}
                                className={'work_phone'}
                                error={missingFields.indexOf('work_phone') > -1}
                                onChange={(key, val) => this.onChange(key, val, 'new')}
                            />
                        </div>
                        <div className='section__subheader'>Current:</div>
                        <div className='row'>
                            <TextFieldContainer
                                key={'work_phone'}
                                ref={'work_phone'}
                                text={this.state.current.work_phone}
                                label={'Work Phone'}
                                className={'work_phone'}
                                error={missingFields.indexOf('work_phone') > -1}
                                onChange={(key, val) => this.onChange(key, val, 'current')}
                            />
                        </div>
                    </div>
                    <div className='section'>
                        <div className='section__header'>Email Address</div>
                        <div className='section__subheader'>New:</div>
                        <div className='row'>
                            <TextFieldContainer
                                key={'email'}
                                ref={'email'}
                                text={this.state.new.email}
                                label={'Email Address'}
                                className={'email'}
                                error={missingFields.indexOf('email') > -1}
                                onChange={(key, val) => this.onChange(key, val, 'new')}
                            />
                        </div>
                        <div className='section__subheader'>Current:</div>
                        <div className='row'>
                            <TextFieldContainer
                                key={'email'}
                                ref={'email'}
                                text={this.state.current.email}
                                label={'Email Address'}
                                className={'email'}
                                error={missingFields.indexOf('email') > -1}
                                onChange={(key, val) => this.onChange(key, val, 'current')}
                            />
                        </div>
                        <div className='row'>
                            <UploadContainer
                                label='Upload Documentation'
                                onChange={file => this.onChange('contact_info_change_file', file, 'new')}
                            />
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
