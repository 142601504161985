import React, { Component } from 'react';
import T from 'prop-types';

import './Button.scss';

export default class Button extends Component {
    static propTypes = {
        text: T.string.isRequired,
        selected: T.bool,
        disabled: T.bool,
        allowDisabledClick: T.bool,
        onClick: T.func,
        image: T.string,
        imageSelected: T.string
    };

    onClick(event) {
        const {disabled, allowDisabledClick = false, onClick} = this.props;
        if ((!disabled || allowDisabledClick) && onClick) {
            onClick(event);
        }
    }

    render() {
        const {text, disabled, selected, theme, className, image, imageSelected, lowercase = false} = this.props;
        let classes = 'button';
        let buttonImg = image;
        if (disabled) {
            classes += ' disabled';
        }
        if (selected) {
            classes += ' selected';
            buttonImg = imageSelected;
        }
        if (theme) {
            if (theme.constructor === Array) {
                theme.forEach(t => {
                    classes += ' ' + t;
                });
            } else {
                classes += ' ' + theme;
            }
        }
        if (className) {
            classes += ' ' + className;
        }
        if (lowercase) {
            classes += ' lowercase';
        }

        return (
            <div className={classes} onClick={this.onClick.bind(this)}>
                {buttonImg &&
                    <img alt="" src={buttonImg} height='16' width='16' />
                }
                <span className='text'>{text}</span>
            </div>
        );
    }
}
