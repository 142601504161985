import { connect } from 'react-redux';
import Dropdown from './Dropdown';

const mapStateToProps = ({ User }) => {
    return {
        isLoggedIn: User.isLoggedIn,
        currentUser: User.currentUser
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dropdown);
