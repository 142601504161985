import React, { Component } from 'react';

import { capitalize, formatDate } from '../../../utils';

import {DropdownMenu, Checkbox} from '../../index';

import {DebounceInput} from 'react-debounce-input';

import './Home.scss';

const STATUSES = [
    "APPLICATION_NOT_STARTED",
    "APPLICATION_IN_PROGRESS",
    "APPLICATION_COMPLETE",
    "FULFILLMENT_PENDING_REVIEW",
    "FULFILLMENT_SUBMITTED_TO_CARRIER",
    "FULFILLMENT_FOLLOWED_UP_WITH_CARRIER",
    "FULFILLMENT_FOLLOWING_UP_WITH_CLIENT",
    "FULFILLMENT_CONFIRMED_BY_CARRIER",
    "FULFILLMENT_CONFIRMED_WITH_CLIENT",
    "FULFILLMENT_COMPLETED",
    "INTERNAL_ERROR"
];

const TYPES = [
    "New Hire Enrollment",
    "Termination",
    "Qualifying Life Event",
    "Open Enrollment",
    "Demographic Change"
];

const SUBMISSION_METHODS = [
    'Email',
    'Broker Portal',
    'EDI',
    'Fax',
    'Phone',
    'CSV',
    'Mail',
    'API',
    'Other'
]

class TransactionRow extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);

        this.state = {
        	transaction: this.props.transaction,
            downloading: false,
        };
    }

    itemClick(i) {

        let { transaction } = this.state;
        transaction.status = i;

        this.setState({ transaction });

        this.props.updateTransaction(transaction);

        if (this.props.onTransactionUpdate) {
            this.props.onTransactionUpdate(transaction);
        }
    }

    downloadTransaction(transactionId, event) {
        event.preventDefault();
        this.setState(
            { downloading: true },
            this.props.getTransactionDownload(transactionId, () => this.setState({ downloading: false }))
        );
        return false;
    }

    _updateTransaction(key, value) {
        let {transaction} = this.state;
        transaction[key] = value;
        this.setState({transaction}, this.props.updateTransaction(transaction));
    }

    onSubmissionMethodChange(key, value) {
        this._updateTransaction('submission_method', parseInt(value))
    }

    onFieldChange(key, event) {
        const value = event.target.value;
        this._updateTransaction(key, value);
    }

    render() {
    	const {transaction, downloading} = this.state;
        const {id, priority, status, application_type, application, transaction_type, line_of_coverage, carrier_name} = transaction;
        const {employee, company, effective_date} = application;

        const transactionType = TYPES[transaction_type];

    	const personType = (<img src={require('../../../assets/images/ic-person.svg')} alt='person'/>);
        const businessType = (<img src={require('../../../assets/images/ic-business.svg')} alt='business'/>)

        const applicationType = application_type === 0 ? businessType : personType;

        // Check for an email associated with the application (new hire not started)
        let clientName = application.email;
        if (employee.personal.first_name && employee.personal.last_name) {
            // Check for a full name associated with the application (application complete)
            clientName = employee.personal.first_name + " " + employee.personal.last_name;
        }
        if (!clientName) {
            // Fall back to company admin email (termination application not started)
            clientName = company.admin.email;
        }

        const location = window.location;
        const application_link_href = location.protocol + '//' + location.host + '/applications/' + application.id;
        let application_link = (<a className='launch-link' href={application_link_href} target='_blank' rel='noopener noreferrer'>Open Link</a>);
        if (status === 10) {
            application_link = "";
        } else if (status >= 2) {
            if (downloading) {
                application_link = (<a href="/">Getting Form...</a>);
            } else {
                application_link = (<a href="/" className='launch-link' onClick={this.downloadTransaction.bind(this, id)} style={{cursor: 'pointer'}} target='_blank'>View Form</a>);
            }
        }

        const status_type = STATUSES[status];
        const status_text = capitalize(status_type.toLowerCase().replace(/_/g, ' '));
        let statusClasses = "status " + status_type;
        let currentStatus = (
            <div className={statusClasses}>
                <div className='circle'></div>
                <span>{status_text}</span>
            </div>
        );

        let menuItems = [];
        STATUSES.forEach((status_type, i) => {
            if (i < 10) {
                let status_text = status_type.toLowerCase().replace(/_/g, ' ');
                status_text = status_text[0].toUpperCase() + status_text.slice(1);
                let statusClasses = "status " + status_type;
                let theStatus = (
                    <div className={statusClasses}>
                        <div className='circle'></div>
                        <span>{status_text}</span>
                    </div>
                );
                menuItems.push({
                    theStatus,
                    status_text,
                    status_type,
                });
            }
        });

        const statusOptions = menuItems.map(({ theStatus, status_text, status_type }, index) => {
            return ({
                display: theStatus,
                value: index,
            });
        })

        let transactionStatus = (
            <DropdownMenu
                options={statusOptions}
                selectedComponent={currentStatus}
                onChange={(value) => this.itemClick(value)}
            />
        );

        const minLength = this.props.debounceMinLength || 2;
        const timeout = this.props.debounceTimeout || 800;

        const associatedPlatformDropdown = (
            <DropdownMenu
                options={[
                    {value: 'EaseCentral'},
                    {value: 'Limelight'},
                    {value: 'Employee Navigator'},
                    {value: 'Namely'},
                    {value: 'Wellthie'},
                    {value: 'Noyo'},
                    {value: 'Other'}
                ]}
                selectedComponent={transaction.associated_platform || 'Noyo'}
                onChange={(value) => this._updateTransaction('associated_platform', value)}
            />
        );

        const submissionMethodDropdown = (
            <DropdownMenu
                options={[
                    {display: 'Email', value: 0},
                    {display: 'Broker Portal', value: 1},
                    {display: 'EDI', value: 2},
                    {display: 'Fax', value: 3},
                    {display: 'Phone', value: 4},
                    {display: 'API', value: 7},
                    {display: 'Other', value: 8}
                ]}
                selectedComponent={SUBMISSION_METHODS[transaction.submission_method || 0]}
                onChange={(value) => this._updateTransaction('submission_method', value)}
            />
        );

        let priorityComponent;
        if (priority) {
            priorityComponent = (
                <div className='priority-container' onClick={() => this._updateTransaction('priority', false)}>
                    <span className='priority-container__bell'></span>
                    <span className='priority-container__text'>Priority</span>
                </div>
            );
        } else {
            priorityComponent = (
                <Checkbox
                    checkboxId={id}
                    label='Mark Priority'
                    checked={priority}
                    theme='small-text'
                    showLabelOnHover
                    onClick={(checked) => this._updateTransaction('priority', checked)}
                />
            );
        }

        let coverageImage = 'ic-box';
        switch (line_of_coverage) {
            case 'medical':
                coverageImage = 'ic-medical';
                break;
            case 'dental':
                coverageImage = 'ic-dental';
                break;
            case 'vision':
                coverageImage = 'ic-vision';
                break;
            default:
                coverageImage = 'ic-medical';
        }
        const lineOfCoverageComponent = (
            <div className='line-of-coverage-container'>
                <img alt="line of coverage" src={require('../../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                <span>{capitalize(line_of_coverage)}</span>
            </div>
        )

        let effectiveDate = formatDate(effective_date);
        if (transaction_type === 1) { // termination type doesn't have effective date so use last_coverage_date (if exists)
            if (application.last_coverage_date) {
                effectiveDate = formatDate(application.last_coverage_date);
            } else {
                effectiveDate = null;
            }

        }

        // empty columns for spacing
        return (
            <tr className='transaction-row' key={id}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className='center'>{applicationType}</td>
                <td>{clientName}</td>
                <td>{company.name}</td>
                <td>{formatDate(transaction.created)}</td>
                <td>{effectiveDate}</td>
                <td className="priority">{priorityComponent}</td>
                <td>{transactionStatus}</td>
                <td>{carrier_name}</td>
                <td>{transactionType}</td>
                <td>{lineOfCoverageComponent}</td>
                <td>{associatedPlatformDropdown}</td>
                <td>{submissionMethodDropdown}</td>
                <td>
                    <DebounceInput
                        key='broker_name'
                        label="broker_name"
                        className='broker_name-textField'
                        ref='broker_name'
                        type='text'
                        placeholder='Add a broker...'
                        value={transaction.broker_name || ''}
                        minLength={minLength}
                        debounceTimeout={timeout}
                        onChange={this.onFieldChange.bind(this, 'broker_name')} />
                </td>
                <td>
                    <DebounceInput
                        key='account_manager_name'
                        label="account_manager_name"
                        className='account_manager_name-textField'
                        ref='account_manager_name'
                        type='text'
                        placeholder='Add an acct manager...'
                        value={transaction.account_manager_name || ''}
                        minLength={minLength}
                        debounceTimeout={timeout}
                        onChange={this.onFieldChange.bind(this, 'account_manager_name')} />
                </td>
                <td>
                    <DebounceInput
                        key='membership_team_rep_name'
                        label="membership_team_rep_name"
                        className='membership_team_rep_name-textField'
                        ref='membership_team_rep_name'
                        type='text'
                        placeholder='Add a team rep...'
                        value={transaction.membership_team_rep_name || ''}
                        minLength={minLength}
                        debounceTimeout={timeout}
                        onChange={this.onFieldChange.bind(this, 'membership_team_rep_name')}
                    />
                </td>
                <td className="notes">
                    <DebounceInput
                        key='notes'
                        label="notes"
                        className='notes-textField transactionRowInput'
                        ref='notes'
                        type='text'
                        placeholder='Add a comment...'
                        value={transaction.notes || ''}
                        minLength={minLength}
                        debounceTimeout={timeout}
                        onChange={this.onFieldChange.bind(this, 'notes')} />
                </td>
                <td>{application_link}</td>
            </tr>
        );
    }
}

export default TransactionRow;
