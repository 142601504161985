import React, { Component } from 'react';
import { node, object } from 'prop-types';

import {HeaderContainer, AlertContainer} from './../index';

import './../../assets/styles/main.scss';
import './App.scss';

class App extends Component {
    static propTypes = {
        children: node,
        alert: object
    };

    render() {
        const {alert} = this.props;
        return (
            <div className='app'>
                <HeaderContainer />
                {alert.visible &&
                    <AlertContainer {...alert} />
                }
                <div className='container'>{this.props.children}</div>
            </div>
        );
    }
}

export default App;
