import produce from 'immer';

const initialState = {
    floatingMsg: null,
    successOrError: null,
    alert: {
        visible: false,
        header: null,
        text: null,
        successButton: {
            text: null,
            onClick: null
        },
        cancelButton: {
            text: null,
            onClick: null
        }
    }
};

const appReducer = (state = initialState, action) => {
    const {
        message,
        alert,
    } = action.payload || {};

    return produce(state, draft => {
        switch (action.type) {
            case 'SHOW_ALERT':
                draft.alert = alert;
                break;

            case 'HIDE_ALERT':
                draft.alert = initialState.alert;
                break;

            case 'SET_SUCCESS_MESSAGE':
                draft.floatingMsg = message;
                draft.successOrError = 'success';
                break;

            case 'SET_ERROR_MESSAGE':
                draft.floatingMsg = message;
                draft.successOrError = 'error';
                break;

            case 'CLEAR_MESSAGE':
                draft.floatingMsg = null;
                draft.successOrError = null;
                break;

            default:
        }
    });
}

export default appReducer;
