import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dependent from './Dependent';

import {
    setApplicationDependents,
} from '../../actions/application';

import './Application.scss';


class StepDependents extends Component {
    onChange(dependentKey, dependent) {
        const {
            dependents,
        } = this.props;

        dependents[dependentKey] = dependent;
        setApplicationDependents(dependents);
    }

    validate() {
        const {
            dependents,
        } = this.props;

        let validated = true;

        Object.keys(dependents).forEach(dependentKey => {
            let dependent = this.refs[dependentKey];
            if (dependent){
                if(!dependent.personalInformation.validate(false) ||
                    !dependent.additionalInformation.validate()) {
                    validated = false;
                }
            } else {
                validated = false;
            }
        });

        return validated;
    }


    // TODO: remove `application` from props here, just connect `<Dependent/>`
    // to Redux instead and get Application from there
    render() {
        const {
            dependents,
            selectedPlans,
        } = this.props;

        const dependentsDiv = Object.keys(dependents).map(dependentKey => {
            let carrierName = null;
            if (dependents[dependentKey].selectedPlans.indexOf('medical') > -1) {
                carrierName = selectedPlans.medical.carrier_name;
            }
            return <Dependent
                key={dependentKey}
                ref={dependentKey}
                carrierName={carrierName}
                dependentKey={dependentKey}
                dependent={dependents[dependentKey]}
                onChange={(dependent) => this.onChange(dependentKey, dependent)}
            />
        });

        return (<div>{dependentsDiv}</div>);
    }
}

const mapStateToProps = ({ Application }) => {

    const {
        formData,
    } = Application;

    const {
        dependents,
        selectedPlans,
    } = formData;

    return {
      selectedPlans,
      dependents,
    };
}

const mapDispatchToProps = {
    setApplicationDependents
};

const ConnectedStepDependents = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true},
)(StepDependents);

export default ConnectedStepDependents;