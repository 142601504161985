import { connect } from 'react-redux';

import { logout } from '../../../actions/user';
import { getTransactions, getTransactionDownload, updateTransaction } from '../../../actions/noyo';

import Home from './Home';

const mapStateToProps = ({ User, Noyo }) => {
    return {
        isLoggedIn: User.isLoggedIn,
        currentUser: User.currentUser,
        transactions: Noyo.transactions
    };
};

const mapDispatchToProps = {
    logout, getTransactions, getTransactionDownload, updateTransaction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
