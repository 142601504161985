import React, { Component } from 'react';

import { validateFields } from '../../utils';

import { TextFieldContainer } from '../index';

import CoverageOptionContainer from './CoverageOptionContainer';
import DemographicOptionContainer from '../application/TerminationOptionContainer';

import './Broker.scss';
import './EligibilityGroupEmployee.scss';

// JIRA NY-137
// const COVERAGE_OPTIONS = ['medical', 'dental', 'vision', 'life', 'std', 'ltd'];
const COVERAGE_OPTIONS = ['medical', 'dental', 'vision'];

class EligibilityGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null,
            description: null,
            client_emails: null,
            coverage_options: [],
            missingFields: []
        }

        COVERAGE_OPTIONS.forEach((option) => {
            this[`coverage_option_${option}_ref`] = React.createRef();
        });
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state, this.props.index));
    }

    _findCoverageOption(line_of_coverage) {
        return this.state.coverage_options.find(option => option.line_of_coverage === line_of_coverage);
    }

    onCoverageOptionChange(value, line_of_coverage) {
        let coverage_options = this.state.coverage_options;
        coverage_options = coverage_options.filter(option => option.line_of_coverage !== line_of_coverage)
        if (value.open) {
            value.line_of_coverage = line_of_coverage;
            coverage_options.push(value);
        }
        this.setState({coverage_options}, () => this.props.onChange(this.state, this.props.index));
    }

    validate() {
        /*
            proper eligibility group state:
            {
                client_emails: required,
                name: required,
                description: required,
                coverage_options: [{validation in component}]
            }
        */
        const {
            transaction_type = 'new_hire_enrollment'
        } = this.props;

        const { coverage_options } = this.state;
        let requiredFields = [];

        if (transaction_type === 'new_hire_enrollment') {
            requiredFields = [
                'client_emails',
                'name',
                'description'
            ];
        } else if (transaction_type !== 'demographic_change') {
            requiredFields = ['name'];
        }

        const missingFields = validateFields(requiredFields, this.state);
        const _this = this;
        let childrenValidated = coverage_options.length > 0;

        coverage_options.forEach(option => {
            childrenValidated &= _this[`coverage_option_${option.line_of_coverage}_ref`].current.validate();
        });

        if (coverage_options.length === 0) {
            missingFields.push('coverage_options');
        }

        this.setState({missingFields});

        return missingFields.length === 0 && childrenValidated;
    }

    getCoverageOptions() {
        const {transaction_type} = this.props;
        let options = [];
        COVERAGE_OPTIONS.forEach((option, index) => {
            if (transaction_type === 'demographic_change') {
                options.push(
                    <DemographicOptionContainer
                        ref={this[`coverage_option_${option}_ref`]}
                        key={option}
                        index={index}
                        transaction_type={this.props.transaction_type}
                        line_of_coverage={option}
                        onChange={this.onCoverageOptionChange.bind(this)}
                    />
                );
            } else {
                options.push(
                    <CoverageOptionContainer
                        ref={this[`coverage_option_${option}_ref`]}
                        key={option}
                        index={index}
                        transaction_type={this.props.transaction_type}
                        line_of_coverage={option}
                        onChange={this.onCoverageOptionChange.bind(this)}
                        className={option}
                    />
                );
            }
        });
        return options;
    }

    render() {
        const {
            transaction_type = 'new_hire_enrollment'
        } = this.props;

        // DEBUG NY-137/138
        // added new_hire_enrollment check here
        const isQLEOE = transaction_type === 'qualifying_life_event' || transaction_type === 'open_enrollment' || transaction_type === 'new_hire_enrollment';

        const { missingFields } = this.state;

        let coverageOptionsText = 'Coverage Options';
        let coverageOptionsClass = 'coverage-options';
        let subtext = null;

        if (isQLEOE) {
            coverageOptionsText = 'Available Coverage';
        } else if (transaction_type === 'demographic_change') {
            coverageOptionsText = 'Member Coverage';
            subtext = 'Provide details on the coverage in which this member is currently enrolled.';
        }

        if (missingFields.indexOf('coverage_options') > -1) {
            coverageOptionsText += ' (select at least one)';
            coverageOptionsClass += ' error';
        }

        return (
            <div className='eligibility-group'>
                {isQLEOE &&
                    transaction_type !== 'new_hire_enrollment' &&
                    <div className='row bold'>Coverage Options</div>
                }
                {transaction_type !== 'demographic_change' &&
                    transaction_type !== 'new_hire_enrollment' &&
                <div>
                    <div className="row">
                        <TextFieldContainer
                            key='name'
                            label="Eligibility Group Name"
                            className='name'
                            ref='name'
                            type='text'
                            error={missingFields.indexOf('name') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                </div>
                }
                {isQLEOE && transaction_type !== 'new_hire_enrollment' &&
                    <div>
                        <div className="row">
                            <TextFieldContainer
                                key='description'
                                label="Group Description"
                                className='description'
                                ref='description'
                                type='text'
                                error={missingFields.indexOf('description') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    </div>
                }
                {isQLEOE &&
                    <div>
                        <div className="row bold">Send To:</div>
                        <div className='row'>
                            <TextFieldContainer
                                key='client_emails'
                                label="Emails of Clients"
                                className='client_emails'
                                ref='client_emails'
                                type='text'
                                error={missingFields.indexOf('client_emails') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    </div>
                }
                <div>
                    <div className={coverageOptionsClass}>{coverageOptionsText}</div>
                    {subtext &&
                        <div className={'coverage-options__subtext'}>{subtext}</div>
                    }
                    <div>{this.getCoverageOptions()}</div>
                </div>
            </div>
        );
    }
}

export default EligibilityGroup;
