import React, { Component } from 'react';

import { Checkbox } from '../../index';
import { capitalize as capitalizeFunc } from '../../../utils';

import './CheckboxQuestion.scss';

export default class CheckboxQuestion extends Component {
    state = {
        options: this.props.options || []
    }

    onChange(key, value, checked) {
        let {options} = this.state;

        const index = options.findIndex(o => o.value === value);
        let option = options[index];
        option.selected = checked;

        options[index] = option;

        this.setState({options});

        const {onChange} = this.props;
        if (onChange) {
            onChange(key, options.filter(o => o.selected).map(o => {return o.value}));
        }
    }

    render() {
        const {disabled, theme, questionText, className, error, capitalize = true} = this.props;
        const {options} = this.state;

        let classes = 'checkbox';
        if (disabled) {
            classes += ' disabled';
        }
        if (theme) {
            classes += ' ' + theme;
        }
        if (className) {
            classes += ' ' + className;
        }

        const randInt = Math.floor(Math.random()*10000);
        const optionsDiv = options.map((option, index) => {
            let label = option.display || option.value;
            if (capitalize) {
                label = capitalizeFunc(label);
            }
            return (
                <span key={'checkbox-' + index} className='checkbox__container'>
                    <Checkbox
                        name={option.name + '-' + randInt}
                        checked={option.selected}
                        error={error}
                        label={label}
                        onClick={(checked) => this.onChange(className, option.value, checked)}
                        checkboxId={className + '-' + option.value + '-' + randInt}
                    />
                </span>
            )
        });

        return (
            <div className={classes}>
                <div className='question-text'>{questionText}</div>
                <div className='questions'>{optionsDiv}</div>
            </div>
        )
    }
}
