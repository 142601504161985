import React, { Component } from 'react';

import {TextFieldContainer, AddressAutocomplete} from '../../index';

import './AddressAutocomplete.scss';


class Address extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: this.props.value || {},
            missingFields: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({address: nextProps.value});
        }
    }

    onAddressSelect(address) {
        this.setState({address}, this.onSetStateComplete);
    }

    onChange(key, value) {
        const {address} = this.state;
        address[key] = value;
        this.setState({address}, this.onSetStateComplete);
    }

    onSetStateComplete() {
        this.props.onChange(this.state.address);
    }

    render() {
        const {address, missingFields} = this.state;
        const {label, error} = this.props;
        const {
            apartment, city, state, zip_code
        } = address;
        return (
            <div style={{width: '100%'}}>
                <div style={{marginBottom: 10, fontSize: 13, textAlign: 'left'}}>
                    {label}
                    {error && <span className='error'>&nbsp;&nbsp;Required</span>}
                </div>
                <div className='row'>
                    <AddressAutocomplete
                        label='Street'
                        value={address}
                        error={missingFields.indexOf('street') > -1}
                        onChange={this.onAddressSelect.bind(this)}
                    />
                    <TextFieldContainer
                        key='apartment'
                        label="Apt."
                        className='apartment'
                        ref='apartment'
                        type='text'
                        text={apartment}
                        theme='tiny'
                        error={missingFields.indexOf('apartment') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row three'>
                    <TextFieldContainer
                        key='city'
                        label="City"
                        className='city'
                        ref='city'
                        type='text'
                        text={city}
                        error={missingFields.indexOf('city') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='state'
                        label="State"
                        className='state'
                        ref='state'
                        type='text'
                        text={state}
                        error={missingFields.indexOf('state') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='zip_code'
                        label="Zip Code"
                        className='zip_code'
                        ref='zip_code'
                        type='text'
                        text={zip_code}
                        error={missingFields.indexOf('zip_code') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default Address;
