import { connect } from 'react-redux';
import Upload from './Upload';

const mapStateToProps = ({User}) => {
    return {
        currentUser: User.currentUser,
        location: window.location
    }
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Upload);
