import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer } from '../../index';

import { ALL_STATES } from '../../../utils';
import { capitalize } from '../../../utils';

import CobraEmployees from './CobraEmployees';
import InactiveEmployees from './InactiveEmployees';
import TerminatedEmployees from './TerminatedEmployees';

export default class OtherEmployeeInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props.other_employee_information
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange('other_employee_information', this.state));
    }

    validate() {
        return true;
    }

    render() {
        const {missingFields} = this.props;
        return (
            <div className='other-coverage-information'>
                <div className='main-header-container border-top'>
                    <div className='text light'>Other Employee Information</div>
                    <div className='sub-text'>Describe covered employees who are not active at work</div>
                </div>
                <div className='section-header'>COBRA</div>
                <div style={{marginBottom: '20px'}}>First, some questions about your COBRA coverage</div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Does the group qualify for COBRA?'}
                        options={[
                            {name: 'cobra_qualify', display: 'no', value: false, selected: (this.state.cobra_qualify !== undefined && !this.state.cobra_qualify)},
                            {name: 'cobra_qualify', display: 'yes', value: true, selected: this.state.cobra_qualify}
                        ]}
                        theme='small-text'
                        error={missingFields.indexOf('cobra_qualify') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Is the group applying for COBRA coverage?'}
                        options={[
                            {name: 'cobra_coverage', display: 'no', value: false, selected: (this.state.cobra_coverage !== undefined && !this.state.cobra_coverage)},
                            {name: 'cobra_coverage', display: 'yes', value: true, selected: this.state.cobra_coverage}
                        ]}
                        theme='small-text'
                        error={missingFields.indexOf('cobra_coverage') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Is the group currently subject to Federal COBRA or State COBRA?'}
                        options={[
                            {name: 'cobra_federal_state', display: 'Federal COBRA', value: 'federal', selected: (this.state.cobra_federal_state === 'federal')},
                            {name: 'cobra_federal_state', display: 'State COBRA', value: 'state', selected: this.state.cobra_federal_state === 'state'}
                        ]}
                        capitalize={false}
                        theme='small-text'
                        error={missingFields.indexOf('cobra_coverage') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.cobra_federal_state === 'federal' &&
                    <div className='row'>
                        <TextFieldContainer
                            key='cobra_federal_enrollees_count'
                            label='Number of Federal COBRA Enrollees'
                            className='cobra_federal_enrollees_count'
                            ref='cobra_federal_enrollees_count'
                            type='text'
                            text={this.state.cobra_federal_enrollees_count}
                            error={missingFields.indexOf('cobra_federal_enrollees_count') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numericOnly: true}}
                        />
                        <TextFieldContainer
                            key='cobra_federal_enrollees_election'
                            label='Number of Enrollees in Federal COBRA Election Period'
                            className='cobra_federal_enrollees_election'
                            ref='cobra_federal_enrollees_election'
                            type='text'
                            theme='smaller-placeholder'
                            text={this.state.cobra_federal_enrollees_election}
                            error={missingFields.indexOf('cobra_federal_enrollees_election') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numericOnly: true}}
                        />
                    </div>
                }
                {this.state.cobra_federal_state === 'state' &&
                    <div>
                        <div className='row'>
                            <DropdownContainer
                                className='state'
                                label='State'
                                options={ALL_STATES.map(state => {
                                    return {display: state.name + ' (' + state.id + ')', value: state.id, selected: state.id === this.state.state};
                                })}
                                error={missingFields.indexOf('state') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className='row'>
                            <TextFieldContainer
                                key='cobra_state_enrollees_count'
                                label='Number of State COBRA employees'
                                className='cobra_state_enrollees_count'
                                ref='cobra_state_enrollees_count'
                                type='text'
                                text={this.state.cobra_state_enrollees_count}
                                error={missingFields.indexOf('cobra_state_enrollees_count') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{numericOnly: true}}
                            />
                            <TextFieldContainer
                                key='cobra_state_enrollees_election'
                                label='Number of Enrollees in State COBRA Election Period'
                                className='cobra_state_enrollees_election'
                                ref='cobra_state_enrollees_election'
                                type='text'
                                theme='smaller-placeholder'
                                text={this.state.cobra_state_enrollees_election}
                                error={missingFields.indexOf('cobra_state_enrollees_election') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{numericOnly: true}}
                            />
                        </div>
                    </div>
                }
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Will a third party administrator/broker administer ' + capitalize(this.state.cobra_federal_state) +  ' COBRA?'}
                        options={[
                            {name: 'cobra_federal_third_party', display: 'no', value: false, selected: (this.state.cobra_federal_third_party !== undefined && !this.state.cobra_federal_third_party)},
                            {name: 'cobra_federal_third_party', display: 'yes', value: true, selected: this.state.cobra_federal_third_party}
                        ]}
                        theme='small-text'
                        error={missingFields.indexOf('cobra_federal_third_party') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Should billing go to the group or directly to the individual?'}
                        options={[
                            {name: 'cobra_billing_location', display: 'Group', value: 'group', selected: (this.state.cobra_billing_location === 'group')},
                            {name: 'cobra_billing_location', display: 'Individual', value: 'individual', selected: this.state.cobra_billing_location === 'individual'}
                        ]}
                        theme='small-text'
                        error={missingFields.indexOf('cobra_coverage') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <CobraEmployees {...this.state.cobra_employees} onChange={data => this.onChange('cobra_employees', data)} />
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Do you have recently terminated employees with a qualifying event (but who are not yet on COBRA)?'}
                        options={[
                            {name: 'terminated_employees_not_on_cobra_yet', display: 'no', value: false, selected: (this.state.terminated_employees_not_on_cobra_yet !== undefined && !this.state.terminated_employees_not_on_cobra_yet)},
                            {name: 'terminated_employees_not_on_cobra_yet', display: 'yes', value: true, selected: this.state.terminated_employees_not_on_cobra_yet}
                        ]}
                        theme='small-text'
                        error={missingFields.indexOf('terminated_employees_not_on_cobra_yet') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.terminated_employees_not_on_cobra_yet &&
                    <TerminatedEmployees {...this.state.terminated_employees} onChange={data => this.onChange('terminated_employees', data)} />
                }
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Are there any employees not currently active at work?'}
                        options={[
                            {name: 'inactive_employees', display: 'no', value: false, selected: (this.state.inactive_employees !== undefined && !this.state.inactive_employees)},
                            {name: 'inactive_employees', display: 'yes', value: true, selected: this.state.inactive_employees}
                        ]}
                        theme='small-text'
                        error={missingFields.indexOf('inactive_employees') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.inactive_employees &&
                    <InactiveEmployees {...this.state.inactive_employees} onChange={data => this.onChange('inactive_employees', data)} />
                }
            </div>
        );
    }
}
