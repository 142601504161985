import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';


import './AddressAutocomplete.scss';

class AddressAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: this.toAddressString(this.props.value) || '' };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                address: this.toAddressString(nextProps.value) || ''
            });
        }
    }

    toAddressString(addressObj) {
        if (!addressObj) {
            return null
        }
        return addressObj.street;
    }

    handleChange(address) {
        this.setState({ address });
    };

    handleSelect(address) {
        this.setState({address});
        geocodeByAddress(address)
            .then(results => {
                const formattedAddress = this.formatAddress(results[0]);
                this.props.onChange(formattedAddress);
            }
        )
        .catch(error => console.error('Error', error));
    };

    formatAddress(address) {
        let formattedAddress = {
            addressString: address.formatted_address
        };
        let street_number = null;
        let street_name = null;
        let sublocality = null; // backup for city

        address.address_components.forEach(component => {
            const {long_name, short_name, types} = component;
            if (types.indexOf('postal_code') > -1) {
                formattedAddress.zip_code = long_name;
            } else if (types.indexOf('administrative_area_level_1') > -1) {
                formattedAddress.state = short_name;
                // formattedAddress.state_code = short_name;
            } else if (types.indexOf('administrative_area_level_2') > -1) {
                formattedAddress.county = long_name;
            } else if (types.indexOf('locality') > -1) {
                formattedAddress.city = long_name;
            } else if (types.indexOf('sublocality') > -1) {
                sublocality = long_name;
            } else if (types.indexOf('route') > -1) {
                street_name = long_name;
            } else if (types.indexOf('street_number') > -1) {
                street_number = long_name;
            }
        });

        formattedAddress.street = street_number ? (street_number + ' ' + street_name) : street_name;
        if (!formattedAddress.city) {
            formattedAddress.city = sublocality;
        }

        return formattedAddress;
    }

    render() {
        const {label, error, helperText = ''} = this.props;
        let customHelperText = helperText;
        if (error) {
            customHelperText = 'Required';
        }
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange.bind(this)}
                onSelect={this.handleSelect.bind(this)}
                searchOptions={
                    // restrict search to US only for now
                    {componentRestrictions: {country: ['us']}}
              }
            >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='address-autocomplete' style={{position: 'relative'}}>
                    <div className='textFieldContainer'>
                        <div className={'textField' + (error ? ' error' : '')}>
                            <div className='form-label form-css-label'>
                                <fieldset className="form-label-placeholder">
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input'
                                        })}
                                    />
                                    <label>{label}</label>
                                </fieldset>
                            </div>
                        </div>
                        <div className={'helperText ' + (error ? 'error' : '')}>{customHelperText}</div>
                    </div>
                    <div className='autocomplete-dropdown-container'>
                        {loading && <div className='suggestion-item'>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            return (
                                <div {...getSuggestionItemProps(suggestion, {className})}>
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            </PlacesAutocomplete>
        );
    }
}

export default AddressAutocomplete;
