import React, { Component } from 'react';

import SignaturePad from 'signature_pad';

import { TextFieldContainer, DropdownContainer, Address, Terms } from '../../index';

import Modal from '../../shared/Modal/Modal';

import { validateFields } from '../../../utils';

export default class Signoff extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props.signoff
        }
    }

    componentDidMount() {
        this.signaturePad = new SignaturePad(this.refs.signatureCanvas);
        console.log(this.signaturePad);
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange('signoff', this.state));
    }

    validate() {
        let requiredFields = ['first_name', 'last_name', 'title', 'license_number', 'address', 'ssn', 'agency_name', 'fein'];

        if (this.state.key_contact === 'broker_sales') {
            requiredFields.push('broker_commission');
            requiredFields.push('agency_commission');
        }
        const missingFields = validateFields(requiredFields, this.state);

        if (this.signaturePad.isEmpty()) {
            missingFields.push('signature');
        }

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    toggleTerms() {
        this.setState({
            termsVisible: !this.state.termsVisible
        });
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div className='signoff'>
                <div className='main-header-container border-top'>
                    <div className='text light'>Contact Information and Signoff</div>
                    <div className='sub-text'>To finalize the application, list key contact and sign off on application terms</div>
                </div>
                <div className='row'>
                    <DropdownContainer
                        key='key_contact'
                        label="Contact Type"
                        className='key_contact'
                        ref='key_contact'
                        onChange={this.onChange.bind(this)}
                        options={[
                            {display: 'Broker Sales / Producer / Selling Agent', value: 'broker_sales'},
                            {display: 'Broker Service / Account Manager', value: 'broker_service'},
                            {display: 'Third Party Administrator', value: 'third_party_admin'},
                            {display: 'General Agent', value: 'general_agent'},
                            {display: 'Carrier Rep', value: 'carrier_rep'},
                        ].map(option => {
                            const {display, value} = option;
                            return {display, value, selected: this.state.key_contact === value}
                        })}
                    />
                </div>
                <div className='row three'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={this.state.first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        text={this.state.last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='title'
                        label="Title"
                        className='title'
                        ref='title'
                        type='text'
                        text={this.state.title}
                        error={missingFields.indexOf('title') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='license_number'
                        label="Broker License Number"
                        className='license_number'
                        ref='license_number'
                        type='text'
                        text={this.state.license_number}
                        error={missingFields.indexOf('license_number') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <Address
                        label='Address'
                        value={this.state.address}
                        error={missingFields.indexOf('address') > -1}
                        onChange={(address) => this.onChange('address', address)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='ssn'
                        label="Social Security Number"
                        className='ssn'
                        ref='ssn'
                        type='text'
                        text={this.state.ssn}
                        error={missingFields.indexOf('ssn') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                    />
                </div>
                <div className='row three'>
                    <TextFieldContainer
                        key='agency_name'
                        label="Agency Name"
                        className='agency_name'
                        ref='agency_name'
                        type='text'
                        text={this.state.agency_name}
                        error={missingFields.indexOf('agency_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='fein'
                        label="FEIN"
                        className='fein'
                        ref='fein'
                        type='text'
                        text={this.state.fein}
                        error={missingFields.indexOf('fein') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{numericOnly: true, blocks: [9]}}
                    />
                    <TextFieldContainer
                        key='crid'
                        label="CRID"
                        className='crid'
                        ref='crid'
                        type='text'
                        theme='margin-left'
                        text={this.state.crid}
                        error={missingFields.indexOf('crid') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row three'>
                    <TextFieldContainer
                        key='phone'
                        label="Phone #"
                        className='phone'
                        ref='phone'
                        type='text'
                        text={this.state.phone}
                        error={missingFields.indexOf('phone') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                    <TextFieldContainer
                        key='fax'
                        label="Fax #"
                        className='fax'
                        ref='fax'
                        type='text'
                        text={this.state.fax}
                        error={missingFields.indexOf('fax') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                    />
                    <TextFieldContainer
                        key='email'
                        label="Email Address"
                        className='email'
                        ref='email'
                        type='text'
                        text={this.state.email}
                        error={missingFields.indexOf('email') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.key_contact === 'broker_sales' &&
                    <div className='row'>
                        <TextFieldContainer
                            key='broker_commission'
                            label="Commission % for Broker"
                            className='broker_commission'
                            ref='broker_commission'
                            type='text'
                            text={this.state.broker_commission}
                            error={missingFields.indexOf('broker_commission') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numeral: true}}
                        />
                        <TextFieldContainer
                            key='agency_commission'
                            label="Commission % for Agency"
                            className='agency_commission'
                            ref='agency_commission'
                            type='text'
                            text={this.state.agency_commission}
                            error={missingFields.indexOf('agency_commission') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{numeral: true}}
                        />
                    </div>
                }
                <div className='signature-container'>
                    <div>Draw your signature with your mouse</div>
                    {missingFields.indexOf('signature') > -1 &&
                        <div className='error'>Signature is required</div>
                    }
                    <div className='signature'><canvas ref="signatureCanvas" width="360" height="120"></canvas></div>
                    <div>
                        <div>By signing, you agree to the <span className='terms' onClick={this.toggleTerms.bind(this)}>Terms of Use & Privacy Policy.</span></div>
                    </div>
                    <Modal isOpen={this.state.termsVisible} close={this.toggleTerms.bind(this)}>
                        <div className='termsContent'>
                            <Terms />
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}
