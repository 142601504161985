import { connect } from 'react-redux';
import Button from './Button';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Button);
