import React, { Component } from 'react';

import { DropdownContainer, RadioButton, Checkbox, TextFieldContainer } from '../index';

import './Application.scss';

class CoverageSelect extends Component {
    constructor(props) {
        super(props);

        const filteredOptions = this.props.options.filter(option => option.selected);
        const selectedOption = filteredOptions[0] || {};

        this.state = {
            selectedOption,
            noChanges: false,
            missingFields: [],
            downloading: false,
        };
    }

    onChange(line_of_coverage, option) {
        this.setState({selectedOption: option}, this.props.onChange(line_of_coverage, option));
    }

    onDropdownChange(key, value) {
        const {line_of_coverage} = this.props;
        let {selectedOption} = this.state;
        selectedOption[key] = value;
        this.setState({selectedOption}, this.props.onChange(line_of_coverage, selectedOption));
    }

    onPlanClick(planId, event) {
        event.preventDefault();
        this.setState(
            { downloading: true },
            this.props.getPlanDetailsDownload(planId, () => this.setState({ downloading: false }))
        );
        return false;
    }

    toggleNoChanges(key, checked) {
        this.setState({noChanges: checked}, this.props.onNoChangeToggle(checked, this.props.line_of_coverage));
    }

    render() {
        const {line_of_coverage, options, transaction_type} = this.props;
        const {selectedOption, noChanges, missingFields, downloading} = this.state;
        const {
            reason,
            waive_carrier_name,
            waive_plan_name,
            waive_policy_id,
            waive_carrier_phone_number,
            waive_sponsor_company,
            waive_first_name,
            waive_last_name
        } = selectedOption;

        const isQLEOE = transaction_type === 'qualifying_life_event' || transaction_type === 'open_enrollment';

        let optionsDiv = [];
        let waiveCoverage;
        let waiveOptions;

        switch (line_of_coverage) {
            case 'medical':
                waiveOptions = [
                    {display: 'COBRA Coverage', value: 'other-cobra', selected: reason === 'other-cobra'},
                    {display: 'Individual Coverage - On Exchange', value: 'other-ind-on-exchange', selected: reason === 'other-ind-on-exchange'},
                    {display: 'Individual Coverage - Off Exchange', value: 'other-ind-off-exchange', selected: reason === 'other-ind-off-exchange'},
                    {display: 'Insurance Through Another Job', value: 'other-employee-group', selected: reason === 'other-employee-group'},
                    {display: 'Medicaid', value: 'medicaid', selected: reason === 'medicaid'},
                    {display: 'Medi-Cal', value: 'medi-cal', selected: reason === 'medi-cal'},
                    {display: 'Medicare', value: 'other-medicare', selected: reason === 'other-medicare'},
                    {display: 'No Other Coverage', value: 'no-coverage', selected: reason === 'no-coverage'},
                    {display: 'Parental Group Coverage', value: 'other-parent-group', selected: reason === 'other-parent-group'},
                    {display: 'Retiree Coverage', value: 'retiree-coverage', selected: reason === 'retiree-coverage'},
                    {display: 'Spouse Group Coverage', value: 'other-spouse-group', selected: reason === 'other-spouse-group'},
                    {display: 'TRICARE Military Coverage', value: 'tricare-coverage', selected: reason === 'tricare-coverage'},
                    {display: 'VA Eligibility', value: 'va-coverage', selected: reason === 'va-coverage'}
                ];
                break;
            case 'dental':
            case 'vision':
                waiveOptions = [
                    {display: 'COBRA Coverage', value: 'other-cobra', selected: reason === 'other-cobra'},
                    {display: 'Insurance Through Another Job', value: 'other-employee-group', selected: reason === 'other-employee-group'},
                    {display: 'No Other Coverage', value: 'no-coverage', selected: reason === 'no-coverage'},
                    {display: 'Parental Group Coverage', value: 'other-parent-group', selected: reason === 'other-parent-group'},
                    {display: 'Spouse Group Coverage', value: 'other-spouse-group', selected: reason === 'other-spouse-group'}
                ];
                break;
            default:
                waiveOptions = [
                    {display: 'Insurance Through Another Job', value: 'other-employee-group', selected: reason === 'other-employee-group'},
                    {display: 'No Other Coverage', value: 'no-coverage', selected: reason === 'no-coverage'},
                    {display: 'Parental Group Coverage', value: 'other-parent-group', selected: reason === 'other-parent-group'},
                    {display: 'Spouse Group Coverage', value: 'other-spouse-group', selected: reason === 'other-spouse-group'}
                ];
        }

        options.forEach((option, index) => {
            if (option.waived) {
                waiveCoverage = (
                    <div className='waive-coverage'>
                        <div className='waive-coverage__radio-container'>
                            <RadioButton
                                name={line_of_coverage}
                                label={'I do not want ' + line_of_coverage + ' coverage'}
                                onClick={() => this.onChange(line_of_coverage, option)}
                                radioId={option.id}
                                checked={option.selected}
                                disabled={noChanges}
                            />
                        </div>
                        {(selectedOption.id === option.id && !isQLEOE) &&
                            <div className='waive-coverage__reason'>
                                <div className='waive-coverage__reason-text'>Why are you waiving your {line_of_coverage} coverage?</div>
                                <DropdownContainer
                                    key='waive-reason'
                                    label='Reason for waiving'
                                    className='reason'
                                    ref='reason'
                                    options={waiveOptions}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                            </div>
                        }
                        {(selectedOption.id === option.id && !isQLEOE && reason === 'other-spouse-group') &&
                        <div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_carrier_name'
                                    label="Carrier Name"
                                    className='waive_carrier_name'
                                    ref='waive_carrier_name'
                                    type='text'
                                    text={waive_carrier_name}
                                    error={missingFields.indexOf('waive_carrier_name') > -1}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_carrier_phone_number'
                                    label="Carrier Phone Number"
                                    className='waive_carrier_phone_number'
                                    ref='waive_carrier_phone_number'
                                    type='text'
                                    formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                                    text={waive_carrier_phone_number}
                                    error={missingFields.indexOf('waive_carrier_phone_number') > -1}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_plan_name'
                                    label="Plan Name"
                                    className='waive_plan_name'
                                    ref='waive_plan_name'
                                    type='text'
                                    text={waive_plan_name}
                                    error={missingFields.indexOf('waive_plan_name') > -1}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_sponsor_company'
                                    label="Sponsoring Company Name"
                                    className='waive_sponsor_company'
                                    ref='waive_sponsor_company'
                                    type='text'
                                    text={waive_sponsor_company}
                                    error={missingFields.indexOf('waive_sponsor_company') > -1}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_policy_id'
                                    label="Policy ID"
                                    className='waive_policy_id'
                                    ref='waive_policy_id'
                                    type='text'
                                    text={waive_policy_id}
                                    error={missingFields.indexOf('waive_policy_id') > -1}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_first_name'
                                    label="Policyholder First Name"
                                    className='waive_first_name'
                                    ref='waive_first_name'
                                    type='text'
                                    text={waive_first_name}
                                    error={missingFields.indexOf('waive_first_name') > -1}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                                <TextFieldContainer
                                    key='waive_last_name'
                                    label="Policyholder Last Name"
                                    className='waive_last_name'
                                    ref='waive_last_name'
                                    type='text'
                                    theme='margin-left'
                                    text={waive_last_name}
                                    error={missingFields.indexOf('waive_last_name') > -1}
                                    onChange={this.onDropdownChange.bind(this)}
                                />
                            </div>
                        </div>
                        }
                    </div>
                );
            } else {
                const input = (
                    <RadioButton
                        name={line_of_coverage}
                        onClick={() => this.onChange(line_of_coverage, option)}
                        radioId={option.id}
                        checked={option.selected}
                        disabled={noChanges}
                    />
                );
                let plan_details_link;
                if (downloading) {
                    plan_details_link = (<a href="/">Downloading...</a>);
                } else {
                    plan_details_link = (<a href="/" onClick={this.onPlanClick.bind(this, option.id)} style={{cursor: 'pointer'}} target='_blank'>Plan Details</a>);
                }
                optionsDiv.push(
                    <div key={'coverage_input_select_' + index} className='select-container'>
                        <div className='select'>
                            {input}
                            <span>{option.display}</span>
                        </div>
                        {option.has_plan_details_file &&
                            <div className='link'>{plan_details_link}</div>
                        }
                    </div>
                );
            }
        });

        let coverageImage = 'ic-box';
        let line_of_coverage_text = line_of_coverage;
        switch (line_of_coverage) {
            case 'medical':
                coverageImage = 'ic-medical';
                break;
            case 'dental':
                coverageImage = 'ic-dental';
                break;
            case 'vision':
                coverageImage = 'ic-vision';
                break;
            case 'std':
                line_of_coverage_text = 'short-term disability';
                break;
            case 'ltd':
                line_of_coverage_text = 'long-term disability';
                break;
            default:
        }

        let noChangesDiv;
        if (isQLEOE) {
            const key = 'no_changes_checkbox';
            noChangesDiv = (
                <div className='no-changes'>
                    <Checkbox
                        checkboxId={key}
                        onClick={(event) => this.toggleNoChanges(key, event)}
                    />
                    <span>{'I don\'t want to make changes to my ' + line_of_coverage_text + ' coverage.'}</span>
                </div>
            );
        }

        return (
            <div className='coverage-select'>
                <img alt="" src={require('../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                <div className='coverage-select__text'>Select {line_of_coverage} coverage</div>
                {noChangesDiv}
                <div className='options'>{optionsDiv}</div>
                <div>{waiveCoverage}</div>
            </div>
        );
    }
}

export default CoverageSelect;
