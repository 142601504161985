import React, { Component } from 'react';

import { ALL_STATES } from '../../utils';
import { validateFields } from '../../utils';
import { shouldDisplayCarriers } from '../../utils';

import { TextFieldContainer, DropdownContainer, UploadContainer } from '../index';

import './Broker.scss';

class CoverageOption extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            numPlans: 1,
            waiting_period: null,
            termination_window: null,
            group_id: null,
            plans: [{name: null, index: 0}],  // always minimum one plan
            missingFields: []
        };
    }

    _findPlan(index) {
        return this.state.plans.find(plan => plan.index === index);
    }

    onPlanChange(key, value, index) {
        let plans = this.state.plans;
        let plan = this._findPlan(index);
        if (plan) {
            plan[key] = value;
            plans = plans.filter(plan => plan.index !== index);
            plans.push(plan);
        } else {
            plan = {[key]: value, index};
            plans.push(plan);
        }
        plans = plans.sort((a,b) => a.index - b.index);
        this.setState({plans}, this.stateChangeComplete);
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.stateChangeComplete);
    }

    close() {
        this.setState({open: false}, this.stateChangeComplete);
    }

    stateChangeComplete() {
        this.props.onChange(this.state, this.props.line_of_coverage);
    }

    validate() {
        /*
            proper coverage option state:
            {
                carrier_id: required,
                group_id: required,
                line_of_coverage: required,
                termination_window: required,
                waiting_period: required,
                group_id: optional,
                plans: [
                    {
                        name: required
                    }
                ]
            }
        */

        const {transaction_type = 'new_hire_enrollment'} = this.props;
        const {plans} = this.state;

        let requiredFields = ['carrier_id'];
        if (transaction_type === 'new_hire_enrollment') {

            // JIRA NY-137
            // requiredFields = requiredFields.concat(['group_id', 'waiting_period', 'termination_window']);
            requiredFields = requiredFields.concat(['group_id']);
        }
        const missingFields = validateFields(requiredFields, this.state);

        plans.forEach(plan => {
            if (!plan.name) {
                missingFields.push('plan_' + plan.index);
            }
        });

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        }, this.stateChangeComplete);
    }

    addPlan() {
        this.setState({
            numPlans: this.state.numPlans + 1
        });
    }

    removePlan(index) {
        const plans = this.state.plans.filter(plan => plan.index !== index);
        this.setState({
            plans,
            numPlans: this.state.numPlans - 1
        }, this.validate);
    }

    getLineOfCoverageText() {
        const {line_of_coverage} = this.props;
        let line_of_coverage_text;
        switch(line_of_coverage) {
            case 'std':
                line_of_coverage_text = 'Short-term disability';
                break;
            case 'ltd':
                line_of_coverage_text = 'Long-term disability';
                break;
            default:
                line_of_coverage_text = line_of_coverage.charAt(0).toUpperCase() + line_of_coverage.slice(1)
        }
        return line_of_coverage_text;
    }

    getPlans() {
        const {numPlans, missingFields} = this.state;

        const line_of_coverage_text = this.getLineOfCoverageText();

        let plansArray = [];
        for (let i = 0; i < numPlans; i++) {
            const key = 'plan_' + i;
            plansArray.push(
                <div className='plan' key={key + '_container'}>
                    <TextFieldContainer
                        key={key}
                        label={line_of_coverage_text + ' Plan'}
                        className='name'
                        ref={key}
                        type='text'
                        error={missingFields.indexOf(key) > -1}
                        onChange={(key, value) => this.onPlanChange(key, value, i)}
                    />
                    {i > 0 &&
                        <img
                            alt=""
                            className='plan__close'
                            src={require('../../assets/images/ic-close.svg')}
                            height='12' width='12'
                            onClick={this.removePlan.bind(this, i)}
                        />
                    }
                    <UploadContainer
                        onChange={file => this.onPlanChange('file_details', file, i)}
                    />
                </div>
            );
        }
        return plansArray;
    }

    filterCarriersList(carriers) {
        const {state} = this.state;
        const {line_of_coverage} = this.props;
        return shouldDisplayCarriers(carriers, state, line_of_coverage);
    }

    render() {
        const {line_of_coverage, transaction_type = 'new_hire_enrollment', className} = this.props;
        const {open, missingFields} = this.state;

        const line_of_coverage_text = this.getLineOfCoverageText();
        let html = (
            <div className='link plus' onClick={this.toggleOpen.bind(this)}>
                add {line_of_coverage_text} coverage
            </div>
        );
        if (open) {
            let coverageImage = 'ic-box';
            switch (line_of_coverage) {
                case 'medical':
                    coverageImage = 'ic-medical';
                    break;
                case 'dental':
                    coverageImage = 'ic-dental';
                    break;
                case 'vision':
                    coverageImage = 'ic-vision';
                    break;
                default:
            }

            // JIRA NY-137
            const loc_medical_dental_vision = ['medical', 'dental', 'vision'].includes(line_of_coverage) ? true : false;

            html = (
                <div>
                    <div className='line-of-coverage'>
                        <img alt="" src={require('../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                        <span>{line_of_coverage_text}</span>
                        <img alt="" className='line-of-coverage__close' src={require('../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this)} />
                    </div>
                    <div className='plans-container'>
                        <div className='plans'>{this.getPlans()}</div>
                        <div className='link plus' onClick={this.addPlan.bind(this)}>Add Plan</div>
                    </div>
                    <div className='coverage-details'>
                        <DropdownContainer
                            className='state'
                            label='Carrier State'
                            options={ALL_STATES.map(state => {
                                return {display: state.name + ' (' + state.id + ')', value: state.id};
                            })}
                            error={missingFields.indexOf('state') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <DropdownContainer
                            className='carrier_id'
                            label='Carrier Name'
                            options={this.filterCarriersList(this.props.carriers).map(carrier => {
                                return {display: carrier.name, value: carrier.id};
                            })}
                            error={missingFields.indexOf('carrier_id') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        {transaction_type === 'new_hire_enrollment' &&
                            <TextFieldContainer
                                key='group_id'
                                label='Group ID'
                                className='group_id'
                                ref='group_id'
                                type='text'
                                error={missingFields.indexOf('group_id') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        }
                        {(transaction_type === 'qualifying_life_event' || transaction_type === 'open_enrollment') &&
                            <TextFieldContainer
                                key='group_id'
                                label='Group ID'
                                className='group_id'
                                ref='group_id'
                                type='text'
                                error={missingFields.indexOf('group_id') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        }
                    </div>
                    {transaction_type === 'new_hire_enrollment' && !loc_medical_dental_vision &&
                        <div className='row'>
                            <DropdownContainer
                                className='waiting_period'
                                label='Waiting Period'
                                options={[
                                    {display: 'Date of Hire', value: 'date_of_hire'},
                                    {display: 'First of Month After Hire Date', value: 'first_of_month_after_hire_date'},
                                    {display: 'First of Month Following One Month from Hire Date', value: 'first_of_month_following_one_month_from_hire_date'},
                                    {display: 'First of Month Following Two Months from Hire Date', value: 'first_of_month_following_two_months_from_hire_date'}
                                ]}
                                error={missingFields.indexOf('waiting_period') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                            <DropdownContainer
                                className='termination_window'
                                label='Termination Window'
                                options={[
                                    {display: 'Date of Termination', value: 'date_of_termination'},
                                    {display: 'Last Day of Month', value: 'last_day_of_month'},
                                ]}
                                error={missingFields.indexOf('termination_window') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                </div>
            );
        }

        return (
            <div className={`${className} coverage-option`}>
                {html}
            </div>
        );
    }
}

export default CoverageOption;
