import moment from 'moment';

import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DateSelectContainer } from '../../index';
import { guid } from '../../../utils';

import Owner from './Owner';

export default class WorkersCompensation extends Component {
    state = {
        owners: [],
        ...this.props
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    addItem(itemType) {
        let items = this.state[itemType];
        items.push({id: guid()});
        this.setState({[itemType]: items});
    }

    onItemChange(itemType, id, item) {
        let items = this.state[itemType];
        items = items.filter(i => i.id !== id);
        items.push(item);
        items = items.sort((a,b) => a.id - b.id);
        this.setState({[itemType]: items}, () => this.props.onChange(this.state));
    }

    removeItem(itemType, id) {
        let items = this.state[itemType];
        items = items.filter(i => i.id !== id);
        this.setState({[itemType]: items}, () => this.props.onChange(this.state));
    }

    render() {
        const {owners} = this.state;
        const {missingFields} = this.props;

        let ownersDiv = [];
        owners.forEach((o, index) => {
            ownersDiv.push(
                <Owner
                    key={o.id}
                    index={index + 1}
                    {...o}
                    workersComp={true}
                    onChange={(owner) => this.onItemChange('owners', o.id, owner)}
                    remove={this.removeItem.bind(this, 'owners', o.id)}
                />
            );
        });

        return (
            <div>
                <div className='main-text'>
                    <div className='name light'>Workers' Compensation</div>
                    <div>Tell us about the workers' compensation policy</div>
                </div>
                <div className='row'>
                    <RadioButtonQuestionContainer
                        questionText={'Does the company have a workers\' compensation policy in place?'}
                        options={[
                            {display: 'no'},
                            {display: 'yes'},
                            {display: 'pending'}
                        ].map(o => {
                            const {display} = o;
                            return {name: 'offer_coverage', display, value: display, selected: this.state.offer_coverage === display}
                        })}
                        error={missingFields.indexOf('offer_coverage') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.offer_coverage === 'yes' &&
                    <div>
                        <div className='row'>
                            <TextFieldContainer
                                key='carrier_name'
                                label="Carrier Name"
                                className='carrier_name'
                                ref='carrier_name'
                                type='text'
                                text={this.state.carrier_name}
                                error={missingFields.indexOf('carrier_name') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='policy_number'
                                label="Policy Number"
                                className='policy_number'
                                ref='policy_number'
                                type='text'
                                theme='margin-left'
                                text={this.state.policy_number}
                                error={missingFields.indexOf('policy_number') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className='row'>
                            <DateSelectContainer
                                key='renewal_date'
                                className='renewal_date'
                                label='Renewal Date'
                                value={this.state.renewal_date ? moment(this.state.renewal_date) : null}
                                minDate={moment().subtract(3, 'months')}
                                startDate={moment().startOf('month')}
                                error={missingFields.indexOf('renewal_date') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className='row'>List of owners/partners not covered by policy</div>
                        <div className='owners'>
                            {ownersDiv}
                            <div onClick={this.addItem.bind(this, 'owners')} className='link plus'>Add owner/partner</div>
                        </div>
                    </div>
                }
                {(this.state.offer_coverage !== undefined && !this.state.offer_coverage) &&
                    <div className='row'>
                        <TextFieldContainer
                            key='no_coverage_reason'
                            label="Why is there no policy in place?"
                            className='no_coverage_reason'
                            ref='no_coverage_reason'
                            type='text'
                            text={this.state.no_coverage_reason}
                            error={missingFields.indexOf('no_coverage_reason') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
            </div>
        )
    }
}
