import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import T from 'prop-types';
import queryString from 'query-string';

import { capitalize } from '../../../utils';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import './Account.scss';

export class Account extends Component {
    static propTypes = {
        navigate: T.func,
        onClick: T.func,
        onSuccess: T.func,
        default: T.string,
        loginOrRegister: T.func,
        forgotPassword: T.func,
        resetPassword: T.func
    }

    constructor (...args) {
        super(...args);

        this.state = {
            type: this.props.default || 'login'
        };
    }

    componentWillMount() {
        document.title = 'Noyo - Login';
    }

    componentDidMount() {
        const search = this.props.location.search;
        if (search.indexOf('reset-password') > -1) {
            const parsed = queryString.parse(search);
            this.setState({
                code: parsed.code,
                type: 'reset-password'
            });
            document.title = 'Noyo - Reset Password';
        }
    }

    componentDidUpdate() {
        const userId = localStorage.getItem('userId');
        const accessToken = localStorage.getItem('accessToken');
        if (userId !== null && accessToken !== null) {
            browserHistory.push('/');
        }
    }

    componentWillUnmount() {
        if (this.props.location.search.indexOf('reset-password') > -1) {
            this.props.location.search = '';
        }
    }

    navigate(type) {
        document.title = 'Noyo - ' + capitalize(type.replace('_', ' '));
        this.setState({
            type
        });
    }

    alert(message) {
        this.props.setMessage('Error', message);
    }

    loginOrSignup(type, payload) {
        const {email, password} = payload;
        if (!email || email.length === 0) {
            this.alert('Email required');
            return;
        }

        if (!password || password.length < 6) {
            this.alert('Password should be at least 6 characters');
            return;
        }

        const onSuccess = () => {
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
        }

        this.props.loginOrRegister(type, payload, onSuccess);
    }

    forgotPassword(payload, onSuccess) {
        const {email} = payload;

        if (!email || email.length === 0) {
            this.alert('Email required');
            return;
        }

        this.props.forgotPassword(payload.email, onSuccess);
    }

    resetPassword(payload) {
        const {password} = payload;

        if (!password || password.length < 6) {
            this.alert('Password should be at least 6 characters');
            return;
        }

        let error = false;
        const code = this.state.code;
        if (!code) {
            error = true;
            console.error('no code present to reset password');
        }

        if (error) {
            this.alert('Unable to reset password');
            return;
        }

        payload.code = code;
        this.props.resetPassword(payload, this.resetPasswordSuccess.bind(this));
    }

    resetPasswordSuccess() {
        this.setState({
            resetPasswordSuccess: true
        });
        browserHistory.push('/login');
        this.navigate('login');
    }

    render () {
        let Container = null;
        let data = {
            onClick: this.loginOrSignup.bind(this),
            navigate: this.navigate.bind(this)
        };
        const type = this.state.type;
        if (type === 'login') {
            Container = Login;
            data.resetPasswordSuccess = this.state.resetPasswordSuccess;
        } else if (type === 'reset-password') {
            Container = ResetPassword;
            data.onClick = this.resetPassword.bind(this)
        } else {
            Container = ForgotPassword;
            data.onClick = this.forgotPassword.bind(this)
        }
        const accountDiv = (<Container {...data} />);
        return (
            <div className="account">
                {accountDiv}
            </div>
        );
    }
}

export default Account;
