import React, { Component } from 'react';

import { validateFields, getApplicationType } from '../../utils';

import moment from 'moment';

import {TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, DateSelectContainer} from '../index';

import './Application.scss';


class CoverageInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applicationType: getApplicationType(this.props.application),
            missingFields: [],
            ...this.props
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.props.onChange(key, value));
    }

    validate() {
        let requiredFields = [];

        const {selectedPlans} = this.props.formData;
        const selectedMedical = selectedPlans.medical;
        if (this.state.applicationType !== 'new_hire_enrollment' && selectedMedical && selectedMedical.value === 'waive_coverage') {
            requiredFields.push('waive_reason');
        }

        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields} = this.state;
        const {
            formData, pcp, pcp_physician_name, pcp_physician_id, pcp_existing_patient, pcp_ipamg_name, pcp_ipamg_id,
            obgyn, obgyn_physician_name, obgyn_physician_id, obgyn_existing_patient, obgyn_ipamg_name, obgyn_ipamg_id,
            last_coverage_date, waive_reason, waive_carrier_name, waive_carrier_phone_number,
            waive_plan_name, waive_sponsor_company, waive_policy_id, waive_first_name, waive_last_name
        } = this.props;

        const {selectedPlans} = formData;
        const selectedMedical = selectedPlans.medical;

        let addOrChange = false;
        let isHMO = false;
        if (selectedMedical && selectedMedical.value !== 'waive_coverage') {
            addOrChange = true;
            isHMO = selectedMedical.display.toLowerCase().indexOf('hmo') > -1;
        }

        let waiving = false;
        ['medical', 'dental', 'vision', 'life'].forEach(lineOfCoverage => {
            if (!waiving && selectedPlans[lineOfCoverage] && selectedPlans[lineOfCoverage].value === 'waive_coverage') {
                waiving = true;
            }
        });

        let waiveOptions = []
        if (waiving) {
            waiveOptions = [
                {display: 'COBRA Coverage', value: 'other-cobra', selected: waive_reason === 'other-cobra'},
                {display: 'Individual Coverage - On Exchange', value: 'other-ind-on-exchange', selected: waive_reason === 'other-ind-on-exchange'},
                {display: 'Individual Coverage - Off Exchange', value: 'other-ind-off-exchange', selected: waive_reason === 'other-ind-off-exchange'},
                {display: 'Insurance Through Another Job', value: 'other-employee-group', selected: waive_reason === 'other-employee-group'},
                {display: 'Medicaid', value: 'medicaid', selected: waive_reason === 'medicaid'},
                {display: 'Medi-Cal', value: 'medi-cal', selected: waive_reason === 'medi-cal'},
                {display: 'Medicare', value: 'other-medicare', selected: waive_reason === 'other-medicare'},
                {display: 'No Other Coverage', value: 'no-coverage', selected: waive_reason === 'no-coverage'},
                {display: 'Parental Group Coverage', value: 'other-parent-group', selected: waive_reason === 'other-parent-group'},
                {display: 'Retiree Coverage', value: 'retiree-coverage', selected: waive_reason === 'retiree-coverage'},
                {display: 'Spouse Group Coverage', value: 'other-spouse-group', selected: waive_reason === 'other-spouse-group'},
                {display: 'TRICARE Military Coverage', value: 'tricare-coverage', selected: waive_reason === 'tricare-coverage'},
                {display: 'VA Eligibility', value: 'va-coverage', selected: waive_reason === 'va-coverage'}
            ];
        }

        return (
            <div className='employment'>
                {addOrChange &&

                    <div>
                        {isHMO &&
                        <div>
                            <div className='row radio'>
                                <RadioButtonQuestionContainer
                                    questionText='Would you like to select a Primary Care Physician (PCP)?'
                                    options={[
                                        {name: 'pcp', display: 'no', value: false, selected: (pcp !== undefined && !pcp)},
                                        {name: 'pcp', display: 'yes', value: true, selected: pcp}
                                    ]}
                                    className='pcp'
                                    error={missingFields.indexOf('pcp') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            {pcp &&
                                <div>
                                <div className='row'>
                                    <TextFieldContainer
                                        key='pcp_physician_name'
                                        label="Physician Name"
                                        className='pcp_physician_name'
                                        ref='pcp_physician_name'
                                        type='text'
                                        text={pcp_physician_name}
                                        error={missingFields.indexOf('pcp_physician_name') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                    <TextFieldContainer
                                        key='pcp_physician_id'
                                        label="Physician ID"
                                        className='pcp_physician_id'
                                        ref='pcp_physician_id'
                                        type='text'
                                        theme={'margin-left'}
                                        text={pcp_physician_id}
                                        error={missingFields.indexOf('pcp_physician_id') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                    {/* NY-154 */}
                                    {false && <div className='row'>
                                        <TextFieldContainer
                                            key='pcp_ipamg_name'
                                            label="IPAMG Name"
                                            className='pcp_ipamg_name'
                                            ref='pcp_ipamg_name'
                                            type='text'
                                            text={pcp_ipamg_name}
                                            error={missingFields.indexOf('pcp_ipamg_name') > -1}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <TextFieldContainer
                                            key='pcp_ipamg_id'
                                            label="IPAMG ID"
                                            className='pcp_ipamg_id'
                                            ref='pcp_ipamg_id'
                                            type='text'
                                            theme={'margin-left'}
                                            text={pcp_ipamg_id}
                                            error={missingFields.indexOf('pcp_ipamg_id') > -1}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                }
                                <div className='row radio'>
                                    <RadioButtonQuestionContainer
                                        questionText='Existing patient?'
                                        options={[
                                            {name: 'pcp_existing_patient', display: 'no', value: false, selected: (pcp_existing_patient !== undefined && !pcp_existing_patient)},
                                            {name: 'pcp_existing_patient', display: 'yes', value: true, selected: pcp_existing_patient}
                                        ]}
                                        className='pcp_existing_patient'
                                        error={missingFields.indexOf('pcp_existing_patient') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        }
                        {false && isHMO &&
                        <div>
                            <div className='row radio'>
                                <RadioButtonQuestionContainer
                                    questionText='Do you have a OBGYN?'
                                    options={[
                                        {name: 'obgyn', display: 'no', value: false, selected: (obgyn !== undefined && !obgyn)},
                                        {name: 'obgyn', display: 'yes', value: true, selected: obgyn}
                                    ]}
                                    className='obgyn'
                                    error={missingFields.indexOf('obgyn') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            {obgyn &&
                            <div>
                                <div className='row'>
                                    <TextFieldContainer
                                        key='obgyn_physician_name'
                                        label="Physician Name"
                                        className='obgyn_physician_name'
                                        ref='obgyn_physician_name'
                                        type='text'
                                        text={obgyn_physician_name}
                                        error={missingFields.indexOf('obgyn_physician_name') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                    <TextFieldContainer
                                        key='obgyn_physician_id'
                                        label="Physician ID"
                                        className='obgyn_physician_id'
                                        ref='obgyn_physician_id'
                                        type='text'
                                        theme={'margin-left'}
                                        text={obgyn_physician_id}
                                        error={missingFields.indexOf('obgyn_physician_id') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className='row'>
                                    <TextFieldContainer
                                        key='obgyn_ipamg_name'
                                        label="IPAMG Name"
                                        className='obgyn_ipamg_name'
                                        ref='obgyn_ipamg_name'
                                        type='text'
                                        text={obgyn_ipamg_name}
                                        error={missingFields.indexOf('obgyn_ipamg_name') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                    <TextFieldContainer
                                        key='obgyn_ipamg_id'
                                        label="IPAMG ID"
                                        className='obgyn_ipamg_id'
                                        ref='obgyn_ipamg_id'
                                        type='text'
                                        theme={'margin-left'}
                                        text={obgyn_ipamg_id}
                                        error={missingFields.indexOf('obgyn_ipamg_id') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className='row radio'>
                                    <RadioButtonQuestionContainer
                                        questionText='Existing patient?'
                                        options={[
                                            {name: 'obgyn_existing_patient', display: 'no', value: false, selected: (obgyn_existing_patient !== undefined && !obgyn_existing_patient)},
                                            {name: 'obgyn_existing_patient', display: 'yes', value: true, selected: obgyn_existing_patient}
                                        ]}
                                        className='obgyn_existing_patient'
                                        error={missingFields.indexOf('obgyn_existing_patient') > -1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        }
                    </div>
                }

                {!isHMO && waiving &&
                    <div>
                        <div className='row'>
                            <DateSelectContainer
                                key='last_coverage_date'
                                label="Last Date of Desired Coverage"
                                className='last_coverage_date'
                                ref='last_coverage_date'
                                type='text'
                                value={last_coverage_date ? moment(last_coverage_date) : null}
                                startDate={moment().startOf('month')}
                                minDate={moment().subtract(3, 'months')}
                                error={missingFields.indexOf('last_coverage_date') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                            />
                        </div>
                        <div className='row waive-coverage__reason'>
                            <DropdownContainer
                                key='waive_reason'
                                label='Reason for waiving'
                                className='waive_reason'
                                ref='waive_reason'
                                options={waiveOptions}
                                error={missingFields.indexOf('waive_reason') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        {waive_reason === 'other-spouse-group' &&
                        <div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_carrier_name'
                                    label="Carrier Name"
                                    className='waive_carrier_name'
                                    ref='waive_carrier_name'
                                    type='text'
                                    text={waive_carrier_name}
                                    error={missingFields.indexOf('waive_carrier_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_carrier_phone_number'
                                    label="Carrier Phone Number"
                                    className='waive_carrier_phone_number'
                                    ref='waive_carrier_phone_number'
                                    type='text'
                                    formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                                    text={waive_carrier_phone_number}
                                    error={missingFields.indexOf('waive_carrier_phone_number') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_plan_name'
                                    label="Plan Name"
                                    className='waive_plan_name'
                                    ref='waive_plan_name'
                                    type='text'
                                    text={waive_plan_name}
                                    error={missingFields.indexOf('waive_plan_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_sponsor_company'
                                    label="Sponsoring Company Name"
                                    className='waive_sponsor_company'
                                    ref='waive_sponsor_company'
                                    type='text'
                                    text={waive_sponsor_company}
                                    error={missingFields.indexOf('waive_sponsor_company') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_policy_id'
                                    label="Policy ID"
                                    className='waive_policy_id'
                                    ref='waive_policy_id'
                                    type='text'
                                    text={waive_policy_id}
                                    error={missingFields.indexOf('waive_policy_id') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='waive_first_name'
                                    label="Policyholder First Name"
                                    className='waive_first_name'
                                    ref='waive_first_name'
                                    type='text'
                                    text={waive_first_name}
                                    error={missingFields.indexOf('waive_first_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                                <TextFieldContainer
                                    key='waive_last_name'
                                    label="Policyholder Last Name"
                                    className='waive_last_name'
                                    ref='waive_last_name'
                                    type='text'
                                    theme='margin-left'
                                    text={waive_last_name}
                                    error={missingFields.indexOf('waive_last_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                        </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default CoverageInformation;
