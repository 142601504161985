//
//
// CONNECT THIS TO REDUX AND GET `APPLICATION` STORE IN HERE INSTEAD
// OF PASSING `APPLICATION` THROUGH A SERIES OF NESTED PROPS
//
//

import React, { Component, Fragment } from 'react';

import { validateFields, isTermination, validateAddress, getApplicationType } from '../../utils';

import {TextFieldContainer, RadioButtonQuestionContainer, DropdownContainer, Address} from '../index';

import './Application.scss';


class PersonalInformation extends Component {
    constructor(props) {
        super(props);

        const {email, first_name} = this.props;

        this.state = {
            missingFields: [],
            email, first_name,
            ...props
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({...nextProps});
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.props.onChange(key, value));
    }

    validate(validate_work_address) {
        const {isDependent} = this.props;

        if (validate_work_address === undefined){
            validate_work_address = true
        }

        const requiredFields = ['first_name', 'last_name', 'ssn', 'dob', 'sex'];

        if (!isDependent) {
            requiredFields.push('home_phone');
            requiredFields.push('email_type');
            requiredFields.push('email');
        }

        if (!isDependent && getApplicationType(this.props.application) === 'new_hire_enrollment') {
            requiredFields.push('marital_status');
            requiredFields.push('preferred_language')
        }

        const missingFields = validateFields(requiredFields, this.state);
        // check addresses separately since they're objects

        if (!isDependent) {
            const home_address = this.state.home_address;
            if (!validateAddress(home_address)) {
                missingFields.push('home_address');
            }

            if (validate_work_address) {
                const work_address = this.state.work_address;
                if (!validateAddress(work_address)) {
                    missingFields.push('work_address');
                }
            }
        }

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {missingFields} = this.state;
        const {
            first_name,
            middle_name,
            last_name,
            suffix,
            ssn,
            dob,
            sex,
            marital_status,
            home_address,
            home_phone,
            email_type,
            email,
            work_address,
            work_phone,
            preferred_language,
            isDependent,
            application
        } = this.props;
        return (
            <div className='personal'>
                <div className='row'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='middle_name'
                        label="M.I."
                        className='middle_name'
                        ref='middle_name'
                        type='text'
                        theme='tiny'
                        text={middle_name}
                        onChange={this.onChange.bind(this)}
                        maxLength={1}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        text={last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='suffix'
                        label="Suffix"
                        className='suffix'
                        ref='suffix'
                        type='text'
                        theme='tiny'
                        text={suffix}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='ssn'
                        label="Social Security Number"
                        className='ssn'
                        ref='ssn'
                        type='text'
                        text={ssn}
                        error={missingFields.indexOf('ssn') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='dob'
                        label="Date of Birth"
                        className='dob'
                        ref='dob'
                        type='text'
                        text={dob}
                        error={missingFields.indexOf('dob') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                    />
                </div>
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='Sex'
                        options={[
                            {name: 'sex', value: 'F', display: 'female', selected: sex === 'F'},
                            {name: 'sex', value: 'M', display: 'male', selected: sex === 'M'}
                        ]}
                        error={missingFields.indexOf('sex') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {(!isTermination(application) && !isDependent) &&
                    <div className='row'>
                        <DropdownContainer
                            key='marital_status'
                            label="Marital Status"
                            className='marital_status'
                            ref='marital_status'
                            value={marital_status}
                            options={[
                                {display: 'Single', value: 'single', selected: marital_status === 'single'},
                                {display: 'Married', value: 'married', selected: marital_status === 'married'},
                                {display: 'Domestic partner', value: 'domestic-partner', selected: marital_status === 'domestic-partner'},
                                {display: 'Divorced', value: 'divorced', selected: marital_status === 'divorced'},
                                {display: 'Widowed', value: 'widowed', selected: marital_status === 'widowed'},
                                {display: 'Legally separated', value: 'legally-separated', selected: marital_status === 'legally-separated'}
                            ]}
                            error={missingFields.indexOf('marital_status') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
                <div className='row'>
                    <Address
                        label='Home Address'
                        value={home_address}
                        error={missingFields.indexOf('home_address') > -1}
                        onChange={(address) => this.onChange('home_address', address)}
                    />
                </div>
                {(!isDependent) && 
                <Fragment>
                    <div className='row'>
                        <TextFieldContainer
                            key='home_phone'
                            label="Home Phone #"
                            className='home_phone'
                            ref='home_phone'
                            type='text'
                            text={home_phone}
                            error={missingFields.indexOf('home_phone') > -1}
                            onChange={this.onChange.bind(this)}
                            formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                        />
                    </div>
                    <div className='row'>
                        <DropdownContainer
                            key='email_type'
                            label="Email Type"
                            className='email_type'
                            ref='email_type'
                            type='text'
                            theme='small'
                            error={missingFields.indexOf('email_type') > -1}
                            onChange={this.onChange.bind(this)}
                                options={[
                                    {display: 'Home', value: 'home', selected: email_type === 'home'},
                                    {display: 'Work', value: 'work', selected: email_type === 'work'}
                                ]}
                            />
                        <TextFieldContainer
                            key='email'
                            label="Email address"
                            className='email'
                            ref='email'
                            type='text'
                            text={email}
                            error={missingFields.indexOf('email') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                </Fragment>
                }
                {(!isDependent && !isTermination(application)) &&
                    <div>
                        <div className='row'>
                            <TextFieldContainer
                                key='work_phone'
                                label="Work Phone # (optional)"
                                className='work_phone'
                                ref='work_phone'
                                type='text'
                                text={work_phone}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{phone: true, phoneRegionCode: 'US', delimiter: '-'}}
                            />
                        </div>
                        <div className='row'>
                            <Address
                                label='Work Address'
                                value={work_address}
                                error={missingFields.indexOf('work_address') > -1}
                                onChange={(address) => this.onChange('work_address', address)}
                            />
                        </div>
                    </div>
                }
                {(!isTermination(application) && !isDependent) &&
                    <div className='row'>
                        <DropdownContainer
                                key='preferred_language'
                                label="Preferred Language"
                                className='preferred_language'
                                ref='preferred_language'
                                type='text'
                                error={missingFields.indexOf('preferred_language') > -1}
                                onChange={this.onChange.bind(this)}
                                options={['English', 'Spanish', 'Chinese', 'Korean', 'Vietnamese', 'Tagalog'].map(display => {
                                    const value = display.toLowerCase();
                                    return {display, value, selected: preferred_language === value}
                                })}
                            />
                    </div>
                }
            </div>
        );
    }
}

export default PersonalInformation;
