export const STANDARD_HTTP_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export function setMessage(header, message) {
    return (dispatch, getState) => {
        const data = {
            header: header,
            text: message,
            successButton: {
                text: 'OK'
            }
        };
        dispatch(showAlert(data));
    };
}

export function showAlert(alert) {
    return (dispatch, getState) => {
        const {visible, text} = getState().App.alert;
        if (visible && text === alert.text) {
            return;
        }
        alert.visible = true;
        dispatch({
            type: 'SHOW_ALERT',
            payload: {alert}
        });
    };
}

export function hideAlert() {
    return (dispatch, getState) => {
        dispatch({
            type: 'HIDE_ALERT'
        });
    };
}
