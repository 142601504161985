import React, { Component } from 'react';

import {MDCFormField} from '@material/form-field/dist/mdc.formField';
import {MDCRadio} from '@material/radio/dist/mdc.radio';

import './RadioButton.scss';

export default class RadioButton extends Component {
    componentDidMount() {
        const radio = new MDCRadio(this.refs.radio);
        const formField = new MDCFormField(this.refs.formField);
        formField.input = radio;

        if (this.props.checked) {
            radio.checked = true;
        }

        if (this.props.disabled) {
            radio.disabled = true;
        }

        radio.listen('click', this.props.onClick);

        this.setState({radio});
    }

    componentWillReceiveProps(nextProps) {
        if (this.state) {
            const {radio} = this.state;
            const {checked, disabled} = this.props;

            if (checked !== nextProps.checked) {
                radio.checked = nextProps.checked;
            }

            if (disabled !== nextProps.disabled) {
                radio.disabled = nextProps.disabled;
            }
        }
    }

    render() {
        const {disabled, theme, className, label = '', name, radioId, error} = this.props;

        let classes = 'radio';
        if (disabled) {
            classes += ' disabled';
        }
        if (theme) {
            classes += ' ' + theme;
        }
        if (className) {
            classes += ' ' + className;
        }
        if (error) {
            classes += ' error';
        }

        let labelClass = '';
        if (!label) {
            labelClass = 'hide';
        }

        return (
            <div className={classes}>
                <div className="mdc-form-field" ref='formField'>
                    <div className="mdc-radio" ref='radio'>
                        <input
                            className="mdc-radio__native-control"
                            type="radio"
                            id={"radio-" + radioId}
                            name={name}
                        />
                        <div className="mdc-radio__background">
                            <div className="mdc-radio__outer-circle"></div>
                            <div className="mdc-radio__inner-circle"></div>
                        </div>
                    </div>
                    <label className={labelClass} htmlFor={"radio-" + radioId}>{label}</label>
                </div>
            </div>
        );
    }
}
