import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import CoverageOptionEmployer from './CoverageOptionEmployer';

const mapStateToProps = ({User, Noyo}) => {
    return {
        currentUser: User.currentUser,
        carriers: Noyo.carriers
    }
};

const mapDispatchToProps = {};

const ConnectedCoverageOptionEmployer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
        forwardRef: true,
    },
)(CoverageOptionEmployer);

export default forwardRef((props, ref) => {
    return <ConnectedCoverageOptionEmployer {...props} ref={ref} />
})