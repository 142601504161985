import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import './Header.scss';

class Header extends Component {
    broker() {
        browserHistory.push('/');
    }

    render() {
        const {currentUser, userLocation=''} = this.props;
        let headerElement = null;
        if (userLocation.indexOf('applications') > -1) {
           // do nothing? status bar handled within Application.js
        }
        else if (currentUser) {
            if (userLocation.indexOf('broker') > -1) {
                headerElement = (
                    <div ref='header' className='header'>
                        <div className='back link lower' onClick={this.broker.bind(this)}>
                            <img
                                alt=""
                                src={require('../../../assets/images/ic-arrow.svg')}
                                width="24" height="24"
                                style={{transform:'rotate(90deg)', marginRight: '10px'}}
                            />
                            <span>Transaction Dashboard</span>
                        </div>
                        <div className='center-text'>Create Application</div>
                        <div className='logout' onClick={this.props.logout}>Logout</div>
                    </div>
                )
            } else {
                headerElement = (
                    <div ref='header' className='header'>
                        <div className='logo' onClick={this.broker.bind(this)}>
                            <img alt="" src={require('../../../assets/images/brokerLogo.svg')} width="123" height="30" />
                        </div>
                        <div className='logout' onClick={this.props.logout}>Logout</div>
                    </div>
                )
            }
        } else {
            // Switching this to hard-coded NFP given they are the only user
            // headerElement = (<div ref='header' className='header'><div className='noyo center-text'>NOYO</div></div>);
            headerElement = (
                <div ref='header' className='header'>
                    <div className='logo' onClick={this.broker.bind(this)}>
                        <img alt="" src={require('../../../assets/images/brokerLogo.svg')} width="123" height="30" />
                    </div>
                    <div className='logout' onClick={this.props.logout}>Logout</div>
                </div>
            )
        }
        return (<div>{headerElement}</div>);
    }
}

export default Header;
