import moment from 'moment';

import React, { Component } from 'react';

import { ButtonContainer } from '../index';

import InitialInformation from './employer/InitialInformation';
import CoverageSelections from './employer/CoverageSelections';
import CoverageMaintenance from './employer/CoverageMaintenance';
import OtherCoverageInformation from './employer/OtherCoverageInformation';
import OtherEmployeeInformation from './employer/OtherEmployeeInformation';
import Signoff from './employer/Signoff';

import './Broker.scss';


const STEPS = [
    InitialInformation,  // step 0
    CoverageSelections,  // step 1
    CoverageMaintenance,  // step 2
    OtherCoverageInformation,  // step 3
    OtherEmployeeInformation,  // step 4
    Signoff  // step 5
];

export default class BrokerEmployeeApplication extends Component {
    state = {
        missingFields: [],
        currentStep: 0,
        submitting: false
    }

    applicationStarted() {
        // TODO: fill this out properly
        return false;
    }

    enableButton() {
        this.setState({submitting: false});
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => console.log(this.state));
    }

    back(currentStep) {
        this.setState({currentStep}, window.scrollTo(0,0));
    }

    continue() {
        const {currentStep} = this.state;
        if (this.currentStepComponent.validate()) {
            let nextStep = currentStep + 1;
            this.setState({
                currentStep: nextStep,
                error: false
            }, window.scrollTo(0,0));
        } else {
            this.setState({error: true});
        }
    }

    submit() {
        if (this.currentStepComponent.validate()) {
            const imageDataUrl = this.currentStepComponent.signaturePad.toDataURL();
            const signature = imageDataUrl.replace('data:image/png;base64,','');

            this.setState(
                {submitting: true, signature, signature_date: moment()},
                () => this.props.onSubmit(this.state)
            )
        } else {
            this.setState({error: true});
        }
    }

    render() {
        const {currentStep, submitting, error} = this.state;
        const CurrentStepComponent = STEPS[currentStep];

        const buttonText = currentStep < (STEPS.length - 1) ? 'continue' : 'Launch Application';
        const buttonOnChange = currentStep < (STEPS.length - 1) ? this.continue.bind(this) : this.submit.bind(this);

        return (
            <div className='broker__application-employer'>
                <CurrentStepComponent
                    ref={ref => this.currentStepComponent = ref}
                    back={this.back.bind(this)}
                    currentStep={currentStep}
                    carriers={this.props.carriers}
                    onChange={this.onChange.bind(this)}
                    {...this.state}
                />
                <div className={'footer ' + (currentStep === 0 ? 'initial' : '')}>
                    {currentStep > 0 &&
                        <ButtonContainer text={'back'} disabled={submitting} selected={true} theme='filled' onClick={this.back.bind(this, currentStep - 1)} />
                    }
                    {error &&
                        <div className='footer__error'>Errors found above</div>
                    }
                    <ButtonContainer text={submitting ? 'submitting...' : buttonText} disabled={submitting} selected={true} theme='filled' onClick={buttonOnChange} />
                </div>
            </div>
        )
    }
}
