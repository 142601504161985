import React, { Component } from 'react';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
    setApplicationMissingFields,
} from '../../actions/application';

import Config from '../../config';
import { validateFields } from '../../utils';

import ConnectedTerminationOption from './TerminationOption';

import './Application.scss';

const propTypes = {
    missingFields: PropTypes.array,
    termination_options: PropTypes.any,
};

const defaultProps = {
    missingFields: [],
    termination_options: [],
}

class StepTermination extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        Config.REACT_APP_LINES_OF_COVERAGE.forEach((option) => {
            this[`termination_option_${option}_ref`] = React.createRef();
        });

    }

    onChange(key, value) {
        console.log(key, value);
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    validate() {
        const {
            termination_options,
            setApplicationMissingFields,
        } = this.props;

        const requiredFields = [];
        const missingFields = validateFields(requiredFields, this.state);
        let childrenValidated = termination_options.length > 0;

        termination_options.forEach(option => {
            childrenValidated &= this[`termination_option_${option.line_of_coverage}_ref`].current.validate();
        });

        if (termination_options.length === 0) {
            missingFields.push('termination_options');
        }

        setApplicationMissingFields(missingFields);

        return missingFields.length === 0 && childrenValidated;
    }

    getTerminationOptions() {
        let options = [];
        Config.REACT_APP_LINES_OF_COVERAGE.forEach((loc) => {
            // NY-138
            if (['life','std','ltd'].includes(loc)) return;

            const termination_options = this.props.termination_options.filter(({ line_of_coverage }) => line_of_coverage === loc);

            console.log('termination_options', termination_options, loc);

            options.push(
                <ConnectedTerminationOption
                    ref={this[`termination_option_${loc}_ref`]}
                    key={loc}
                    line_of_coverage={loc}
                />
            );
        });

        return options;
    }

    render() {
        const {
            missingFields
        } = this.props;

        let coverageOptionsText = 'Coverage Affected';
        let coverageOptionsClass = 'coverage-options';
        if (missingFields.indexOf('termination_options') > -1) {
            coverageOptionsText += ' (select at least one)';
            coverageOptionsClass += ' error';
        }

        return (
            <div>
                <div className='main-text'>
                    <div className='name light'>Terminating Coverage</div>
                    <div>Which coverage does this person need to be removed from?</div>
                </div>
                <div className='termination-options-container'>
                    <div className='eligibility-group'>
                        <div className={coverageOptionsClass}>{coverageOptionsText}</div>
                        <div>{this.getTerminationOptions()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

StepTermination.propTypes = propTypes;
StepTermination.defaultProps = defaultProps;

const mapStateToProps = ({ Application }) => {

    const {
        formData
    } = Application;

    const {
        missingFields,
        termination_info,
    } = formData;

    const {
        termination_options,
    } = termination_info;

    return {
        missingFields,
        termination_options,
    }
};

const mapDispatchToProps = {
    setApplicationMissingFields,
};

const ConnectedTerminationStep = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(StepTermination);

export default ConnectedTerminationStep;
