import moment from 'moment';
import qs from 'qs';
import { get } from 'lodash';

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + s4() + s4() + s4();
}

const STATUSES = [
    "APPLICATION_NOT_STARTED",
    "APPLICATION_IN_PROGRESS",
    "APPLICATION_COMPLETE",
    "FULFILLMENT_PENDING_REVIEW",
    "FULFILLMENT_SUBMITTED_TO_CARRIER",
    "FULFILLMENT_FOLLOWED_UP_WITH_CARRIER",
    "FULFILLMENT_FOLLOWING_UP_WITH_CLIENT",
    "FULFILLMENT_CONFIRMED_BY_CARRIER",
    "FULFILLMENT_CONFIRMED_WITH_CLIENT",
    "FULFILLMENT_COMPLETED",
    "INTERNAL_ERROR"
];

const TYPES = [
    "New Hire Enrollment",
    "Termination",
    "Qualifying Life Event",
    "Open Enrollment",
    "Demographic Change"
];

const SUBMISSION_METHODS = [
    'Email',
    'Broker Portal',
    'EDI',
    'Fax',
    'Phone',
    'CSV',
    'Mail',
    'API',
    'Other'
]

export const ALL_STATES = [
    {name: 'Alabama', id: 'AL'},
    {name: 'Alaska', id: 'AK'},
    {name: 'Arizona', id: 'AZ'},
    {name: 'Arkansas', id: 'AR'},
    {name: 'California', id: 'CA'},
    {name: 'Colorado', id: 'CO'},
    {name: 'Connecticut', id: 'CT'},
    {name: 'District of Columbia', id: 'DC'},
    {name: 'Delaware', id: 'DE'},
    {name: 'Florida', id: 'FL'},
    {name: 'Georgia', id: 'GA'},
    {name: 'Hawaii', id: 'HI'},
    {name: 'Idaho', id: 'ID'},
    {name: 'Illinois', id: 'IL'},
    {name: 'Indiana', id: 'IN'},
    {name: 'Iowa', id: 'IA'},
    {name: 'Kansas', id: 'KS'},
    {name: 'Kentucky', id: 'KY'},
    {name: 'Louisiana', id: 'LA'},
    {name: 'Maine', id: 'ME'},
    {name: 'Maryland', id: 'MD'},
    {name: 'Massachusetts', id: 'MA'},
    {name: 'Michigan', id: 'MI'},
    {name: 'Minnesota', id: 'MN'},
    {name: 'Mississippi', id: 'MS'},
    {name: 'Missouri', id: 'MO'},
    {name: 'Montana', id: 'MT'},
    {name: 'Nebraska', id: 'NE'},
    {name: 'Nevada', id: 'NV'},
    {name: 'New Hampshire', id: 'NH'},
    {name: 'New Jersey', id: 'NJ'},
    {name: 'New Mexico', id: 'NM'},
    {name: 'New York', id: 'NY'},
    {name: 'North Carolina', id: 'NC'},
    {name: 'North Dakota', id: 'ND'},
    {name: 'Ohio', id: 'OH'},
    {name: 'Oklahoma', id: 'OK'},
    {name: 'Oregon', id: 'OR'},
    {name: 'Pennsylvania', id: 'PA'},
    {name: 'Rhode Island', id: 'RI'},
    {name: 'South Carolina', id: 'SC'},
    {name: 'South Dakota', id: 'SD'},
    {name: 'Tennessee', id: 'TN'},
    {name: 'Texas', id: 'TX'},
    {name: 'Utah', id: 'UT'},
    {name: 'Vermont', id: 'VT'},
    {name: 'Virginia', id: 'VA'},
    {name: 'Washington', id: 'WA'},
    {name: 'West Virginia', id: 'WV'},
    {name: 'Wisconsin', id: 'WI'},
    {name: 'Wyoming', id: 'WY'},
]


export function shouldDisplayCarriers(carriers, state, line_of_coverage) {
    // Return no results if state is not selected
    if (!state) {
      return [];
    }

    // First filter list of carriers by supported line of coverage
    const matchingCarriers = carriers.filter(c => c.lines_of_coverage.indexOf(line_of_coverage) > -1);

    const carriersMap = {};
    matchingCarriers.forEach(carrier => {
        if (carrier.state === state) {
            carriersMap[carrier.internal_name] = carrier;
        }
        if (carrier.state === 'US' && !carriersMap[carrier.internal_name]) {
            carriersMap[carrier.internal_name] = carrier;
        }
    });
    return Object.values(carriersMap);
}

export function formatTransaction(transaction, csv = false) {
    const {priority, status, application_type, application, transaction_type, line_of_coverage, carrier_name} = transaction;
    const {employee, company, effective_date} = application;

    const applicationType = application_type === 0 ? 'Employer' : 'Employee';

    const companyName = company.name;
    const dateReceived = moment(transaction.created).format('MM/DD/YYYY');
    const effectiveDate = moment(effective_date).format('MM/DD/YYYY');

    const transactionType = TYPES[transaction_type];
    const associatedPlatform = transaction.associated_platform || 'Noyo';
    const submissionMethod = SUBMISSION_METHODS[transaction.submission_method || 0];

    const brokerName = transaction.broker_name || '';
    const accountManagerName = transaction.account_manager_name || '';
    const memberTeamRepName = transaction.membership_team_rep_name || '';
    const notes = transaction.notes || '';

    // Check for an email associated with the application (new hire not started)
    let clientName = application.email;
    if (employee.personal.first_name && employee.personal.last_name) {
        // Check for a full name associated with the application (application complete)
        clientName = employee.personal.first_name + " " + employee.personal.last_name;
    }
    if (!clientName) {
        // Fall back to company admin email (termination application not started)
        clientName = company.admin.email;
    }

    const statusType = STATUSES[status];
    const statusText = capitalize(statusType.toLowerCase().replace(/_/g, ' '));

    if (csv) {
        return {
            'Application Type': applicationType,
            'Client Name': clientName,
            'Company': companyName,
            'Date Received': dateReceived,
            'Effective date': effectiveDate,
            'Priority': priority,
            'Status': statusText,
            'Carrier': carrier_name,
            'Transaction Type': transactionType,
            'Coverage Type': capitalize(line_of_coverage),
            'Association Platform': associatedPlatform,
            'Submission Method': submissionMethod,
            'Broker': brokerName,
            'Account Manager': accountManagerName,
            'Membership Team Rep': memberTeamRepName,
            'Notes': notes
        }
    } else {
        return {
            clientName, companyName, dateReceived, effectiveDate, priority,
            statusText, carrierName: carrier_name, transactionType, lineOfCoverage: line_of_coverage,
            associatedPlatform, submissionMethod, brokerName, accountManagerName, memberTeamRepName, notes
        }
    }
}


export function getApplicationType(application) {
    return application.carrier_form.form_type;
}

export function isTermination(application) {
    return get(application, 'carrier_form.form_type', false) === 'termination';
}

export function validateAddress(addressObj) {
    return addressObj && addressObj.city && addressObj.state && addressObj.zip_code;
}

export function capitalize(text) {
    // returns text with first letter capitalized:
    if (!text) {
        return ''
    }
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

export function formatDate(dateStr) {
    return moment(dateStr).format('MM/DD/YYYY');
}

export function validateFields(requiredFields, data) {
    let missingFields = [];
    requiredFields.forEach(field => {
        const value = data[field]

        if (value === undefined || value === null || (typeof(value) === 'string' && value.length === 0)) {
            missingFields.push(field);
        }

        // NY-102
        if (field === 'carrier_terms_agreed' || field === 'noyo_terms_agreed') {
            if (!value) {
                missingFields.push(field);
            }
        }

        if (field === 'signature' && value === 'iVBORw0KGgoAAAANSUhEUgAAAWgAAAB4CAYAAADfRGj6AAAEDklEQVR4Xu3UQQ0AAAwCseHf9Gzco1NAysLOESBAgEBSYMlUQhEgQIDAGWhPQIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgAABA+0HCBAgEBUw0NFixCJAgICB9gMECBCIChjoaDFiESBAwED7AQIECEQFDHS0GLEIECBgoP0AAQIEogIGOlqMWAQIEDDQfoAAAQJRAQMdLUYsAgQIGGg/QIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgAABA+0HCBAgEBUw0NFixCJAgICB9gMECBCIChjoaDFiESBAwED7AQIECEQFDHS0GLEIECBgoP0AAQIEogIGOlqMWAQIEDDQfoAAAQJRAQMdLUYsAgQIGGg/QIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgAABA+0HCBAgEBUw0NFixCJAgICB9gMECBCIChjoaDFiESBAwED7AQIECEQFDHS0GLEIECBgoP0AAQIEogIGOlqMWAQIEDDQfoAAAQJRAQMdLUYsAgQIGGg/QIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgAABA+0HCBAgEBUw0NFixCJAgICB9gMECBCIChjoaDFiESBAwED7AQIECEQFDHS0GLEIECBgoP0AAQIEogIGOlqMWAQIEDDQfoAAAQJRAQMdLUYsAgQIGGg/QIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgAABA+0HCBAgEBUw0NFixCJAgICB9gMECBCIChjoaDFiESBAwED7AQIECEQFDHS0GLEIECBgoP0AAQIEogIGOlqMWAQIEDDQfoAAAQJRAQMdLUYsAgQIGGg/QIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgAABA+0HCBAgEBUw0NFixCJAgICB9gMECBCIChjoaDFiESBAwED7AQIECEQFDHS0GLEIECBgoP0AAQIEogIGOlqMWAQIEDDQfoAAAQJRAQMdLUYsAgQIGGg/QIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgAABA+0HCBAgEBUw0NFixCJAgICB9gMECBCIChjoaDFiESBAwED7AQIECEQFDHS0GLEIECBgoP0AAQIEogIGOlqMWAQIEDDQfoAAAQJRAQMdLUYsAgQIGGg/QIAAgaiAgY4WIxYBAgQMtB8gQIBAVMBAR4sRiwABAgbaDxAgQCAqYKCjxYhFgACBB07SAHmHPmrOAAAAAElFTkSuQmCC') {
            missingFields.push(field);
        }

    });
    return missingFields;
}

export function getLineOfCoverageImage(lineOfCoverage) {
    let image = 'ic-box';
    switch (lineOfCoverage) {
        case 'medical':
            image = 'ic-medical';
            break;
        case 'dental':
            image = 'ic-dental';
            break;
        case 'vision':
            image = 'ic-vision';
            break;
        default:
    }
    return image;
}

export function p(n, area) {
    if (n === area) {
        return true;
    }
    let parent = n.parentNode;
    if (parent) {
        if (parent === area) {
            return true;
        }
        return p(parent);
    }
    return false;
}

export function stringToInt(str) {
    return parseInt(str.replace(/,/g, ''));
}

export function formatCost(cost, decimals, excludeSymbol) {
    if (typeof cost !== 'number') {
        return '';
    }
    if (decimals === null || decimals === undefined) {
        decimals = 0;
    }
    let symbol = '$';
    if (excludeSymbol) {
        symbol = '';
    }
    if (cost < 0) {
        symbol = '-' + symbol;
        cost *= -1;
    }
    return (symbol + (cost / 100).toFixed(decimals)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function newUrl(url, params) {
    if (params) {
        url = url + '?' + qs.stringify(params);
    }
    return url;
}

export function scheduled(func) {
    let timeout = null;
    let nextRun = null;
    return function(wait) {
        if (timeout == null || (nextRun - new Date()) > wait) {
            if (timeout) {
                clearTimeout(timeout);
            }
            nextRun = new Date();
            nextRun.setMilliseconds(nextRun.getMilliseconds() + wait);
            timeout = setTimeout(() => {
                // clear the timeout, then call the function. that way the function
                // could potentially schedule another call
                timeout = null;
                nextRun = null;
                func.apply(this);
            }, wait);
        }
    };
}

export class ClientError {
    code: string;
    message: any;

    constructor(code: string, message: any) {
        this.code = code;
        this.message = message;
    }
}

export function defer() {
    let result = {};
    result.promise = new Promise(function(resolve, reject) {
        result.resolve = resolve;
        result.reject = reject;
    });
    return result;
}
