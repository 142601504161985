import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer } from '../../index';

export default class CompanyInformation extends Component {
    constructor(props) {
        super(props);

        const {id, first_name, last_name, percent_ownership, coverage_eligible} = props;
        this.state = {
            missingFields: [],
            id, first_name, last_name, percent_ownership, coverage_eligible
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    render() {
        const {missingFields} = this.state;
        const {workersComp = false} = this.props;
        return (
            <div className='owner'>
                <div className='row'>
                    <div>Owner #{this.props.index}</div>
                    <img
                        alt=""
                        style={{cursor: 'pointer', marginLeft: 10, marginTop: 3}}
                        className='close'
                        src={require('../../../assets/images/ic-close.svg')}
                        height='12' width='12'
                        onClick={this.props.remove}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={this.state.first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        theme='margin-left'
                        text={this.state.last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {workersComp &&
                    <div>
                        <div className='row'>
                            <TextFieldContainer
                                key='title'
                                label="Title"
                                className='title'
                                ref='title'
                                type='text'
                                text={this.state.title}
                                error={missingFields.indexOf('title') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <RadioButtonQuestionContainer
                            questionText={'Is employee exempt from workers\' comp based on definition of California Labor Code Section 3351?'}
                            options={[
                                {name: 'california_exempt', display: 'no', value: false, selected: (this.state.california_exempt !== undefined && !this.state.california_exempt)},
                                {name: 'california_exempt', display: 'yes', value: true, selected: this.state.california_exempt}
                            ]}
                            error={missingFields.indexOf('california_exempt') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
                {!workersComp &&
                    <div>
                        <div className='row'>
                            <TextFieldContainer
                                key='percent_ownership'
                                label='Percent Ownership'
                                className='percent_ownership'
                                ref='percent_ownership'
                                type='text'
                                text={this.state.percent_ownership}
                                error={missingFields.indexOf('percent_ownership') > -1}
                                onChange={this.onChange.bind(this)}
                                formatOptions={{numeral: true}}
                            />
                        </div>
                        <div className='row'>
                            <RadioButtonQuestionContainer
                                questionText={'Is this owner eligible for coverage?'}
                                options={[
                                    {name: 'coverage_eligible', display: 'no', value: false, selected: (this.state.coverage_eligible !== undefined && !this.state.coverage_eligible)},
                                    {name: 'coverage_eligible', display: 'yes', value: true, selected: this.state.coverage_eligible}
                                ]}
                                error={missingFields.indexOf('coverage_eligible') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}
