import React, { Component } from 'react';

import { guid } from '../../../utils';

import Contact from './Contact';

export default class ContactInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props.formData.contact_information
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, () => this.props.onChange(this.state));
    }

    validate() {
        const {contacts} = this.state;
        // if (!contacts || contacts.length === 0) {
        //     missingFields.push('no_contacts');
        //     this.setState({missingFields});
        //     return false;
        // }
        // let foundCompanyContact = false;
        let childrenValidated = true;
        contacts.forEach((contact, index) => {
            childrenValidated &= this['contact_' + index].validate();
        });
        // if (!foundCompanyContact) {
        //     missingFields.push('no_company_contact');
        //     this.setState({missingFields});
        //     return false;
        // }
        return childrenValidated;
    }

    addContact() {
        let {contacts} = this.state;
        if (contacts.length < 4) {
            contacts.push({id: guid()});
            this.setState({missingFields: [], contacts});
        }
    }

    onContactChange(id, contact) {
        let {contacts} = this.state;
        contacts = contacts.filter(o => o.id !== id);
        contacts.push(contact);
        contacts = contacts.sort((a,b) => a.id - b.id);
        this.setState({contacts}, () => this.props.onChange(this.state));
    }

    removeItem(itemType, id) {
        let items = this.state[itemType];
        items = items.filter(i => i.id !== id);
        this.setState({[itemType]: items}, () => this.props.onChange(this.state));
    }

    render() {
        const {missingFields, contacts} = this.state;
        let contactsDiv = [];
        contacts.forEach((c, i) => {
            contactsDiv.push(
                <Contact ref={ref => this['contact_' + i] = ref} key={c.id} index={i+1} {...c} onChange={(contact) => this.onContactChange(c.id, contact)} remove={this.removeItem.bind(this, 'contacts', c.id)} />
            );
        });

        return (
            <div>
                <div className='main-text'>
                    <div className='name light'>Contact Information</div>
                    <div>Please provide details about key contacts at the company and finalize the application.</div>
                </div>
                <div className='owners'>
                    {missingFields.indexOf('no_contacts') > -1 &&
                        <div className='error'>Minimum one contact required</div>
                    }
                    {contactsDiv}
                    {contacts.length < 4 &&
                        <div onClick={this.addContact.bind(this)} className='link plus'>Add contact</div>
                    }
                </div>
            </div>
        );
    }
}
