import moment from 'moment';

import React, { Component } from 'react';

import { TextFieldContainer, RadioButtonQuestionContainer, DateSelectContainer } from '../../index';

export default class InactiveEmployee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props
        };
    }

    stateChangeComplete() {
        this.props.onChange(this.state);
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.stateChangeComplete);
    }

    render() {
        const {missingFields} = this.state;
        return (
            <div className='terminated-employee'>
                <div className='row'>
                    <TextFieldContainer
                        key='first_name'
                        label="First Name"
                        className='first_name'
                        ref='first_name'
                        type='text'
                        text={this.state.first_name}
                        error={missingFields.indexOf('first_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    <TextFieldContainer
                        key='last_name'
                        label="Last Name"
                        className='last_name'
                        ref='last_name'
                        type='text'
                        text={this.state.last_name}
                        error={missingFields.indexOf('last_name') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    {/*<TextFieldContainer
                        key='dob'
                        label="Date of Birth"
                        className='dob'
                        ref='dob'
                        type='text'
                        text={this.state.dob}
                        error={missingFields.indexOf('dob') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{date: true, datePattern: ['m', 'd', 'Y']}}
                    />*/}
                    <TextFieldContainer
                        key='ssn'
                        label="Social Security Number"
                        className='ssn'
                        ref='ssn'
                        type='text'
                        text={this.state.ssn}
                        error={missingFields.indexOf('ssn') > -1}
                        onChange={this.onChange.bind(this)}
                        formatOptions={{delimiter: '-', blocks: [3, 2, 4], numericOnly: true}}
                    />
                </div>
                <div className='row'>
                    <TextFieldContainer
                        key='inactive_reason'
                        label="Reason why employee is not actively working"
                        className='inactive_reason'
                        ref='inactive_reason'
                        type='text'
                        theme='full-width'
                        text={this.state.inactive_reason}
                        error={missingFields.indexOf('inactive_reason') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row'>
                    <DateSelectContainer
                        key='inactive_date'
                        className='inactive_date'
                        label={'Last day the employee worked'}
                        value={this.state.inactive_date ? moment(this.state.inactive_date) : null}
                        minDate={moment().subtract(3, 'months')}
                        startDate={moment().startOf('month')}
                        error={missingFields.indexOf('inactive_date') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='To best of your knowledge, will this employee return to work?'
                        options={[
                            {name: 'inactive_return', value: false, display: 'No', selected: (this.state.inactive_return !== undefined && !this.state.inactive_return)},
                            {name: 'inactive_return', value: true, display: 'Yes', selected: this.state.inactive_return}
                        ]}
                        error={missingFields.indexOf('inactive_return') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.inactive_return &&
                    <div className='row'>
                        <DateSelectContainer
                            key='inactive_return_date'
                            className='inactive_return_date'
                            label={'Expected return date'}
                            value={this.state.inactive_return_date ? moment(this.state.inactive_return_date) : null}
                            minDate={moment().subtract(3, 'months')}
                            startDate={moment().startOf('month')}
                            error={missingFields.indexOf('inactive_return_date') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='Is this employee presently disabled?'
                        options={[
                            {name: 'disabled', value: false, display: 'No', selected: (this.state.disabled !== undefined && !this.state.disabled)},
                            {name: 'disabled', value: true, display: 'Yes', selected: this.state.disabled}
                        ]}
                        error={missingFields.indexOf('disabled') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                {this.state.disabled &&
                    <div className='row'>
                        <TextFieldContainer
                            key='disabled_reason'
                            label="What's the disability?"
                            className='disabled_reason'
                            ref='disabled_reason'
                            type='text'
                            theme='full-width'
                            text={this.state.disabled_reason}
                            error={missingFields.indexOf('disabled_reason') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                }
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText='To best of your knowledge, will this employee exercise their Cal-COBRA/COBRA option?'
                        options={[
                            {name: 'exercise_cobra', value: false, display: 'No', selected: (this.state.exercise_cobra !== undefined && !this.state.exercise_cobra)},
                            {name: 'exercise_cobra', value: true, display: 'Yes', selected: this.state.exercise_cobra}
                        ]}
                        error={missingFields.indexOf('exercise_cobra') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>
        )
    }
}
