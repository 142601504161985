import {node, string, func, bool} from 'prop-types';
import React, {Component} from 'react';

import './Overlay.scss';

class Overlay extends Component {
    static propTypes = {
        className: string,
        children: node,
        onClick: func,
        overlay: bool,
        priority: bool
    }

    render() {
        const {className, priority} = this.props;
        let classes = ['overlay'];
        if (className) {
            classes.push(className);
        }
        if (priority) {
            classes.push('priority');
        }
        return (
            <div className={classes.join(' ')} onClick={this.props.onClick}>
                <div className='overlay__container'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Overlay;
