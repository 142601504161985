import {bool, func, node} from 'prop-types';
import React, {Component} from 'react';
import Overlay from './Overlay';

import './Modal.scss';

export class ModalBody extends Component {
    static propTypes = {
        prev: func,
        next: func,
        close: func,
        showClose: bool,
        children: node,
        setWrapperRef: func,
        small: bool
    }

    componentWillUnmount() {
        document.documentElement.classList.remove('modal-open');
        document.body.classList.remove('modal-open');
    }

    render() {
        let classes = ['modal'];
        const {small, prev, next, close, showClose, children, setWrapperRef} = this.props;

        if (small) {
            classes.push('small');
        }

        return <div ref={setWrapperRef} className={classes.join(' ')}>
            <div className={small ? 'modal__container small' : 'modal__container'}>
                <div className='modal__container__nav'>
                    {prev &&
                        <div className='modal__container__nav__prev' onClick={prev} />
                    }
                    {next &&
                        <div className='modal__container__nav__next' onClick={next} />
                    }
                    {showClose &&
                        <div className={small ? 'modal__container__nav__close small' : 'modal__container__nav__close'} onClick={close}>
                           <img src={require('../../../assets/images/ic-close.svg')} alt="close modal" />
                        </div>
                    }
                </div>
                <div className="detailContent">
                    {children}
                </div>
            </div>
        </div>;
    }
}

class Modal extends Component {
    static propTypes = {
        isOpen: bool,
        prev: func,
        next: func,
        close: func,
        children: node,
        small: bool,
        disableOutsideClick: bool,
        priority: bool
    }

    componentDidUpdate() {
        if (this.props.isOpen) {
            // document.documentElement.classList.add('modal-open');
            document.body.classList.add('modal-open');
        }
    }

    close() {
        // document.documentElement.classList.remove('modal-open');
        document.body.classList.remove('modal-open');
        this.props.close();
    }

    handleClickOutside(evt) {
        if (!this.props.disableOutsideClick && this.wrapperRef && !this.wrapperRef.contains(evt.target)) {
            this.close();
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {
        if (!this.props.isOpen) return null;

        const { small, prev, next, children, priority } = this.props;
        const showClose = this.props.close || false;
        return (
            <Overlay onClick={this.handleClickOutside.bind(this)} priority={priority}>
                <ModalBody
                    small={small}
                    prev={prev}
                    next={next}
                    showClose={showClose}
                    close={this.close.bind(this)}
                    setWrapperRef={this.setWrapperRef.bind(this)}
                >
                    {children}
                </ModalBody>
            </Overlay>
        );
    }
}

export default Modal;
