import { setMessage } from './app';
import { logout } from './user';
import {
    setApplicationId,
    setApplicationData,
} from './application';


export function getCarriers(onSuccess) {
    return (dispatch, getState, {auth}) => {
        auth.getCarriers()
            .then(data => dispatch({type: 'CARRIERS_STORE', payload: {carriers: data}}))
            .catch(console.error);
    }
}

export function getApplication(applicationId, onSuccess, onError) {
    return (dispatch, getState, { auth }) => {

        const state = getState();
        const { Application } = state;
        const id = applicationId ||Application.id;

        // if we have an ID _and_ the application data
        // associated with that ID in local Redux storage
        if (Application.id && Application.data) {
            onSuccess(Application.data);

        // if we have an ID, we can grab the application
        // data from the server. Also make sure we have
        // onSuccess & onError handlers to do use
        } else if (id && onSuccess && onError) {
            return auth.getApplication(id)
                .then(data => {
                    onSuccess(data);
                    dispatch(setApplicationId(applicationId));
                    dispatch(setApplicationData(data));
                })
                .catch(onError);
        }
    }
}

export function submitEmployerApplication(applicationId, payload, onSuccess, onError) {
    return (dispatch, getState, {auth}) => {
        auth.submitEmployerApplication(applicationId, payload)
            .then(data => onSuccess && onSuccess(data))
            .catch(err => onError && onError(err));
    }
}

export function submitEmployeeApplication(applicationId, payload, onSuccess, onError) {
    return (dispatch, getState, {auth}) => {
        const state = getState();
        const { Application } = state;
        const { formData } = Application;

        auth.submitEmployeeApplication(applicationId, formData)
            .then(data => onSuccess && onSuccess(data))
            .catch(err => onError && onError(err));
    }
}

export function createEmployerApplication(payload, onSuccess, onError) {
    return (dispatch, getState, {auth}) => {
        auth.createEmployerApplication(payload)
            .then(data => {
                dispatch(getTransactions());
                onSuccess && onSuccess();
            })
            .catch(errors => onError && onError())
   };
}

export function createEmployeeApplication(payload, onSuccess, onError) {
    return (dispatch, getState, {auth}) => {
        auth.createEmployeeApplication(payload)
            .then(data => {
                dispatch(getTransactions());
                onSuccess && onSuccess();
            })
            .catch(errors => onError && onError(errors))

    };
}

export function getTransactions(filter) {
    return (dispatch, getState, {auth}) => {
        auth.getTransactions(filter)
            .then(data => dispatch({type: 'TRANSACTIONS_STORE', payload: {transactions: data}}))
            .catch(errors => {
                if (errors[0] === 'Unauthorized. Please login again.') {
                    dispatch(logout(() => setMessage('Error', errors[0])));
                } else {
                    dispatch(setMessage('Error', errors[0]));
                }
            });
    };
}


export function getPlanDetailsDownload(planId, onSuccess) {
    return (dispatch, getState, {auth}) => {
        auth.getPlanDetailsDownload(planId).then(data => {
            if (data['url']) {
                window.open(data['url'], '_blank');
            }

            if (onSuccess) {
                onSuccess();
            }
        });
    };
}

export function getTransactionDownload(transactionId, onSuccess) {
    return (dispatch, getState, {auth}) => {
        auth.getTransactionDownload(transactionId).then(data => {
            if (data['url']) {
                window.open(data['url'], '_blank');
            }

            if (onSuccess) {
                onSuccess();
            }
        });
    };
}

export function updateTransaction(payload, onSuccess) {
    return (dispatch, getState, {auth}) => {
        // locally update transactions so the UI will show new state immediately
        const transactions = getState().Noyo.transactions.filter(tranx => tranx.id !== payload.id);
        transactions.push(payload);
        transactions.sort((a, b) => b.id - a.id);

        dispatch({
            type: 'TRANSACTIONS_STORE',
            payload: {transactions}
        });

        auth.updateTransaction(payload)
            .then(data => onSuccess && onSuccess())
            .catch(errors => alert(errors[0]));
    };
}
