// @flow

import flatten from 'flat';

import EventEmitter from 'events';
import {ClientError} from '../../utils';

import Config from '../../config';
import {STANDARD_HTTP_HEADERS} from './../../actions/app';

export class AuthError extends ClientError {
}

export class RefreshTokenError extends AuthError {
    constructor(message) {
        super('refresh_token_error', message);
    }
}

export default class AuthManager extends EventEmitter {
    fetch;

    constructor(fetch) {
        super();

        this.fetch = fetch;
    }

    onChange(fn) {
        this.addListener('change', fn);
    }

    handleError(resp) {
        if (resp instanceof Error) {
            const errors = ['Unable to complete submission. Please contact support.'];
            throw errors;
        }

        return resp.json().then(body => {
            if (body.errors) {
                const errors = Object.values(flatten(body.errors));
                throw errors;
            } else if (body.error) {
                const errors = [body.error];
                throw errors;
            } else if (body.message && resp.status === 422) {
                const errors = [body.message];
                throw errors;
            } else if (resp.status === 401) {
                const errors = ['Unauthorized. Please login again.'];
                throw errors;
            } else {
                const errors = ['Unable to complete submission. Please contact support.'];
                throw errors;
            }
        });
    }

    getWithRegistrationAuth(url) {
        const fetch = this.fetch;
        return fetch(url, {
            method: 'GET',
            headers: Object.assign({
                Authorization: `Bearer ${Config.REACT_APP_REGISTRATION_OAUTH_ACCESS_TOKEN}`
            }, STANDARD_HTTP_HEADERS)
        })
        .catch(this.handleError)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return this.handleError(resp);
            }
        });
   }

    postWithRegistrationAuth(url, data) {
        const fetch = this.fetch;

        const _data = {
            ...data,
            client_id: Config.REACT_APP_CLIENT_ID,
        };

        // check application/reducer
        delete _data.______________fix_this_hack__store_last_updated

        return fetch(url, {
            method: 'POST',
            headers: Object.assign({
                Authorization: `Bearer ${Config.REACT_APP_REGISTRATION_OAUTH_ACCESS_TOKEN}`
            }, STANDARD_HTTP_HEADERS),
            body: JSON.stringify(_data)
        })
        .catch(this.handleError)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return this.handleError(resp);
            }
        });
    }

    getWithUserAuth(url, optionalAuth = false) {
        const accessToken = localStorage.getItem('accessToken');
        let headers = Object.assign({}, {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });
        if (!accessToken) {
            if (!optionalAuth) {
                return Promise.resolve({error: 'no access token present'});
            }
        } else {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return fetch(url, {
            method: 'GET',
            headers: headers
        })
        .catch(this.handleError)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return this.handleError(resp);
            }
        });
    }

    postWithUserAuth(url, data, optionalAuth = false) {
        const accessToken = localStorage.getItem('accessToken');
        let headers = Object.assign({}, {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });
        if (!accessToken) {
            if (!optionalAuth) {
                return Promise.resolve({error: 'no access token present'});
            }
        } else {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
        .catch(this.handleError)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return this.handleError(resp);
            }
        });
    }

    getCarriers() {
        return this.getWithUserAuth(Config.REACT_APP_BASE_API_URL + '/carriers', true);
    }

    createEmployerApplication(data) {
        return this.postWithUserAuth(Config.REACT_APP_BASE_API_URL + '/applications/employer', data)
    }

    createEmployeeApplication(data) {
        return this.postWithUserAuth(Config.REACT_APP_BASE_API_URL + '/applications/employee', data);
    }

    getApplication(applicationId) {
        return this.getWithRegistrationAuth(Config.REACT_APP_BASE_API_URL + '/applications/' + applicationId);
    }

    getTransactions(filter) {
        let filterString = ""
        if(filter && (filter !== "")){
            filterString = "?filter=" + encodeURIComponent(filter);
        }
        return this.getWithUserAuth(Config.REACT_APP_BASE_API_URL + '/transactions' + filterString);
    }

    getPlanDetailsDownload(planId) {
        return this.getWithUserAuth(Config.REACT_APP_BASE_API_URL + '/plans/' + planId + '/download');
    }

    getTransactionDownload(transactionId) {
        return this.getWithUserAuth(Config.REACT_APP_BASE_API_URL + '/transactions/' + transactionId + '/download');
    }

    submitEmployerApplication(applicationId, data) {
        return this.postWithRegistrationAuth(Config.REACT_APP_BASE_API_URL + '/applications/employer/' + applicationId, data);
    }

    submitEmployeeApplication(applicationId, data) {
        return this.postWithRegistrationAuth(Config.REACT_APP_BASE_API_URL + '/applications/' + applicationId, data);
    }

    loginOrRegister(type, data) {
        return this.postWithRegistrationAuth(`${Config.REACT_APP_BASE_API_URL}/oauth/${type}`, data);
    }

    forgotPassword(data) {
        return this.postWithRegistrationAuth(Config.REACT_APP_BASE_API_URL + '/oauth/forgot_password', data);
    }

    resetPassword(data) {
        return this.postWithRegistrationAuth(Config.REACT_APP_BASE_API_URL + '/oauth/reset_password', data);
    }

    updateTransaction(data) {
        return this.postWithUserAuth(Config.REACT_APP_BASE_API_URL + '/transactions/' + data.id, data);
    }

    getCarrierText(data) {
        return this.getWithUserAuth(Config.REACT_APP_BASE_API_URL + '/carrier_texts?' + data);
    }
}
