import React, { Component } from 'react';

import { validateFields } from '../../utils';

import {TextFieldContainer, RadioButtonQuestionContainer} from '../index';

import './Application.scss';


class AdditionalInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingFields: [],
            ...props
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({...nextProps});
        }
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.props.onChange(key, value));
    }

    validate() {
        const requiredFields = [];

        const missingFields = validateFields(requiredFields, this.state);

        this.setState({missingFields});

        return missingFields.length === 0;
    }

    render() {
        const {
            disability,
            disability_description,
            disability_read_write,
            isDependent,
            student
        } = this.props;
        const {missingFields} = this.state;

        return (
            <div className='personal'>
                <div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText={isDependent ? 'Is this person disabled?' : 'Are you disabled?'}
                        options={[
                            {name: 'disability', display: 'no', value: false, selected: (disability !== undefined && !disability)},
                            {name: 'disability', display: 'yes', value: true, selected: disability}
                        ]}
                        error={missingFields.indexOf('disability') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                    {disability &&
                        <div>
                            <TextFieldContainer
                                key='disability_description'
                                label="Describe Disability"
                                className='disability_description'
                                ref='disability_description'
                                type='text'
                                text={disability_description}
                                onChange={this.onChange.bind(this)}
                            />
                            <RadioButtonQuestionContainer
                                questionText='Does disability impact ability to read or write?'
                                options={[
                                    {name: 'disability_read_write', display: 'no', value: false, selected: (disability_read_write !== undefined && !disability_read_write)},
                                    {name: 'disability_read_write', display: 'yes', value: true, selected: disability_read_write}
                                ]}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                </div>
                {/*<div className='row radio'>
                    <RadioButtonQuestionContainer
                        questionText={isDependent ? 'Is this person an American Indian?' : 'Are you an American Indian?'}
                        options={[
                            {name: 'american_indian', display: 'no', value: false, selected: (american_indian !== undefined && !american_indian)},
                            {name: 'american_indian', display: 'yes', value: true, selected: american_indian}
                        ]}
                        className='american_indian'
                        error={missingFields.indexOf('american_indian') > -1}
                        onChange={this.onChange.bind(this)}
                    />
                </div>*/}
                {isDependent &&
                    <div>
                        <div className='row radio'>
                            <RadioButtonQuestionContainer
                                questionText='Is this person a student? (post high school)'
                                options={[
                                    {name: 'student', display: 'no', value: false, selected: (student !== undefined && !student)},
                                    {name: 'student', display: 'yes', value: true, selected: student}
                                ]}
                                className='student'
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default AdditionalInformation;
