import React, { Component } from 'react';

import moment from 'moment';

import {ButtonContainer, StatusBar} from '../index';

import StepQLEOE from './StepQLEOE';
import StepPersonal from './StepPersonal';
import StepDependents from './StepDependents';
import StepVerify from './StepVerify';

import './Application.scss';

const STEPS = [
    null, // step 0
    StepQLEOE, // step 1
    StepPersonal, // step 2
    StepDependents, // step 3
    StepVerify // step 4
];
const TOTAL_STEPS = STEPS.length - 1;

const appWelcomeText = {
    'qualifying_life_event': 'If you have recently experience a Qualifying Life Event, you are eligible to make changes to your insurance coverage. A Qualifying Life Event is a significant change in your personal or family circumstance, such as a change in your family structure or move outside your coverage area. To request changes to your insurance coverage following Qualifying Life Event, please complete the following information.',
    'open_enrollment': 'You are eligible to make changes to your insurance coverage as part of your company\'s annual open enrollment period. Please complete the following information to make adjustments to your insurance coverage.'
}

const appTitle = {
    'qualifying_life_event': 'Noyo - Qualifying Life Event Application',
    'open_enrollment': 'Noyo - Open Enrollment Application',
}

class QLEApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            application: null,
            currentStep: 0,
            formData: {primary: {}},
            showSuccess: false,
            error: null,
            submitting: false
        }
    }

    componentWillMount() {
        const formType = this.props.application.carrier_form.form_type;
        document.title = appTitle[formType] || 'Noyo';
    }

    onChange(data) {
        let {formData, currentStep} = this.state;
        if (currentStep === 1) {
            formData.qle = data.qle;
            formData.dependents = data.dependents;
            formData.selectedPlans = data.selectedPlans;
            formData.currentPlans = data.currentPlans;
        } else if (currentStep === 2) {
            formData.primary = data;
        } else if (currentStep === 3) {
            formData.dependents = data;
        }

        this.setState({formData}, () => console.log(this.state));
    }

    back(step) {
        this.setState({currentStep: step}, window.scrollTo(0,0));
    }

    continue() {
        const {currentStep} = this.state;
        // validate current step before proceeding
        if (currentStep === 0 || this.currentStepComponent.validate()) {
            let nextStep = currentStep + 1;
            if (currentStep === 2) {
                const {formData} = this.state;
                if (!formData.dependents || Object.keys(formData.dependents).length === 0) {
                    nextStep += 1;
                }
            }
            this.setState({
                currentStep: nextStep,
                error: false
            }, window.scrollTo(0, 0));
        } else {
            this.setState({error: true});
        }
    }

    _getSignature() {
        let imageDataUrl = this.currentStepComponent.signaturePad.toDataURL();
        let imageData = imageDataUrl.replace('data:image/png;base64,','');
        return imageData;
    }

    submit() {
        let {application} = this.props;
        let {formData} = this.state;
        formData.signature = this._getSignature();

        this.setState(
            {submitting: true},
            this.props.submitEmployeeApplication(
                application.id, formData,
                this.showSuccess.bind(this),
                this.showError.bind(this)
            )
        );
    }

    showSuccess() {
        this.setState({showSuccess: true}, window.scrollTo(0,0));
    }

    showError() {
        this.props.setMessage('Error', 'An error occurred. Please try again.');
        this.setState({submitting: false}, window.scrollTo(0,0));
    }

    render() {
        const {application} = this.props;
        const {currentStep, formData, showSuccess, error, submitting} = this.state;

        let displayDiv;
        if (showSuccess) {
            displayDiv = (
                <div className='employee-application'>
                    <div className='header status'></div>
                    <div className='employee-application__success-container'>
                        <div className='employee-application__success-container__checkmark'>
                            <img alt="" src={require('../../assets/images/ic-green-check.svg')} height='48' width='48' />
                        </div>
                        <div className='employee-application__success-container__header'>Application Complete!</div>
                        <div className='employee-application__success-container__text'>
                            {"Thanks for submitting your application."}
                        </div>
                        <div className='employee-application__success-container__text'>Have a great day.</div>
                    </div>
                </div>
            );
        }
        else if (application) {
            const buttonText = currentStep === 0 ? 'get started' : currentStep < TOTAL_STEPS ? 'continue' : 'submit';
            const buttonOnChange = currentStep < TOTAL_STEPS ? this.continue.bind(this) : this.submit.bind(this);

            const formType = application.carrier_form.form_type;

            if (currentStep === 0) {
                displayDiv = (
                    <div className='employee-application termination'>
                        <div className='employee-application__container'>
                            <div className='main-text'>
                                <div className='name'>Hello {application.user.email}!</div>
                                <div>{appWelcomeText[formType]}</div>
                                {formType === 'open_enrollment' &&
                                    <div>Your open enrollment period will close on {moment(application.expiration_date).format('MM/DD/YYYY')}, so please make your changes before then.</div>
                                }
                                <div className='bold'></div>
                            </div>
                        </div>
                        <div className='employee-application__footer'>
                            <ButtonContainer text={buttonText} selected={true} theme='filled' onClick={buttonOnChange} />
                        </div>
                    </div>
                );
            } else {
                const CurrentStepComponent = STEPS[currentStep];
                displayDiv = (
                    <div className='employee-application termination'>
                        <div className='employee-application__container'>
                            <CurrentStepComponent ref={ref => this.currentStepComponent = ref} back={this.back.bind(this)} transaction_type={formType} currentStep={currentStep} application={application} formData={formData} onChange={this.onChange.bind(this)} />
                        </div>
                        <div className='employee-application__footer'>
                            {error &&
                                <div className='employee-application__footer__error'>Errors found above</div>
                            }
                            <ButtonContainer text={submitting ? 'submitting...' : buttonText} disabled={submitting} selected={true} theme='filled' onClick={buttonOnChange} />
                        </div>
                    </div>
                )
            }
        }

        return (
            <div>
                <StatusBar currentStep={currentStep} />
                {displayDiv}
            </div>
        );
    }
}

export default QLEApplication;
