import { connect } from 'react-redux';

import { setMessage } from '../../../actions/app';

import EmployerApplication from './EmployerApplication';

const mapStateToProps = ({ User, Noyo }) => {
    return {};
};

const mapDispatchToProps = {
    setMessage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployerApplication);
