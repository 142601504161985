import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import App from './app';
import User from './user';
import Noyo from './noyo';
import Application from './application';

// App ≠ Application
// App == Noyo App
// Application == the active application "form" being worked on
const applicationReducers = {
    App,
    User,
    Noyo,
    Application,
};

export default function createReducer() {
    return combineReducers({...applicationReducers, routing: routerReducer});
}
