import React, { Component } from 'react';
import T from 'prop-types';

import {ButtonContainer, TextFieldContainer} from '../../index';

export class ForgotPassword extends Component {
    static propTypes = {
        navigate: T.func,
        onClick: T.func
    }

    constructor (...args) {
        super(...args);

        this.state = {
            email: null,
            password: null,
            submitted: false
        };
    }

    componentWillUnmount() {
        this.setState({
            successMessage: null
        });
    }

    submit(event) {
        event.preventDefault();
        this.setState(
            {submitted: true},
            this.props.onClick(this.state, this.displaySuccess.bind(this))
        )
    }

    displayLogin(e) {
        e.preventDefault();
        this.props.navigate('login');
        return false;
    }

    displaySuccess(email) {
        this.setState({
            successMessage: 'Check your inbox! You will receive an email shortly to help you reset your password'
        });
    }

    onChange(className, text) {
        this.setState({[className]: text});
    }

    render () {
        return (
            <div className="login">
                <div className="signup-form content">
                    <div className='login-header'>Forgot Password</div>
                    {this.state.successMessage &&
                        <p className='signup-form__success-message'>{this.state.successMessage}</p>
                    }
                    <form ref='signup' className="form">
                        <div className="row">
                            <TextFieldContainer
                                key='email'
                                label="Email"
                                className='email'
                                ref='email'
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className="ctaRow">
                            <a href="/" className='forgot-password' onClick={this.displayLogin.bind(this)}>Cancel</a>
                            <ButtonContainer
                                text='Send'
                                disabled={this.state.submitted}
                                selected={true}
                                theme='filled'
                                onClick={this.submit.bind(this)}
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
