import moment from 'moment';

import React, { Component } from 'react';

import { TextFieldContainer, DateSelectContainer, RadioButtonQuestionContainer, Checkbox } from '../../index';

import '../Broker.scss';

export default class PriorCoverageOption extends Component {
    constructor(props) {
        super(props);

        const {line_of_coverage} = props;
        const maxPlans = line_of_coverage === 'medical' ? 3 : line_of_coverage === 'dental' ? 2 : line_of_coverage === 'vision' ? 1 : 0;
        this.state = {
            ...props,
            maxPlans,
            missingFields: []
        };
    }

    onChange(key, value) {
        this.setState({[key]: value}, this.stateChangeComplete);
    }

    close() {
        this.setState({open: false}, this.stateChangeComplete);
    }

    stateChangeComplete() {
        this.props.onChange(this.state, this.props.line_of_coverage);
    }

    validate() {
        return true;
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        }, this.stateChangeComplete);
    }

    getLineOfCoverageText() {
        const {line_of_coverage} = this.props;
        let line_of_coverage_text;
        switch(line_of_coverage) {
            case 'std':
                line_of_coverage_text = 'Short-Term Disability';
                break;
            case 'ltd':
                line_of_coverage_text = 'Long-Term Disability';
                break;
            default:
                line_of_coverage_text = line_of_coverage.charAt(0).toUpperCase() + line_of_coverage.slice(1)
        }
        return line_of_coverage_text;
    }

    render() {
        const {line_of_coverage} = this.props;
        const {open, missingFields} = this.state;

        const line_of_coverage_text = this.getLineOfCoverageText();
        let html = (
            <div className='link plus' onClick={this.toggleOpen.bind(this)}>
                add prior {line_of_coverage_text} coverage
            </div>
        );
        if (open) {
            let coverageImage = 'ic-box';
            switch (line_of_coverage) {
                case 'medical':
                    coverageImage = 'ic-medical';
                    break;
                case 'dental':
                    coverageImage = 'ic-dental';
                    break;
                case 'vision':
                    coverageImage = 'ic-vision';
                    break;
                default:
            }

            html = (
                <div>
                    <div className='line-of-coverage'>
                        <img alt="" src={require('../../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                        <span>{line_of_coverage_text}</span>
                        <img alt="" className='line-of-coverage__close' src={require('../../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this)} />
                    </div>
                    <div className='row'>
                        <RadioButtonQuestionContainer
                            questionText={'Has the company ever had ' + line_of_coverage + ' coverage with another insurance carrier?'}
                            options={[
                                {name: 'previous_coverage_with_other_carrier', display: 'no', value: false, selected: (this.state.previous_coverage_with_other_carrier !== undefined && !this.state.previous_coverage_with_other_carrier)},
                                {name: 'previous_coverage_with_other_carrier', display: 'yes', value: true, selected: this.state.previous_coverage_with_other_carrier}
                            ]}
                            error={missingFields.indexOf('previous_coverage_with_other_carrier') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    {this.state.previous_coverage_with_other_carrier &&
                        <div className='row'>
                            <DateSelectContainer
                                key='previous_coverage_with_other_carrier_end'
                                className='previous_coverage_with_other_carrier_end'
                                label='When did the coverage end?'
                                disabled={this.state.previous_coverage_with_other_carrier_ongoing}
                                value={this.state.previous_coverage_with_other_carrier_end ? moment(this.state.previous_coverage_with_other_carrier_end) : null}
                                minDate={moment().subtract(3, 'months')}
                                startDate={moment().startOf('month')}
                                error={missingFields.indexOf('previous_coverage_with_other_carrier_end') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                            <Checkbox
                                label='Coverage Ongoing'
                                checked={this.state.previous_coverage_with_other_carrier_ongoing}
                                checkboxId={'previous_coverage_with_other_carrier_ongoing'}
                                onClick={(event) => this.onChange('previous_coverage_with_other_carrier_ongoing', event)}
                            />
                        </div>
                    }
                    {this.state.previous_coverage_with_other_carrier_ongoing &&
                        <div className='row'>
                            <RadioButtonQuestionContainer
                                questionText={'Is this plan intended to replace existing ' + line_of_coverage + ' coverage?'}
                                options={[
                                    {name: 'previous_coverage_with_other_carrier_replace_existing', display: 'no', value: false, selected: (this.state.previous_coverage_with_other_carrier_replace_existing !== undefined && !this.state.previous_coverage_with_other_carrier_replace_existing)},
                                    {name: 'previous_coverage_with_other_carrier_replace_existing', display: 'yes', value: true, selected: this.state.previous_coverage_with_other_carrier_replace_existing}
                                ]}
                                error={missingFields.indexOf('previous_coverage_with_other_carrier_replace_existing') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                    {this.state.previous_coverage_with_other_carrier_replace_existing &&
                        <div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='existing_carrier_name'
                                    label="Existing Carrier Name"
                                    className='existing_carrier_name'
                                    ref='existing_carrier_name'
                                    theme='full-width'
                                    type='text'
                                    text={this.state.existing_carrier_name}
                                    error={missingFields.indexOf('existing_carrier_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row three'>
                                <DateSelectContainer
                                    key='existing_effective_date'
                                    className='existing_effective_date'
                                    label='Effective Date'
                                    value={this.state.existing_effective_date ? moment(this.state.existing_effective_date) : null}
                                    minDate={moment().subtract(3, 'months')}
                                    startDate={moment().startOf('month')}
                                    error={missingFields.indexOf('existing_effective_date') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                                <DateSelectContainer
                                    key='existing_end_date'
                                    className='existing_end_date'
                                    label='End Date'
                                    value={this.state.existing_end_date ? moment(this.state.existing_end_date) : null}
                                    minDate={moment().subtract(3, 'months')}
                                    startDate={moment().startOf('month')}
                                    error={missingFields.indexOf('existing_end_date') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                                <DateSelectContainer
                                    key='existing_renewal_date'
                                    className='existing_renewal_date'
                                    label='Renewal Date'
                                    value={this.state.existing_renewal_date ? moment(this.state.existing_renewal_date) : null}
                                    minDate={moment().subtract(3, 'months')}
                                    startDate={moment().startOf('month')}
                                    error={missingFields.indexOf('existing_renewal_date') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                        </div>
                    }
                    {(this.state.previous_coverage_with_other_carrier_replace_existing !== undefined && !this.state.previous_coverage_with_other_carrier_replace_existing) &&
                        <div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='concurrent_carrier_name'
                                    label="Concurrent Carrier Name"
                                    className='concurrent_carrier_name'
                                    ref='concurrent_carrier_name'
                                    theme='full-width'
                                    type='text'
                                    text={this.state.concurrent_carrier_name}
                                    error={missingFields.indexOf('concurrent_carrier_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row three'>
                                <TextFieldContainer
                                    key='concurrent_num_employees_covered'
                                    label="# of Employees Covered"
                                    className='concurrent_num_employees_covered'
                                    ref='concurrent_num_employees_covered'
                                    type='text'
                                    text={this.state.concurrent_num_employees_covered}
                                    error={missingFields.indexOf('concurrent_num_employees_covered') > -1}
                                    onChange={this.onChange.bind(this)}
                                    formatOptions={{numeral: true}}
                                />
                                <DateSelectContainer
                                    key='other_carrier_oe_start_date'
                                    className='other_carrier_oe_start_date'
                                    label='Other Carrier OE Start Date'
                                    value={this.state.other_carrier_oe_start_date ? moment(this.state.other_carrier_oe_start_date) : null}
                                    minDate={moment().subtract(3, 'months')}
                                    startDate={moment().startOf('month')}
                                    error={missingFields.indexOf('other_carrier_oe_start_date') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                                <DateSelectContainer
                                    key='other_carrier_oe_end_date'
                                    className='other_carrier_oe_end_date'
                                    label='Other Carrier OE End Date'
                                    value={this.state.other_carrier_oe_end_date ? moment(this.state.other_carrier_oe_end_date) : null}
                                    minDate={moment().subtract(3, 'months')}
                                    startDate={moment().startOf('month')}
                                    error={missingFields.indexOf('other_carrier_oe_end_date') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <RadioButtonQuestionContainer
                            questionText={'Has the company ever had ' + line_of_coverage + ' coverage with this carrier?'}
                            options={[
                                {name: 'previous_coverage_with_current_carrier', display: 'no', value: false, selected: (this.state.previous_coverage_with_current_carrier !== undefined && !this.state.previous_coverage_with_current_carrier)},
                                {name: 'previous_coverage_with_current_carrier', display: 'yes', value: true, selected: this.state.previous_coverage_with_current_carrier}
                            ]}
                            error={missingFields.indexOf('previous_coverage_with_current_carrier') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    {this.state.previous_coverage_with_current_carrier &&
                        <div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='previous_coverage_company_name'
                                    label="Company Name (if different than present)"
                                    className='previous_coverage_company_name'
                                    ref='previous_coverage_company_name'
                                    theme='full-width'
                                    type='text'
                                    text={this.state.previous_coverage_company_name}
                                    error={missingFields.indexOf('previous_coverage_company_name') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='previous_coverage_group_id'
                                    label="Group ID"
                                    className='previous_coverage_group_id'
                                    ref='previous_coverage_group_id'
                                    type='text'
                                    text={this.state.previous_coverage_group_id}
                                    error={missingFields.indexOf('previous_coverage_group_id') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                                <TextFieldContainer
                                    key='previous_coverage_group_number'
                                    label="Group Number"
                                    className='previous_coverage_group_number'
                                    ref='previous_coverage_group_number'
                                    type='text'
                                    text={this.state.previous_coverage_group_number}
                                    error={missingFields.indexOf('previous_coverage_group_number') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className='row'>
                                <DateSelectContainer
                                    key='previous_coverage_effective_date'
                                    className='previous_coverage_effective_date'
                                    label='Effective Date'
                                    value={this.state.previous_coverage_effective_date ? moment(this.state.previous_coverage_effective_date) : null}
                                    minDate={moment().subtract(3, 'months')}
                                    startDate={moment().startOf('month')}
                                    error={missingFields.indexOf('previous_coverage_effective_date') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                                <DateSelectContainer
                                    key='previous_coverage_end_date'
                                    className='previous_coverage_end_date'
                                    label='End Date'
                                    value={this.state.previous_coverage_end_date ? moment(this.state.previous_coverage_end_date) : null}
                                    minDate={moment().subtract(3, 'months')}
                                    startDate={moment().startOf('month')}
                                    error={missingFields.indexOf('previous_coverage_end_date') > -1}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                        </div>
                    }
                </div>
            );
        }

        return (
            <div className={'prior-coverage-option-employer ' + (open ? ' open' : '')}>
                {html}
            </div>
        );
    }
}
