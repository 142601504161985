import moment from 'moment';

import React, {
    Component,
} from 'react';

import {
    getLineOfCoverageImage,
    isTermination,
    validateFields
} from '../../utils';

import {
    Terms,
    TextFieldContainer
} from '../index';

import { connect } from 'react-redux';
import { get, bindAll } from 'lodash';
import {
    setApplicationMissingFields,
    setApplicationCurrentStep,
    setApplicationCompanyAdminInfo,
    setApplicationNoyoTerms,
    setApplicationCarrierTerms,
    getTermsofService,
} from '../../actions/application';

import Modal from '../shared/Modal/Modal';

import './Application.scss';

import Checkbox from '@material-ui/core/Checkbox';
import SignaturePad from 'signature_pad';


class StepVerify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            termsVisible: false,
            missingFields: [],
        }

        bindAll(this, [
            'onChange',
            'toggleTerms',
            'toggleNoyoTerms',
            'handleNoyoTermsCheckbox',
            'handleCarrierTermsCheckbox',
        ]);
    }

    componentDidMount() {
        this.signaturePad = new SignaturePad(this.refs.signatureCanvas);
        this.props.getTermsofService();
    }

    toggleTerms() {

        this.setState(() => {
            return {
                noyoTerms: false,
                termsVisible: !this.state.termsVisible
            };
        });
    }

    toggleNoyoTerms() {
        this.setState(() => {
            return {
                noyoTerms: true,
                termsVisible: !this.state.termsVisible
            };
        })
    }

    onChange(key, value) {
        this.props.setApplicationCompanyAdminInfo({
            [key]: value
        });
    }

    validate({ signature }) {

        const requiredFields = [
            'first_name',
            'last_name',
            'email',
            'noyo_terms_agreed',
        ];

        const {
            application,
            setApplicationMissingFields,
            companyAdmin,
            primary,
            noyo_terms_agreed,
            carrier_terms_agreed,
        } = this.props;

        const isTerminationApplication = isTermination(application);

        const person = isTerminationApplication ? companyAdmin : primary;

        const {
            first_name,
            last_name,
            title,
            email,
            work_phone,
        } = person;

        if (!isTerminationApplication) {
            requiredFields.push('carrier_terms_agreed');
        } else {
            requiredFields.push('title');
            requiredFields.push('work_phone');
        }

        if (signature) {
            requiredFields.push('signature');
        }

        const missingFields = validateFields(requiredFields, {
            first_name,
            last_name,
            title,
            work_phone,
            email,
            signature,
            noyo_terms_agreed,
            carrier_terms_agreed,
        });

        setApplicationMissingFields(missingFields);

        return missingFields.length === 0;
    }

    handleNoyoTermsCheckbox(event, checked) {
        this.props.setApplicationNoyoTerms(checked);
    }

    handleCarrierTermsCheckbox(event, checked) {
        this.props.setApplicationCarrierTerms(checked);
    }

    render() {

        const {
            application,
            primary,
            dependents,
            selectedPlans,
            termination_info,
            missingFields,
            companyAdmin,
            carrierTerms,
        } = this.props;

        const {
            first_name,
            last_name,
            title,
            email,
            work_phone,
        } = companyAdmin;

        const verifyDependentsDiv = Object.values(dependents).map((dependent, i) => {
            return (
                <ConnectedVerifyInformation
                    key={'verify-dependent-'+i}
                    application={application}
                    client={dependent}
                    selectedPlans={selectedPlans}
                    terminationInfo={termination_info}
                    setApplicationCurrentStep={setApplicationCurrentStep}
                />
            );
        });

        const contactString = isTermination(application) ? 'broker' : "company’s HR representative";
        const finalNote = `Note: All submissions are final. If you need to make changes after submitting, contact your ${contactString}.`;

        // TODO
        // pass these in to `<Terms />` to reduce the amount of work
        // that actions -> Application -> getTermsOfService has to do
        const transactionType = ''
        const effectiveDate = ''
        const carrierIds = ''
        const textType = ''

        return (
            <div className='step-verify'>
                <div className='main-text'>
                    <img alt="" src={require('../../assets/images/ic-edit.svg')} height='24' width='24' />
                    <div className='name light'>Review &amp; sign your application</div>
                    <div>You’re almost done! Confirm the information below is correct, add your signature, and then submit your application.</div>
                </div>
                <ConnectedVerifyInformation
                    key='verify-primary'
                    application={application}
                    client={primary}
                    selectedPlans={selectedPlans}
                    terminationInfo={termination_info}
                    isPrimary={true}
                    setApplicationCurrentStep={setApplicationCurrentStep}
                />
                {verifyDependentsDiv}
                <div className='signature-container'>
                    {isTermination(application) &&
                        <div>
                            <div>Enter Your Information:</div>
                            <div className='row margin-top'>
                                <TextFieldContainer
                                    key='first_name'
                                    label="First Name"
                                    className='first_name'
                                    ref='first_name'
                                    type='text'
                                    text={first_name}
                                    error={missingFields.indexOf('first_name') > -1}
                                    onChange={this.onChange}
                                />
                                <TextFieldContainer
                                    key='last_name'
                                    label="Last Name"
                                    className='last_name'
                                    ref='last_name'
                                    type='text'
                                    theme='margin-left'
                                    text={last_name}
                                    error={missingFields.indexOf('last_name') > -1}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='row'>
                                <TextFieldContainer
                                    key='work_phone'
                                    label="Work Phone"
                                    className='work_phone'
                                    ref='work_phone'
                                    type='text'
                                    text={work_phone}
                                    error={missingFields.indexOf('work_phone') > -1}
                                    onChange={this.onChange}
                                    formatOptions={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                                />
                                <TextFieldContainer
                                    key='email'
                                    label="Email"
                                    className='email'
                                    ref='email'
                                    type='text'
                                    theme='margin-left'
                                    text={email}
                                    error={missingFields.indexOf('email') > -1}
                                    onChange={this.onChange}
                                />
                                <TextFieldContainer
                                    key='title'
                                    label="Title"
                                    className='title'
                                    ref='title'
                                    type='text'
                                    theme='margin-left'
                                    text={title}
                                    error={missingFields.indexOf('title') > -1}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    }
                    <div>Draw your signature with your mouse</div>
                    <div className='signature'><canvas ref="signatureCanvas" width="360" height="120"></canvas></div>
                    <div>
                        {!isTermination(application) &&
                            carrierTerms &&
                            <div className="tos-checkbox-group" data-error={missingFields.indexOf('carrier_terms_agreed') > -1}>
                                <Checkbox
                                    checked={this.props.carrier_terms_agreed}
                                    onChange={this.handleCarrierTermsCheckbox}
                                    value={'true'}
                                />

                                <div>By signing, you agree to the <span className='terms' onClick={this.toggleTerms}>Carriers' Terms, Conditions, and Authorizations.</span></div>
                            </div>
                        }

                        <div className="tos-checkbox-group" data-error={missingFields.indexOf('noyo_terms_agreed') > -1}>
                            <Checkbox
                                checked={this.props.noyo_terms_agreed}
                                onChange={this.handleNoyoTermsCheckbox}
                                value={'true'}
                            />
                            <div>By signing, you agree to the <span className='terms' onClick={this.toggleNoyoTerms}>Terms of Use & Privacy Policy.</span></div>
                        </div>


                        <div style={{margin: '10px 0'}}>{finalNote}</div>
                    </div>
                </div>
                <Modal isOpen={this.state.termsVisible} close={this.toggleTerms}>
                    <div className='termsContent'>
                        <Terms
                            noyo={this.state.noyoTerms}
                            transactionType={transactionType}
                            effectiveDate={effectiveDate}
                            carrierIds={carrierIds}
                            textType={textType}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

const KEYS_TO_SKIP = [
    'email',
    'email_type',
    'home_address',
    'work_address',
    'selectedPlans',
    'properties',
];

const DATE_KEYS = [
    'dob',
    'hire_date',
    'full_time_start_date',
    'last_work_date',
    'last_coverage_date'
];

class VerifyInformation extends Component {

    constructor(props) {
        super(props);

        bindAll(this, [
            'personalInfoBack',
            'healthInfoBack',
        ]);
    }

    _getFormattedKey(key) {
        key = key.replace(/_/g, ' ');
        if (key === 'ssn') {
            key = key.toUpperCase();
        } else {
            key = key.charAt(0).toUpperCase() + key.slice(1);
        }
        return key;
    }

    personalInfoBack() {
        const {
            isPrimary,
            setApplicationCurrentStep,
        } = this.props;

        let backStep;
        const form_type = get(this.props, 'application.carrier_form.form_type');
        switch (form_type) {
            case 'qualifying_life_event':
            case 'open_enrollment':
                backStep = 2;
                break;
            default:
                if (isPrimary) backStep = 1;
                else backStep = 2;
        }

        setApplicationCurrentStep(backStep);
    }

    healthInfoBack() {
        let backStep;
        const {
            setApplicationCurrentStep,
        } = this.props;
        const form_type = get(this.props, 'application.carrier_form.form_type');
        switch (form_type) {
            case 'qualifying_life_event':
            case 'open_enrollment':
                backStep = 1;
                break;
            case 'termination':
                backStep = 2;
                break;
            default:
                backStep = 0;
        }

        setApplicationCurrentStep(backStep);
    }

    render() {
        const {
            client,
            selectedPlans,

            // TODO - add terminationInfo to Redux
            terminationInfo = {},
            isPrimary
        } = this.props;

        const memberType = isPrimary ? 'Primary member' : 'Dependent';

        const personalInfo = [];
        if (client.email) {
            personalInfo.push(<li key={'email'}>{this._getFormattedKey('email')}: {client.email}</li>);
        }
        if (client.email_type) {
            personalInfo.push(<li key={'email-type'}>{this._getFormattedKey('email_type')}: {client.email_type}</li>);
        }

        Object.keys(client).forEach((key, index) => {
            if (KEYS_TO_SKIP.indexOf(key) === -1) {
                let value = client[key];
                if (DATE_KEYS.indexOf(key) > -1) {
                    value = moment(value).format('MM/DD/YYYY');
                } else if (typeof(value) === 'boolean') {
                    value = value ? 'Yes' : 'No';
                }
                personalInfo.push(<li key={key + '-' + index}>{this._getFormattedKey(key)}: {value}</li>);
            }
        });

        let dependentSelectedPlans;
        if (!isPrimary) {
            dependentSelectedPlans = client.selectedPlans;
        }

        let healthInfo = [];
        Object.keys(selectedPlans).forEach(key => {
            if (isPrimary || (dependentSelectedPlans && dependentSelectedPlans.indexOf(key) > -1)) {
                const image = getLineOfCoverageImage(key);
                const imageUrl = (<img alt="" src={require('../../assets/images/' + image + '.svg')} height='24' width='24' />);
                healthInfo.push(<div className='health-info'>{imageUrl} <span>{selectedPlans[key].display}</span></div>);
            }
        });

        if (Object.keys(terminationInfo).length > 0) {
            const terminationOptions = terminationInfo.termination_options;
            terminationOptions.forEach(option => {
                const { line_of_coverage } = option;
                const isLifeOrDisability = ['life', 'std', 'ltd'].indexOf(line_of_coverage) > -1;
                const image = getLineOfCoverageImage(line_of_coverage);
                const imageUrl = (<img alt="" src={require('../../assets/images/' + image + '.svg')} height='24' width='24' />);
                healthInfo.push(
                    <div className='health-info termination padding-bottom'>
                        <div className='line-of-coverage'>
                            {imageUrl}
                            <span>{line_of_coverage}</span>
                        </div>
                        <div>
                            <div>Group ID: {option.group_id}</div>
                            <div>Carrier: {option.carrier_name}</div>
                            <div>State: {option.carrier_state}</div>
                            {isLifeOrDisability &&
                                <div>
                                    <div>Basic Member ID: {option.basic_member_id || 'N/A'}</div>
                                    <div>Voluntary Member ID: {option.voluntary_member_id || 'N/A'}</div>
                                </div>
                            }
                            {!isLifeOrDisability &&
                                <div>Member ID: {option.member_id || 'N/A'}</div>
                            }
                        </div>
                    </div>);
            });

            // Object.keys(terminationInfo).forEach((key, index) => {
            //     let formattedKey = null;
            //     switch(key) {
            //         case 'cobra_employee':
            //             formattedKey = 'COBRA Option for Employees';
            //             break;
            //         case 'cobra_dependent':
            //             formattedKey = 'COBRA Option for Dependents';
            //             break;
            //         case 'cobra_eligible_reason':
            //             formattedKey = 'Why employee is eligible for COBRA'
            //             break;
            //         case 'cobra_enroll':
            //             formattedKey = 'Employee enrolling in COBRA'
            //             break;
            //     }
            //     if (formattedKey) {
            //         let value = terminationInfo[key];
            //         if (typeof(value) === 'boolean') {
            //             value = value ? 'Yes' : 'No';
            //         }
            //         healthInfo.push(<li key={key + '-' + index}>{formattedKey}: {value}</li>);
            //     }
            // });
            // const {cobra_duration_time, cobra_duration_units} = terminationInfo;
            // if (cobra_duration_time && cobra_duration_units) {
            //     healthInfo.push(<li key={'cobra-duration'}>{'COBRA will be offered for'}: {cobra_duration_time} {cobra_duration_units}</li>);
            // }
        }


        return (
            <div className='step-verify__container'>
                <div className='verify-header'>
                    <div>{client.first_name} {client.last_name}</div>
                    <div className='verify-header__subheading'>{memberType}</div>
                </div>
                <div className='verify-information'>
                    <div className='verify-information__personal'>
                        <div className='verify-information__header'>
                            <span>Personal info </span>
                            <span className='link lower' onClick={this.personalInfoBack.bind(this)}>(edit)</span>
                        </div>
                        <ul className='bullet-list'>{personalInfo}</ul>
                    </div>
                    <div className='verify-information__health'>
                        <div className='verify-information__header'>
                            <span>Health coverage info </span>
                            <span className='link lower' onClick={this.healthInfoBack.bind(this)}>(edit)</span>
                        </div>
                        {healthInfo}
                    </div>
                </div>
            </div>
        );
    }
}

const mstpVerifyInfo = () => {
    return {};
}

const mdtpVerifyInfo = {
    setApplicationCurrentStep,
};

const ConnectedVerifyInformation = connect(
    mstpVerifyInfo,
    mdtpVerifyInfo,
)(VerifyInformation);

const mapStateToProps = ({ Application }) => {

    const {
        data,
        formData,
        carrierTerms,
    } = Application;

    const {
        company,
        dependents,
        primary,
        selectedPlans,
        termination_info,
        missingFields,
        noyo_terms_agreed,
        carrier_terms_agreed,
    } = formData;

    const companyAdmin = company.admin;

    return {
        application: data,
        company,
        primary,
        selectedPlans,
        termination_info,
        dependents,
        missingFields,
        carrierTerms,

        // admin contact info
        companyAdmin,
        noyo_terms_agreed,
        carrier_terms_agreed,
    };
}

const mapDispatchToProps = {
    setApplicationCurrentStep,
    setApplicationCompanyAdminInfo,
    setApplicationMissingFields,
    setApplicationNoyoTerms,
    setApplicationCarrierTerms,
    getTermsofService,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(StepVerify);
