import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import TerminationOption from './TerminationOption';

const mapStateToProps = ({User, Noyo}) => {
    return {
        currentUser: User.currentUser,
        carriers: Noyo.carriers
    }
};

const mapDispatchToProps = {};

const ConnectedTerminationOptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
        forwardRef: true,
    },
)(TerminationOption);

export default forwardRef((props, ref) => {
    return <ConnectedTerminationOptionContainer {...props} ref={ref} />
})
