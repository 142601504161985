import React, { Component } from 'react';

import { connect } from 'react-redux';

import Config from '../../config';

import AdditionalInformation from './AdditionalInformation';
import PersonalInformation from './PersonalInformation';
import EmploymentInformation from './EmploymentInformation';
import CoverageInformation from './CoverageInformation';

import moment from 'moment';
import { get, bindAll } from 'lodash';

import { isTermination } from '../../utils';

import {
    setApplicationFormData,
} from '../../actions/application';

import './Application.scss';


const PREFILL_DATA = {
  "email": "test@test.com",
  "first_name": "Test",
  "last_name": "Name",
  "ssn": "328-92-3892",
  "dob": "11/09/1973",
  "sex": "M",
  "home_address": {
    "addressString": "123 Fake Hollow Rd, York, PA 17406, USA",
    "city": "York",
    "county": "York County",
    "state": "PA",
    "zip_code": "17406",
    "street": "123 Fake Hollow Road",
    "apartment": "426"
  },
  "home_phone": "678-678-6789",
  "email_type": "work",
  "hire_date": "09/08/2010",
  "occupation": "Software Engineer",
  "employment_status": "full-time",
  "hours_worked": "45",
  "termination_reason": "involuntary",
  "last_work_date": "2019-07-15",
  "last_coverage_date": "2019-08-01",
  "marital_status": "single",
  "work_address": {
    "addressString": "123 7th Ave, New York, NY 10011, USA",
    "city": "New York",
    "county": "New York County",
    "state": "NY",
    "zip_code": "10011",
    "street": "123 7th Avenue"
  },
  "preferred_language": "english",
  "disability": false,
  "union_member": false,
  "income": "100000",
  "income_type": "salary",
  "income_unit": "month"
};


class StepPersonal extends Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'prefill',
        ]);
    }

    prefill() {
        this.props.setApplicationFormData({ primary: PREFILL_DATA});
    }

    onChange(key, value) {

        const {
            setApplicationFormData,
            formData
        } = this.props;

        const primary = Object.assign({}, formData.primary)

        primary[key] = value;

        if (value instanceof moment){
            primary[key] = value.format();
        }

        setApplicationFormData({ primary });
    }

    validate() {

        let validated = true;

        // these are refs
        const {
            personalInformation,
            additionalInformation,
            employmentInformation,
            coverageInformation,
        } = this;

        if (personalInformation)   validated &= personalInformation.validate(!isTermination(this.props.application));
        if (additionalInformation) validated &= additionalInformation.validate();
        if (employmentInformation) validated &= employmentInformation.validate();
        if (coverageInformation)   validated &= coverageInformation.validate();

        return validated;
    }

    render() {
        const {
            // from redux
            application,
            formData,

            // from parent,
            // though this is within `Application` store,
            // so we _could_ get it from Redux too, later
            transaction_type,
        } = this.props;

        const {
            selectedPlans = {},
            primary,
        } = formData;

        let header = 'Enter your personal information';
        if (isTermination(application)) {
            header = "Enter employee's information";
        }

        let description = 'To apply for your insurance coverage, please complete the following questions.';
        if (isTermination(application)) {
            description = "To request the removal of a member from " + application.company.name + "'s insurance, please complete the following information about the employee who has been terminated and needs to be removed from coverage."
        }

        let showNHECoverage = false;
        if (selectedPlans.medical && selectedPlans.medical.display.toLowerCase().indexOf('hmo') > -1) {
          showNHECoverage = true;
        }
        return (
            <div className='step-personal'>
                <div className='main-text'>
                    <img alt="" src={require('../../assets/images/ic-person.svg')} height='24' width='24' />
                    <div className='name light'>{header}</div>
                    <div>{description}</div>
                    {Config.REACT_APP_DEV_MODE &&
                        <div className='link' style={{textAlign: 'left'}} onClick={this.prefill}>PREFILL INFO</div>
                    }
                </div>
                <div className='personal-container'>
                    <PersonalInformation ref={ref => this.personalInformation = ref} {...primary} application={application} onChange={this.onChange.bind(this)} />
                    {!isTermination(application) &&
                        <AdditionalInformation ref={ref => this.additionalInformation = ref} {...primary} onChange={this.onChange.bind(this)} />
                    }
                </div>
                <div className='main-text border-top'>
                    <img alt="" src={require('../../assets/images/ic-vision.svg')} height='24' width='24' />
                    <div className='name light'>Employment Information</div>
                </div>
                <EmploymentInformation ref={ref => this.employmentInformation = ref} {...primary} application={application} onChange={this.onChange.bind(this)} />
                {(showNHECoverage || transaction_type === 'qualifying_life_event' || transaction_type === 'open_enrollment') &&
                    <div>
                        <div className='main-text border-top'>
                            <img alt="" src={require('../../assets/images/ic-vision.svg')} height='24' width='24' />
                            <div className='name light'>Coverage Information</div>
                        </div>
                        <CoverageInformation ref={ref => this.coverageInformation = ref} {...primary} formData={formData} application={application} onChange={this.onChange.bind(this)} />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ Application }) => {
    const {
        formData,
        data,
    } = Application;

    if (!get(formData, 'primary.email') && !isTermination(data)) {
        formData.primary.email = data.user.email;
    }

    return {
        application: data,
        formData,
    }
}

const mapDispatchToProps = {
    setApplicationFormData,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true},
)(StepPersonal);
