import React, { Component } from 'react';

import { ALL_STATES } from '../../utils';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import {
    setApplicationMissingFields,
    setApplicationTerminationOptions,
} from '../../actions/application';

import { validateFields } from '../../utils';
import { shouldDisplayCarriers } from '../../utils';

import { TextFieldContainer, DropdownContainer } from '../index';

const propTypes = {
    missingFields: PropTypes.array,
    line_of_coverage: PropTypes.string,
    termination_options: PropTypes.array,
};

const defaultProps = {
    missingFields: [],
    line_of_coverage: '',
    termination_options: [],
};

class TerminationOption extends Component {
    constructor(props) {
        super(props);

        const {
            isLifeOrDisability = ['life', 'std', 'ltd'].indexOf(this.props.line_of_coverage) > -1
        } = props;

        this.state = {
            open: false,
            isLifeOrDisability,
            selected_carrier_state: null
        };
    }

    onChange(key, value) {
        if (key === 'carrier_state') {
            this.setState({
                selected_carrier_state: value
            });
        }
        this.stateChangeComplete(key, value);
    }

    close() {
        this.setState({
            open: false
        }, this.stateChangeComplete);
    }

    componentWillMount() {
        const currentOption = this.getOptionFromProps()

        const rest = {};
        if (currentOption) {
            rest.carrier_id = currentOption.carrier_id;
            rest.carrier_state = currentOption.carrier_state;
            rest.selected_carrier_state = currentOption.carrier_state;
            rest.group_id = currentOption.group_id;
            rest.member_id = currentOption.member_id;
            rest.open = true;
        }

        this.setState(() => {
            return {
                ...rest,
            }
        });
    }

    stateChangeComplete(key, value) {
        const {
            line_of_coverage,
            termination_options,
            setApplicationTerminationOptions,
        } = this.props;

        const newValues = {
            line_of_coverage,
        };

        let oldValues = {};

        if (key === 'carrier_id') {
            newValues.carrier_name = this.props.carriers.filter(c => c.id === value)[0].name;
            newValues.carrier_id = value;
        } else {
            newValues[key] = value;
        }

        // grab all selected termination options
        // and then filter out the "current" one
        // so we can re-push it into the array with new values

        // if the field isn't "open", then "remove" the current LOC by simply not re-pushing it into the filtered array
        // not super efficient, but it works...
        const options = termination_options.filter((option) => {
            if (option.line_of_coverage === this.props.line_of_coverage) {
                oldValues = option;
            }
            return option.line_of_coverage !== this.props.line_of_coverage;
        });

        if (this.state.open) {
            options.push({
                ...oldValues,
                ...newValues,
            });
        }

        setApplicationTerminationOptions(options);
    }

    getOptionFromProps() {
        return this.props.termination_options.filter(option => {
            return option.line_of_coverage === this.props.line_of_coverage;
        })[0];
    }

    validate() {
        // TODO find a more efficient way to handle validting each option
        // Ideally, each termination option would be able to track it's own state
        // in Redux, instead of lumping everything into
        // `formData.terminationInfo.terminatiojn_options`
        const currentOption = this.getOptionFromProps()

        const requiredFields = [
            'carrier_id',
            'carrier_state',
            'group_id'
        ];

        const {
            carrier_id,
            carrier_state,
            group_id,
        } = currentOption;

        const missingFields = validateFields(requiredFields, {
            carrier_id,
            carrier_state,
            group_id,
        });

        this.props.setApplicationMissingFields(missingFields);

        return missingFields.length === 0;
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        }, this.stateChangeComplete);
    }

    getLineOfCoverageText() {
        const { line_of_coverage } = this.props;

        let line_of_coverage_text;
        switch(line_of_coverage) {
            case 'std':
                line_of_coverage_text = 'Short-Term Disability';
                break;
            case 'ltd':
                line_of_coverage_text = 'Long-Term Disability';
                break;
            default:
                line_of_coverage_text = line_of_coverage.charAt(0).toUpperCase() + line_of_coverage.slice(1)
        }
        return line_of_coverage_text;
    }

    filterCarriersList(carriers) {
        const {line_of_coverage} = this.props;
        const {selected_carrier_state} = this.state;
        return shouldDisplayCarriers(carriers, selected_carrier_state, line_of_coverage);
    }

    carrierStateOptions(selected_carrier_state) {
        return ALL_STATES.map(stateData => {
            return {display: stateData.name + ' (' + stateData.id + ')', value: stateData.id, selected: selected_carrier_state === stateData.id};
        })
    }

    carrierOptions(selected_carrier_id) {
        return this.filterCarriersList(this.props.carriers).map(carrier => {
            return {display: carrier.name, value: carrier.id, selected: selected_carrier_id === carrier.id};
        })
    }

    render() {
        const {
            line_of_coverage,
            missingFields,
        } = this.props;

        const {
            open,

            // NOTE:
            // this is kinda hacky,
            // because ideally we'd be tracking these in Redux (and we are)
            // however, because of how deeply nested things are (for now)
            // it's most expedient to componentDidMount map props to local state
            carrier_id,
            carrier_state,
            group_id,
            member_id,

            // NOTE:
            // these next 3 props aren't being used in the context of ny-103
            basic_member_id,
            voluntary_member_id,
            isLifeOrDisability
        } = this.state;

        const line_of_coverage_text = this.getLineOfCoverageText();
        let html = (
            <div className='link plus' onClick={this.toggleOpen.bind(this)}>
                {line_of_coverage_text} coverage
            </div>
        );
        let carrierStateOptions = this.carrierStateOptions(carrier_state)
        let carrierOptions = this.carrierOptions(carrier_id)
        if (open) {
            let coverageImage = 'ic-box';
            switch (line_of_coverage) {
                case 'medical':
                    coverageImage = 'ic-medical';
                    break;
                case 'dental':
                    coverageImage = 'ic-dental';
                    break;
                case 'vision':
                    coverageImage = 'ic-vision';
                    break;
                default:
                    coverageImage = 'ic-medical';
            }

            html = (
                <div>
                    <div className='line-of-coverage'>
                        <img alt="line of coverage" src={require('../../assets/images/' + coverageImage + '.svg')} height='24' width='24' />
                        <span>{line_of_coverage_text}</span>
                        <img alt="close" className='line-of-coverage__close' src={require('../../assets/images/ic-close.svg')} height='12' width='12' onClick={this.close.bind(this)} />
                    </div>
                    <div className='coverage-details'>
                        <DropdownContainer
                            className='carrier_state'
                            label='State'
                            options={carrierStateOptions}
                            error={missingFields.indexOf('carrier_state') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        <DropdownContainer
                            className='carrier_id'
                            label='Carrier'
                            options={carrierOptions}
                            error={missingFields.indexOf('carrier_id') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className='row'>
                        <TextFieldContainer
                            key='group_id'
                            label='Group ID'
                            className='group_id'
                            ref='group_id'
                            type='text'
                            text={group_id}
                            debounce={true}
                            debounceTimeout={500}
                            error={missingFields.indexOf('group_id') > -1}
                            onChange={this.onChange.bind(this)}
                        />
                        {!isLifeOrDisability &&
                            <TextFieldContainer
                                key='member_id'
                                label='Member ID'
                                className='member_id'
                                ref='member_id'
                                type='text'
                                theme='margin-left'
                                text={member_id}
                                debounce={true}
                                debounceTimeout={500}
                                error={missingFields.indexOf('member_id') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        }
                    </div>
                    {isLifeOrDisability &&
                        <div className='row'>
                            <TextFieldContainer
                                key='basic_member_id'
                                label='Basic Member ID'
                                className='basic_member_id'
                                ref='basic_member_id'
                                type='text'
                                text={basic_member_id}
                                debounce={true}
                                debounceTimeout={500}
                                error={missingFields.indexOf('basic_member_id') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextFieldContainer
                                key='voluntary_member_id'
                                label='Voluntary Member ID'
                                className='voluntary_member_id'
                                ref='voluntary_member_id'
                                type='text'
                                theme='margin-left'
                                text={voluntary_member_id}
                                debounce={true}
                                debounceTimeout={500}
                                error={missingFields.indexOf('voluntary_member_id') > -1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    }
                </div>
            );
        }

        return (
            <div className='coverage-option'>
                {html}
            </div>
        );
    }
}

TerminationOption.propTypes = propTypes;
TerminationOption.defaultProps = defaultProps;

const mapStateToProps = ({ User, Noyo, Application }) => {

    const {
        formData,
    } = Application;

    const {
        missingFields,
        termination_info,
    } = formData;

    const {
        termination_options,
    } = termination_info;

    return {
        missingFields,
        termination_options,
        carriers: Noyo.carriers,
    }
}

const mapDispatchToProps = {
    setApplicationMissingFields,
    setApplicationTerminationOptions,
}

const ConnectedTerminationOption = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(TerminationOption);

export default ConnectedTerminationOption;
