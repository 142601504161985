import React, { Component } from 'react';

import {MDCFormField} from '@material/form-field/dist/mdc.formField';
import {MDCCheckbox} from '@material/checkbox/dist/mdc.checkbox';

import './Checkbox.scss';

export default class Checkbox extends Component {
    componentDidMount() {
        const checkbox = new MDCCheckbox(this.refs.checkbox);
        const formField = new MDCFormField(this.refs.formField);
        formField.input = checkbox;

        const {checked, disabled} = this.props;

        if (checked) {
            checkbox.checked = true;
        }

        if (disabled) {
            checkbox.disabled = true;
        } else {
            checkbox.listen('click', () => {
                if (this.props.onClick) { this.props.onClick(checkbox.checked); }
            });
        }
    }

    render() {
        const {disabled, theme, className, label = '', showLabelOnHover} = this.props;

        let classes = 'checkbox';
        if (disabled) {
            classes += ' disabled';
        }
        if (theme) {
            classes += ' ' + theme;
        }
        if (className) {
            classes += ' ' + className;
        }
        if (showLabelOnHover) {
            classes += ' hoverOnly';
        }

        let labelClass = '';
        if (!label) {
            labelClass = 'hide';
        }

        return (
            <div className={classes}>
                <div className="mdc-form-field" ref="formField">
                    <div className={"mdc-checkbox"} ref="checkbox">
                        <input type="checkbox"
                               className="mdc-checkbox__native-control"
                               id={'checkbox-' + this.props.checkboxId}
                        />
                        <div className="mdc-checkbox__background">
                            <svg className="mdc-checkbox__checkmark"
                                 viewBox="0 0 24 24">
                                <path
                                    className="mdc-checkbox__checkmark-path"
                                    fill="none"
                                    d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                />
                            </svg>
                            <div className="mdc-checkbox__mixedmark"></div>
                        </div>
                    </div>
                    <label className={labelClass} htmlFor={'checkbox-' + this.props.checkboxId}>{label}</label>
                </div>
            </div>
        );
    }
}
